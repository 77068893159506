import React, { ReactElement, useEffect, useRef, useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download'

import './styles.scss'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'

import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined'

import pdfImage from 'img/pdf.png'
import {
  useDeleteSocialContractMutation,
  useGetSimpleSocialContractsQuery,
  usePostSocialContractMutation,
} from 'store/NodeApi/socialContract/endpoint'
import DeleteIcon from '@mui/icons-material/Delete'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { DialogConfig } from 'domain/models/dialog'
import { defaultDialogState } from 'data/utils/Dialog/static'
import MaterialDialog from '../MaterialDialog'
import { setSocialContracts } from 'store/NodeApi/slice'
import { useDispatch } from 'react-redux'

const SocialContract: React.FC = () => {
  const [dialogConfig, setDialogConfig] =
    useState<DialogConfig>(defaultDialogState)
  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false)
  const [contracts, setContracts] = useState<any>([])

  const [selectedContract, setSelectedContract] = useState<any>({})

  const { errorSnackbar, successSnackbar } = useCustomSnackbar()

  const inputFileRef: any = useRef(null)

  const { data: simpleContracts } = useGetSimpleSocialContractsQuery('')
  const [deleteContract] = useDeleteSocialContractMutation()
  const [postSocialContract] = usePostSocialContractMutation()

  const dispatch = useDispatch()

  useEffect(() => {
    if (!simpleContracts?.length) return

    const contracts = simpleContracts.map((item) => ({
      ...item,
      filename: decodeURIComponent(escape(item.filename)),
    }))

    setContracts(contracts)
  }, [simpleContracts])

  useEffect(() => {
    dispatch(setSocialContracts(contracts))
  }, [contracts])

  const handleOpen = () => setOpenSpeedDial(true)
  const handleClose = () => setOpenSpeedDial(false)

  const handleAddContractAction = () => {
    if (!inputFileRef || !inputFileRef.current) return
    inputFileRef.current.value = ''
    inputFileRef?.current?.click()
  }

  const handleFileSelected = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile: File | undefined = event?.target?.files?.[0]
    if (!selectedFile) return

    const contract = new FormData()
    contract.append('contract', selectedFile)

    try {
      const newSocialContract = await postSocialContract(contract).unwrap()
      setContracts((contracts) => [
        ...contracts,
        {
          filename: decodeURIComponent(
            escape(newSocialContract?.data?.filename)
          ),
          s3key: newSocialContract?.s3key,
        },
      ])
    } catch (error) {
      errorSnackbar('Erro ao adicionar Contrato Social')
    }
  }

  const getDialActions = (): {
    icon: ReactElement
    name: string
    onClick: any
  }[] => {
    const newContractBody = {
      icon: <DocumentScannerOutlinedIcon />,
      name: 'Adicionar contrato',
      onClick: handleAddContractAction,
    }

    const selectedBody = [newContractBody]

    return selectedBody
  }

  const handleDeleteContract = (selectedContract) => {
    deleteContract(selectedContract?.s3key)
      .unwrap()
      .then((result) => {
        const filteredContracts = contracts.filter(
          (contract) => contract?.s3key !== selectedContract?.s3key
        )

        setContracts(filteredContracts)

        successSnackbar('Contrato removido com sucesso')
      })
      .catch((err) => {
        errorSnackbar('Erro ao remover contrato')
      })

    handleCloseDialog()
  }

  const handleRemoveAction = (contract: any) => {
    setSelectedContract(contract)

    setDialogConfig({
      open: true,
      onConfirm: () => handleDeleteContract(contract),
      dialogBodyText: 'Deseja remover o contrato?',
    })
  }

  const handleCloseDialog = () => {
    setDialogConfig({ ...dialogConfig, open: false })
  }

  const dialogProps = {
    open: dialogConfig?.open,
    onClose: handleCloseDialog,
    onConfirm: dialogConfig?.onConfirm,
    dialogBodyText: dialogConfig?.dialogBodyText,
  }

  return (
    <>
      <MaterialDialog {...dialogProps} />
      <div className="contract__container">
        <div>
          <SpeedDial
            direction="down"
            onOpen={handleOpen}
            open={openSpeedDial}
            onClose={handleClose}
            icon={<SpeedDialIcon />}
            className="speed-dial-container"
            ariaLabel="SpeedDial tooltip example"
          >
            {getDialActions().map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                onClick={action.onClick}
                tooltipPlacement="right"
                tooltipTitle={action.name}
              />
            ))}
          </SpeedDial>
        </div>
        {contracts?.length === 0 && <div>Nenhum contrato encontrado!</div>}
        {contracts?.map((contract) => (
          <article
            key={contract?.filename}
            className="contract"
            style={{ position: 'relative' }}
          >
            <img src={pdfImage} alt="" />
            <div className="contract__name">{contract?.filename}</div>
            <div
              className="contract__remove"
              onClick={() => handleRemoveAction(contract)}
            >
              <DeleteIcon
                fontSize="large"
                style={{ color: 'rgb(255, 75, 82)' }}
              />
              <span>Remover esse contrato?</span>
            </div>
          </article>
        ))}
        <input
          type="file"
          accept=".pdf,.pfx"
          ref={inputFileRef}
          onChange={handleFileSelected}
        />
      </div>
    </>
  )
}

export default SocialContract
