import certificadoDigital from '../../assets/images/certificadodigital.jpeg'
import bannerJOBCContratante from '../../assets/images/BannerJOBC.jpeg'
import RECRUITER_SERVICES from '../../assets/contracts/portfolio_recrutadores.pdf'
import { Typography, Box, Paper, Card, CardMedia, CircularProgress, Chip } from '@mui/material'
import { PlansContratante } from 'pages/PlansContratante'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
	BannerBenefitsContainer,
	CertificateBanner,
	Container,
	Iframe,
	List,
} from './styles'
import { useProfessionalsQuantityQuery } from 'store/Professional/api'
import Services from 'data/components/Services'

const bannerItems = [
	'Mais de 11 mil profissionais de tecnologia',
	'Sem limite de publicações de vagas',
	'Profissionais sugeridos quando as vagas de emprego são publicadas',
	'Acesso imediato aos profissionais',
	'Hunting especializado',
	'Descontos em treinamentos e muito mais...',
]

export const DashboardContratante = () => {
	const user = useSelector((state: RootState) => state.userState.loggedUser)
	const { data, isLoading } = useProfessionalsQuantityQuery('')

	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<Card sx={{ position: 'relative', height: '250px' }}>
					<BannerBenefitsContainer backgroundURL={bannerJOBCContratante}>
						<Typography fontSize={20} >
							O PORTAL DO RECRUTADOR DE TECNOLOGIA
						</Typography>
						<List>
							{bannerItems.map((bannerItem) => (
								<li>{bannerItem}</li>
							))}
						</List>
					</BannerBenefitsContainer>
				</Card>

				<Typography variant="h6" sx={{ margin: '1rem 0rem', color: 'black' }}>
					Olá <strong style={{ fontSize: '22px' }}>{user?.nome ?? ''}</strong>,
					seja bem-vindo a plataforma, no momento estamos com{' '}
					{isLoading ? (
						<CircularProgress size={18} />
					) : (
						<Chip
							clickable
							variant="outlined"
							color="primary"
							style={{ borderRadius: '30px' }}
							icon={<PersonRoundedIcon color='primary' />}
							label={<strong>{data?.numero_profissionais}</strong>}
						/>
					)}{' '}
					profissionais em nosso banco de talentos.
				</Typography>
				<Services file={RECRUITER_SERVICES} />

				<section style={{ marginTop: '1rem' }}>
					<PlansContratante />
				</section>

				<Container>
					<Iframe
						style={{ border: 0 }}
						src="https://www.youtube.com/embed/FdXaxO1dYUE"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
					/>
					<Paper style={{ flex: 1 }}>
						<a
							target="_blank"
							href="https://jobc.com.br/certificado-digital-e-com/"
						>
							<CertificateBanner
								src={certificadoDigital}
								alt="CertificadoDigital"
								width={'100%'}
								height="100%"
							/>
						</a>
					</Paper>
				</Container>
			</Box>
		</>
	)
}
