import { Card } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  margin-top: 1rem;
  gap: 1rem;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Iframe = styled.iframe`
  width: 560px;
  border: 0;

  @media (max-width: 1200px) {
    aspect-ratio: 16/9;
  }
`

export const CertificateBanner = styled.img`
  width: 100%;
  height: 100%;
`

export const BannerBenefitsContainer = styled.div <{ backgroundURL: string }> `
	display: flex;
	flex-direction: column;
	justify-content: center;
  z-index: 99 !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
	height: 100%;
	padding-left: 2rem;
  background-color: transparent !important;
	background-image: ${props => `url(${props.backgroundURL})`};
	background-size: 100% 100%;
	background-repeat: no-repeat;
  box-shadow: none !important;
  color: #fff !important;
`
export const List = styled.ul`
  padding-left: 1rem;
  margin-top: 1rem;
  width: 83%;
  text-transform: uppercase;
  list-style: disc;
`
