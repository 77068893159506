import PageTitle from '../../components/Atoms/PageTitle'
import BoxLogin from '../../components/Organisms/BoxLogin'
import TemplateDefault from '../../components/Templates/Default'
import * as S from './styles'

import './styles.scss'

export const Login = () => {
  return (
    <TemplateDefault>
      <>
        <PageTitle title={'Login'} />
        <S.MainContainer>
          <BoxLogin />
        </S.MainContainer>
      </>
    </TemplateDefault>
  )
}
