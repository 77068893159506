import './styles.scss'
import CurrencyInput from '../CurrencyInput'
import { PartnerFormModel } from 'domain/models/partner'

import React from 'react'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import {
  defaultPartnerForm,
  getRemainingQuota,
} from 'data/utils/Partner/static'
import { cpfMask } from 'data/utils/regex'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'

export interface PartnerFormProps {
  errorField?: string
  setPartnerForm: any
  handleAddNewPartner?: any
  partnerForm: PartnerFormModel
  partners: any
}

const PartnerForm: React.FC<PartnerFormProps> = ({
  setPartnerForm = () => {},
  handleAddNewPartner,
  errorField = '',
  partners,
  partnerForm = defaultPartnerForm,
}) => {
  const { errorSnackbar } = useCustomSnackbar()

  const handleChangePartnerForm = (field: string, value: any) => {
    let fieldValue = value

    if (field === 'cpf') fieldValue = cpfMask(value)
    if (field === 'quota') {
      const remainingQuota = getRemainingQuota(partners)

      if (fieldValue > remainingQuota) {
        errorSnackbar(`A cota restante é de ${remainingQuota}%`)
        return
      }
    }

    setPartnerForm({ ...partnerForm, [field]: fieldValue })
  }

  const remainingQuota = getRemainingQuota(partners)

  return (
    <div className="partner-form box-container">
      <div className="box-container-header flex-box">
        <p>Adicionar sócio</p>
      </div>
      <div className="partner-form-body flex-box" style={{ color: 'black' }}>
        <div className="name-cpf-quota">
          <TextField
            id="name"
            variant="standard"
            label="Nome"
            value={partnerForm?.name}
            placeholder="Insira o nome"
            onChange={(e) => handleChangePartnerForm('name', e?.target?.value)}
            error={errorField === 'name'}
          />
          <TextField
            id="cpf"
            variant="standard"
            label="CPF"
            value={partnerForm?.cpf}
            placeholder="Insira o CPF"
            onChange={(e) => handleChangePartnerForm('cpf', e?.target?.value)}
            error={errorField === 'cpf'}
          />

          <TextField
            id="quota"
            variant="standard"
            label="Cota (%)"
            value={partnerForm?.quota}
            className="quota"
            placeholder="Insira o cota do sócio"
            onChange={(e) => handleChangePartnerForm('quota', e?.target?.value)}
            error={errorField === 'quota'}
          />
          <TextField
            id="remaining-quota"
            variant="standard"
            label="Cota restante (%)"
            value={remainingQuota}
            className="quota"
            disabled={true}
          />
        </div>
        <FormControlLabel
          control={<Checkbox />}
          value={partnerForm?.hasProlabore}
          onChange={() =>
            handleChangePartnerForm('hasProlabore', !partnerForm?.hasProlabore)
          }
          label="Retira prolabore?"
        />
        {partnerForm?.hasProlabore && (
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <CurrencyInput
              id="prolabore-value"
              label="Valor do prolabore"
              value={partnerForm?.prolaboreValue}
              onChange={(value) =>
                handleChangePartnerForm('prolaboreValue', value)
              }
              error={errorField === 'prolaboreValue'}
            />
            <TextField
              style={{ marginBottom: '2.45rem' }}
              fullWidth
              id="pis-nit"
              variant="standard"
              label="PIS/NIT"
              value={partnerForm?.pisNit}
              placeholder="Insira o PIS/NIT"
              onChange={(e) =>
                handleChangePartnerForm('pisNit', e?.target?.value)
              }
              error={errorField === 'pisNit'}
            />
          </div>
        )}
        <FormControlLabel
          control={<Checkbox />}
          value={partnerForm?.hasDependents}
          onChange={() =>
            handleChangePartnerForm(
              'hasDependents',
              !partnerForm?.hasDependents
            )
          }
          label="Possui dependentes sem renda?"
        />
        {handleAddNewPartner && (
          <Button
            className="light-blue-button"
            onClick={() => handleAddNewPartner()}
            disabled={true}
          >
            Cadastrar
          </Button>
        )}
      </div>
    </div>
  )
}

export default PartnerForm
