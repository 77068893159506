import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CheckUserEmailResponse } from 'types'
import {
	APP_STORAGE_PREFIX,
	APP_API_URI,
	APP_API_VERSION,
	APP_PREFIX,
} from 'utils/Constants'
import { StorageGetItem } from 'utils/Storage'

export const cadNewUser = createApi({
	reducerPath: `${APP_PREFIX}/User/cadNewUser`,
	baseQuery: fetchBaseQuery({
		baseUrl: `${APP_API_URI}`,
		prepareHeaders: (headers) => {
			const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))
			if (token) headers.set('Authorization', `JWT ${token}`)

			return headers
		},
	}),
	endpoints: (builder) => ({
		checkEmail: builder.mutation<CheckUserEmailResponse, string>({
			query: (email) => ({
				url: `${APP_API_VERSION}/usuario/verifica-cadastro/`,
				method: 'POST',
				body: { email },
			}),
		}),
		checkIfCanAddAJob: builder.query<{ bloqueio_inatividade: boolean }, void>({
			query: () => `${APP_API_VERSION}/usuario/bloqueio-inatividade/`
		}),
		postSendCodeEmail: builder.mutation({
			query: (post) => ({
				url: `${APP_API_VERSION}/usuario/codigo-senha-cadastro/`,
				method: 'POST',
				body: post,
			}),
		}),
	}),
})

export const { useCheckEmailMutation, usePostSendCodeEmailMutation, useLazyCheckIfCanAddAJobQuery } =
	cadNewUser
