import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-self: center;

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      padding: 0; 
      font-size: 15px; 
    }
  }
`
