import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Stack } from '@mui/material'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'

import { useGetInvitationsStatusAndamentoQuery } from 'store/WeInvitateVacanciesJob'
import { VacancyItem } from '../VacancyItem'
import { InvitationResponse } from 'types'
import JobDetails from 'components/Molecules/JobDetails'
import { setLoading } from 'store/Layout/slice'
import SkeletonJob from 'components/Molecules/SkeletonJob'
import { PageParam, PageSizeParam } from 'utils/Constants'
import Pagination from 'components/Organisms/Pagination'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import * as S from './styles'
import { isEmpty } from 'lodash'
import { Column, JobList, Row } from '../style'

export default function InProgressVacancies() {
  const dispatch = useDispatch()
  const { warningSnackbar } = useCustomSnackbar()
  const [selectedJob, setSelectedJob] = useState<InvitationResponse>()
  const {
    data: invitationsWithAndamentoStatus,
    isLoading,
    isSuccess,
    refetch,
  } = useGetInvitationsStatusAndamentoQuery('')

  const [query, setQuery] = useQueryParams({
    h: StringParam,
    p: PageParam,
    ps: PageSizeParam,
    t: NumberParam,
  })
  const { h: selectedJobHashcod, p: page, ps: pageSize, t: selectedTab } = query

  const [needToScroll, setNeedToScroll] = useState(!!selectedJobHashcod)

  const paginatedData = useMemo(() => {
    return (
      invitationsWithAndamentoStatus?.slice(
        page * pageSize,
        (page + 1) * pageSize
      ) ?? []
    )
  }, [invitationsWithAndamentoStatus, page, pageSize])

  useEffect(() => {
    if (isLoading || !invitationsWithAndamentoStatus) return

    if (isEmpty(invitationsWithAndamentoStatus))
      return setSelectedJob(undefined)

    if (selectedJobHashcod) {
      const foundItem = invitationsWithAndamentoStatus.find(
        ({ projeto }) => projeto.hashcod === selectedJobHashcod
      )

      if (foundItem) setSelectedJob(foundItem)
      else {
        setSelectedJob(invitationsWithAndamentoStatus[0])
      }
    } else {
      setSelectedJob(invitationsWithAndamentoStatus[0])
    }
  }, [
    isLoading,
    invitationsWithAndamentoStatus,
    selectedJobHashcod,
    warningSnackbar,
  ])

  useEffect(() => {
    setQuery({ h: selectedJob?.projeto.hashcod })
  }, [selectedJob, setQuery])

  useEffect(() => {
    if (needToScroll && isSuccess && selectedJobHashcod) {
      document
        .getElementById(selectedJobHashcod)
        ?.scrollIntoView({ behavior: 'smooth' })

      setNeedToScroll(false)
    }
  }, [isSuccess, needToScroll, selectedJobHashcod])

  useEffect(() => {
    return () => setQuery({ h: undefined, p: undefined, ps: undefined })
  }, [setQuery])

  useEffect(refetch, [refetch, selectedTab])

  useEffect(() => {
    dispatch(setLoading(isLoading))
  }, [dispatch, isLoading])

  useEffect(() => refetch, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [selectedJob])

  if (!invitationsWithAndamentoStatus?.length && isSuccess) {
    return <Box style={{ color: 'black' }}>Não há vagas em andamento.</Box>
  }

  return (
    <>
      <Row>
        {isLoading ? (
          <SkeletonJob />
        ) : (
          <Column>
            <JobList>
              {paginatedData.map((item) => (
                <VacancyItem
                  id={item.hashcod}
                  key={item.hashcod}
                  item={item.projeto}
                  onClick={() => setSelectedJob(item)}
                  isSelected={selectedJob == item}
                />
              ))}
            </JobList>
          </Column>
        )}

        {selectedJob && (
          <JobDetails
            job={selectedJob.projeto}
            hasBtnApplyJob={false}
            refetchData={refetch}
            isApplyJob
            dataJob={invitationsWithAndamentoStatus}
            isWorking={selectedJob.status === 'A'}
            index={
              invitationsWithAndamentoStatus?.findIndex(
                (item) => item.hashcod === selectedJob.hashcod
              ) as number
            }
          />
        )}
      </Row>

      <Pagination
        count={invitationsWithAndamentoStatus?.length ?? 0}
        page={isLoading ? 0 : page}
        onPageChange={(_, newPage) => setQuery({ p: newPage })}
        onRowsPerPageChange={(event) =>
          setQuery({ p: 0, ps: Number(event.target.value) })
        }
        rowsPerPage={pageSize}
      />
    </>
  )
}
