import React, { useState, useEffect } from 'react'
import './styles.scss'
import {
  Paper,
  Box,
  Rating,
  Typography,
  CircularProgress,
  Avatar,
} from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { useGetRatingTechQuery, useEditRatingTechMutation } from 'store/Rating'
import { Button } from 'components/Atoms/Button'

interface CompanyCardProps {
  companyHash: string
  name: string
  imagem: string
  rating: any
  refetchRating: any
}

export const CompanyCardTech = ({
  companyHash,
  name,
  imagem,
  rating,
  refetchRating,
}: CompanyCardProps) => {
  const [editRatingTech] = useEditRatingTechMutation()
  const {
    data: ratingItem,
    isSuccess,
    refetch,
  } = useGetRatingTechQuery(companyHash)
  const { successSnackbar } = useCustomSnackbar()

  const [valueLocalizacao, setValueLocalizacao] = useState<number | null>(0)
  const [valueGestao, setValueGestao] = useState<number | null>(0)
  const [valueRelacionamento, setValueRelacionamento] = useState<number | null>(
    0
  )
  const [valuePagamento, setValuePagamento] = useState<number | null>(0)
  const [hoverLocalizacao, setHoverLocalizacao] = useState(-1)
  const [hoverGestao, setHoverGestao] = useState(-1)
  const [hoverRelacionamento, setHoverRelacionamento] = useState(-1)
  const [hoverPagamento, setHoverPagamento] = useState(-1)

  const [nameUser, setNameUser] = useState('')
  const [photoUser, setPhotoUser] = useState<string | null>('')

  useEffect(() => {
    setPhotoUser(imagem)
    setNameUser(name)
  }, [ratingItem, imagem, name])

  const labelsRating: { [index: string]: string } = {
    1: 'Péssimo',
    2: 'Ruim',
    3: 'Mediano',
    4: 'Bom',
    5: 'Excelente +',
  }

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labelsRating[value]}`
  }

  return (
    <Box>
      <Paper sx={{ padding: '18px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginBottom: '12px',
          }}
        >
          {photoUser ? (
            <Avatar src={photoUser} sx={{ width: 100, height: 100 }} />
          ) : (
            <Avatar sx={{ width: 100, height: 100 }}>
              {nameUser?.charAt(0)}
            </Avatar>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            sx={{ marginRight: 1 }}
          >
            Consultor:
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {nameUser}
          </Typography>
        </Box>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ marginBottom: '12px' }}
        >
          {rating?.projeto?.nome}
        </Typography>
        <Typography component="legend" variant="body1">
          Conhecimento
        </Typography>
        {isSuccess ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rating
                name="hover-feedback"
                getLabelText={getLabelText}
                style={{ zoom: '110%' }}
                value={ratingItem?.contratado_pergunta1}
                onChangeActive={(event, newHover) => {
                  setHoverLocalizacao(newHover)
                }}
                icon={<StarIcon style={{ fontSize: '24px', color: 'gold' }} />}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                }
                onChange={(event, newValue) => {
                  editRatingTech({
                    hashcod: rating?.hashcod,
                    body: {
                      contratado_pergunta1: newValue,
                    },
                  })
                    .unwrap()
                    .then(() => {
                      successSnackbar('Conhecimento avaliada com sucesso')
                    })
                    .then(() => {
                      refetch()
                    })
                }}
              />
              {valueLocalizacao !== null && (
                <Box sx={{ ml: 2 }}>
                  {
                    labelsRating[
                      hoverLocalizacao !== -1
                        ? hoverLocalizacao
                        : valueLocalizacao
                    ]
                  }
                </Box>
              )}
            </Box>

            <Typography
              component="legend"
              variant="body1"
              sx={{ marginTop: '12px' }}
            >
              Agilidade
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rating
                name="hover-feedback"
                style={{ zoom: '110%' }}
                getLabelText={getLabelText}
                value={ratingItem?.contratado_pergunta2}
                onChangeActive={(event, newHover) => {
                  setHoverGestao(newHover)
                }}
                icon={<StarIcon style={{ fontSize: '24px', color: 'gold' }} />}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                }
                onChange={(event, newValue) => {
                  editRatingTech({
                    hashcod: rating?.hashcod,
                    body: {
                      contratado_pergunta2: newValue,
                    },
                  })
                    .unwrap()
                    .then(() => {
                      successSnackbar('Agilidade avaliada com sucesso')
                    })
                    .then(() => {
                      refetch()
                    })
                }}
              />
              {valueGestao !== null && (
                <Box sx={{ ml: 2 }}>
                  {labelsRating[hoverGestao !== -1 ? hoverGestao : valueGestao]}
                </Box>
              )}
            </Box>
            <Typography
              component="legend"
              variant="body1"
              sx={{ marginTop: '12px' }}
            >
              Postura
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rating
                name="hover-feedback"
                style={{ zoom: '110%' }}
                getLabelText={getLabelText}
                value={ratingItem?.contratado_pergunta3}
                onChangeActive={(event, newHover) => {
                  setHoverRelacionamento(newHover)
                }}
                icon={<StarIcon style={{ fontSize: '24px', color: 'gold' }} />}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                }
                onChange={(event, newValue) => {
                  editRatingTech({
                    hashcod: rating?.hashcod,
                    body: {
                      contratado_pergunta3: newValue,
                    },
                  })
                    .unwrap()
                    .then(() => {
                      successSnackbar('Postura avaliada com sucesso')
                    })
                    .then(() => {
                      refetch()
                    })
                }}
              />
              {valueRelacionamento !== null && (
                <Box sx={{ ml: 2 }}>
                  {
                    labelsRating[
                      hoverRelacionamento !== -1
                        ? hoverRelacionamento
                        : valueRelacionamento
                    ]
                  }
                </Box>
              )}
            </Box>
            <Typography
              component="legend"
              variant="body1"
              sx={{ marginTop: '12px' }}
            >
              Entregas
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rating
                name="hover-feedback"
                style={{ zoom: '110%' }}
                getLabelText={getLabelText}
                value={ratingItem?.contratado_pergunta4}
                onChangeActive={(event, newHover) => {
                  setHoverPagamento(newHover)
                }}
                icon={<StarIcon style={{ fontSize: '24px', color: 'gold' }} />}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                }
                onChange={(event, newValue) => {
                  editRatingTech({
                    hashcod: rating?.hashcod,
                    body: {
                      contratado_pergunta4: newValue,
                    },
                  })
                    .unwrap()
                    .then(() => {
                      successSnackbar('Entregas avaliada com sucesso')
                    })
                    .then(() => {
                      refetch()
                    })
                }}
              />
              {valuePagamento !== null && (
                <Box sx={{ ml: 2 }}>
                  {
                    labelsRating[
                      hoverPagamento !== -1 ? hoverPagamento : valuePagamento
                    ]
                  }
                </Box>
              )}
            </Box>
            <Button
              style={{ marginTop: '18px' }}
              buttonType="primary"
              onClick={() => {
                editRatingTech({
                  hashcod: rating?.hashcod,
                  body: {
                    contratante_avaliado: true,
                  },
                })
                  .unwrap()
                  .then(() => {
                    successSnackbar('Avaliação enviada com sucesso')
                  })
                  .then(() => {
                    refetch()
                  })
                  .then(() => {
                    refetchRating()
                  })
              }}
            >
              Enviar avaliação
            </Button>
          </>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}
      </Paper>
    </Box>
  )
}
