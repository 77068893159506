import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'

const CodeVerificationModal = ({
  code,
  open,
  onCodeChange,
  onCodeSubmit,
  onClose,
}: CodeVerificationModalProps) => {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Código de verificação</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Para finalizar seu cadastro, digite o código enviado para seu
            e-mail. Verifique se o e-mail não caiu na caixa de spam, sugerimos
            configurar como (não é spam), para que você possa receber as
            mensagem de vagas de emprego dos recrutadores.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Código de verificação"
            type="email"
            fullWidth
            variant="standard"
            value={code}
            onChange={(event) => onCodeChange(event.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>

          <Button onClick={onCodeSubmit}>Enviar</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface CodeVerificationModalProps {
  open: boolean
  code: string
  onClose: () => void
  onCodeChange: (string) => void
  onCodeSubmit: () => void
}

export default CodeVerificationModal
