import { DisplayNoteFields, PersonTypes } from 'domain/models/sendNote'
import moment from 'moment'

export const getNotesByPeriodBody = () => {
  const notesByPeriodBody = {
    finalDate: moment().endOf('month').format('YYYY-MM-DD'),
    initialDate: moment().startOf('month').format('YYYY-MM-DD'),
  }

  return notesByPeriodBody
}

export const isCurrencyValid = (value: any = 0) => {
  return !isNaN(+value)
}

export const findNoteById = (notes: any[], id: number) => {
  return notes?.find((note) => note.id === id)
}

export const defaultDisplayFields = {
  footer: false,
  noteConfig: true,
  clientForm: false,
  addressForm: false,
  serviceForm: false,
  noteClientInfo: false,
}

export const enabledDisplayFields = {
  footer: true,
  clientForm: true,
  addressForm: true,
  serviceForm: true,
}

export const defaultPersonType = PersonTypes.LEGAL_PERSON

export const getClientButtonConfig = ({
  clientForm,
  noteClientInfo,
}: DisplayNoteFields) => {
  let config = {
    classNames: 'light-blue-button',
    label: 'Cadastrar novo cliente',
  }

  const selectedBody = {
    classNames: 'white-button',
    label: 'Usar cliente já cadastrado',
  }

  if (clientForm && !noteClientInfo) config = selectedBody

  return config
}

export const defaultNoteConfig = {
  cpfCnpjSearch: '',
  notInformClient: false,
  enablePaymentEmission: false,
  enableSendNotebyEmail: false,
}

export const defaultClientForm = {
  name: '',
  phone: '',
  email: '',
  cpfCnpj: '',
  socialReason: '',
  municipalRegistration: '',
  personType: defaultPersonType,
  sendEmail: true,
}

export const defaultServiceForm = {
  selectedOperationNature: '',
  operatioNatureOptions: [],
  selectedActivity: '',
  activityOptions: [],
  enableCulturalBooster: false,
  cnae: '',
  serviceDescription: '',
  serviceValue: '',
  serviceLiquidValue: '',
  aliquot: '',
  serviceItemCode: '',
  advancedOptions: {
    calcBase: '',
    municipalTaxValue: '',
    inconditionalDiscountValue: '',
    conditionalDiscountValue: '',
    enableRetainISS: false,
  },
}

export const defaultNotePostBody = {
  idIntegracao: '',
  prestador: {
    cpfCnpj: '',
  },
  tomador: {
    cpfCnpj: '',
    razaoSocial: '',
    email: '',
    endereco: {
      descricaoCidade: '',
      cep: '',
      tipoLogradouro: '',
      logradouro: '',
      tipoBairro: '',
      codigoCidade: '',
      complemento: '',
      estado: '',
      numero: '',
      bairro: '',
    },
  },
  servico: [
    {
      codigo: '',
      discriminacao: '',
      cnae: '',
      iss: {
        aliquota: 0,
      },
      valor: {
        servico: 0,
      },
    },
  ],
}
