import React from 'react'

import TextField from '@mui/material/TextField'
import { getBrazilianCurrency, removeBrazilianCurrency } from 'data/utils/regex'
import { isCurrencyValid } from 'data/utils/SendNote/static'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

interface CurrencyInputProps {
  id: string
  value: string
  name?: string
  type?: string
  label: string
  error?: boolean
  disabled?: boolean
  onChange: (value: any) => void
}

const CurrencyInput = (props: CurrencyInputProps) => {
  const isValid = isCurrencyValid(props?.value)
  return (
    <CurrencyTextField
      variant="standard"
      value={props.value}
      currencySymbol="R$"
      outputFormat="string"
      decimalCharacter=","
      digitGroupSeparator="."
      minimumValue={0}
      onChange={(event, value) => props.onChange(value)}
      error={props?.error || !isValid || !props.value}
      helperText="Ex: 2,50 | 35,50 | 25.000,30"
    />
  )
}

export default CurrencyInput
