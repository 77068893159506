import { endOfDay, isAfter, parseISO } from "date-fns";
import { useGetDiscriminatedProjectsQuery } from "store/SearchVacancies"
import { JobResponse } from "types";

interface ReducerReturnType {
	published: JobResponse[]
	inProgress: JobResponse[]
	finished: JobResponse[]
}

type Filters = {
	client: string;
	name: string;
}

export const useDiscriminitadedProjects = (filters?: Filters) => {
	const { data: jobs, ...rest } = useGetDiscriminatedProjectsQuery(filters);

	const projects = jobs?.reduce<ReducerReturnType>(
		(projects, project) => {
			const date = parseISO(project.limite_submissao)

			if (
				(!isAfter(endOfDay(date), new Date()) &&
					project.status === 'P') ||
				project.status === 'F'
			) {
				return { ...projects, finished: [...projects.finished, project] }
			}

			if (project.status === 'P') {
				return {
					...projects,
					published: [...projects.published, project],
				}
			}

			if (project.status === 'E') {
				return {
					...projects,
					inProgress: [...projects.inProgress, project],
				}
			}

			return projects
		},
		{
			published: [],
			inProgress: [],
			finished: [],
		}
	)

	return { data: projects, ...rest }
}