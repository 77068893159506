import { Container, Modal, Slide } from './styles'
import { useJobSuggestionsModal } from './hooks/useJobSuggestionsModal'
import List from 'data/components/CompoundInvitations/List'
import ProfessionalCard from 'data/components/CompoundInvitations/ProfessionalCard'
import { Fragment } from 'react'
import { PLANS } from '../constants'
import { Divider } from '@mui/material'
import InviteStatus from './InviteStatus'
import Professional from 'data/components/CompoundInvitations/Professional'
import { useGetUserInfosQuery } from 'store/UserType'
import ExitRequest from 'pages/CalendarContratante/Row/ApprovedApplicantsModal/ExitRequest'

interface JobSuggestionsModalProps {
  onClose: () => void
  projectHashcode: string
}

const JobSuggestionsModal = ({
  onClose,
  projectHashcode,
}: JobSuggestionsModalProps) => {
  const {
    handleSelectProfessional,
    suggestedProfessionals,
    selectedProfessional,
    updateSuggestedProfessionals,
  } = useJobSuggestionsModal(projectHashcode)

  const { data: user } = useGetUserInfosQuery('')

  if (!suggestedProfessionals) return null
  if (!user) return null

  const isProfessionalSelected = !!Number.isInteger(selectedProfessional)
  const professional = suggestedProfessionals[selectedProfessional!]

  return (
    <Modal open onClose={onClose}>
      <Container>
        <Slide isProfessionalSelected={isProfessionalSelected}>
          <List title="Profissionais Sugeridos" onClose={onClose}>
            {suggestedProfessionals?.map((suggestion, index) => (
              <Fragment key={suggestion.hashcod}>
                <ProfessionalCard
                  professional={suggestion}
                  onSelectProfessional={
                    index <= PLANS[user.plano]
                      ? handleSelectProfessional(index)
                      : undefined
                  }
                >
                  {index <= PLANS[user.plano] && (
                    <InviteStatus
                      updateSuggestions={() => updateSuggestedProfessionals()}
                      projectHashcode={projectHashcode}
                      professionalHashcode={suggestion.hashcod}
                      status={suggestion.convite?.status}
                    />
                  )}
                </ProfessionalCard>
                <Divider style={{ marginRight: '1.5rem' }} />
              </Fragment>
            ))}
          </List>
          {isProfessionalSelected && (
            <Professional
              hashcode={professional.hashcod}
              onUnselectProfessional={handleSelectProfessional(null)}
              title="Voltar as sugestões"
              SlotCardContainer={
                <ExitRequest
                  updateSuggestions={updateSuggestedProfessionals}
                  hashcode={professional!.convite?.hashcod}
                  status={professional!.convite?.status}
                />
              }
            />
          )}
        </Slide>
      </Container>
    </Modal>
  )
}

export default JobSuggestionsModal
