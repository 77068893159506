import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import {
  Autocomplete,
  Button,
  TextField,
  Stack,
  Typography,
  Divider,
  Chip,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { FormikConfig, useFormik } from 'formik'

import validationSchema from './validations'
import { INITIAL_VALUES } from './constants'
import {
  useGetLanguageLevelOptionsQuery,
  useGetLanguageOptionsQuery,
} from 'store/Profile'
import { LanguageRequest } from 'types'
import { LanguageTabForm, LanguageTabRef } from './types'
import { some } from 'lodash'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { useDispatch } from 'react-redux'
import { setLoading } from 'store/Layout/slice'

const LanguageTab = (_, ref: React.Ref<LanguageTabRef>) => {
  const { warningSnackbar } = useCustomSnackbar()
  const dispatch = useDispatch()
  const { data: languageOptions, isLoading: getLanguageIsLoading } =
    useGetLanguageOptionsQuery()
  const { data: languageLevelOptions, isLoading: getLanguageLevelIsLoading } =
    useGetLanguageLevelOptionsQuery()
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageRequest[]>(
    []
  )

  const onSubmit: FormikConfig<LanguageTabForm>['onSubmit'] = (
    values,
    { resetForm }
  ) => {
    setSelectedLanguages((current) => [...current, values])

    resetForm()
  }

  const { handleSubmit, values, setFieldValue, errors } =
    useFormik<LanguageTabForm>({
      validationSchema,
      enableReinitialize: true,
      initialValues: INITIAL_VALUES,
      onSubmit,
      validateOnChange: false,
      validateOnBlur: false,
    })

  const removeLanguage = (index: number) => {
    setSelectedLanguages((current) => {
      const cloned = [...current]
      cloned.splice(index, 1)

      return cloned
    })
  }

  const validate = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        if (some(values, (item) => !!item)) {
          reject()
          warningSnackbar('Limpe o formulário ou adicione um item.')
        } else {
          resolve()
        }
      }),
    [values, warningSnackbar]
  )

  useImperativeHandle(ref, () => ({ validate, selectedLanguages }), [
    validate,
    selectedLanguages,
  ])

  useEffect(() => {
    dispatch(setLoading(getLanguageIsLoading || getLanguageLevelIsLoading))

    return () => {
      dispatch(setLoading(false))
    }
  }, [dispatch, getLanguageIsLoading, getLanguageLevelIsLoading])

  return (
    <form onSubmit={handleSubmit}>
      <div className="horinzontal">
        <div className="row" style={{ justifyContent: 'flex-start' }}>
          <div className="row" style={{ width: '70%', gap: 16 }}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={`idiomas`}
                  label="Idioma"
                  variant="standard"
                  error={!!errors.lingua}
                  helperText={errors.lingua}
                  margin="dense"
                />
              )}
              style={{ width: '48%' }}
              value={values.lingua}
              autoHighlight
              getOptionLabel={(option) =>
                languageOptions?.find((item) => item.hashcod === option)
                  ?.descricao ?? ''
              }
              onChange={(_, newValue) => {
                setFieldValue('lingua', newValue)
                setFieldValue('nivel', null)
              }}
              options={
                languageOptions
                  ?.filter((language) =>
                    selectedLanguages.every(
                      (selectedLanguage) =>
                        selectedLanguage.lingua !== language.hashcod
                    )
                  )
                  ?.map((item) => item.hashcod) ?? []
              }
            />

            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nível"
                  variant="standard"
                  error={!!errors.nivel}
                  helperText={errors.nivel}
                  margin="dense"
                />
              )}
              style={{ width: '48%' }}
              value={values.nivel}
              disabled={!values.lingua}
              autoHighlight
              getOptionLabel={(option) =>
                languageLevelOptions?.find((item) => item.hashcod === option)
                  ?.descricao ?? ''
              }
              onChange={(_, newValue) => setFieldValue('nivel', newValue)}
              options={languageLevelOptions?.map((item) => item.hashcod) ?? []}
            />

            <div className="button-container">
              <Button variant="contained" type="submit">
                Adicionar
              </Button>
            </div>
          </div>
        </div>

        {selectedLanguages && selectedLanguages.length > 0 && (
          <>
            <Typography variant="subtitle2" component="div" marginTop={2}>
              Minhas linguas
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              marginTop={1}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {selectedLanguages.map(({ lingua, nivel }, index) => {
                const languageName = languageOptions?.find(
                  (item) => item.hashcod === lingua
                )?.descricao
                const languageLevelName = languageLevelOptions?.find(
                  (item) => item.hashcod === nivel
                )?.descricao

                return (
                  <Chip
                    key={`${lingua}${nivel}`}
                    color="primary"
                    onDelete={() => {
                      removeLanguage(index)

                      return <ClearIcon />
                    }}
                    label={`${languageName} (${languageLevelName})`}
                  />
                )
              })}
            </Stack>
          </>
        )}
      </div>
    </form>
  )
}

export default forwardRef(LanguageTab)

export type { LanguageTabRef }
