import { Grid, Tabs, Tab } from '@mui/material'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import {
	useGetUserInfosQuery,
	useSolicitarPlanoUserContabilidadeMutation,
} from '../../store/UserType'
import ACCOUNTING_SERVICES from '../../assets/contracts/portfolio_contabilidade.pdf'
import { Container } from './styles'
import { useState } from 'react'
import PlanCard from './PlanCard'
import TabPanel from './TabPanel'
import Services from 'data/components/Services'

export const PlansContabilidade = () => {
	const [selectedTab, setSelectedTab] = useState(0)
	const { data, refetch } = useGetUserInfosQuery('')
	const [solicitarPlanoUserContabilidade] =
		useSolicitarPlanoUserContabilidadeMutation()
	const { successSnackbar } = useCustomSnackbar()

	const handleSolicitarPlanoUser = (emailValue, planoValue, link) => {
		solicitarPlanoUserContabilidade({ emailValue, planoValue })
			.unwrap()
			.then(() => {
				successSnackbar('Parabéns, você solicitou um novo plano')
			})
			.then(() => {
				refetch()
			})
			.then(() => {
				link && window.open(link)
			})
	}

	return (
		<Container>
			<Services file={ACCOUNTING_SERVICES} />
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Tabs
					value={selectedTab}
					onChange={(_, value) => {
						setSelectedTab(value)
					}}
					sx={{ marginBottom: '16px' }}
				>
					<Tab label="Simples Nacional" value={0} style={{ fontSize: 20 }} />
					<Tab label="Lucro Presumido" value={1} style={{ fontSize: 20 }} />
				</Tabs>
			</div>

			<TabPanel value={selectedTab} index={0}>
				<Grid container rowSpacing={2} columnSpacing={{ xs: 4 }}>
					{plansContabilidadeSimplesNacional?.map((itemPlano, index) => (
						<Grid item xs={3} key={itemPlano.titulo}>
							<PlanCard
								itemPlano={itemPlano}
								data={data}
								onClick={() =>
									handleSolicitarPlanoUser(
										data.email,
										itemPlano.plano_contabilidade_solicitado,
										itemPlano.link
									)
								}
							></PlanCard>
						</Grid>
					))}
				</Grid>
			</TabPanel>

			<TabPanel value={selectedTab} index={1}>
				<Grid container rowSpacing={2} columnSpacing={{ xs: 4 }}>
					{plansContabilidadeLucroPresumido?.map((itemPlano, index) => (
						<Grid item xs={3} key={itemPlano.titulo}>
							<PlanCard
								itemPlano={itemPlano}
								data={data}
								onClick={() =>
									handleSolicitarPlanoUser(
										data.email,
										itemPlano.plano_contabilidade_solicitado,
										itemPlano.link
									)
								}
							></PlanCard>
						</Grid>
					))}
				</Grid>
			</TabPanel>
		</Container>
	)
}

const plansContabilidadeSimplesNacional = [
	{
		titulo: 'Basic',
		subTitulo: 'Faturamento Até 15 mil',
		descricao: [
			'Contabilidade completa',
			'Processo de abertura grátis',
			'Abertura sem sair de casa',
			"Emissor de NF's ilimitado",
			'Pró-Labore De Até 1 Sócio',
			'Fazemos sua Declaração IRPJ',
			'Não cobramos Décimo Terceiro',
			'Chat e E-mail (das 9h às 18h)',
		],
		preco: 'Custo: R$95/mês',
		plano_contabilidade_solicitado: 1,
		link: 'https://jobc.com.br/product/basic-2/',
	},
	{
		titulo: 'Advanced',
		subTitulo: 'Faturamento Até 50 mil',
		descricao: [
			'Contabilidade completa',
			'Processo de abertura grátis',
			'Abertura sem sair de casa',
			"Emissor de NF's ilimitado",
			'Pró-Labore De Até 2 Sócios',
			'Fazemos sua Declaração IRPJ',
			'Não cobramos Décimo Terceiro',
			'Chat e E-mail (das 9h às 18h)',
		],
		preco: 'Custo: a partir de R$145/mês',
		plano_contabilidade_solicitado: 2,
		link: 'https://jobc.com.br/product/advanced-2/',
	},
	{
		titulo: 'Premium',
		subTitulo: 'Faturamento Até 100 mil',
		descricao: [
			'Contabilidade completa',
			'Processo de abertura grátis',
			'Abertura sem sair de casa',
			"Emissor de NF's ilimitado",
			'Equipe de contabilidade exclusiva',
			'Pró-labore de até 2 sócios',
			'Folha de Pagamento de Até 2 Funcionários',
			'Fazemos sua Declaração IRPJ',
			'Não cobramos Décimo Terceiro',
			'Chat e E-mail (das 9h às 18h)',
		],
		preco: 'Custo: a partir de R$345/mês',
		plano_contabilidade_solicitado: 3,
		link: 'https://jobc.com.br/product/premium-2/',
	},
	{
		titulo: 'Exclusive',
		subTitulo: 'Faturamento Até 150 mil',
		descricao: [
			'Contabilidade completa',
			'Processo de abertura grátis',
			'Abertura sem sair de casa',
			"Emissor de NF's ilimitado",
			'Equipe de contabilidade exclusiva',
			'Pró-labore de até 3 sócios',
			'Folha de Pagamento de Até 2 Funcionários',
			'Fazemos sua Declaração IRPJ',
			'Não cobramos Décimo Terceiro',
			'Chat e E-mail (das 9h às 18h)',
		],
		preco: 'Custo: a partir de R$420/mês',
		plano_contabilidade_solicitado: 4,
		link: 'https://jobc.com.br/product/exclusive-2/',
	},
]

const plansContabilidadeLucroPresumido = [
	{
		titulo: 'Basic',
		subTitulo: 'Faturamento Até 15 mil',
		descricao: [
			'Contabilidade completa',
			'Processo de abertura grátis',
			'Abertura sem sair de casa',
			"Emissor de NF's ilimitado",
			'Pró-Labore De Até 1 Sócio',
			'Fazemos sua Declaração IRPJ',
			'Não cobramos Décimo Terceiro',
			'Chat e E-mail (das 9h às 18h)',
		],
		preco: 'Custo: R$175/mês',
		plano_contabilidade_solicitado: 5,
		link: 'https://jobc.com.br/product/basic-3/',
	},
	{
		titulo: 'Advanced',
		subTitulo: 'Faturamento Até 50 mil',
		descricao: [
			'Contabilidade completa',
			'Processo de abertura grátis',
			'Abertura sem sair de casa',
			"Emissor de NF's ilimitado",
			'Pró-Labore De Até 2 Sócios',
			'Fazemos sua Declaração IRPJ',
			'Não cobramos Décimo Terceiro',
			'Chat e E-mail (das 9h às 18h)',
		],
		preco: 'Custo: a partir de R$235/mês',
		plano_contabilidade_solicitado: 6,
		link: 'https://jobc.com.br/product/advanced-3/',
	},
	{
		titulo: 'Premium',
		subTitulo: 'Faturamento Até 100 mil',
		descricao: [
			'Contabilidade completa',
			'Processo de abertura grátis',
			'Abertura sem sair de casa',
			"Emissor de NF's ilimitado",
			'Equipe de contabilidade exclusiva',
			'Pró-labore de até 2 sócios',
			'Folha de Pagamento de Até 2 Funcionários',
			'Fazemos sua Declaração IRPJ',
			'Não cobramos Décimo Terceiro',
			'Chat e E-mail (das 9h às 18h)',
		],
		preco: 'Custo: a partir de R$375/mês',
		plano_contabilidade_solicitado: 7,
		link: 'https://jobc.com.br/product/premium-3/',
	},
	{
		titulo: 'Exclusive',
		subTitulo: 'Faturamento Até 150 mil',
		descricao: [
			'Contabilidade completa',
			'Processo de abertura grátis',
			'Abertura sem sair de casa',
			"Emissor de NF's ilimitado",
			'Equipe de contabilidade exclusiva',
			'Pró-labore de até 3 sócios',
			'Folha de Pagamento de Até 2 Funcionários',
			'Fazemos sua Declaração IRPJ',
			'Não cobramos Décimo Terceiro',
			'Chat e E-mail (das 9h às 18h)',
		],
		preco: 'Custo: a partir de R$555/mês',
		plano_contabilidade_solicitado: 8,
		link: 'https://jobc.com.br/product/exclusive-3/',
	},
]
