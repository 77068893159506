import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from '@mui/material'

import api from '../../../../../../data/api/api'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import '../../../styles.scss'
import { useDispatch } from 'react-redux'
import { setLoading } from 'store/Layout/slice'

const UseTermsTab = ({ accepted, setAccepted }: UseTermsTabProps) => {
  const { warningSnackbar } = useCustomSnackbar()
  const dispatch = useDispatch()
  const [useTerms, setUseTerms] = useState<string>()

  const getTermoDeUso = useCallback(async () => {
    dispatch(setLoading(true))

    const url = `api/v1/configuracoes/termo-contratante/`

    api
      .get(url)
      .then((res) => {
        setUseTerms(res.data[0].termo_contratante)
      })
      .catch(() => {
        warningSnackbar(
          'Falha ao recuperar termos de uso. Tente novamente mais tarde.'
        )
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [dispatch, warningSnackbar])

  useEffect(() => {
    getTermoDeUso()
  }, [getTermoDeUso])

  return (
    <div className="horinzontal">
      <Typography variant="h6" gutterBottom>
        Termos de uso
      </Typography>

      <Box>
        <Paper
          sx={{
            padding: 2,
            width: '80%',
            maxHeight: '420px',
            overflowY: 'scroll',
            whiteSpace: 'pre-line',
          }}
        >
          {useTerms && <label dangerouslySetInnerHTML={{ __html: useTerms }} />}
        </Paper>
      </Box>

      <FormControlLabel
        control={
          <Checkbox
            checked={accepted}
            onChange={(e) => {
              setAccepted(e.target.checked)
            }}
          />
        }
        label={<div className="termosUso">Li e aceito os termos de uso</div>}
      />
    </div>
  )
}

interface UseTermsTabProps {
  accepted: boolean
  setAccepted: React.Dispatch<React.SetStateAction<boolean>>
}

export default UseTermsTab
