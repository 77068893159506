import React, { useMemo, useEffect } from 'react'
import { JobResponse } from 'types'
import { getStateUf } from 'utils'
import HeaderCardInfo from './HeaderCardInfo'
import JobAttribute from './JobAttribute/JobAttribute'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useNavigate } from 'react-router-dom'
import { Button } from 'components/Atoms/Button'
import { Box, Typography, Rating } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import StarIcon from '@mui/icons-material/Star'
import _ from 'lodash'
import { useGetJobFinalizadoRatedMutation } from 'store/WeInvitateVacanciesJob'

import * as S from './styles'

const JobDetails: React.FC<JobDetailsProps> = ({
  job,
  isInvite,
  hasFinished,
  isApplyJob,
  isWorking,
  hasBtnApplyJob,
  dataVacation,
  dataJob,
  index,
  refetchData,
  jobFinishRated,
}) => {
  const navigate = useNavigate()
  const planoUser = useSelector((state: RootState) => state?.personalData.plano)
  const planoUserContactQtd = useSelector(
    (state: RootState) => state?.personalData.planoApplyJobQtd
  )

  const jobFinishRatedDetails = _.find(jobFinishRated, function (item) {
    return item?.projeto?.hashcod === job?.hashcod
  })

  const [jobFinalizadoRated, setJobFinalizadoRated] = React.useState<any>()

  const [getJobFinalizadoRated] = useGetJobFinalizadoRatedMutation()

  const showWhatsapp = useMemo(() => {
    if (!index) return true

    if (planoUser === undefined || !planoUserContactQtd) return false

    return index + 1 <= JOB_LIMITS[planoUser as 0 | 1 | 2 | 3]
  }, [index, planoUser, planoUserContactQtd])

  useEffect(() => {
    if (!hasFinished) return

    getJobFinalizadoRated(jobFinishRatedDetails?.hashcod)
      .unwrap()
      .then((data) => {
        setJobFinalizadoRated(data)
      })
  }, [getJobFinalizadoRated, hasFinished, jobFinishRatedDetails])

  return (
    <S.Container>
      <HeaderCardInfo
        index={index!}
        job={job}
        isInvite={isInvite}
        hasFinished={hasFinished}
        isApplyJob={isApplyJob}
        isWorking={isWorking}
        hasBtnApplyJob={hasBtnApplyJob}
        dataVacation={dataVacation}
        dataJob={dataJob}
        refetchData={refetchData}
      />
      <S.JobDescription id="myContent">
        {job && (
          <>
            {job.alocacao_remota === false && (
              <JobAttribute
                name="Local da Vaga de Emprego"
                value={
                  job.estado && job.cidade
                    ? `${getStateUf(job.estado)}/${job.cidade}`
                    : 'Indeterminado'
                }
              />
            )}

            {job.alocacao_remota === true && (
              <JobAttribute
                name="Vaga de Emprego Remota"
                value={'Home Office'}
              />
            )}

            <JobAttribute
              name="Duração"
              value={`De: ${job.data_inicio ?? 'Indeterminado'} | Até: ${
                job.data_final ?? 'Indeterminado'
              }`}
            />

            <JobAttribute
              name="Dedicação Diária"
              value={
                job.dedicacao_diaria
                  ? `${job.dedicacao_diaria} Horas`
                  : 'Indeterminado'
              }
            />

            <JobAttribute
              name="Valor/Hora"
              value={
                job.pagamento ? 'R$ ' + job.pagamento + '/Hora' : 'À combinar'
              }
            />

            <JobAttribute
              name="Tipo de Contratação"
              value={job.tipo_contratacao ?? 'Indeterminado'}
            />

            <JobAttribute
              name="Número de profissionais envolvidos"
              value={job.numero_profissionais ?? 'Indeterminado'}
            />

            <JobAttribute
              name="Sobre a Vaga de Emprego"
              value={`${job.sobre_projeto ?? 'Indeterminado'}`}
            />

            <JobAttribute
              name="Sobre a Empresa"
              value={job.sobre_empresa ?? 'Indeterminado'}
            />

            {job?.empresa?.telefone &&
              (showWhatsapp ? (
                <JobAttribute
                  name="Contato do Recrutador"
                  whatsapp={
                    job?.empresa.telefone ? (
                      <Box sx={{ padding: '0px 6px' }}>
                        +{job?.empresa.get_ddi} {job?.empresa.telefone}
                      </Box>
                    ) : (
                      'Indeterminado'
                    )
                  }
                  whatsappNumber={`${
                    job?.empresa.get_ddi
                  }${job?.empresa.telefone.replaceAll(/\(?\)?\s?\-?/g, '')}`}
                  whatsappText={`Venho via JOB Connect, por meio da vaga de emprego: ${window.location.href} - ${job.nome}`}
                />
              ) : (
                <JobAttribute
                  name="Contato do Recrutador"
                  value={
                    <Button
                      style={{ width: '80%', height: '50%' }}
                      buttonType="info"
                      onClick={() => navigate('/plans_contratado')}
                    >
                      Atualize o seu plano para vizualizar o contato
                    </Button>
                  }
                />
              ))}

            {hasFinished && jobFinalizadoRated && (
              <Grid2 container spacing={2}>
                <Grid2 xs={12}>
                  <Typography
                    component="legend"
                    variant="h6"
                    sx={{ marginTop: '12px' }}
                  >
                    Minha avaliação da vaga de emprego
                  </Typography>
                </Grid2>

                <Grid2 xs={6}>
                  <Typography component="legend" variant="body1">
                    Localização
                  </Typography>
                  <Rating
                    name="read-only"
                    value={jobFinalizadoRated?.contratante_pergunta1}
                    readOnly
                    icon={
                      <StarIcon style={{ fontSize: '24px', color: 'gold' }} />
                    }
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                    }
                  />
                </Grid2>
                <Grid2 xs={6}>
                  <Typography component="legend" variant="body1">
                    Gestão
                  </Typography>
                  <Rating
                    name="read-only"
                    value={jobFinalizadoRated?.contratante_pergunta2}
                    readOnly
                    icon={
                      <StarIcon style={{ fontSize: '24px', color: 'gold' }} />
                    }
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                    }
                  />
                </Grid2>
                <Grid2 xs={6}>
                  <Typography
                    component="legend"
                    variant="body1"
                    sx={{ marginTop: '12px' }}
                  >
                    Relacionamento
                  </Typography>
                  <Rating
                    name="read-only"
                    value={jobFinalizadoRated?.contratante_pergunta3}
                    readOnly
                    icon={
                      <StarIcon style={{ fontSize: '24px', color: 'gold' }} />
                    }
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                    }
                  />
                </Grid2>
                <Grid2 xs={6}>
                  <Typography
                    component="legend"
                    variant="body1"
                    sx={{ marginTop: '12px' }}
                  >
                    Pagamento
                  </Typography>
                  <Rating
                    name="read-only"
                    value={jobFinalizadoRated?.contratante_pergunta4}
                    readOnly
                    icon={
                      <StarIcon style={{ fontSize: '24px', color: 'gold' }} />
                    }
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                    }
                  />
                </Grid2>
              </Grid2>
            )}
          </>
        )}
      </S.JobDescription>
    </S.Container>
  )
}

const JOB_LIMITS = {
  0: 5,
  1: 10,
  2: 20,
  3: Infinity,
}

interface JobDetailsProps {
  job: JobResponse
  isInvite?: boolean
  hasFinished?: boolean
  isApplyJob?: boolean
  isWorking?: boolean
  isCandidate?: boolean
  hasBtnApplyJob?: boolean
  index?: number
  dataVacation?: any
  dataJob?: any
  refetchData?: any
  showWhatsapp?: any
  jobFinishRated?: any
}

export default JobDetails
