import { AddressFormModel } from './address'

export interface CompanyFormModel {
  cnpj: string
  phone: string
  email: string
  taxRegime: string
  fantasyName: string
  legalNature: string
  socialReason: string
  taxRegimeOptions: any
  companyBilling: number
  companyBillingOptions: any
  nationalSimple: boolean
  specialTaxRegime: string
  specialTaxRegimeOptions: any
  municipalRegistration?: string
}

export enum CompanyBillingTypes {
  firstOption = 'Até 15 mil',
  secondOption = 'Até 50 mil',
  thirdOption = 'Até 100 mil',
  fourthOption = 'Acima de 100 mil',
}

export enum TaxRegimeTypes {
  nenhum = 'Nenhum',
  simplesNacional = 'Simples Nacional',
  simplesNacionalExcesso = 'Simples Nacional - Excesso de sublimite de receita bruta',
  normalLucroPresumido = 'Lucro Presumido',
  normalLucroReal = 'Lucro Real',
}

export enum SpecialTaxRegimeTypes {
  semRegimeTributarioEspecial = 'Sem Regime Tributário Especial',
  MicroEmpresaMunicipal = 'Micro Empresa Municipal',
  Estimativa = 'Estimativa',
  SociedadeDeProfissionais = 'Sociedade de Profissionais',
  Cooperativa = 'Cooperativa',
  MicroEmpresárioIndividual = 'Micro Empresário Individual',
  MicroEmpresaOuPequenoPorte = 'Micro Empresa ou Pequeno Porte',
}

export interface PostCompany {
  email: string
  cpfCnpj: string
  telefone: string
  certificado: string
  razaoSocial: string
  nomeFantasia: string
  simplesNacional: boolean
  inscricaoMunicipal: string
  endereco: any
  nfse: {
    ativo: boolean
    tipoContrato: 0 | 1
    config: {
      producao: any // ALTERAR QUANDO O SISTEMA SUBIR P PRODUÇÃO
      prefeitura: {
        login: any
        senha: any
      }
    }
  }
  regimeTributario: TaxRegimeTypes
  regimeTributarioEspecial: SpecialTaxRegimeTypes
}
