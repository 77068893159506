import * as yup from 'yup'
import { REQUIRED_MSG } from 'utils/Constants'

const validationSchema = yup.object().shape({
  cep: yup.string().min(8, 'CEP inválido.').required(REQUIRED_MSG),
  rua: yup.string().required(REQUIRED_MSG),
  numero: yup.string().required(REQUIRED_MSG),
  bairro: yup.string().required(REQUIRED_MSG),
  cidade: yup.string().required(REQUIRED_MSG),
  estado: yup.string().required(REQUIRED_MSG),
})

export default validationSchema
