import React from 'react'
import './styles.scss'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined'
import { useNavigate } from 'react-router-dom'

const AccountingPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className="box-container">
        <div className="box-container-header flex-box">
          <LinearScaleIcon />
          <p>Contabilidade</p>
        </div>
        <div className="accounting-menu">
          <div
            className="onboarding-option"
            onClick={() => navigate('/onboarding')}
          >
            <BadgeOutlinedIcon className="onboarding-option-icon" />
            <div className="accounting-option-title">Contabilidade JOBC</div>
          </div>
          <div
            className="onboarding-option"
            onClick={() => navigate('/nota-fiscal')}
          >
            <DocumentScannerOutlinedIcon className="onboarding-option-icon" />
            <div className="accounting-option-title">Nota Fiscal</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountingPage
