import {
	Card,
	CardActions,
	Grid,
	Typography,
} from '@mui/material'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import {
	useGetContratanteInfosQuery,
	useSolicitarPlanoUserContratanteMutation,
} from '../../store/UserType'
import { Container, Topic, Topics } from './styles'

import { Button } from 'components/Atoms/Button'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from 'store/Layout/slice'
import Services from 'data/components/Services'
import RECRUITER_SERVICES from '../../assets/contracts/portfolio_recrutadores.pdf'
import { useLocation } from 'react-router-dom'

export const PlansContratante = () => {
	const { pathname } = useLocation()
	const dispatch = useDispatch()
	const { data, refetch } = useGetContratanteInfosQuery('')
	const [
		solicitarPlanoContratante,
		{ isLoading: solicitarPlanoContratanteIsLoading },
	] = useSolicitarPlanoUserContratanteMutation()
	const { successSnackbar, infoSnackbar } = useCustomSnackbar()

	const planoAtual = data?.plano
	const planoSolicitado = data?.plano_solicitado

	const handleSolicitarPlanoContratante = (emailValue, planoValue, link) => {
		solicitarPlanoContratante({ emailValue, planoValue })
			.unwrap()
			.then(() => {
				successSnackbar('Parabéns, você solicitou um novo plano')
			})
			.then(() => {
				refetch()
			})
			.then(() => {
				link && window.open(link)
			})
	}

	useEffect(() => {
		dispatch(setLoading(solicitarPlanoContratanteIsLoading))

		return () => {
			dispatch(setLoading(false))
		}
	}, [dispatch, solicitarPlanoContratanteIsLoading])

	return (
		<Container>
			{pathname !== "/home" && (
				<>
					<Services file={RECRUITER_SERVICES} />
					<div style={{ paddingTop: '1rem' }}></div>
				</>
			)}
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
				{plansContratante?.map((itemPlano, index) => (
					<Grid item xs={3} mt={2} key={itemPlano.plano_solicitado}>
						<Card
							sx={{
								display: 'flex',
								flexDirection: 'column',
								borderRadius: '28px',
								height: '100%',
							}}
							key={index}
						>
							<h4
								translate="no"
								style={{
									padding: '12px',
									backgroundColor: '#274490',
									textAlign: 'center',
									color: 'white',
									fontSize: '2.125rem',
									fontWeight: 400,
								}}
							>
								{itemPlano.titulo}
							</h4>

							<Topics>
								{itemPlano.descricao.map((item) => (
									<Topic key={item}>{item}</Topic>
								))}
							</Topics>
							<Typography
								variant="h6"
								color="text.secondary"
								sx={{ textAlign: 'center' }}
							>
								{itemPlano.preco}
							</Typography>

							<CardActions
								sx={{
									width: '100%',
									display: 'block',
									padding: '18px',
									mt: 'auto',
								}}
							>
								{planoAtual === itemPlano.plano_solicitado && (
									<Button
										style={{
											width: '100%',
											border: 'none',
											borderTopLeftRadius: '24px',
											borderBottomRightRadius: '24px',
										}}
										buttonType="success"
										onClick={() => infoSnackbar('Esse já é o seu plano atual')}
									>
										Plano atual
									</Button>
								)}
								{planoSolicitado === itemPlano.plano_solicitado &&
									planoSolicitado !== planoAtual && (
										<Button
											style={{
												width: '100%',
												border: 'none',
												borderTopLeftRadius: '24px',
												borderBottomRightRadius: '24px',
											}}
											buttonType="warning"
											onClick={() =>
												infoSnackbar('Você já solicitou este plano')
											}
										>
											Plano solicitado
										</Button>
									)}
								{itemPlano.plano_solicitado !== planoSolicitado &&
									itemPlano.plano_solicitado !== planoAtual && (
										<Button
											style={{
												width: '100%',
												border: 'none',
												borderTopLeftRadius: '24px',
												borderBottomRightRadius: '24px',
											}}
											buttonType="primary"
											onClick={() =>
												handleSolicitarPlanoContratante(
													data.email,
													itemPlano.plano_solicitado,
													itemPlano.link
												)
											}
										>
											Solicitar plano
										</Button>
									)}
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	)
}

const plansContratante = [
	{
		titulo: 'Basic',
		descricao: [
			'Publicação de vagas de emprego ilimitadas.',
			'A Plataforma irá disponibilizar 3 perfis de profissionais sugeridos para cada vaga de emprego.',
			'Você terá acesso a todos os profissionais que se candidatarem para suas vagas de emprego.',
		],
		preco: 'Custo: Free',
		plano_solicitado: 0,
	},
	{
		titulo: 'Advanced',
		descricao: [
			'Publicação de vagas de emprego ilimitadas.',
			'A Plataforma irá disponibi[lizar 10 perfis de profissionais sugeridos para cada vaga de emprego.',
			'Você terá acesso a todos os profissionais que se candidatarem para suas vagas de emprego.',
		],
		preco: 'Custo: R$ 250/mês',
		plano_solicitado: 1,
		link: 'https://jobc.com.br/product/advanced-recrutador/',
	},
	{
		titulo: 'Premium',
		descricao: [
			'Publicação de vagas de emprego ilimitadas.',
			'A Plataforma irá disponibilizar 20 perfis de profissionais sugeridos para cada vaga de emprego.',
			'Você terá acesso a todos os profissionais que se candidatarem para suas vagas de emprego.',
		],
		preco: 'Custo: R$ 500/mês',
		plano_solicitado: 2,
		link: 'https://jobc.com.br/product/premium-recrutador/',
	},
	{
		titulo: 'Exclusive',
		descricao: [
			'Publicação de vagas de emprego ilimitadas.',
			'A Plataforma irá sugerir todos os profissionais que correspondem ao perfil para cada vaga de emprego.',
			'*** Acesso ilimitado à busca de profissionais no banco de talentos, com mais de 12 mil profissionais cadastrados.',
		],
		preco: 'Custo: R$ 1500/mês',
		plano_solicitado: 3,
		link: 'https://jobc.com.br/product/exclusive-recrutador/',
	},
]
