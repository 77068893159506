import './styles.scss'
import PartnerData from 'data/components/PartnerData'
import SocialContract from 'data/components/SocialContract'
import MaterialProgress from 'data/components/MaterialProgress'
import OnboardingWelcome from 'data/components/OnboardingWelcome'
import OnboardingDone from 'data/components/OnboardingDone'
import CompanyCertificate from 'data/components/CompanyCertificate'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useCallback, useState } from 'react'
import ServiceContract from 'data/components/ServiceContract'
import Responsibilities from 'data/components/Responsibilities'
// import { useOnboardingAdvance } from 'data/hooks/useOnboardingAdvance'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Button, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ChosenPanel from 'pages/Company/TechJobs/ChosenPanel'
import { useGetOnboardingAdvanceQuery } from 'store/NodeApi/api'
// import { useOnboardingAdvance } from 'data/hooks/useOnboardingAdvance'

const Onboarding: React.FC<any> = ({ adminEdition = false }) => {
  const [currentTab, setCurrentTab] = useState(0)

  const handleChangeCurrentTab = useCallback(
    (_: React.SyntheticEvent, currentTab: number) => {
      setCurrentTab(currentTab)
    },
    []
  )
  const navigate = useNavigate()

  // const [advancePercentage] = useOnboardingAdvance()

  const nodeCompany = useSelector(({ node }: RootState) => node?.nodeCompany)
  // const { data: progressSituation } = useGetOnboardingAdvanceQuery(nodeCompany)
  const hasCompany = !!nodeCompany?.id

  // const isAdvanceComplete = progressSituation! * 100 === 100

  return (
    <>
      <Grid display={'flex'} alignItems="center">
        <Button
          onClick={() => navigate('/accounting')}
          style={{ gap: '0.5rem' }}
        >
          <ArrowBackRoundedIcon /> Voltar
        </Button>
      </Grid>
      {/* {!adminEdition && isAdvanceComplete ? (
        <OnboardingDone />
      ) : (
        <OnboardingWelcome />
      )} */}

      <div className="profileContainer">
        <div style={{ display: 'flex' }}>
          <div className="onboarding-progress">
            <MaterialProgress value={50} />
            <div>Progresso</div>
          </div>
          <Tabs
            value={currentTab}
            onChange={handleChangeCurrentTab}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
            className="profile-tabs"
          >
            <Tab className="profile-tab" label="Dados da Empresa" />
            <Tab
              className="partner-tab"
              label="Sócios"
              disabled={!hasCompany}
            />
            <Tab
              className="social-contract-tab"
              label="Contrato Social"
              disabled={!hasCompany}
            />
            <Tab
              className="service-contract-tab"
              label="Contrato de Serviço"
              disabled={!hasCompany}
            />
            <Tab
              className="responsibilities-tab"
              label="Responsabilidades"
              disabled={!hasCompany}
            />
          </Tabs>
        </div>
        <section className="panels">
          <ChosenPanel is={currentTab}>
            <CompanyCertificate />
            <PartnerData />
            <SocialContract />
            <ServiceContract />
            <Responsibilities />
          </ChosenPanel>
        </section>
      </div>
    </>
  )
}

export default Onboarding
