import styled from 'styled-components'

export const Container = styled.div`
  gap: 0.3rem;
  width: 180px;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Chip = styled.div`
  border-radius: 10px;
  font-size: 0.925rem;
  text-align: center;
  padding: 0.2rem;
  border: 1px solid #555;
`
