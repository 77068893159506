import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import routes from 'utils/Constants/routes'

type ExceptionProps = {
  image: string
  alt: string
  title: string
}

const ExceptionContent = (props: ExceptionProps) => {
  const navigate = useNavigate()

  return (
    <div className="exception-content">
      <img src={`/assets/images/asset-${props.image}.svg`} alt={props.alt} />
      <span>{props.title}</span>

      <Button variant="outlined" onClick={() => navigate(routes.HOME)}>
        Voltar à página inicial
      </Button>
    </div>
  )
}

export default ExceptionContent
