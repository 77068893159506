import { PersonalDataTabForm } from './types'

export const INITIAL_VALUES: PersonalDataTabForm = {
  nome: '',
  email: '',
  pais: 'BR',
  telefone: '',
  senha: '',
  imagem: undefined,
}
