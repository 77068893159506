import { MaterialTableColumn } from 'domain/models/materialColumn'
import { getBrazilianCurrency } from '../regex'

export const serviceColumns: readonly MaterialTableColumn[] = [
	{ id: 'service', label: 'Serviço', minWidth: 170 },
	{ id: 'description', label: 'Descrição', minWidth: 100 },
	{
		id: 'value',
		minWidth: 170,
		label: 'Valor',
		format: getBrazilianCurrency,
	},
	{
		minWidth: 170,
		id: 'payment_description',
		label: 'Descrição do pagamento',
	},
	{
		minWidth: 100,
		id: 'average_delivery_time',
		label: 'Tempo médio de entrega',
	},
]

export const defaultAdditionalServiceForm = {
	value: '',
	service: '',
	description: '',
	payment_description: '',
	average_delivery_time: '',
}

export const defaultDisplayFields = {
	serviceForm: false,
	serviceList: true,
}

export const displayListBody = {
	serviceForm: false,
	serviceList: true,
}

export const displayFormBody = {
	serviceForm: true,
	serviceList: false,
}
