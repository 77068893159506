import { useEffect, useState } from 'react'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Box,
	TextField,
	Typography,
	Stepper,
	Step,
	StepLabel,
	StepContent,
} from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { object, string } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import InputText from '../../Atoms/InputText'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoadingButton as _LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify'
import {
	useSignInMutation,
	useForgetPasswordMutation,
	useForgetPasswordCodeMutation,
	useForgetPasswordCodeSendMutation,
} from '../../../store/Auth/AuthAPI'
import * as S from './styles'
import { Button } from 'components/Atoms/Button'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { SignInRequest } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { useLazyGetUserRoleQuery } from 'store/UserType'
import { setUserRole } from 'store/User/UserSlice'
import { Role } from 'utils/Enums'
import routes from 'utils/Constants/routes'
import { setLoading } from 'store/Layout/slice'
import { clearAuth } from 'utils/Storage'

const loginSchema = object({
	email: string()
		.nonempty('Email address is required')
		.email('Email Address is invalid'),
	password: string()
		.nonempty('Password is required')
		.min(8, 'Password must be more than 8 characters')
		.max(32, 'Password must be less than 32 characters'),
})

const LoginForm = () => {
	const routeState = useLocation().state as {
		from: { pathname: string }
	} | null
	const dispatch = useDispatch()
	const methods = useForm<SignInRequest>({
		resolver: zodResolver(loginSchema),
	})
	const { warningSnackbar, successSnackbar } = useCustomSnackbar()
	const navigate = useNavigate()
	const [signIn, { isLoading: signInIsLoading, isError, error }] =
		useSignInMutation()
	const [forgetPassword, { isLoading: forgetPasswordIsLoading }] =
		useForgetPasswordMutation()
	const [forgetPasswordCode, { isLoading: forgetPasswordCodeIsLoading }] =
		useForgetPasswordCodeMutation()
	const [
		forgetPasswordCodeSend,
		{ isLoading: forgetPasswordCodeSendIsLoading },
	] = useForgetPasswordCodeSendMutation()
	const [dataCodeResponse, setDataCodeResponse] = useState('')
	const [getUserRole] = useLazyGetUserRoleQuery()
	const { role: userRole } = useSelector((state: RootState) => state.userState)
	const { loading } = useSelector((state: RootState) => state.layout)

	const { isAuthenticated } = useSelector((state: RootState) => state.auth)

	const [passwordUser1, setPasswordUser1] = useState('')
	const [passwordUser2, setPasswordUser2] = useState('')

	const [modalCode, setModalCode] = useState(false)
	const [emailForgetSend, setMailForgetSend] = useState('')
	const [codeForgetSend, setCodeForgetSend] = useState('')
	const [activeStep, setActiveStep] = useState(0)

	const [pathnameFrom] = useState<string | undefined>(
		routeState?.from?.pathname
	)

	const {
		reset,
		handleSubmit,
		formState: { isSubmitSuccessful },
	} = methods

	useEffect(() => {
		if (!isAuthenticated) return

		if (!userRole) {
			dispatch(setLoading(true))
			getUserRole()
				.unwrap()
				.then((res) => {
					if (!res) {
						toast.warning('Sua sessão expirou, efetue login novamente');
						clearAuth()
						return;
					}

					dispatch(setUserRole(res ?? Role.EMPTY))
					toast.success('Login realizado com sucesso!')
					if (pathnameFrom === undefined || pathnameFrom === '/login') {
						if (res === Role.ADMIN) navigate(routes.ADMIN)
						else navigate(routes.HOME)
					} else navigate(pathnameFrom)
				})
				.finally(() => {
					dispatch(setLoading(false))
				})
		}
	}, [dispatch, getUserRole, isAuthenticated, pathnameFrom, navigate, userRole])

	useEffect(() => {
		if (isError) {
			if (Array.isArray((error as any).data?.error)) {
				; (error as any).data.error.forEach((el: any) =>
					toast.error(el.message, {
						position: 'top-right',
					})
				)
			} else {
				toast.error((error as any).data?.message, {
					position: 'top-right',
				})
			}
		}
	}, [error, isError])

	useEffect(() => {
		if (isSubmitSuccessful) reset()
	}, [isSubmitSuccessful, reset])

	const onSubmitHandler: SubmitHandler<SignInRequest> = (values) => {
		signIn(values)
			.unwrap()
			.catch((err) => {
				if (err.data.non_field_errors) {
					err.data.non_field_errors.forEach((item) => warningSnackbar(item))
				}
			})
	}

	const onSubmitForgetHandler = () => {
		forgetPassword(emailForgetSend)
			.unwrap()
			.then(() => {
				successSnackbar(
					'Um código para a restauração de senha será enviado para seu email'
				)
			})
			.then(() => {
				setActiveStep((prevActiveStep) => prevActiveStep + 1)
			})
			.catch(() => {
				warningSnackbar(
					'Erro ao enviar código para email. Tente novamente mais tarde.'
				)
			})
	}

	const onSubmitForgetCodeHandler = () => {
		forgetPasswordCode({ code: codeForgetSend, email: emailForgetSend })
			.unwrap()
			.then((res) => {
				setDataCodeResponse(res.codigo_verificado)

				switch (res.result) {
					case 'CODIGO_INVALIDO':
						warningSnackbar('Código digitado é inválido')
						break

					case 'TENTATIVAS_EXCEDIDAS':
						warningSnackbar('Tentativas excedidas, tente novamente!')
						break

					case 'OK':
					default:
						successSnackbar('Código validado com sucesso')
				}
			})
			.then(() => {
				setActiveStep((prevActiveStep) => prevActiveStep + 1)
			})
			.catch((res) => {
				warningSnackbar('Falha ao validar código.')
			})
	}

	const onSubmitForgetCodeSendHandler = () => {
		forgetPasswordCodeSend({
			password: passwordUser1,
			code: dataCodeResponse,
			email: emailForgetSend,
		})
			.unwrap()
			.then((res) => {
				successSnackbar('Senha alterada com sucesso')
			})
			.then((res) => {
				setModalCode(false)
			})
			.catch(() => {
				warningSnackbar('Erro ao trocar senha. Tente novamente mais tarde.')
			})
	}

	const handleClose = () => {
		setModalCode(false)
	}

	const handleNext = () => {
		switch (activeStep) {
			case 0:
				onSubmitForgetHandler()
				break
			case 1:
				onSubmitForgetCodeHandler()
				break
			case 2:
				onSubmitForgetCodeSendHandler()
				break

			default:
				break
		}
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleReset = () => {
		setActiveStep(0)
	}

	const emailData = () => {
		return (
			<>
				<TextField
					sx={{ padding: '4px' }}
					autoFocus
					margin="dense"
					id="name"
					label="Digite o seu e-mail"
					type="email"
					fullWidth
					variant="standard"
					value={emailForgetSend}
					onChange={(event) => setMailForgetSend(event.target.value)}
				/>
			</>
		)
	}
	const codigoData = () => {
		return (
			<>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Digite o código"
					type="email"
					fullWidth
					variant="standard"
					value={codeForgetSend}
					onChange={(event) => setCodeForgetSend(event.target.value)}
				/>
			</>
		)
	}
	const senhaData = () => {
		return (
			<>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Digite a nova senha"
					type="password"
					fullWidth
					variant="standard"
					value={passwordUser1}
					onChange={(event) => setPasswordUser1(event.target.value)}
				/>
				{!!passwordUser1 && passwordUser1.length <= 8 && (
					<Typography variant="subtitle1" gutterBottom>
						Senha deve conter mais de 8 caracteres
					</Typography>
				)}
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Digite a nova senha"
					type="password"
					fullWidth
					variant="standard"
					value={passwordUser2}
					onChange={(event) => setPasswordUser2(event.target.value)}
				/>
				{!!passwordUser2 && passwordUser1 !== passwordUser2 && (
					<Typography variant="subtitle1" gutterBottom>
						Senha que você digitou não coincidem
					</Typography>
				)}
			</>
		)
	}

	const steps = [
		{
			label: 'Digite o seu e-mail',
			func: emailData(),
		},
		{
			label: 'Digite o código',
			func: codigoData(),
		},
		{
			label: 'Digite a nova senha',
			func: senhaData(),
		},
	]

	return (
		<Box sx={{ width: ['-webkit-fill-available', '-moz-available'] }}>
			<FormProvider {...methods}>
				<Box
					component="form"
					onSubmit={handleSubmit(onSubmitHandler)}
					noValidate
					autoComplete="off"
					width="100%"
				>
					<InputText name="email" label="Email" type="email" />
					<InputText name="password" label="Senha" type="password" />
					<Button
						type={'submit'}
						loading={signInIsLoading || loading}
						buttonType="primary"
					>
						Entrar
					</Button>

					<S.PasswordForget onClick={() => setModalCode(true)}>
						Esqueci minha senha
					</S.PasswordForget>

					<S.PasswordForget>Versão: 7.0.0</S.PasswordForget>

					<S.RowDivider>
						<S.CreateAccountText>Não tem conta ainda? </S.CreateAccountText>
						<S.CreateAccountLink
							onClick={() => {
								navigate('/select-type-user')
							}}
						>
							Criar Conta
						</S.CreateAccountLink>
					</S.RowDivider>
				</Box>
			</FormProvider>

			<Dialog open={modalCode} onClose={handleClose} maxWidth="sm" fullWidth>
				<DialogTitle>Recuperação de senha</DialogTitle>
				<DialogContent>
					<Stepper activeStep={activeStep} orientation="vertical">
						{steps.map((step, index) => (
							<Step key={step.label}>
								<StepLabel>{step.label}</StepLabel>
								<StepContent>
									<Typography>{step.func}</Typography>
									<Box sx={{ mb: 2 }}>
										<div>
											<Button
												buttonType="primary"
												onClick={handleNext}
												loading={
													forgetPasswordIsLoading ||
													forgetPasswordCodeIsLoading ||
													forgetPasswordCodeSendIsLoading
												}
												disabled={
													(activeStep === 0 && !emailForgetSend) ||
													(activeStep === 1 && !codeForgetSend) ||
													(activeStep === 2 &&
														(!passwordUser1 ||
															!passwordUser2 ||
															passwordUser1.length <= 8 ||
															passwordUser1 !== passwordUser2))
												}
											>
												{index === steps.length - 1
													? 'Salvar senha nova'
													: 'Enviar'}
											</Button>
										</div>
									</Box>
								</StepContent>
							</Step>
						))}
					</Stepper>
				</DialogContent>
			</Dialog>
		</Box>
	)
}

export default LoginForm
