import { cpf } from "cpf-cnpj-validator";
import { DependentFormModel } from "domain/models/dependent";

export const dependentFormValidation = (partnerForm: DependentFormModel) => {
  const errors: any = {};

  if (partnerForm.name.length === 0) errors.name = "Nome é obrigatório";
  if (partnerForm.cpf.length === 0 || !cpf.isValid(partnerForm.cpf))
    errors.cpf = "Insira um CPF válido";

  return errors;
};
