import nodeApi from '../api'

const injectedNodeApi = nodeApi.injectEndpoints({
    endpoints: (build) => ({
        postSocialContract: build.mutation<any, any>({
            query: (socialContractFile) => ({
                method: 'POST',
                url: '/socialContracts',
                body: socialContractFile,
            }),
        }),
        getSimpleSocialContracts: build.query<any, any>({
            query: () => `/socialContracts`,
            transformResponse: (response: any) => response?.data,
        }),
        deleteSocialContract: build.mutation<any, any>({
            query: (s3key) => ({
                method: 'DELETE',
                url: `/socialContracts/${s3key}`,
            }),
        }),
    }),
})

export const {
    usePostSocialContractMutation,
    useDeleteSocialContractMutation,
    useGetSimpleSocialContractsQuery,
} = injectedNodeApi

export default injectedNodeApi
