import { Divider, Pagination, TextField } from '@mui/material'
import { userColumns } from 'data/utils/User/static'
import Onboarding from 'presentation/views/Onboarding'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyGetNodeCompanyByEmailQuery } from 'store/NodeApi/company/endpoint'
import { useGetAllUsersQuery } from 'store/NodeApi/user/endpoint'
import { setLoggedUser } from 'store/User/UserSlice'
import MaterialTable from '../MaterialTable'

import './styles.scss'
import { NOTA_FISCAL_URL } from 'utils/Constants'

const defaultUser = {
  nome: '',
  cnpj_cpf: '',
  telefone: '',
  whatsapp: '',
  get_whatsapp: '',
  get_ddi: '',
  email: '',
  imagem: '',
  cep: '',
  rua: '',
  numero: '',
  bairro: '',
  cidade: '',
  estado: '',
  descricao: '',
  curriculo_doc: '',
  qtde_oportunidades: 0,
  pais: '',
  plano: 0,
  plano_solicitado: 0,
  plano_contabilidade: 0,
  plano_contabilidade_solicitado: 0,
}

const OnboardingController: React.FC = () => {
  const [page, setPage] = useState(1)
  const [userFilter, setUserFilter] = useState('')
  const [selectedUserRows, setSelectedUserRows] = useState<number[]>([])
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [getCompanyByEmail] = useLazyGetNodeCompanyByEmailQuery()

  const { data } = useGetAllUsersQuery({
    page,
    pageSize: 10,
    username: userFilter,
  })

  const users = useMemo(() => {
    if (!data?.users) return []

    return data.users
  }, [data?.users])

  const dispatch = useDispatch()

  useEffect(() => {
    const selectedUserId = selectedUserRows?.[0]

    const selectedUser = data?.users.find((user) => user?.id === selectedUserId)

    if (!selectedUser?.id) return

    dispatch(setLoggedUser({ ...defaultUser, ...selectedUser }))
    getCompanyByEmail(selectedUser?.email).unwrap()
  }, [data, dispatch, getCompanyByEmail, selectedUserRows])

  const handleChangeUserFilter = (value: string) => {
    setUserFilter(value)
  }

  const tableProps = {
    data: users,
    columns: userColumns,
    selectedRows: selectedUserRows,
    setSelectedRows: setSelectedUserRows,
  }

  const selectedUserId = selectedUserRows?.[0] || null

  useEffect(() => {
    if (!iframeRef.current) return

    setTimeout(() => {
      iframeRef.current?.contentWindow?.postMessage(selectedUserId, '*')
    }, 2500)
  }, [selectedUserId])

  return (
    <div className="onboarding-controller-container">
      <div className="onboarding-controller-user-container">
        <TextField
          id="service-filter"
          value={userFilter}
          label="Filtrar usuários"
          className="onboarding-controller-user-filter"
          placeholder="Insira o nome/email do usuário"
          onChange={(e) => handleChangeUserFilter(e?.target?.value)}
        />
        <MaterialTable {...tableProps} />
        <Pagination
          style={{
            marginLeft: 'auto',
            width: 'fit-content',
            marginTop: '1rem',
          }}
          count={data?.totalPages}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(_, page) => setPage(page)}
        />
      </div>
      {selectedUserId ? (
        <>
          <Onboarding adminEdition={true} />
          <Divider />
          <iframe
            ref={iframeRef}
            src={NOTA_FISCAL_URL}
            name="IFRAME"
            style={{
              border: 'none',
              height: '100vh',
            }}
          />
        </>
      ) : (
        <div
          style={{
            width: '100%',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h1>Selecione um usuário!</h1>
        </div>
      )}
    </div>
  )
}

export default OnboardingController
