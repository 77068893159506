import { MaterialTableColumn } from 'domain/models/materialColumn'
import { getBrazilianCurrency } from '../regex'

export const getRemainingQuota = (partners: any) => {
  const totalQuota = partners?.reduce((accumulator, object) => {
    return accumulator + object?.quota
  }, 0)

  if (totalQuota > 100) return 0

  return 100 - totalQuota
}

export const partnerColumns: readonly MaterialTableColumn[] = [
  { id: 'name', label: 'Nome', minWidth: 170 },
  { id: 'cpf', label: 'CPF', minWidth: 100 },
  {
    id: 'quota',
    label: 'Cota (%)',
    minWidth: 170,
  },
  {
    id: 'prolaboreValue',
    label: 'Prolabore',
    format: getBrazilianCurrency,
    minWidth: 170,
  },
  {
    id: 'pisNit',
    label: 'Pis/Nit',
    minWidth: 170,
  },
]

export const defaultPartnerForm = {
  cpf: '',
  name: '',
  quota: '',
  pisNit: '',
  dependents: [],
  prolaboreValue: '',
  hasProlabore: false,
  hasDependents: false,
}

export const defaultDisplayFields = {
  partnerForm: false,
  partnerList: true,
}

export const displayListBody = {
  partnerList: true,
  partnerForm: false,
}

export const displayFormBody = {
  partnerList: false,
  partnerForm: true,
}
