import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  LanguageLevelOptionResponse,
  LanguageRequest,
  LanguageOptionResponse,
  UserLanguageResponse,
} from 'types'

import {
  APP_STORAGE_PREFIX,
  APP_API_URI,
  LANGUAGE,
  LANGUAGE_LEVELS,
  USER_LANGUAGE,
} from 'utils/Constants'
import { StorageGetItem } from 'utils/Storage'

export const profileRedux = createApi({
  reducerPath: 'profileRedux',
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_API_URI}`,
    prepareHeaders: (headers) => {
      const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))

      if (token) headers.set('Authorization', `JWT ${token}`)

      return headers
    },
  }),
  endpoints: (builder) => ({
    getLanguageOptions: builder.query<LanguageOptionResponse[], void>({
      query: () => `${LANGUAGE}`,
    }),
    getLanguageLevelOptions: builder.query<LanguageLevelOptionResponse[], void>(
      {
        query: () => `${LANGUAGE_LEVELS}`,
      }
    ),
    getUserLanguages: builder.query<UserLanguageResponse[], void>({
      query: () => `${USER_LANGUAGE}`,
    }),
    addUserLanguage: builder.mutation<
      LanguageOptionResponse[],
      LanguageRequest
    >({
      query: (body) => ({
        url: `${USER_LANGUAGE}`,
        method: 'POST',
        body,
      }),
    }),
    deleteUserLanguage: builder.mutation<void, string>({
      query: (hash) => ({
        url: `${USER_LANGUAGE}${hash}/`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetLanguageOptionsQuery,
  useGetLanguageLevelOptionsQuery,
  useGetUserLanguagesQuery,
  useAddUserLanguageMutation,
  useDeleteUserLanguageMutation,
} = profileRedux
