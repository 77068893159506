import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import {
  Autocomplete,
  TextField,
  Box,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { useFormik } from 'formik'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { PatternFormat } from 'react-number-format'
import { isEmpty } from 'lodash'

import cameraImg from '../../../../../../img/camera.png'
import blankProfile from '../../../../../../img/blank_profile.png'
import validationSchema from './validations'
import { INITIAL_VALUES } from './constants'
import { COUNTRIES } from 'utils/Constants'
import { CompanyDataTabForm, CompanyDataTabRef } from './types'

const CompanyDataTab = (_, ref: React.Ref<CompanyDataTabRef>) => {
  const [showPassword, setShowPassword] = useState(false)

  const { errors, handleChange, setFieldValue, values, validateForm } =
    useFormik<CompanyDataTabForm>({
      validationSchema,
      initialValues: INITIAL_VALUES,
      onSubmit: () => {},
      validateOnChange: false,
      validateOnBlur: false,
    })

  const validate = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        validateForm().then((errors) => {
          if (isEmpty(errors)) resolve()
          else reject()
        })
      }),
    [validateForm]
  )

  const getNumberFormat = useCallback(() => {
    const country = COUNTRIES.find((item) => item.code === values.pais)

    if (!country) return '(##) #####-####'

    return `+${country.phone} (##) #####-####`
  }, [values.pais])

  useImperativeHandle(ref, () => ({ validate, values }), [validate, values])

  return (
    <div className="box-all">
      <div className="horinzontal">
        <TextField
          name="nome"
          variant="standard"
          label="Nome"
          value={values.nome}
          onChange={handleChange}
          error={!!errors.nome}
          helperText={errors.nome}
          margin="dense"
        />

        <TextField
          label="Email"
          name="email"
          variant="standard"
          value={values.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          margin="dense"
        />

        <Autocomplete
          renderOption={(props, option) => {
            const country = COUNTRIES.find((item) => item.code === option)

            if (!country) return <></>

            return (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {country.label} ({country.code}) +{country.phone}
              </Box>
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="País"
              name="pais"
              variant="standard"
              error={!!errors.pais}
              helperText={errors.pais}
              margin="dense"
              InputProps={{
                ...params.InputProps,
                startAdornment: values.pais && (
                  <InputAdornment position="start">
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${values.pais.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${values.pais.toLowerCase()}.png 2x`}
                      alt=""
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
          value={values.pais}
          autoHighlight
          getOptionLabel={(option) =>
            COUNTRIES.find((item) => item.code === option)?.label ?? ''
          }
          onChange={(_, newValue) =>
            setFieldValue('pais', newValue || undefined)
          }
          options={COUNTRIES.map((item) => item.code)}
        />

        <PatternFormat
          customInput={TextField}
          label="Whatsapp | Telefone"
          type="tel"
          variant="standard"
          name="telefone"
          value={values.telefone}
          onValueChange={({ value }) => setFieldValue('telefone', value)}
          error={!!errors.telefone}
          helperText={errors.telefone}
          margin="dense"
          format={getNumberFormat()}
          valueIsNumericString
        />

        <PatternFormat
          customInput={TextField}
          label="CNPJ"
          type="tel"
          variant="standard"
          value={values.cnpj_cpf}
          onValueChange={({ value }) => {
            setFieldValue('cnpj_cpf', value)
          }}
          error={errors.cnpj_cpf !== undefined}
          helperText={errors.cnpj_cpf}
          margin="dense"
          format={'##.###.###/###-##'}
          valueIsNumericString
        />

        <TextField
          type={showPassword ? 'text' : 'password'}
          label="Senha"
          name="senha"
          variant="standard"
          value={values.senha}
          onChange={handleChange}
          error={!!errors.senha}
          helperText={errors.senha}
          margin="dense"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div className="align">
        <label className="foto" htmlFor="input-img">
          <img
            className={`img_profile ${errors.imagem ? 'error' : 'normal'}`}
            src={
              values.imagem ? URL.createObjectURL(values.imagem) : blankProfile
            }
          />

          <img className="camera-icon" src={cameraImg} />
        </label>

        <input
          type="file"
          accept="imagem/*"
          id="input-img"
          className="no-hidden"
          onChange={(event) => {
            if (event.target.files)
              setFieldValue('imagem', event.target.files[0])
          }}
        />

        <label className="helper-box">
          {errors.imagem && 'Foto é obrigatória'}
        </label>
      </div>
    </div>
  )
}

export default forwardRef(CompanyDataTab)

export type { CompanyDataTabRef }
