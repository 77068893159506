export const getNodeUrlRoute = () => {
  const url = window?.location?.href

  if (url.includes('localhost')) return NODE_API_LOCALHOST
  if (url.includes('qa')) return NODE_API_QA

  return NODE_API_PROD
}

export const NOTA_FISCAL_URL = 'https://jobc-contabilidade.vercel.app'

//  GLOBALS CONSTANTS

/*
 *  API AND STORAGE
//  */
export const APP_API_URI = 'https://plataforma.jobc.com.br/api/'
export const APP_API_URI_WITHOUT_API = 'https://plataforma.jobc.com.br/'

// export const APP_API_URI_WITHOUT_API = 'http://localhost:8000'
// export const APP_API_URI = APP_API_URI_WITHOUT_API + '/api/'

// export const APP_API_URI = 'https://teste-plataforma.jobc.com.br/api/'
// export const APP_API_URI_WITHOUT_API = 'https://teste-plataforma.jobc.com.br/'
export const APP_STORAGE_PREFIX = '@JOB-CONNECT/'
export const APP_PREFIX = 'JOB-Connect'
export const APP_API_VERSION = 'v1'
export const UNAUTHORIZED = 401

/*
 *  NODE API
 */

export const NODE_API_LOCALHOST = 'https://job-api.jobc.com.br/api/'
export const NODE_API_QA = 'https://job-api-qa.jobc.com.br/api/'
export const NODE_API_PROD = 'https://job-api.jobc.com.br/api/'
export const NODE_API_VERSION = 'v1/'
export const NODE_API = getNodeUrlRoute() + NODE_API_VERSION
/*
 *  BOLETOS
 */
export const BOLETO_API_URI = 'https://plugboleto.com.br/api/v1'
export const CNPJ_SH = '41630410000101'
export const TOKEN_SH = '6102130b77f4e8bf37c51172fdf01703'

/*
 *  NOTAS
 */
export const NOTAS_API_URI = 'https://api.plugnotas.com.br'
export const X_API_KEY = '634b7e73-f801-4193-b225-ec6e24562a49'

/*
 *  TITLES AND SLOGAN
 */
export const APP_TITLE = 'JOB Connect'
export const APP_SLOGAN =
  'Plataforma de vagas de emprego de tecnologia e seleção de profissionais.'

/*
 *  API ROUTES CONSTANTS
 */

// AUTH
export const API_TOKEN_AUTH = `api-token-auth/`

// V1 USER
export const USER_TYPE_VERIFY = `${APP_API_VERSION}/usuario/tipo/`
export const USER_INFOS = `${APP_API_VERSION}/usuario/update/`
export const USER_INFOS_CONTRATANTE = `${APP_API_VERSION}/usuarios/observacao/listagem/adm/altera_plano_solicitado/`
export const USER_CHECK_REGISTRATION = `${APP_API_VERSION}/usuario/verifica-cadastro/`
export const USER_FORGET_PASSWORD = `${APP_API_VERSION}/usuario/codigo-senha/`
export const USER_FORGET_PASSWORD_CODE = `${APP_API_VERSION}/usuario/validar-codigo-senha/`
export const USER_FORGET_PASSWORD_CODE_SEND = `${APP_API_VERSION}/usuario/alterar-senha-codigo/`
export const USER_REQUEST_LEAVE = `${APP_API_VERSION}/convites/solicitar-saida/`
export const WORKER_ACCEPT_LEAVE = '/v1/convites/aprovar-saida/'
export const WORKER_CANCEL_LEAVE = '/v1/convites/cancelar-saida/'
export const RECRUITER_REFUSE_LEAVE = `${APP_API_VERSION}/convites/recusar-saida/`
export const OPERATION_RADIUS = `${APP_API_VERSION}/usuario/raio/atuacao/`
export const USER_OPERATION_AREA = `${APP_API_VERSION}/usuario/atuacao/`
export const USER_EXPERIENCE = `${APP_API_VERSION}/usuario/experiencia/`
export const PROJECT_PEOPLE_SUGGESTION = `${APP_API_VERSION}/usuario/sugestao-projeto`

// PROFILE
export const OPERATION_AREA = `${APP_API_VERSION}/areaatuacao/`
export const OPERATION_AREA_LEVELS = `${APP_API_VERSION}/areaatuacao/niveis/`
export const LANGUAGE = `${APP_API_VERSION}/lingua/`
export const LANGUAGE_LEVELS = `${APP_API_VERSION}/lingua/niveis/`
export const USER_LANGUAGE = `${APP_API_VERSION}/usuario/lingua/`

export const UPDATE_APPLICATION = `${APP_API_VERSION}/convites/alterar-candidatura`

// V1 PROJETOS
export const PROJECTS = `${APP_API_VERSION}/projetos/`
export const GET_JOBS_WITH_FILTER = `${APP_API_VERSION}/projetos/filtrar/listagem/`
export const SET_JOB_PROGRESS = `${APP_API_VERSION}/projetos/alterar-andamento-force/`
export const SET_JOB_FINISHED_STATUS = `${APP_API_VERSION}/projetos/alterar-finalizado-force/`
export const REPUBLISH_JOB = `${APP_API_VERSION}/projetos/republicar_projeto/`

// Promotion
export const PROMOTION = `${APP_API_VERSION}/promocoes/get/listagem/`

// Rating
export const RATINGQTD = `${APP_API_VERSION}/convites/avaliacoes-pendentes-qtd/`
export const RATING = `${APP_API_VERSION}/convites/avaliacoes-pendentes/`
export const RATINGCOMPANY = `${APP_API_VERSION}/convites/avaliar-empresa/`
export const RATINGTECH = `${APP_API_VERSION}/convites/avaliar-consultor/`

// Favorite Vacancies
export const ADD_FAVORITE = `${APP_API_VERSION}/usuario/favoritar-projeto/`
export const DELETE_FAVORITE = `${APP_API_VERSION}/usuario/desfavoritar-projeto/`

// Job Preferencies
export const JOB_PREFERENCIES = `${APP_API_VERSION}/preferencia_busca/`
export const ADD_JOB_PREFERENCE = `${APP_API_VERSION}/preferencia_busca/`
export const DELETE_JOB_PREFERENCE = `${APP_API_VERSION}/preferencia_busca/`
export const JOB_PREFERENCE_QUANTITY = `${APP_API_VERSION}/preferencia_busca/qtd-vagas-encontrados/`

// Invitation Vacancies
export const INVITATION_VACANCIES_PEDENT = `${APP_API_VERSION}/convites/avaliacoes-pendentes/`
export const INVITATION = `${APP_API_VERSION}/convites/`
export const INVITATION_ACCEPT = `${APP_API_VERSION}/convites/aceitar-aprovacao/`
export const MY_APPLYCATIONS = `${APP_API_VERSION}/convites/busca/convites/?tipo=CANDIDATURA`
export const MY_INVITATIONS = `${APP_API_VERSION}/convites/busca/convites/`
export const MY_INVITATIONS_QTD = `${APP_API_VERSION}/convites/oportunidades-qtd/`
export const ACCEPT_INVITATION = `${APP_API_VERSION}/convites/aceitar/`
export const RESTART_INVITATION = `${APP_API_VERSION}/convites/reiniciar-convite`
export const REFUSE_INVITATION = ``
// export const REFUSE_INVITATION = `${APP_API_VERSION}/convites/oportunidades-qtd/`

// Professional
export const SEARCH_PROFESSIONALS = `${APP_API_VERSION}/usuario/pesquisa-profissional/`
export const PENDING_APPLICATIONS_QUANTITY = `${APP_API_VERSION}/projetos/candidaturas_pendentes/quantidade/`
export const PROFESSIONAL_PROFILE = `${APP_API_VERSION}/usuario/perfil/`
export const PROFESSIONAL_QUANTITY = `${APP_API_VERSION}/usuario/numero_profissionais/`
