export enum RelationshipGrade {
  CHILDREN = 'children',
  PARENT = 'parent',
  PARTNER = 'partner',
}

export interface DependentFormModel {
  cpf: string
  name: string
  birthdate: string
  relationshipGradeOptions: any
  relationshipGrade: RelationshipGrade
  partnerId?: any
}

export interface DisplayFields {
  dependentForm: boolean
  dependentList: boolean
}
