import { useCallback } from 'react'
import { useSnackbar } from 'notistack'

export const useCustomSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar()

  const infoSnackbar = useCallback(
    (message = '') => {
      enqueueSnackbar(message, {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    },
    [enqueueSnackbar]
  )

  const warningSnackbar = useCallback(
    (message = '') => {
      enqueueSnackbar(message, {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    },
    [enqueueSnackbar]
  )

  const successSnackbar = useCallback(
    (message = '') => {
      enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    },
    [enqueueSnackbar]
  )

  const errorSnackbar = useCallback(
    (message = '') => {
      enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    },
    [enqueueSnackbar]
  )

  return { warningSnackbar, successSnackbar, errorSnackbar, infoSnackbar }
}
