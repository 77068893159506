import * as S from './styles'
import CircularProgress from '@mui/material/CircularProgress'
import { IButton } from './types'

export const Button: React.FC<IButton> = ({
  children,
  disabled,
  type,
  iconLeft,
  iconRight,
  variant = 'medium',
  buttonType = 'primary',
  loading = false,
  fullWidth,
  marginLeft,
  marginRight,
  ...rest
}) => (
  <S.Container
    buttonType={buttonType}
    type={type || 'button'}
    fullWidth={fullWidth}
    marginLeft={marginLeft}
    marginRight={marginRight}
    variant={variant}
    iconLeft={iconLeft}
    iconRight={iconRight}
    disabled={loading || disabled}
    {...rest}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: variant === 'large' ? 56 : variant === 'small' ? 24 : 32,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {iconLeft}
        <S.Content isLoading={loading}>{children}</S.Content>
        {iconRight}
      </div>

      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: '#ffffff',
            position: 'relative',
            top: '-16px',
            alignSelf: 'center',
            flexBasis: 0,
          }}
        />
      )}
    </div>
  </S.Container>
)
