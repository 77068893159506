import { BankAccountFormModel } from "domain/models/bankAccount";
import { MaterialTableColumn } from "domain/models/materialColumn";

export const getBankByCode = (code: string) => {
  return getTecnoSpeedSimpleBanksInfo().find(
    (bankInfo) => bankInfo.code === code
  );
};

export const getTecnoSpeedSimpleBanksInfo = () => {
  return [
    /*
    {
      name: "Sofisa",
      code: "133",
    },
    {
      name: "Banco Uniprime (099)",
      code: "099",
    },
    {
      name: "Banco Uniprime (084)",
      code: "084",
    },
    {
      name: "Banco ABC",
      code: "246",
    },
    {
      name: "Ailos",
      code: "085",
    },
    {
      name: "Banco Inter",
      code: "077",
    },
    {
      name: "Cresol",
      code: "133",
    },
    {
      name: "Banco Unicred",
      code: "136",
    },
    {
      name: "Banco do Nordeste",
      code: "004",
    },
    {
      name: "Banco Banrisul",
      code: "041",
    },
    {
      name: "Banco Safra",
      code: "422",
    },
    */
    {
      name: "Banco Santander",
      code: "033",
    },
    { name: "Banco do Brasil", code: "001" },
    { name: "Banco Bradesco", code: "237" },
    ///{ name: "Sicoob", code: "756" },
    { name: "Banco Caixa", code: "104" },
    //{ name: "Sicredi", code: "748" },
    { name: "Banco Itáu", code: "341" },
    //{ name: "Banco Daycoval", code: "707" },
  ];
};

export const bankAccountColumns: readonly MaterialTableColumn[] = [
  { id: "bankName", label: "Banco", minWidth: 170 },
  { id: "bankCode", label: "Código Banco", minWidth: 100 },
  {
    id: "agency",
    label: "Agência",
    minWidth: 170,
  },
  {
    id: "account",
    label: "Conta",
    minWidth: 170,
  },
  {
    id: "accountType",
    label: "Tipo Conta",
    minWidth: 170,
  },
];

const getBankOptions = () => {
  const simpleBankInfo = getTecnoSpeedSimpleBanksInfo();

  return simpleBankInfo.map((bankInfo) => {
    return {
      label: bankInfo.name,
      value: bankInfo.code,
    };
  });
};

const getAccountTypeOptions = () => {
  return [
    { label: "Corrente", value: "CORRENTE" },
    { label: "Poupança", value: "POUPANÇA" },
  ];
};

export const defaultTecnoSpeedAccountForm = {
  id: 0,
  conta: "",
  criado: "",
  ativo: true,
  agencia: "",
  conta_dv: "",
  convenios: [],
  id_cedente: 0,
  agencia_dv: "",
  conta_tipo: "",
  codigo_banco: "",
};

export const defaultBankAccountForm = {
  bankName: "",
  bankCode: "",
  agency: "",
  agencyDv: "",
  account: "",
  accountDv: "",
  beneficiaryCode: "",
  accountType: "",
  companyCode: "",
  bankOptions: getBankOptions(),
  accountTypeOptions: getAccountTypeOptions(),
};

export const getPostNewAccountBody = (
  bankAccountForm: BankAccountFormModel
) => {
  return {
    ContaCodigoBanco: bankAccountForm?.bankCode,
    ContaAgencia: bankAccountForm?.agency,
    ContaAgenciaDV: bankAccountForm?.agencyDv,
    ContaNumero: bankAccountForm?.account,
    ContaNumeroDV: bankAccountForm?.accountDv,
    ContaTipo: bankAccountForm?.accountType,
    ContaCodigoBeneficiario: bankAccountForm?.beneficiaryCode,
    ContaCodigoEmpresa: bankAccountForm?.companyCode,
  };
};

export const getTecnoSpeedBanks = () => {
  return {
    /*
    {
      name: "Sofisa",
      code: "133",
      convention: {
        walletValues: [
          { name: "Cobrança Simples", code: "1" },
          { name: "Cobrança Vinculada", code: "2" },
          { name: "Cobrança Caucionada", code: "3" },
          { name: "Título Descontado", code: "4" },
        ],
        cnabPattern: [{ value: "400" }, { value: "404" }],
      },
    },
    {
      name: "Banco Uniprime (099)",
      code: "099",
      convention: {
        walletValues: [{ name: "Cobrança com registro", code: "09" }],
        cnabPattern: [{ value: "400" }],
      },
    },
    {
      name: "Banco Uniprime (084)",
      code: "246",
      convention: {
        walletValues: [{ name: "Cobrança com registro", code: "09" }],
        cnabPattern: [{ value: "400" }],
      },
    },
    {
      name: "Banco ABC",
      code: "246",
      convention: {
        walletValues: [
          {
            name: "Cobrança a cargo do banco cedente, sem emissão",
            code: "100",
          },
          { name: "Cobrança expressa – bloquete", code: "121" },
          { name: "Cobrança expressa – carnê", code: "101" },
          { name: "Direta emissão integral", code: "180" },
          { name: "Direta emissão integral carnê", code: "108" },
          { name: "Direta sem emissão", code: "110" },
          { name: "Cobrança registrada", code: "112" },
          { name: "Cobrança registrada – carnê", code: "144" },
        ],
        cnabPattern: [{ value: "400" }, { value: "240" }],
      },
    },
    {
      name: "Ailos",
      code: "085",
      convention: {
        walletValues: [
          { name: "Cobranca Simples", code: "1" },
          { name: "Cobrança Vinculada", code: "2" },
          { name: "Cobranca Caucionada", code: "3" },
          { name: "Cobranca Descontada", code: "4" },
          { name: "Cobranca Vendedor", code: "5" },
        ],
        cnabPattern: [{ value: "240" }],
      },
    },
    {
      name: "Banco Inter",
      code: "077",
      convention: {
        walletValues: [
          { name: "Cobrança com registro", code: "11" },
          { name: "Carteira escritural", code: "112" },
        ],
        cnabPattern: [{ value: "240" }],
      },
      instantlyRegister: true,
      contractNumber: "Numero do Contrato",
    },
    {
      name: "Cresol",
      code: "",
      convention: {
        walletValues: [{ name: "Cobrança com registro", code: "09" }],
        cnabPattern: [{ value: "400" }],
      },
    },
    {
      name: "Banco Unicred",
      code: "",
      convention: {
        walletValues: [{ name: "Cobrança com registro", code: "09" }],
        cnabPattern: [{ value: "400" }],
      },
    },
    {
      name: "Banco do Nordeste",
      code: "",
      convention: {
        walletValues: [{ name: "Cobrança com registro", code: "09" }],
        cnabPattern: [{ value: "400" }],
      },
    },
    {
      name: "Banco Banrisul",
      code: "",
      convention: {
        walletValues: [{ name: "Cobrança com registro", code: "09" }],
        cnabPattern: [{ value: "400" }],
      },
    },
    {
      name: "Banco Safra",
      code: "",
      convention: {
        walletValues: [{ name: "Cobrança com registro", code: "09" }],
        cnabPattern: [{ value: "400" }],
      },
    },
    { name: "Sicoob", code: "" },
    { name: "Sicredi", code: "" },
    { name: "Banco Daycoval", code: "" },
    */
    "033": {
      name: "Banco Santander",
      code: "033",
      convention: {
        walletValues: [
          { name: "Cobrança Simples Rápida com Registro", code: "RCR" },
          { name: "Cobrança Simples sem Registro", code: "CSR" },
        ],
        hasreloadDialyOption: true,
      },
    },
    "001": {
      name: "Banco do Brasil",
      code: "001",
      convention: {
        walletValues: [
          { name: "Cobrança com registro", code: "17" },
          { name: "Cobrança sem registro", code: "18" },
        ],
        hasreloadDialyOption: true,
      },
    },
    "104": {
      name: "Banco Caixa",
      code: "104",
      convention: {
        walletValues: [
          { name: "Cobrança com registro (SIGCB)", code: "14" },
          { name: "Cobrança sem registro (SIGCB)", code: "24" },
        ],
        hasShippingNumber: true,
      },
    },
    "237": {
      name: "Banco Bradesco",
      code: "237",
      convention: {
        walletValues: [
          { name: "Cobrança com registro", code: "02" },
          { name: "Cobrança com registro", code: "04" },
          { name: "Cobrança sem registro", code: "06" },
          { name: "Cobrança com registro", code: "09" },
        ],
        hasShippingNumber: true,
      },
    },
    "341": {
      name: "Banco Itáu",
      code: "341",
      convention: {
        walletValues: [
          { name: "Cobrança com registro", code: "109" },
          {
            name: "Cobrança com registro (Escritural Eletrônica)",
            code: "104",
          },
          { name: "Cobrança sem registro", code: "175" },
        ],
        hasShippingNumber: true,
      },
    },
  };
};
