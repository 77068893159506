import { SearchProfessionalsForm } from './types'

export const INITIAL_VALUES: SearchProfessionalsForm = {
  areaatuacao: '',
  descricao: '',
  languages: [],
  locationAreas: [],
  modulos_atuacao: [],
  niveis_experiencia: [],
  projetos_executados: '',
  valor_hora: '',
}
