import './styles.scss'
import React from 'react'
import TemplateAppBar from '../../../components/Templates/AppBar'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import jobLogo from '../../../img/job-logo.png'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'

const SelectTypeUser = () => {
  const navigate = useNavigate()
  return (
    <>
      <TemplateAppBar />
      <div className="register-new-user-container">
        <Paper style={{ width: '60%' }}>
          <div className="content-box">
            <img src={jobLogo} width="25%" />
            <Stack
              direction="row"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Button
                variant="outlined"
                size="large"
                onClick={() => navigate('/user-register')}
              >
                Sou Profissional de Tecnologia
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => navigate('/recruiter-register')}
              >
                Sou Recrutador de Tecnologia
              </Button>
            </Stack>
          </div>
        </Paper>
      </div>
    </>
  )
}
export default SelectTypeUser
