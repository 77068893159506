import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import {
  Autocomplete,
  Button,
  TextField,
  Stack,
  Typography,
  Divider,
  Chip,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { FormikConfig, useFormik } from 'formik'

import validationSchema from './validations'
import { DEFAULT_VALUES } from './constants'

import { BRAZIL_STATES } from 'utils/Constants'
import { useLazyGetBrazilCitiesByStateQuery } from 'store/Location/endpoint'
import { BrazilCityModel } from 'store/Location/types'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { some } from 'lodash'
import { OperationTabForm, OperationTabRef } from './types'
import { OperationAreaRequest } from 'types'
import { useDispatch } from 'react-redux'
import { setLoading } from 'store/Layout/slice'

const OperationTab = (_, ref: React.Ref<OperationTabRef>) => {
  const { warningSnackbar } = useCustomSnackbar()
  const dispatch = useDispatch()
  const [cities, setCities] = useState<BrazilCityModel[]>([])
  const [selectedOperationAreas, setSelectedOperationAreas] = useState<
    OperationAreaRequest[]
  >([])

  const [
    getBrazilCitiesByState,
    { isLoading: getBrazilCitiesByStateIsLoading },
  ] = useLazyGetBrazilCitiesByStateQuery()

  const onSubmit: FormikConfig<OperationTabForm>['onSubmit'] = (
    values,
    { resetForm }
  ) => {
    setSelectedOperationAreas((current) => {
      // QUANDO 'TODAS' É SELECIONADO, AS OUTRAS CIDADES JÁ SELECIONADAS DO ESTADO
      // SÃO EXCLUÍDAS
      const itens =
        values.cidade === 'Todas'
          ? current.filter((item) => item.stateUF !== values.estado)
          : current

      return [...itens, { city: values.cidade, stateUF: values.estado }]
    })

    resetForm()
  }

  const { values, handleSubmit, setFieldValue, errors } = useFormik({
    validationSchema,
    initialValues: DEFAULT_VALUES,
    onSubmit,
    validateOnChange: false,
  })

  const removeOperationArea = (index: number) => {
    setSelectedOperationAreas((current) => {
      const cloned = [...current]
      cloned.splice(index, 1)

      return cloned
    })
  }

  const validate = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        if (some(values, (item) => !!item)) {
          reject()
          warningSnackbar('Limpe o formulário ou adicione um item.')
        } else {
          resolve()
        }
      }),
    [values, warningSnackbar]
  )

  useImperativeHandle(ref, () => ({ selectedOperationAreas, validate }), [
    selectedOperationAreas,
    validate,
  ])

  useEffect(() => {
    const state = BRAZIL_STATES.find((state) => state.uf === values.estado)

    if (state)
      getBrazilCitiesByState(state.value)
        .unwrap()
        .then((response) => {
          setCities(response)
        })
        .catch(() => {
          warningSnackbar(
            'Falha ao recuperar lista de cidades. Tente novamente mais tarde.'
          )
        })
  }, [getBrazilCitiesByState, values.estado, warningSnackbar])

  useEffect(() => {
    dispatch(setLoading(getBrazilCitiesByStateIsLoading))

    return () => {
      dispatch(setLoading(false))
    }
  }, [dispatch, getBrazilCitiesByStateIsLoading])

  return (
    <form onSubmit={handleSubmit}>
      <div className="horinzontal">
        <div className="row" style={{ justifyContent: 'flex-start' }}>
          <div className="row" style={{ width: '70%' }}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Estado"
                  error={!!errors.estado}
                  helperText={errors.estado}
                  margin="dense"
                />
              )}
              style={{ width: '48%' }}
              value={values.estado}
              autoHighlight
              getOptionLabel={(option) =>
                BRAZIL_STATES?.find((item) => item.uf === option)?.title ?? ''
              }
              onChange={(_, newValue) => {
                setFieldValue(`estado`, newValue)
                setFieldValue('cidade', null)
              }}
              options={[
                'Todas',
                ...BRAZIL_STATES.filter((state) =>
                  selectedOperationAreas.every(
                    (operationArea) =>
                      state.uf !== operationArea.stateUF ||
                      operationArea.city !== 'Todas'
                  )
                ).map((item) => item.uf),
              ]}
            />

            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Cidade"
                  error={!!errors.cidade}
                  helperText={errors.cidade}
                  margin="dense"
                />
              )}
              style={{ width: '48%' }}
              value={values.cidade}
              disabled={!values.estado}
              autoHighlight
              onChange={(_, newValue) => setFieldValue(`cidade`, newValue)}
              options={[
                'Todas',
                ...cities
                  .filter((city) =>
                    selectedOperationAreas.every(
                      (operationArea) =>
                        city.microrregiao.mesorregiao.UF.sigla !==
                          operationArea.stateUF ||
                        operationArea.city !== city.nome
                    )
                  )
                  .map((item) => item.nome),
              ]}
            />
          </div>

          <div className="button-container">
            <Button
              variant="contained"
              type="submit"
              style={{ marginRight: '1rem' }}
            >
              +
            </Button>
          </div>
        </div>

        {selectedOperationAreas && selectedOperationAreas.length > 0 && (
          <>
            <Typography variant="subtitle2" component="div" marginTop={2}>
              Minhas experiências
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              marginTop={1}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {selectedOperationAreas.map(({ city, stateUF }, index) => (
                <Chip
                  key={`${city}${stateUF}`}
                  color="primary"
                  onDelete={() => {
                    removeOperationArea(index)

                    return <ClearIcon />
                  }}
                  label={`${city === 'Todas' ? '' : city} (${stateUF})`}
                />
              ))}
            </Stack>
          </>
        )}
      </div>
    </form>
  )
}

export default forwardRef(OperationTab)

export type { OperationTabRef }
