export const getCnpjBasicInfoBody = (cnpjInfo: any) => {
  return {
    email: cnpjInfo?.email || '',
    phone: cnpjInfo?.telefone || '',
    fantasyName: cnpjInfo?.fantasia || '',
    socialReason: cnpjInfo?.razao_social || '',
    legalNature: cnpjInfo?.natureza_juridica || '',
  }
}

export const getAddressCnpjInfoBody = (cnpjInfo: any) => {
  return {
    cep: cnpjInfo?.endereco?.cep,
    state: cnpjInfo?.endereco?.uf,
    complement: cnpjInfo?.complemento,
    number: cnpjInfo?.endereco?.numero,
    city: cnpjInfo?.endereco?.municipio,
    district: cnpjInfo?.endereco?.bairro,
    publicPlace: cnpjInfo?.endereco?.logradouro,
  }
}

export const getCnpjInfoBody = (cnpjInfo: any) => {
  const addressCnpjInfoBody = getAddressCnpjInfoBody(cnpjInfo)
  const cnpjBasicInfoBody = getCnpjBasicInfoBody(cnpjInfo)

  return {
    ...addressCnpjInfoBody,
    ...cnpjBasicInfoBody,
  }
}

export const removeCnpjMask = (cnpj: any) => {
  return cnpj?.replace(/[^\d]+/g, '')
}
