import { Modal as MUIModal, Paper } from '@mui/material'
import styled from 'styled-components'

export const Modal = styled(MUIModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled(Paper)`
  max-width: 720px;
  width: 100%;
  height: 80%;
  overflow: hidden;
`

export const Slide = styled.div<{ isProfessionalSelected?: boolean }>`
  width: 200%;
  height: 100%;
  display: flex;
  transition: transform 1s ease;
  ${(props) => props.isProfessionalSelected && 'transform: translateX(-50%);'}
`
