import { useEffect, useState } from 'react'
import {
  Button as MUIButton,
  Autocomplete,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FormikConfig, useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import ClearIcon from '@mui/icons-material/Clear'

import '../../../styles.scss'
import {
  useAddOperationAreaMutation,
  useDeleteUserOperationAreaMutation,
  useGetUserOperationAreasQuery,
} from 'store/PersonalData'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { setLoading } from 'store/Layout/slice'
import validationSchema from './validation'
import { INITIAL_VALUES } from './constants'
import { useLazyGetBrazilCitiesByStateQuery } from 'store/Location/endpoint'
import { BRAZIL_STATES } from 'utils/Constants'
import { BrazilCityModel } from 'store/Location/types'
import { OperationTabForm } from './types'

const OperationTab = () => {
  const dispatch = useDispatch()
  const { warningSnackbar, successSnackbar } = useCustomSnackbar()
  const [cities, setCities] = useState<BrazilCityModel[]>([])
  const { data: userOperationAreas, refetch: refetchUserOperationAreas } =
    useGetUserOperationAreasQuery()
  const [addOperationArea, { isLoading: addOperationAreaIsLoading }] =
    useAddOperationAreaMutation()
  const [deleteOperationArea, { isLoading: deleteOperationAreaIsLoading }] =
    useDeleteUserOperationAreaMutation()
  const [
    getBrazilCitiesByState,
    { isLoading: GetBrazilCitiesByStateIsLoading },
  ] = useLazyGetBrazilCitiesByStateQuery()

  const onSubmit: FormikConfig<OperationTabForm>['onSubmit'] = (
    { cidade, estado },
    { resetForm }
  ) => {
    // isRemote: false, pois no App também está sempre falso.
    addOperationArea({ isRemote: false, city: cidade, stateUF: estado })
      .unwrap()
      .then(refetchUserOperationAreas)
      .then(() => resetForm())
      .then(() => {
        successSnackbar('Área adicionada com sucesso.')
      })
      .catch(() =>
        warningSnackbar('Falha ao adicionar área. Tente novamente mais tarde.')
      )
  }

  const { values, handleSubmit, handleChange, setFieldValue, errors } =
    useFormik({
      validationSchema,
      initialValues: INITIAL_VALUES,
      onSubmit,
      validateOnChange: false,
    })

  const handleDeleteOperationArea = (hash: string) => {
    deleteOperationArea(hash)
      .unwrap()
      .then(refetchUserOperationAreas)
      .then(() => {
        successSnackbar('Área apagada com sucesso.')
      })
      .catch(() =>
        warningSnackbar('Falha ao apagar áreas. Tente novamente mais tarde.')
      )
  }

  useEffect(() => {
    const state = BRAZIL_STATES.find((state) => state.uf === values.estado)

    if (state)
      getBrazilCitiesByState(state.value)
        .unwrap()
        .then((response) => {
          setCities(response)
        })
        .catch(() => {
          warningSnackbar(
            'Falha ao recuperar lista de cidades. Tente novamente mais tarde.'
          )
        })
  }, [getBrazilCitiesByState, values.estado, warningSnackbar])

  useEffect(() => {
    dispatch(
      setLoading(
        addOperationAreaIsLoading ||
          deleteOperationAreaIsLoading ||
          GetBrazilCitiesByStateIsLoading
      )
    )

    return () => {
      dispatch(setLoading(false))
    }
  }, [
    dispatch,
    addOperationAreaIsLoading,
    deleteOperationAreaIsLoading,
    GetBrazilCitiesByStateIsLoading,
  ])

  return (
    <form onSubmit={handleSubmit}>
      <div className="horinzontal">
        <div className="row" style={{ justifyContent: 'flex-start' }}>
          <div className="row" style={{ width: '70%' }}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Estado"
                  error={!!errors.estado}
                  helperText={errors.estado}
                  margin="dense"
                />
              )}
              style={{ width: '48%' }}
              value={values.estado}
              autoHighlight
              getOptionLabel={(option) =>
                BRAZIL_STATES?.find((item) => item.uf === option)?.title ?? ''
              }
              onChange={(_, newValue) => {
                setFieldValue(`estado`, newValue)
                setFieldValue('cidade', null)
              }}
              options={[
                'Todas',
                ...BRAZIL_STATES.filter((state) =>
                  userOperationAreas?.every(
                    (operationArea) =>
                      state.uf !== operationArea.estado ||
                      operationArea.cidade !== ''
                  )
                ).map((item) => item.uf),
              ]}
            />

            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Cidade"
                  error={!!errors.cidade}
                  helperText={errors.cidade}
                  margin="dense"
                />
              )}
              style={{ width: '48%' }}
              value={values.cidade}
              disabled={!values.estado}
              autoHighlight
              onChange={(_, newValue) => setFieldValue(`cidade`, newValue)}
              options={[
                'Todas',
                ...cities
                  .filter((city) =>
                    userOperationAreas?.every(
                      (operationArea) =>
                        city.microrregiao.mesorregiao.UF.sigla !==
                          operationArea.estado ||
                        operationArea.cidade !== city.nome
                    )
                  )
                  .map((item) => item.nome),
              ]}
            />
          </div>

          <div className="button-container">
            <MUIButton
              variant="contained"
              type="submit"
              style={{ marginRight: '1rem' }}
            >
              +
            </MUIButton>
          </div>
        </div>

        {userOperationAreas && userOperationAreas.length > 0 && (
          <>
            <Typography variant="subtitle2" component="div" marginTop={2}>
              Minhas experiências
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              marginTop={1}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {userOperationAreas.map(({ cidade, estado, hashcod }) => (
                <Chip
                  key={hashcod}
                  color="primary"
                  onDelete={() => {
                    handleDeleteOperationArea(hashcod)

                    return <ClearIcon />
                  }}
                  label={`${cidade ?? 'Todas'} (${estado})`}
                />
              ))}
            </Stack>
          </>
        )}
      </div>
    </form>
  )
}

export default OperationTab
