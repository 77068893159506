import { TecnoSpeedBankAccountModel } from "domain/models/bankAccount";
import { BillClientForm, BillFormModel } from "domain/models/bankslip";
import moment from "moment";

export const defaultBillForm = {
  value: "",
  dueDate: new Date(),
  description: "",
};

export const defaultBillClientForm = {
  cep: "",
  city: "",
  phone: "",
  email: "",
  state: "",
  number: "",
  cpfCnpj: "",
  district: "",
  complement: "",
  publicPlace: "",
  socialReason: "",
};

export const getBankSlipBody = ({
  accountConvention = {} as any,
  billForm = {} as BillFormModel,
  billClientForm = {} as BillClientForm,
  bankAccount = {} as TecnoSpeedBankAccountModel,
}) => {
  return {
    CedenteContaNumero: bankAccount?.conta,
    CedenteContaNumeroDV: bankAccount?.conta_dv,
    CedenteConvenioNumero: accountConvention?.id,
    CedenteContaCodigoBanco: bankAccount?.codigo_banco,
    SacadoCPFCNPJ: billClientForm?.cpfCnpj,
    SacadoEmail: billClientForm?.email,
    SacadoEnderecoNumero: billClientForm?.number,
    SacadoEnderecoBairro: billClientForm?.district,
    SacadoEnderecoCEP: billClientForm?.cep,
    SacadoEnderecoCidade: billClientForm?.city,
    SacadoEnderecoComplemento: billClientForm?.complement,
    SacadoEnderecoLogradouro: billClientForm?.publicPlace,
    SacadoEnderecoPais: "Brasil",
    SacadoEnderecoUF: billClientForm?.state,
    SacadoNome: billClientForm?.socialReason,
    SacadoTelefone: billClientForm?.phone,
    TituloDataEmissao: moment().format("DD/MM/YYYY"),
    TituloDataVencimento: billForm.dueDate,
    //TituloMensagem01: "Juros de 0,01 ao dia",
    //TituloMensagem02: "Nao receber apos 30 dias de atraso",
    //TituloMensagem03: "Titulo sujeito a protesto apos 30 dias",
    //TituloNossoNumero: "6",
    //TituloNumeroDocumento: "01012020",
    TituloValor: billForm.value,
    TituloLocalPagamento: "Pagável em qualquer banco até o vencimento.",
  };
};
