import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfessionalModel } from '../../utils/Interfaces'
import { clearState } from '../actions'

export const initialState: ProfessionalModel = {
  pendingApplicationsQuantity: undefined,
}

export const professionalSlice = createSlice({
  name: 'JOB-Connect/professional',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    setPendingApplicationsQuantity: (state, action: PayloadAction<number>) => {
      state.pendingApplicationsQuantity = action.payload
    },
  },
})

export const { setPendingApplicationsQuantity } = professionalSlice.actions

export default professionalSlice.reducer
