import { useState } from 'react'

const useApprovedApplicantsModal = () => {
  const [professionalSelected, setProfessionalSelected] = useState<
    null | number
  >()

  const handleSelectProfessional = (value: number | null) => () => {
    setProfessionalSelected(value)
  }

  return {
    professionalSelected,
    handleSelectProfessional,
  } as const
}

export { useApprovedApplicantsModal }
