import { getTecnoSpeedCityByName } from 'data/utils/Address/static'
import { AdditionalServiceFormModel } from 'domain/models/additionalService'
import { Certificate } from 'store/PlugNotas/endpoint'
import { Atividade, Company, Endereco } from 'store/PlugNotas/types'

const normalizeCompany = (
	company: Omit<Company, 'endereco' | 'atividades'>,
	certificate: Certificate
) => ({
	cpfCnpj: company.cpf_cnpj,
	inscricaoMunicipal: company?.inscricaoMunicipal,
	razaoSocial: company.razao_social,
	nomeFantasia: company.fantasia,
	certificado: certificate?.id || '',
	simplesNacional: company?.simplesNacional,
	regimeTributario: company?.regimeTributario,
	regimeTributarioEspecial: company?.regimeTributarioEspecial,
	telefone: company.telefone,
	email: company.email,
})

const normalizeAddress = (endereco: Endereco, tecnoSpeedCities: any) => ({
	bairro: endereco.bairro,
	cep: endereco.cep,
	estado: endereco.uf,
	logradouro: endereco.logradouro,
	numero: endereco.numero,
	complemento: endereco.complemento,
	descricaoCidade: endereco.municipio,
	codigoCidade: getTecnoSpeedCityByName({
		cityName: endereco.municipio,
		tecnoSpeedCities,
	})?.id,
	tipoLogradouro: endereco.logradouro.substring(0, 3),
})

const normalizeNFSE = (login: string, senha: string, numero: number, serie: string) => ({
	ativo: true,
	tipoContrato: 0,
	config: {
		producao: true, // ALTERAR QUANDO O SISTEMA SUBIR P PRODUÇÃO
		prefeitura: {
			login,
			senha,
		},
		rps: {
			numeracao: [
				{
					numero,
					serie,
				},
			],
		},
	},
})

const normalizeCnaes = (cnaes: Atividade[], companyId: number) =>
	cnaes.map((cnae) => ({
		...(cnae.id && { id: cnae.id }),
		cnae: cnae.codigo,
		aliquot: cnae.aliquot,
		companyId,
		serviceItem: cnae.service_item,
	}))

const normalizeService = ({ id, average_delivery_time, description, payment_description, service, value }: AdditionalServiceFormModel) => ({
	id,
	service,
	value,
	description,
	averageDeliveryTime: average_delivery_time,
	paymentDescription: payment_description,
})

export { normalizeCompany, normalizeAddress, normalizeNFSE, normalizeCnaes, normalizeService }
