import { Moment } from 'moment'
import * as yup from 'yup'

import { REQUIRED_MSG } from 'utils/Constants'

const validationSchema = yup.object().shape({
  finalDate: yup
    .mixed<Moment>()
    .test(
      'invalid-end-date',
      'Data final inválida',
      (value) => !!value && value.isValid()
    )
    .required(REQUIRED_MSG)
    .typeError('data final inválida'),
  submissionLimit: yup
    .mixed<Moment>()
    .test(
      'invalid-first-date',
      'Data de submissão inválida',
      (value) => !!value && value.isValid()
    )
    .test(
      'invalid-dates',
      'Data limite de submissão deve ser igual ou anterior à data final',
      function compareDates(submissionLimit) {
        const startOfSubmissionLimit =
          !!submissionLimit && submissionLimit.startOf('day')
        const startOfFinalDate = this.parent.finalDate?.startOf('day')

        return (
          !!startOfSubmissionLimit &&
          startOfSubmissionLimit.isSameOrBefore(startOfFinalDate)
        )
      }
    )
    .required(REQUIRED_MSG)
    .typeError('Data limite de submissão inválida'),
})

export default validationSchema
