import { useState } from 'react'
import {
  Card,
  CardActionArea,
  Grid,
  CardContent,
  CardActions,
  Typography,
  Box,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Jobs from './components/Jobs'
import { useGetWeFoundYourJobPreferenciesQuery } from '../../store/WeFoundYourJob'
import { JobPreferenceResponse } from '../../types/index'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import './styles.scss'

export const WeFoundYourJob = () => {
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false)
  const { data, error, isLoading, isSuccess } =
    useGetWeFoundYourJobPreferenciesQuery('')

  const [preference, setPreference] = useState<
    JobPreferenceResponse | undefined
  >()

  return (
    <div className="we-found-your-job-container">
      {isSuccess && (
        <Grid>
          <header
            onClick={() => setIsPreferencesOpen(!isPreferencesOpen)}
            style={{
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <ExpandMoreRoundedIcon
              fontSize="large"
              style={{
                transform: isPreferencesOpen ? '' : 'rotate(-90deg)',
              }}
            />
            Preferências de vagas{' '}
            <span style={{ color: 'orange' }}>&nbsp;(clique aqui) </span>
          </header>
          <Grid
            className="preferencies-container"
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{
              height: isPreferencesOpen ? 'fit-content' : 0,
              overflow: 'hidden',
            }}
          >
            {!!data.length &&
              data.map((preference: JobPreferenceResponse) => (
                <Grid key={preference.hashcod} item xs={4}>
                  <Card
                    key={preference?.hashcod}
                    onClick={() => setPreference(preference)}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          {preference?.palavras_chave}
                        </Typography>
                      </CardContent>

                      <CardActions>
                        {preference?.localizacoes?.map(
                          (findJobLocalization) => (
                            <Typography
                              key={`${findJobLocalization.cidade}${findJobLocalization.cidade}${findJobLocalization.uf}`}
                              component="legend"
                            >
                              {`${findJobLocalization?.uf}${
                                findJobLocalization?.cidade
                                  ? ' - ' + findJobLocalization?.cidade
                                  : ''
                              }`}
                            </Typography>
                          )
                        )}
                      </CardActions>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}

            {!data.length && (
              <Box>
                Não encontramos vagas de emprego de acordo com sua preferência.
              </Box>
            )}
          </Grid>
        </Grid>
      )}
      {isLoading && <CircularProgress />}
      {!!error && <h3>Houve um erro ao recuperar a lista de preferências.</h3>}
      {isSuccess && !preference && (
        <h3 style={{ color: 'rgba(41,50,65,0.8)' }}>
          Selecione uma preferência para visualizar as vagas de emprego.
        </h3>
      )}
      {!isLoading && isSuccess && preference && (
        <Jobs preference={preference} />
      )}
    </div>
  )
}
