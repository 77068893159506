import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react'
import { TextField, MenuItem } from '@mui/material'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'

import { BRAZIL_STATES } from 'utils/Constants'
import { INITIAL_VALUES } from './constants'
import validationSchema from './validations'
import { AddressTabRef } from './types'
import '../../../styles.scss'
import { useDispatch } from 'react-redux'
import { setLoading } from 'store/Layout/slice'

const AddressTab = (_, ref: React.Ref<AddressTabRef>) => {
  const dispatch = useDispatch()

  const {
    errors,
    handleChange,
    setFieldValue,
    setValues,
    validateForm,
    values,
  } = useFormik({
    validationSchema,
    initialValues: INITIAL_VALUES,
    onSubmit: () => {},
    validateOnChange: false,
    validateOnBlur: false,
  })

  const validate = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        validateForm().then((errors) => {
          if (isEmpty(errors)) resolve()
          else reject()
        })
      }),
    [validateForm]
  )

  useImperativeHandle(ref, () => ({ validate, values }), [validate, values])

  useEffect(() => {
    if (values.cep && values?.cep?.toString().length < 8) return
    dispatch(setLoading(true))

    const url = `https://viacep.com.br/ws/${values.cep}/json/`

    fetch(url)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setValues((current) => ({
          ...current,
          rua: data.logradouro,
          bairro: data.bairro,
          cidade: data.localidade,
          estado: data.uf,
        }))
      })
      .finally(() => dispatch(setLoading(false)))
  }, [dispatch, setValues, values.cep])

  return (
    <div className="horinzontal">
      <PatternFormat
        customInput={TextField}
        label="CEP"
        variant="standard"
        value={values.cep}
        onValueChange={({ value }) => setFieldValue('cep', value)}
        error={!!errors.cep}
        helperText={errors.cep}
        margin="dense"
        format={'#####-###'}
        valueIsNumericString
      />

      <div className="row">
        <TextField
          style={{ width: '78%' }}
          name="rua"
          label="Rua"
          variant="standard"
          value={values.rua}
          onChange={handleChange}
          error={!!errors.rua}
          helperText={errors.rua}
          margin="dense"
        />

        <NumericFormat
          customInput={TextField}
          style={{ width: '20%' }}
          label="Número"
          variant="standard"
          name="numero"
          value={values.numero}
          onValueChange={({ value }) => setFieldValue('numero', value)}
          error={!!errors.numero}
          helperText={errors.numero}
          margin="dense"
          allowNegative={false}
          decimalScale={0}
        />
      </div>

      <TextField
        name="bairro"
        label="Bairro"
        variant="standard"
        value={values.bairro}
        onChange={handleChange}
        error={!!errors.bairro}
        helperText={errors.bairro}
        margin="dense"
      />

      <div className="row">
        <TextField
          style={{ width: '78%' }}
          name="cidade"
          label="Cidade"
          variant="standard"
          value={values.cidade}
          onChange={handleChange}
          error={!!errors.cidade}
          helperText={errors.cidade}
          margin="dense"
        />

        <TextField
          style={{ width: '20%' }}
          select
          name="estado"
          variant="standard"
          label="Estado"
          value={values.estado}
          onChange={handleChange}
          error={!!errors.estado}
          helperText={errors.estado}
          margin="dense"
        >
          {BRAZIL_STATES.map((option) => (
            <MenuItem key={`est-${option.value}`} value={option.uf}>
              {option.uf}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  )
}

export default forwardRef(AddressTab)

export type { AddressTabRef }
