import { useEffect, useMemo, useState } from 'react'
import { TablePagination, Stack } from '@mui/material'

import { useGetSearchJobsQuery } from 'store/SearchVacancies'
import { JobPreferenceResponse, JobResponse } from '../../../types/index'
import { VacancyItem } from 'presentation/views/Vacancy/VacancyItem'
import JobDetails from 'components/Molecules/JobDetails'
import Filters from 'presentation/views/Vacancy/Filters'
import { mock2 } from './mock2'
import CircularProgress from '@mui/material/CircularProgress'
import { setLoading } from 'store/Layout/slice'
import { useDispatch } from 'react-redux'
import * as S from './styles'
import SkeletonJob from 'components/Molecules/SkeletonJob'
import { Column, JobList, Row } from 'presentation/views/Vacancy/style'

interface JobsProps {
  preference: JobPreferenceResponse
}

const Jobs = ({ preference }: JobsProps) => {
  const dispatch = useDispatch()
  const { data, error, isFetching, isSuccess, refetch } =
    useGetSearchJobsQuery(preference)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [selectedFilter, setSelectedFilter] = useState(mock2[0])
  const [selectedJob, setSelectedJob] = useState<JobResponse | undefined>()

  const paginatedData = useMemo(() => {
    if (!data) return []

    return data.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  }, [data, page, rowsPerPage])

  useEffect(() => {
    if (paginatedData.length > 0) {
      setSelectedJob(paginatedData[0])
    } else {
      setSelectedJob(undefined)
    }
  }, [preference, paginatedData])

  useEffect(() => {
    dispatch(setLoading(isFetching))
  }, [dispatch, isFetching])

  return (
    <>
      {isSuccess && data.length === 0 && (
        <h3 style={{ color: 'black' }}>
          Não há vagas de emprego para a preferência selecionada.
        </h3>
      )}

      <Row>
        {data ? (
          <Column>
            <Filters setSelectedFilter={setSelectedFilter} />
            <JobList>
              {paginatedData?.map((item) => (
                <VacancyItem
                  item={item}
                  onClick={() => setSelectedJob(item)}
                  isSelected={selectedJob == item}
                />
              ))}
            </JobList>
          </Column>
        ) : (
          <SkeletonJob />
        )}
        {selectedJob && (
          <JobDetails
            job={selectedJob}
            hasBtnApplyJob={true}
            refetchData={refetch}
            index={
              data?.findIndex(
                (item) => item.hashcod === selectedJob.hashcod
              ) as number
            }
          />
        )}
      </Row>

      {isSuccess && (
        <S.ContainerTablePagination>
          <TablePagination
            component="div"
            count={data?.length ?? 0}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            labelRowsPerPage={'Linhas por página'}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10))
              setPage(0)
            }}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[15, 50, 100]}
            showFirstButton
            showLastButton
            className="pagination"
          />
        </S.ContainerTablePagination>
      )}

      {isFetching && <CircularProgress />}

      {!!error && (
        <h3>
          Houve um erro ao recuperar a lista de vagas de emprego de acordo com
          suas preferência.
        </h3>
      )}
    </>
  )
}

export default Jobs
