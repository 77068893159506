import './styles.scss'

import React from 'react'

const BillingHistory: React.FC = () => {
  return (
    <div className="billing-history-container">
      <div className="billing-history">
        <p className="billing-history-title">Histórico de cobranças</p>
      </div>
    </div>
  )
}

export default BillingHistory
