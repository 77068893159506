import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { isAfter, endOfDay, parseISO, format } from 'date-fns'
import { isUndefined, isNull } from 'lodash'
import moment from 'moment'

import {
	CompanySearchJobPreferencies,
	JobPreferenceResponse,
	JobRequest,
	JobResponse,
	RepublishJobResponse,
} from '../../types/index'
import {
	APP_STORAGE_PREFIX,
	APP_API_URI,
	GET_JOBS_WITH_FILTER,
	APP_PREFIX,
	PROJECTS,
	SET_JOB_PROGRESS,
	REPUBLISH_JOB,
	SET_JOB_FINISHED_STATUS,
} from '../../utils/Constants'
import { StorageGetItem } from '../../utils/Storage'

export const searchVacanciesRedux = createApi({
	reducerPath: `${APP_PREFIX}/Projects/searchVacanciesRedux`,
	tagTypes: ['Projects'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${APP_API_URI}`,
		prepareHeaders: (headers, { getState }) => {
			const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))

			if (token) {
				headers.set('Authorization', `JWT ${token}`)
			}

			return headers
		},
	}),
	endpoints: (builder) => ({
		getJob: builder.query<JobResponse, string>({
			query: (hashcod) => `${PROJECTS}${hashcod}/`,
		}),
		deleteJob: builder.mutation<void, string>({
			query: (hashcod) => ({
				url: `${PROJECTS}${hashcod}/`,
				method: 'DELETE',
			}),
			onQueryStarted: (hashcode, { dispatch }) => {
				dispatch(searchVacanciesRedux.util.updateQueryData('getDiscriminatedProjects', undefined, (jobs) => {
					const projectPosition = jobs.findIndex(job => job.hashcod === hashcode);

					jobs.splice(projectPosition, 1)
				}))
			},
		}),
		createProject: builder.mutation<any, JobRequest>({
			query: (values) => {
				const body = new FormData()

				Object.entries(values).forEach(([key, value]) => {
					body.append(
						key,
						value === undefined || value === null ? '' : String(value)
					)
				})

				return {
					url: `${PROJECTS}`,
					method: 'POST',
					body,
				}
			},
		}),
		updateProject: builder.mutation<
			any,
			{ hashcod: string; project: JobRequest }
		>({
			query: ({ hashcod, project }) => {
				const body = new FormData()

				Object.entries(project).forEach(([key, value]) => {
					body.append(
						key,
						isUndefined(value) || isNull(value) ? '' : String(value)
					)
				})

				return {
					url: `${PROJECTS}${hashcod}/`,
					method: 'PUT',
					body,
				}
			},
			onQueryStarted: ({ project, hashcod }, { dispatch }) => {
				dispatch(
					searchVacanciesRedux.util.updateQueryData('getDiscriminatedProjects', undefined, draft => {
						const index = draft.findIndex(item => item.hashcod === hashcod);
						const updatedProject = { ...draft[index], ...project };
						draft.splice(index, 1);
						draft.splice(index, 0, updatedProject)
					})
				)
			}
		}),
		getProjects: builder.query<
			JobResponse[],
			void | CompanySearchJobPreferencies
		>({
			query: (preferencies) => {
				if (!preferencies || (!preferencies.client && !preferencies.name))
					return `${PROJECTS}`

				let filter = ''

				if (preferencies.client) filter += `cliente=${preferencies.client}`

				if (preferencies.name) filter += `nome=${preferencies.name}`

				return { url: `${PROJECTS}?${filter}` }
			},
			// providesTags: ['Projects'],
		}),
		getProjectsToCalendar: builder.query<JobResponse[], void>({
			query: () => `${PROJECTS}`,
			transformResponse: (projects: JobResponse[]) => {
				return projects.filter((project) => {
					return project.status === 'E' || project.status === 'F' && !project.data_final
				})
			},
		}),
		getDiscriminatedProjects: builder.query<
			JobResponse[],
			void | CompanySearchJobPreferencies
		>({
			query: (preferencies) => {
				if (!preferencies || (!preferencies.client && !preferencies.name))
					return `${PROJECTS}`

				let filter = ''

				if (preferencies.client) filter += `cliente=${preferencies.client}`

				if (preferencies.name) filter += `nome=${preferencies.name}`

				return { url: `${PROJECTS}?${filter}` }
			},
			// transformResponse: (jobs: JobResponse[]) => {
			// 	return jobs.reduce<ReducerReturnType>(
			// 		(projects, project) => {
			// 			const date = parseISO(project.limite_submissao)

			// 			if (
			// 				(!isAfter(endOfDay(date), new Date()) &&
			// 					project.status === 'P') ||
			// 				project.status === 'F'
			// 			) {
			// 				return { ...projects, finished: [...projects.finished, project] }
			// 			}

			// 			if (project.status === 'P') {
			// 				return {
			// 					...projects,
			// 					published: [...projects.published, project],
			// 				}
			// 			}

			// 			if (project.status === 'E') {
			// 				return {
			// 					...projects,
			// 					inProgress: [...projects.inProgress, project],
			// 				}
			// 			}

			// 			return projects
			// 		},
			// 		{
			// 			published: [],
			// 			inProgress: [],
			// 			finished: [],
			// 		}
			// 	)
			// },
			// providesTags: ['Projects'],
		}),
		getSearchJobs: builder.query<JobResponse[], JobPreferenceResponse | void>({
			query: (preference) => {
				console.table(preference)
				if (!preference) return `${GET_JOBS_WITH_FILTER}`

				let filter = ''

				if (preference.tipo_filtro === 'L') {
					preference.localizacoes.forEach((localizacao) => {
						if (localizacao.estado !== '' && localizacao.cidade !== '') {
							filter += `localizacao=${encodeURIComponent(
								localizacao.estado
							)},${encodeURIComponent(localizacao.cidade)}&`
						} else if (localizacao.estado !== '' && localizacao.cidade === '') {
							filter += `localizacao=${encodeURIComponent(localizacao.estado)}&`
						}
					})
				}

				if (preference.tipo_filtro === 'R' && preference.raio_atuacao > 0) {
					filter += `raio_atuacao=${preference.raio_atuacao}&`
				}

				if (preference.palavras_chave) {
					filter += `palavra_chave=${encodeURIComponent(
						preference.palavras_chave
					)}`
				}

				return { url: `${GET_JOBS_WITH_FILTER}?${filter}` }
			},
			transformResponse: (response: any) => {
				const data = response.filter(({ limite_submissao }) => {
					const limiteSubmissao = endOfDay(moment(limite_submissao).toDate())
					const dateNow = new Date()

					return isAfter(limiteSubmissao, dateNow)
				})
				console.table(data)
				return data
			},
		}),
		getSearchJobsQTD: builder.query<number, string>({
			query: () => `${GET_JOBS_WITH_FILTER}`,
			transformResponse: (response: JobResponse[]) => {
				return response
					.filter((item) => item.status === 'P')
					.filter(({ limite_submissao }) => {
						const limiteSubmissao = endOfDay(moment(limite_submissao).toDate())
						const dateNow = new Date()

						return isAfter(limiteSubmissao, dateNow)
					}).length
			},
		}),
		setJobProgress: builder.mutation<
			void,
			{ hashcod: string; status: 'E' | 'F' | 'P' }
		>({
			query: ({ hashcod, status }) => {
				const body = new FormData()
				body.append('status', status)

				return {
					url: `${SET_JOB_PROGRESS}${hashcod}/`,
					method: 'PUT',
					body,
				}
			},
		}),
		setJobFinishedStatus: builder.mutation<
			void,
			{ hashcod: string; status: 'E' | 'F' | 'P' }
		>({
			query: ({ hashcod, status }) => {
				const body = new FormData()
				body.append('status', status)

				return {
					url: `${SET_JOB_FINISHED_STATUS}${hashcod}/`,
					method: 'PUT',
					body,
				}
			},
		}),
		republishJob: builder.mutation<
			RepublishJobResponse,
			{ finalDate: Date; hashcod: string; submissionLimit: Date }
		>({
			query: ({ finalDate, hashcod, submissionLimit }) => {
				const body = new FormData()

				body.append('data_final', moment(finalDate).format('YYYY-MM-DD'))
				body.append(
					'limite_submissao',
					moment(submissionLimit).format('YYYY-MM-DD')
				)
				body.append('status', 'P')

				return {
					url: `${REPUBLISH_JOB}${hashcod}/`,
					method: 'PUT',
					body,
				}
			},
		}),
		jobsQuantity: builder.query<{ quantidade: number }, void>({
			query: () =>
				`${PROJECTS}vagas/quantidade/?limite_submissao=${format(
					new Date(),
					'yyyy-MM-dd'
				)}`,
		}),
	}),
})

export const {
	useGetJobQuery,
	useDeleteJobMutation,
	useLazyGetJobQuery,
	useCreateProjectMutation,
	useUpdateProjectMutation,
	useGetDiscriminatedProjectsQuery,
	useLazyGetProjectsQuery,
	useGetProjectsToCalendarQuery,
	useGetSearchJobsQuery,
	useGetSearchJobsQTDQuery,
	useSetJobProgressMutation,
	useSetJobFinishedStatusMutation,
	useRepublishJobMutation,
	useJobsQuantityQuery,
} = searchVacanciesRedux
