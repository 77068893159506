import { MaterialTableColumn } from 'domain/models/materialColumn'

export const getSnakeCaseApiRequestedServices = (requestedServices: any) => {
  return requestedServices?.map((service) => {
    const serviceId = service?.service_id
    const companyId = service?.company_id

    const done = service?.done === 1
    const paid = service?.paid === 1
    const noteIssued = service?.note_issued === 1

    delete service?.service_id
    delete service?.company_id
    delete service?.note_issued

    return {
      ...service,
      done,
      paid,
      serviceId,
      companyId,
      noteIssued,
    }
  })
}

export const requestedServiceColumns: readonly MaterialTableColumn[] = [
  { id: 'name', label: 'Nome', minWidth: 170 },
  { id: 'service', label: 'Serviço', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'phone', label: 'Telefone', minWidth: 100 },
  { id: 'done', label: 'Atendido', minWidth: 100 },
  { id: 'paid', label: 'Pago', minWidth: 100 },
  { id: 'noteIssued', label: 'Nota Emitida', minWidth: 100 },
]

export const defaultRequestedServiceForm = {
  name: '',
  email: '',
  phone: '',
  done: false,
  service: '',
  paid: false,
  noteIssued: false,
}

export const defaultDisplayFields = {
  requestedServiceForm: false,
  serviceList: true,
}

export const displayListBody = {
  requestedServiceForm: false,
  serviceList: true,
}

export const displayFormBody = {
  requestedServiceForm: true,
  serviceList: false,
}
