import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '..'
import { NODE_API } from 'utils/Constants'

type TOnboardingAdvance = {
  cnaes: boolean
  company: boolean
  partners: boolean
  serviceContract: boolean
  myResponsibilities: boolean
  responsibilityStart: boolean
  socialContracts: boolean
  municipalAccess: boolean
}

type TRequest = {
  data: TOnboardingAdvance
  status: string
}

const nodeApi = createApi({
  reducerPath: 'nodeApi',
  tagTypes: [
    'ADVANCE',
    'MUNICIPAL_ACCESS',
    'CNAES',
    'ADDITIONAL_SERVICES',
    'NODE_COMPANY',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${NODE_API}`,
    prepareHeaders: (headers, { getState }) => {
      const { node: nodeReducer } = getState() as RootState

      const currentCompanyId = nodeReducer?.nodeCompany?.id

      headers.set('company_id', currentCompanyId)

      return headers
    },
  }),
  endpoints: (build) => ({
    getOnboardingAdvance: build.query<number, void>({
      query: () => {
        return {
          url: `/onboardings/advance`,
        }
      },
      transformResponse: (response: any) => {
        return response?.data
      },
      providesTags: ['ADVANCE'],
    }),
  }),
})

export const { useGetOnboardingAdvanceQuery } = nodeApi

export default nodeApi
