import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  TextField,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Autocomplete,
  Button as MUIButton,
  FormLabel,
  Chip,
  Typography,
  Box,
  Modal,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { NumericFormat } from 'react-number-format'
import { FormikConfig, useFormik } from 'formik'

import { INITIAL_VALUES } from './constants'
import { setLoading } from 'store/Layout/slice'
import { useDispatch, useSelector } from 'react-redux'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { SearchProfessionalsForm } from './types'
import validationSchema from './validations'
import {
  useGetLanguageLevelOptionsQuery,
  useGetLanguageOptionsQuery,
} from 'store/Profile'
import { BRAZIL_STATES } from 'utils/Constants'
import { useLazyGetBrazilCitiesByStateQuery } from 'store/Location/endpoint'
import { BrazilCityModel } from 'store/Location/types'
import { LanguageRequest, ProfessionalResponse } from 'types'
import {
  useGetOperationAreaLevelOptionsQuery,
  useGetOperationAreaOptionsQuery,
} from 'store/PersonalData'
import { useLazySearchProfessionalsQuery } from 'store/Professional/api'
import ProfessionalCard from './ProfessionalCard'
import Pagination from 'components/Organisms/Pagination'

import { useGetUserInfosQuery } from 'store/UserType'
import Professional from 'data/components/CompoundInvitations/Professional'
import { useLazyCheckIfCanAddAJobQuery } from 'store/CadNewUser'
import InactivityBlocker from '../TechJobCreateAndUpdate/InactivityBlocker'
import { RootState } from 'store'

const SearchProfessionals = () => {
  const usuarioLogado = useSelector(
    (state: RootState) => state.userState.loggedUser
  )
  const dispatch = useDispatch()
  const [isInactive, setIsInactive] = useState(false)
  const { warningSnackbar } = useCustomSnackbar()

  const [, setShowProfessionalDetailDialog] = useState(false)
  const [selectedProfessional, setSelectedProfessional] =
    useState<ProfessionalResponse>()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(15)

  const { data: languageOptions, isFetching: getLanguageIsFetching } =
    useGetLanguageOptionsQuery()

  const {
    data: languageLevelOptions,
    isFetching: getLanguageLevelOptionsIsFetching,
  } = useGetLanguageLevelOptionsQuery()
  const [
    getBrazilCitiesByState,
    { isFetching: getBrazilCitiesByStateIsFetching },
  ] = useLazyGetBrazilCitiesByStateQuery()
  const [cityOptions, setCityOptions] = useState<BrazilCityModel[]>([])
  const [stateUF, setStateUF] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [checkIfCanAddAJob] = useLazyCheckIfCanAddAJobQuery()
  const [language, setLanguage] = useState<LanguageRequest>({
    lingua: '',
    nivel: '',
  })
  const {
    data: operationAreaOptions,
    isFetching: operationsAreaOptionsIsFetching,
  } = useGetOperationAreaOptionsQuery()
  const {
    data: operationAreaLevelOptions,
    isFetching: operationsAreaLevelOptionsIsFetching,
  } = useGetOperationAreaLevelOptionsQuery()
  const [
    searchProfessionals,
    { data: professionalsFound, isFetching: searchProfessionalsIsFetching },
  ] = useLazySearchProfessionalsQuery()

  const paginatedData = useMemo(() => {
    return (
      professionalsFound?.slice(page * pageSize, (page + 1) * pageSize) ?? []
    )
  }, [professionalsFound, page, pageSize])

  const handleSearchProfessionals = useCallback(
    async (values) => {
      const { bloqueio_inatividade } = await checkIfCanAddAJob().unwrap()

      if (usuarioLogado?.email !== 'rh@jobc.com.br' && bloqueio_inatividade) {
        return setIsInactive(bloqueio_inatividade)
      }

      searchProfessionals(values)
        .unwrap()
        .then((res) => {
          if (res.length === 0)
            warningSnackbar(
              'Não foram encontrados profissionais para os filtros selecionados'
            )
        })
        .catch((err) => {
          if (err.data?.error_bloq) {
            warningSnackbar(err.data.error_bloq)
          } else {
            warningSnackbar(
              'Erro ao buscar profissionais. Tente novamente mais tarde.'
            )
          }
        })
    },
    [
      searchProfessionals,
      warningSnackbar,
      checkIfCanAddAJob,
      usuarioLogado?.email,
    ]
  )

  const onSubmit = useCallback<
    FormikConfig<SearchProfessionalsForm>['onSubmit']
  >(
    (values) => {
      handleSearchProfessionals(values)
    },
    [handleSearchProfessionals]
  )

  const {
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    values,
  } = useFormik<SearchProfessionalsForm>({
    validationSchema,
    initialValues: INITIAL_VALUES,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  })

  const addLanguage = () => {
    if (!language) return

    setLanguage({
      lingua: '',
      nivel: '',
    })
    setValues((values) => ({
      ...values,
      languages: [...values.languages, language],
    }))
  }

  const removeLanguage = (index: number) => {
    setValues((current) => {
      const languages = [...current.languages]
      languages.splice(index, 1)

      return {
        ...current,
        languages,
      }
    })
  }

  const addLocation = () => {
    if (!city || !stateUF) return

    setCity('')
    setStateUF('')
    setValues((values) => ({
      ...values,
      locationAreas: [
        ...values.locationAreas,
        {
          stateUF,
          city,
        },
      ],
    }))
  }

  const removeLocation = (index: number) => {
    setValues((current) => {
      const locationAreas = [...current.locationAreas]
      locationAreas.splice(index, 1)

      return {
        ...current,
        locationAreas,
      }
    })
  }

  const specializations = useMemo(() => {
    if (!operationAreaOptions || !values.areaatuacao) return []

    const area = operationAreaOptions?.find(
      (item) => item.hashcod === values.areaatuacao
    )

    return area?.modulo_areaatuacao ?? []
  }, [operationAreaOptions, values.areaatuacao])

  useEffect(() => {
    const foundState = BRAZIL_STATES.find((item) => item.uf === stateUF)

    if (foundState)
      getBrazilCitiesByState(foundState.value)
        .unwrap()
        .then((response) => {
          setCityOptions(response)
        })
        .catch(() => {
          warningSnackbar(
            'Falha ao recuperar lista de cidades. Tente novamente mais tarde.'
          )
        })
  }, [getBrazilCitiesByState, stateUF, warningSnackbar])

  useEffect(() => {
    dispatch(
      setLoading(
        getBrazilCitiesByStateIsFetching ||
          getLanguageIsFetching ||
          getLanguageLevelOptionsIsFetching ||
          operationsAreaOptionsIsFetching ||
          operationsAreaLevelOptionsIsFetching ||
          searchProfessionalsIsFetching
      )
    )

    return () => {
      dispatch(setLoading(false))
    }
  }, [
    dispatch,
    getBrazilCitiesByStateIsFetching,
    getLanguageIsFetching,
    getLanguageLevelOptionsIsFetching,
    operationsAreaOptionsIsFetching,
    operationsAreaLevelOptionsIsFetching,
    searchProfessionalsIsFetching,
  ])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardHeader title="Buscar profissionais" />

          <CardContent>
            <Grid container spacing={2}>
              <Grid paddingLeft={2} xs={12}>
                <FormLabel>Experiência</FormLabel>
              </Grid>

              <Grid xs={12} sm={4} paddingLeft={2}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Área de atuação"
                      variant="standard"
                      // error={!!errors.areaatuacao}
                      // helperText={errors.areaatuacao}
                      margin="dense"
                    />
                  )}
                  value={values.areaatuacao}
                  autoHighlight
                  getOptionLabel={(option) =>
                    operationAreaOptions?.find(
                      (item) => item.hashcod === option
                    )?.descricao ?? ''
                  }
                  onChange={(_, newValue) => {
                    setFieldValue(`areaatuacao`, newValue)
                    setFieldValue(`modulos_atuacao`, [])
                  }}
                  options={
                    operationAreaOptions?.map((item) => item.hashcod) ?? []
                  }
                />
              </Grid>

              <Grid xs={12} sm={4} paddingLeft={2}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Especialização"
                      margin="dense"
                    />
                  )}
                  multiple
                  value={values.modulos_atuacao}
                  disabled={!values.areaatuacao}
                  autoHighlight
                  getOptionLabel={(option) =>
                    specializations?.find((item) => item.hashcod === option)
                      ?.descricao ?? ''
                  }
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const description =
                        specializations?.find((item) => item.hashcod === option)
                          ?.descricao ?? ''

                      return (
                        <Chip
                          variant="outlined"
                          label={description}
                          {...getTagProps({ index })}
                        />
                      )
                    })
                  }
                  onChange={(_, newValue) =>
                    setValues((current) => ({
                      ...current,
                      modulos_atuacao: newValue,
                    }))
                  }
                  options={specializations?.map((item) => item.hashcod) ?? []}
                />
              </Grid>

              <Grid xs={12} sm={4} paddingLeft={2}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Nível de experiência"
                      margin="dense"
                    />
                  )}
                  multiple
                  value={values.niveis_experiencia}
                  autoHighlight
                  getOptionLabel={(option) =>
                    operationAreaLevelOptions?.find(
                      (item) => item.hashcod === option
                    )?.descricao ?? ''
                  }
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const description =
                        operationAreaLevelOptions?.find(
                          (item) => item.hashcod === option
                        )?.descricao ?? ''

                      return (
                        <Chip
                          variant="outlined"
                          label={description}
                          {...getTagProps({ index })}
                        />
                      )
                    })
                  }
                  onChange={(_, newValue) =>
                    setValues((current) => ({
                      ...current,
                      niveis_experiencia: newValue,
                    }))
                  }
                  options={
                    operationAreaLevelOptions?.map((item) => item.hashcod) ?? []
                  }
                />
              </Grid>

              <Grid xs={12} sm={4} paddingLeft={2}>
                <TextField
                  fullWidth
                  name="descricao"
                  label="Busca por palavras chave no currículo"
                  variant="standard"
                  value={values.descricao}
                  onChange={handleChange}
                  error={!!errors.descricao}
                  helperText={errors.descricao}
                />
              </Grid>
              <Grid xs={12} sm={4} paddingLeft={2}>
                <NumericFormat
                  customInput={TextField}
                  fullWidth
                  label="Projetos Realizados (limite mínimo)"
                  variant="standard"
                  name="projetos_executados"
                  value={values.projetos_executados}
                  onValueChange={({ value }) =>
                    setFieldValue('projetos_executados', value)
                  }
                  error={!!errors.projetos_executados}
                  helperText={errors.projetos_executados}
                  allowNegative={false}
                  decimalScale={0}
                  disabled={values.modulos_atuacao.length > 1}
                />
              </Grid>

              <Grid xs={12} sm={4} paddingLeft={2}>
                <NumericFormat
                  customInput={TextField}
                  fullWidth
                  label="Valor da Hora (limite máximo)"
                  variant="standard"
                  value={values.valor_hora}
                  onValueChange={({ value }) =>
                    setFieldValue('valor_hora', value)
                  }
                  allowNegative={false}
                  allowedDecimalSeparators={['%']}
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator="."
                  decimalSeparator=","
                  disabled={values.modulos_atuacao.length > 1}
                  valueIsNumericString
                  prefix={'R$ '}
                />
              </Grid>

              <Grid item xs={4} />

              <Grid container spacing={0} paddingLeft={2}>
                <FormLabel>Linguagem</FormLabel>
              </Grid>

              <Grid xs={2} paddingLeft={2}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField {...params} label="Idioma" variant="standard" />
                  )}
                  value={language?.lingua}
                  autoHighlight
                  getOptionLabel={(option) =>
                    languageOptions?.find((item) => item.hashcod === option)
                      ?.descricao ?? ''
                  }
                  onChange={(_, newValue) => {
                    if (!newValue) return

                    setLanguage((language) => ({
                      ...language,
                      lingua: newValue,
                    }))
                  }}
                  options={
                    languageOptions
                      ?.filter((language) =>
                        values.languages.every(
                          (selectedLanguage) =>
                            selectedLanguage.lingua !== language.hashcod
                        )
                      )
                      ?.map((item) => item.hashcod) ?? []
                  }
                />
              </Grid>

              <Grid xs={2} paddingLeft={2}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField {...params} label="Nível" variant="standard" />
                  )}
                  value={language?.nivel}
                  disabled={!language?.lingua}
                  autoHighlight
                  getOptionLabel={(option) =>
                    languageLevelOptions?.find(
                      (item) => item.hashcod === option
                    )?.descricao ?? ''
                  }
                  onChange={(_, newValue) => {
                    if (!newValue) return

                    setLanguage((language) => ({
                      ...language,
                      nivel: newValue,
                    }))
                  }}
                  options={
                    languageLevelOptions?.map((item) => item.hashcod) ?? []
                  }
                />
              </Grid>

              <Grid
                container
                xs={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
              >
                <MUIButton
                  size="small"
                  variant="outlined"
                  color="primary"
                  disabled={!language.lingua || !language.nivel}
                  onClick={addLanguage}
                >
                  Adicionar idioma
                </MUIButton>
              </Grid>

              {values.languages.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <FormLabel>Linguas selecionadas</FormLabel>
                    <Grid container spacing={2}>
                      {values.languages.map(({ lingua, nivel }, index) => {
                        const languageName = languageOptions?.find(
                          (item) => item.hashcod === lingua
                        )?.descricao
                        const languageLevelName = languageLevelOptions?.find(
                          (item) => item.hashcod === nivel
                        )?.descricao

                        return (
                          <Grid key={`${lingua}${nivel}`} item xs={'auto'}>
                            <Chip
                              color="primary"
                              onDelete={() => {
                                removeLanguage(index)

                                return <ClearIcon />
                              }}
                              label={`${languageName} (${languageLevelName})`}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={4} />
              <Grid item xs={4} />
              <Grid container spacing={0} paddingLeft={2}>
                <FormLabel>Localização</FormLabel>
              </Grid>

              <Grid xs={2} paddingLeft={2}>
                <TextField
                  fullWidth
                  select
                  label="Estado"
                  value={stateUF}
                  variant="standard"
                  onChange={(e) => {
                    setStateUF(e.target.value)
                    setCity('')
                  }}
                >
                  {BRAZIL_STATES.filter((state) =>
                    values.locationAreas.every(
                      (operationArea) =>
                        state.uf !== operationArea.stateUF ||
                        operationArea.city !== 'Todas'
                    )
                  ).map((option) => (
                    <MenuItem key={`est-${option.value}`} value={option.uf}>
                      {option.uf}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid xs={2} paddingLeft={2}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="standard"
                      label="Cidade"
                    />
                  )}
                  value={city}
                  disabled={!stateUF}
                  autoHighlight
                  onChange={(_, newValue) => setCity(newValue ?? '')}
                  options={[
                    'Todas',
                    ...cityOptions
                      .filter((city) =>
                        values.locationAreas.every(
                          (operationArea) =>
                            city.microrregiao.mesorregiao.UF.sigla !==
                              operationArea.stateUF ||
                            operationArea.city !== city.nome
                        )
                      )
                      .map((item) => item.nome),
                  ]}
                />
              </Grid>

              <Grid
                xs={2}
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
              >
                <MUIButton
                  variant="outlined"
                  size="small"
                  disabled={!city || !stateUF}
                  onClick={addLocation}
                >
                  Adicionar Local
                </MUIButton>
              </Grid>

              {values.locationAreas.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <FormLabel>Localizações selecionadas</FormLabel>

                    <Grid container spacing={2}>
                      {values.locationAreas.map(({ city, stateUF }, index) => (
                        <Grid key={`${city}${stateUF}`} item xs={'auto'}>
                          <Chip
                            color="primary"
                            onDelete={() => {
                              removeLocation(index)

                              return <ClearIcon />
                            }}
                            label={`${
                              city === 'Todas' ? '' : city
                            } (${stateUF})`}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>

          <Grid
            container
            xs={12}
            direction="row"
            justifyContent="flex-start"
            style={{ padding: '1rem' }}
          >
            <MUIButton
              variant="contained"
              endIcon={<SearchIcon />}
              type="submit"
            >
              Buscar
            </MUIButton>
          </Grid>

          {professionalsFound && (
            <Box padding="8px" mt="16px">
              <Typography gutterBottom variant="h5" component="div">
                Resultados
              </Typography>

              <Grid container spacing={2}>
                {paginatedData.map((professional) => (
                  <Grid key={professional.hashcod} item xs={4}>
                    <ProfessionalCard
                      {...professional}
                      onClick={() => {
                        setSelectedProfessional(professional)
                        setShowProfessionalDetailDialog(true)
                      }}
                    />
                  </Grid>
                ))}
              </Grid>

              <Pagination
                count={professionalsFound?.length ?? 0}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setPage(0)
                  setPageSize(Number(event.target.value))
                }}
                rowsPerPage={pageSize}
              />
            </Box>
          )}
        </Card>

        {!!selectedProfessional && (
          <Modal
            open
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClose={() => setSelectedProfessional(undefined)}
          >
            <div
              style={{
                maxWidth: 720,
                width: '100%',
                height: '80%',
                background: 'white',
                borderRadius: 4,
                color: 'black',
              }}
            >
              <Professional
                isExclusive
                hashcode={selectedProfessional.hashcod}
                title="Detalhes do Profissional"
                onUnselectProfessional={() =>
                  setSelectedProfessional(undefined)
                }
              />
            </div>
          </Modal>
        )}
      </form>
      <InactivityBlocker
        isOpen={isInactive}
        onClose={() => setIsInactive(false)}
      />
    </>
  )
}

export default SearchProfessionals
