import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../index'
import { BOLETO_API_URI, CNPJ_SH, TOKEN_SH } from 'utils/Constants'

const plugBoletosApi = createApi({
  reducerPath: `plugBoletosApi`,
  baseQuery: fetchBaseQuery({
    baseUrl: `${BOLETO_API_URI}`,
    prepareHeaders: (headers, { getState }) => {
      const { plugBoletosApi } = getState() as RootState
      const { queries } = plugBoletosApi

      const asssignorByCnpj =
        Object.keys(queries).filter(
          (info: any) => queries[info]?.endpointName === 'getAssignorByCnpj'
        )[0] || ''

      const currentAssignor: any = queries[asssignorByCnpj] || false

      if (currentAssignor) {
        headers.set('cnpj-cedente', currentAssignor?.originalArgs)
      }

      headers.set('cnpj-sh', CNPJ_SH)
      headers.set('token-sh', TOKEN_SH)

      return headers
    },
  }),
  endpoints: () => ({}),
})

export default plugBoletosApi
