import { useCallback, useEffect, useMemo, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {
	TextField,
	Card,
	CardContent,
	CardHeader,
	Grid,
	CardActions,
	FormControlLabel,
	Checkbox,
	Typography,
	Autocomplete,
	Chip,
	Button as MUIButton
} from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { FormikConfig, FormikErrors, useFormik } from 'formik'
import InputMask from 'react-input-mask'

import { BRAZIL_STATES } from 'utils/Constants'
import { INITIAL_VALUES } from './constants'
import { setLoading } from 'store/Layout/slice'
import { useDispatch, useSelector } from 'react-redux'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { Button } from 'components/Atoms/Button'
import { pick } from 'lodash'
import { RootState, store } from 'store'
import {
	searchVacanciesRedux,
	useCreateProjectMutation,
	useLazyGetJobQuery,
	useUpdateProjectMutation,
} from 'store/SearchVacancies'
import moment from 'moment'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { TechJobCreateAndUpdateForm } from './types'
import validationSchema from './validations'
import { ICidade } from 'presentation/views/Vacancy/SearchVacancies/formObject'
import { getStateUf } from 'utils'
import {
	useGetOperationAreaLevelOptionsQuery,
	useGetOperationAreaOptionsQuery,
} from 'store/PersonalData'

const TechJobCreateAndUpdate = () => {
	const navigate = useNavigate()
	const { state } = useLocation()
	const { hashcod } = useParams() as { hashcod: string }
	const isFormDisabled = state === 'view'
	const isToCreateACopy = state === 'copy'
	const [operationType] = useState<'create' | 'update'>(
		!!hashcod ? (isToCreateACopy ? 'create' : 'update') : 'create'
	)
	const [cidades, setCidades] = useState<ICidade[]>([])

	const dispatch = useDispatch()
	const { warningSnackbar, successSnackbar } = useCustomSnackbar()
	const aboutCompany = useSelector((state: RootState) => state.userState.loggedUser?.descricao)
	const [createProject, { isLoading: createProjectIsLoading }] =
		useCreateProjectMutation()
	const [updateProject, { isLoading: updateProjectIsLoading }] =
		useUpdateProjectMutation()
	const [getProject, { data: recoveredProject, isFetching }] =
		useLazyGetJobQuery()
	const { data: operationAreaOptions } = useGetOperationAreaOptionsQuery()
	const { data: operationAreaLevelOptions } =
		useGetOperationAreaLevelOptionsQuery()

	const { loading } = useSelector((state: RootState) => state.layout)

	const handleGetProject = useCallback(
		(
			setValues: (
				values: React.SetStateAction<TechJobCreateAndUpdateForm>,
				shouldValidate?: boolean | undefined
			) =>
				| Promise<void>
				| Promise<FormikErrors<TechJobCreateAndUpdateForm>>
				| void
		) => {
			getProject(hashcod)
				.unwrap()
				.then((res) => {
					console.table({
						areaAtuacao: res.areaatuacao,
						moduloAtuacao: res.moduloatuacao,
					})
					setValues((current) => ({
						...current,
						...pick(res, [
							'alocacao_remota',
							'cidade',
							'data_cad',
							'dedicacao_diaria',
							'estado',
							'nome',
							'numero_profissionais',
							'pagamento',
							'sobre_empresa',
							'sobre_projeto',
							'tipo_contratacao',
							'cliente',
							'areaatuacao',
							'moduloatuacao',
							'nivel',
						]),
						...(res.alocacao_remota && { cidade: '', estado: '' }),
						data_inicio: moment(res.data_inicio),
						data_final: res.data_final ? moment(res.data_final) : null,
						limite_submissao: moment(res.limite_submissao),
					}))
				})
				.catch(() => {
					warningSnackbar(
						'Erro ao recuperar dados da vaga de emprego. Tente novamente mais tarde.'
					)
				})
		},
		[hashcod, getProject, warningSnackbar]
	)

	const onSubmit = useCallback<
		FormikConfig<TechJobCreateAndUpdateForm>['onSubmit']
	>(
		(values, { setValues }) => {
			if (!values.data_inicio || !values.limite_submissao) {
				warningSnackbar('Preencha corretamente as datas')

				return
			}

			const data_inicio = values.data_inicio.format('YYYY-MM-DD')
			const data_final = values.data_final
				? values.data_final.format('YYYY-MM-DD')
				: ''
			const limite_submissao = values.limite_submissao.format('YYYY-MM-DD')

			if (operationType == 'create') {
				createProject({
					...values,
					data_inicio,
					data_final,
					limite_submissao,
				})
					.unwrap()
					.then(data => {
						store.dispatch(searchVacanciesRedux.util.updateQueryData('getDiscriminatedProjects', undefined, draft => {
							draft.unshift(data);
						}))
					})
					.then(() => navigate('/oportunidades'))
					.then(() => {
						successSnackbar('Vaga de emprego criada com sucesso!')
					})
					.catch(() => {
						warningSnackbar('Erro ao criar vaga de emprego')
					})
			}

			if (operationType == 'update') {
				updateProject({
					hashcod,
					project: {
						...values,
						data_inicio,
						data_final,
						limite_submissao,
					},
				})
					.unwrap()
					.then(() => handleGetProject(setValues))
					.then(() => navigate('/oportunidades'))
					.then(() => {
						successSnackbar('Projeto alterado com sucesso')
					})
					.catch(() => {
						warningSnackbar('Erro ao alterar projeto')
					})
			}
		},
		[
			createProject,
			handleGetProject,
			hashcod,
			navigate,
			operationType,
			updateProject,
			successSnackbar,
			warningSnackbar,
		]
	)

	const {
		errors,
		handleChange,
		handleSubmit,
		setFieldValue,
		setValues,
		values,
	} = useFormik<TechJobCreateAndUpdateForm>({
		validationSchema,
		initialValues: INITIAL_VALUES,
		onSubmit,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const specializations = useMemo(() => {
		if (!operationAreaOptions || !values.areaatuacao) return []

		const area = operationAreaOptions?.find(
			(item) => item.id === values.areaatuacao
		)

		return area?.modulo_areaatuacao ?? []
	}, [operationAreaOptions, values.areaatuacao])

	const getCities = (state: string) => {
		dispatch(setLoading(true))

		const stateUF = getStateUf(state)

		const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateUF}/municipios`

		fetch(url)
			.then((response) => {
				return response.json()
			})
			.then((data) => {
				const cities = [{ id: '', nome: 'Todas' }, ...data]

				setCidades(cities)
				setFieldValue('cidade', 'Todas')
			})
			.catch(() => {
				warningSnackbar('Falha ao recuperar cidades')
			})
			.finally(() => {
				dispatch(setLoading(false))
			})
	}

	useEffect(() => {
		if (operationType === 'update') return handleGetProject(setValues)
		if (operationType === 'create' && isToCreateACopy)
			return handleGetProject(setValues)
		else if (operationType === 'create') {
			setFieldValue(
				'sobre_empresa',
				aboutCompany
			)
		}
	}, [
		operationType,
		handleGetProject,
		setFieldValue,
		setValues,
		isToCreateACopy,
		aboutCompany
	])

	useEffect(() => {
		dispatch(setLoading(updateProjectIsLoading || isFetching))

		return () => {
			dispatch(setLoading(false))
		}
	}, [dispatch, isFetching, updateProjectIsLoading])

	return (
		<>
			<MUIButton style={{ width: 'fit-content' }} variant='text' onClick={() => navigate('/oportunidades')}><ArrowBackRoundedIcon />&nbsp;Voltar</MUIButton>
			<form onSubmit={handleSubmit}>
				<Card>
					<CardHeader
						title={
							!!isFormDisabled
								? 'Detalhes da vaga de emprego'
								: `${operationType === 'create' ? 'Adicionar' : 'Atualizar'
								} vaga de emprego`
						}
					/>

					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									name="nome"
									label="Título da vaga de emprego"
									variant="standard"
									value={values.nome}
									onChange={handleChange}
									error={!!errors.nome}
									helperText={errors.nome}
									disabled={!!isFormDisabled}
								/>
							</Grid>

							<Grid item xs={12} sm={6}>
								<DesktopDatePicker
									minDate={moment(new Date())}
									label="Data final da candidatura"
									inputFormat="DD/MM/YYYY"
									value={values.limite_submissao}
									onChange={(date) => setFieldValue('limite_submissao', date)}
									disabled={!!isFormDisabled}
									renderInput={(params) => (
										<TextField
											{...params}
											fullWidth
											error={!!errors.limite_submissao}
											helperText={errors.limite_submissao}
											variant="standard"
										/>
									)}
								/>
							</Grid>

							<Grid item xs={12} sm={6}>
								<DesktopDatePicker
									minDate={moment(new Date())}
									label="Data de início da alocação"
									inputFormat="DD/MM/YYYY"
									value={values.data_inicio}
									onChange={(date) => setFieldValue('data_inicio', date)}
									disabled={!!isFormDisabled}
									renderInput={(params) => (
										<TextField
											{...params}
											fullWidth
											error={!!errors.data_inicio}
											helperText={errors.data_inicio}
											variant="standard"
										/>
									)}
								/>
							</Grid>

							<Grid item xs={12} sm={6}>
								<DesktopDatePicker
									disabled={!!isFormDisabled}
									minDate={moment(new Date())}
									label="Data final da alocação (deixe em branco para indeterminado)"
									inputFormat="DD/MM/YYYY"
									value={values.data_final}
									onChange={(date) => setFieldValue('data_final', date)}
									renderInput={(params) => (
										<TextField
											{...params}
											fullWidth
											error={!!errors.data_final}
											helperText={errors.data_final}
											variant="standard"
										/>
									)}
								/>
							</Grid>

							<Grid
								item
								xs={values.alocacao_remota ? 12 : 4}
								sm={values.alocacao_remota ? 12 : 2}
							>
								<FormControlLabel
									disabled={!!isFormDisabled}
									control={
										<Checkbox
											checked={values.alocacao_remota}
											onChange={(event) => {
												setFieldValue('alocacao_remota', event.target.checked)
												setFieldValue('estado', '')
												setFieldValue('cidade', '')
											}}
										/>
									}
									label="Alocação remota"
								/>
							</Grid>

							{values.alocacao_remota === false && (
								<>
									<Grid item xs={4} sm={5}>
										<Autocomplete
											disabled={!!isFormDisabled}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Estado"
													error={!!errors.estado}
													helperText={errors.estado}
												/>
											)}
											value={values.estado}
											autoHighlight
											onChange={(_, newValue) => {
												setFieldValue(`estado`, newValue || undefined)

												if (newValue) getCities(newValue)
											}}
											options={BRAZIL_STATES.map((item) => item.title)}
										/>
									</Grid>

									<Grid item xs={4} sm={5}>
										<Autocomplete
											disabled={!!isFormDisabled || !values.estado}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="standard"
													label="Cidade"
												/>
											)}
											value={values.cidade}
											autoHighlight
											onChange={(_, newValue) =>
												setFieldValue(`cidade`, newValue || undefined)
											}
											options={cidades.map((item) => item.nome)}
										/>
									</Grid>
								</>
							)}

							<Grid item xs={12} sm={6}>
								<InputMask
									disabled={!!isFormDisabled}
									mask="99:99"
									maskChar=""
									value={values.dedicacao_diaria}
									onChange={handleChange}
								>
									{(input) => (
										<TextField
											{...input}
											disabled={!!isFormDisabled}
											fullWidth
											name="dedicacao_diaria"
											label="Dedicação Diária (ex: 08:00)"
											variant="standard"
											error={!!errors.dedicacao_diaria}
											helperText={errors.dedicacao_diaria}
										/>
									)}
								</InputMask>
							</Grid>

							<Grid item xs={12} sm={6}>
								<NumericFormat
									disabled={!!isFormDisabled}
									customInput={TextField}
									fullWidth
									label={`Valor/Hora (deixe em branco para "a combinar")`}
									variant="standard"
									name="pagamento"
									value={values.pagamento}
									onValueChange={({ value }) => setFieldValue('pagamento', value)}
									error={!!errors.pagamento}
									helperText={errors.pagamento}
									allowNegative={false}
									decimalScale={2}
									decimalSeparator=","
									allowedDecimalSeparators={[',']}
									thousandSeparator="."
									valueIsNumericString
									prefix={'R$ '}
								/>
							</Grid>

							<Grid item xs={12} sm={6}>
								<TextField
									disabled={!!isFormDisabled}
									fullWidth
									name="tipo_contratacao"
									label="Tipo de Contratação"
									variant="standard"
									value={values.tipo_contratacao}
									onChange={handleChange}
									placeholder="CLT/PJ"
									error={!!errors.tipo_contratacao}
									helperText={errors.tipo_contratacao}
								/>
							</Grid>

							<Grid item xs={12} sm={6}>
								<NumericFormat
									disabled={!!isFormDisabled}
									customInput={TextField}
									fullWidth
									label="Número de Profissionais nesta vaga de emprego"
									variant="standard"
									value={values.numero_profissionais}
									onValueChange={({ floatValue }) =>
										setFieldValue('numero_profissionais', floatValue)
									}
									error={!!errors.numero_profissionais}
									helperText={errors.numero_profissionais}
									allowNegative={false}
									decimalScale={0}
								/>
							</Grid>

							<Grid item xs={12} sm={6}>
								<TextField
									disabled={!!isFormDisabled}
									fullWidth
									name="sobre_projeto"
									label="Sobre a Vaga de Emprego"
									value={values.sobre_projeto}
									onChange={handleChange}
									multiline
									minRows={7}
									error={!!errors.sobre_projeto}
									helperText={errors.sobre_projeto}
								/>
							</Grid>

							<Grid item xs={12} sm={6}>
								<TextField
									disabled={!!isFormDisabled}
									fullWidth
									name="sobre_empresa"
									label="Sobre a Empresa"
									value={values.sobre_empresa}
									onChange={handleChange}
									multiline
									minRows={7}
									error={!!errors.sobre_empresa}
									helperText={errors.sobre_empresa}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									disabled={!!isFormDisabled}
									fullWidth
									style={{ zIndex: 0 }}
									label="Cliente"
									name="cliente"
									value={values.cliente}
									onChange={handleChange}
								/>
							</Grid>
							<Grid xs={12} sm={4} paddingLeft={2}>
								<Grid>
									<Autocomplete
										disabled={!!isFormDisabled}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Área de atuação"
												variant="standard"
												// error={!!errors.areaatuacao}
												// helperText={errors.areaatuacao}
												margin="dense"
											/>
										)}
										value={values.areaatuacao}
										autoHighlight
										getOptionLabel={(option) =>
											operationAreaOptions?.find((item) => item.id === option)
												?.descricao ?? ''
										}
										onChange={(_, newValue) => {
											setFieldValue(`areaatuacao`, newValue)
											setFieldValue(`moduloatuacao`, [])
										}}
										options={operationAreaOptions?.map((item) => item.id) ?? []}
									/>
								</Grid>
							</Grid>
							<Grid xs={12} sm={4} paddingLeft={2}>
								<Autocomplete
									renderInput={(params) => (
										<TextField
											{...params}
											variant="standard"
											label="Especialização"
											margin="dense"
										/>
									)}
									value={values.moduloatuacao}
									disabled={!values.areaatuacao || !!isFormDisabled}
									autoHighlight
									getOptionLabel={(option) =>
										specializations?.find((item) => item.id === option)
											?.descricao ?? ''
									}
									renderTags={(value: readonly number[], getTagProps) =>
										value.map((option: number, index: number) => {
											const description =
												specializations?.find((item) => item.id === option)
													?.descricao ?? ''

											return (
												<Chip
													variant="outlined"
													label={description}
													{...getTagProps({ index })}
												/>
											)
										})
									}
									onChange={(_, newValue) =>
										setValues((current) => ({
											...current,
											moduloatuacao: newValue,
										}))
									}
									options={specializations?.map((item) => item.id) ?? []}
								/>
							</Grid>
							<Grid xs={12} sm={4} paddingLeft={2}>
								<Autocomplete
									disabled={!!isFormDisabled}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="standard"
											label="Nível de experiência"
											margin="dense"
										/>
									)}
									value={values.nivel}
									autoHighlight
									getOptionLabel={(option) =>
										operationAreaLevelOptions?.find((item) => item.id === option)
											?.descricao ?? ''
									}
									renderTags={(value: readonly number[], getTagProps) =>
										value.map((option: number, index: number) => {
											const description =
												operationAreaLevelOptions?.find(
													(item) => item.id === option
												)?.descricao ?? ''

											return (
												<Chip
													variant="outlined"
													label={description}
													{...getTagProps({ index })}
												/>
											)
										})
									}
									onChange={(_, newValue) =>
										setValues((current) => ({
											...current,
											nivel: newValue,
										}))
									}
									options={
										operationAreaLevelOptions?.map((item) => item.id) ?? []
									}
								/>
							</Grid>
						</Grid>
					</CardContent>

					{!isFormDisabled && (
						<CardActions>
							<Button
								style={{ width: 'fit-content', padding: '0 1rem' }}
								loading={
									loading || createProjectIsLoading || updateProjectIsLoading
								}
								disabled={
									operationType === 'update' && recoveredProject?.status === 'F'
								}
								type={'submit'}
								buttonType="primary"
							>
								{operationType === 'create' && 'Cadastrar'}
								{operationType === 'update' && 'Atualizar'}
							</Button>

							{operationType === 'update' && recoveredProject?.status === 'F' && (
								<Typography>
									Não é possível alterar um projeto finalizado
								</Typography>
							)}
						</CardActions>
					)}
				</Card>
			</form>
		</>
	)
}

export default TechJobCreateAndUpdate
