import { useMemo } from 'react'
import { Button } from '@mui/material'
import { useParams, useLocation } from 'react-router-dom'

import { useGetJobQuery } from 'store/SearchVacancies'
import { JobResponse } from '../../types/index'
import { getStateUf } from 'utils'
import CircularProgress from '@mui/material/CircularProgress'
import './styles.scss'
import JobAttribute from './JobAttribute'
import { useWebFoundYourJobApplyForAJobMutation } from 'store/WeFoundYourJob'

export default function WeFoundYourJobDetail() {
  const { hashcod } = useParams() as { hashcod: string }
  const { state } = useLocation() as { state: { job: JobResponse } }
  const { data, isError, isLoading, isSuccess } = useGetJobQuery(hashcod)
  const [applyForAjob] = useWebFoundYourJobApplyForAJobMutation()

  const job = useMemo(() => {
    if (isSuccess) return data

    if (state) return state.job

    return undefined
  }, [])

  return (
    <div className="we-found-your-job-detail">
      <h1>Detalhe da Vaga de Emprego {job ? `${job.nome}` : ''}</h1>

      {(job || isSuccess) && job && (
        <>
          <div className="job-description">
            <img className="company-logo" src={job.empresa.imagem}></img>

            <JobAttribute
              name="Local da Vaga de Emprego"
              value={
                job.estado && job.cidade
                  ? `${getStateUf(job.estado)}/${job.cidade}`
                  : 'Indeterminado'
              }
            />

            <JobAttribute
              name="Duração"
              value={`De: ${job.data_inicio ?? 'Indeterminado'}`}
            />

            <JobAttribute
              name="Submissão"
              value={`De: ${job.data_inicio ?? 'Indeterminado'}`}
            />

            <JobAttribute
              name="Dedicação Diária"
              value={`08:00 ${job.data_final ?? 'Indeterminado'}`}
            />

            <JobAttribute
              name="Valor/Hora"
              value={
                job.pagamento ? 'R$ ' + job.pagamento + '/Hora' : 'À combinar'
              }
            />

            <JobAttribute
              name="Tipo de Contratação"
              value={job.tipo_contratacao ?? 'Indeterminado'}
            />

            <JobAttribute
              name="Número de profissionais envolvidos"
              value={job.numero_profissionais ?? 'Indeterminado'}
            />

            <JobAttribute
              name="Sobre a Vaga de Emprego"
              value={`${job.sobre_projeto ?? 'Indeterminado'}`}
            />

            <JobAttribute
              name="Sobre a Empresa"
              value={job.sobre_empresa ?? 'Indeterminado'}
            />

            {job.empresa.telefone && (
              <JobAttribute
                name="Contato do Recrutador"
                value={job.empresa.telefone ?? 'Indeterminado'}
              />
            )}
          </div>

          <Button
            variant="contained"
            fullWidth
            onClick={() =>
              applyForAjob({ contratado: 'CANDIDATURA', projeto: job.hashcod })
            }
          >
            Candidatar-se
          </Button>
        </>
      )}

      {!job && isLoading && <CircularProgress />}

      {!job && isError && (
        <h3>Erro ao carregar dados dessa vaga de emprego.</h3>
      )}
    </div>
  )
}
