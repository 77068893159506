import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import NextWeekIcon from '@mui/icons-material/NextWeek'
import LogoutIcon from '@mui/icons-material/Logout'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'

import { StripedTableRow } from 'components'
import { JobResponse } from 'types'
import {
  useSetJobProgressMutation,
  useSetJobFinishedStatusMutation,
  useDeleteJobMutation,
} from 'store/SearchVacancies'
import { useEffect } from 'react'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { useDispatch } from 'react-redux'
import { setLoading } from 'store/Layout/slice'
import { useNavigate } from 'react-router-dom'
import routes from 'utils/Constants/routes'

const DataTable = ({ data, refetchData }: DataTableProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [setJobProgress, { isLoading: setJobProgressIsLoading }] =
    useSetJobProgressMutation()
  const [setJobFinishedStatus, { isLoading: setJobFinishedStatusIsLoading }] =
    useSetJobFinishedStatusMutation()
  const [deleteJob, { isLoading: deleteJobIsLoading }] = useDeleteJobMutation()
  const { warningSnackbar, successSnackbar } = useCustomSnackbar()

  const putJobInProgress = (item: JobResponse) => {
    dispatch(setLoading(true))

    setJobProgress({
      hashcod: item.hashcod,
      status: 'E',
    })
      .unwrap()
      .then(refetchData)
      .then(() => {
        successSnackbar('Oportunidade colocada em andamento com sucesso!')
      })
      .catch((err) => {
        if (err.data[0] === 'PROFISSIONAIS_EXCEDEM_PROJETO')
          warningSnackbar('Profissionais excedem projeto.')
        else if (err.data[0] === 'PROFISSIONAIS_EXCEDEM_PROJETO_PENDENTES')
          warningSnackbar('Profissionais excedem projetos pendentes.')
        else
          warningSnackbar(
            'Erro ao colocar oportunidade em andamento. Tente novamente mais tarde.'
          )
      })
  }

  const closeJob = (item: JobResponse) => {
    dispatch(setLoading(true))

    setJobFinishedStatus({
      hashcod: item.hashcod,
      status: 'F',
    })
      .unwrap()
      .then(refetchData)
      .then(() => {
        successSnackbar('Oportunidade encerrada com sucesso!')
      })
      .catch(() => {
        warningSnackbar(
          'Erro ao encerrar a vaga de emprego. Tente novamente mais tarde.'
        )
      })
  }

  const handleDeleteJob = (hashcod: string) => {
    deleteJob(hashcod)
      .unwrap()
      .then(refetchData)
      .then(() => {
        successSnackbar('Oportunidade apagada com sucesso!')
      })
      .catch(() => {
        warningSnackbar(
          'Erro ao apagar oportunidade. Tente novamente mais tarde.'
        )
      })
  }

  useEffect(() => {
    dispatch(
      setLoading(
        setJobProgressIsLoading ||
          setJobFinishedStatusIsLoading ||
          deleteJobIsLoading
      )
    )

    return () => {
      dispatch(setLoading(false))
    }
  }, [
    dispatch,
    setJobProgressIsLoading,
    setJobFinishedStatusIsLoading,
    deleteJobIsLoading,
  ])

  return (
    <Card>
      <CardHeader title="Processo de hunting" />

      <CardContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Descrição da vaga de emprego</TableCell>
                <TableCell align="left">Cliente</TableCell>
                <TableCell align="left">Data Final de Candidaturas</TableCell>
                <TableCell align="left">
                  Data Final da Vaga de Emprego
                </TableCell>
                <TableCell align="center">Profissionais Sugeridos</TableCell>
                <TableCell align="center">Candidaturas</TableCell>
                <TableCell align="center">Colocar Em Andamento</TableCell>
                <TableCell align="center">Encerrar vaga de emprego</TableCell>
                <TableCell align="center">Editar</TableCell>
                <TableCell align="center">Apagar</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((item) => (
                <StripedTableRow
                  hover
                  key={item.hashcod}
                  onClick={() => {}}
                  sx={{
                    cursor: 'pointer',
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row" align="left">
                    {item.nome}
                  </TableCell>

                  <TableCell component="th" scope="row" align="left">
                    --------
                  </TableCell>

                  <TableCell component="th" scope="row" align="left">
                    {moment(item.limite_submissao).format('DD/MM/YYYY')}
                  </TableCell>

                  <TableCell component="th" scope="row" align="left">
                    {item.data_final
                      ? moment(item.data_final).format('DD/MM/YYYY')
                      : 'indeterminado'}
                  </TableCell>

                  <TableCell component="th" scope="row" align="center">
                    <Chip label="--------" color="primary" />
                  </TableCell>

                  <TableCell component="th" scope="row" align="center">
                    <Chip label="--------" color="primary" />
                  </TableCell>

                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => putJobInProgress(item)}
                    >
                      <NextWeekIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell align="center">
                    <IconButton color="primary" onClick={() => closeJob(item)}>
                      <LogoutIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(`${routes.TECH_JOB_UPDATE}/${item.hashcod}`)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => handleDeleteJob(item.hashcod)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </StripedTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

interface DataTableProps {
  data: JobResponse[]
  refetchData: () => void
}

export default DataTable
