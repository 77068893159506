import React from 'react'

import Modal from '@mui/material/Modal'

import './styles.scss'
import { Button } from '@mui/material'

export interface MaterialModalProps {
  open: boolean
  cancelText?: string
  blockCancel?: boolean
  confirmText?: string
  onClose?: () => void
  blockConfirm?: boolean
  onConfirm?: () => void
  children: React.ReactNode
  containerCustomClassName?: string
}

const MaterialModal: React.FC<MaterialModalProps> = ({
  open,
  children = <></>,
  onClose,
  onConfirm,
  cancelText = 'Cancelar',
  confirmText = 'Confirmar',
  containerCustomClassName = '',
  blockCancel = false,
  blockConfirm = false,
}) => {
  const DisplayButton = ({ onFunction, children }: any) => {
    if (onFunction) return children

    return <></>
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={`material-ui-modal ${containerCustomClassName}`}>
        {children}
        <div className="material-ui-modal-footer">
          <DisplayButton onFunction={onClose}>
            <Button onClick={onClose} disabled={blockCancel}>
              {cancelText}
            </Button>
          </DisplayButton>
          <DisplayButton onFunction={onConfirm}>
            <Button
              className="modal-confirm-button"
              variant="contained"
              onClick={onConfirm}
              disabled={blockConfirm}
            >
              {confirmText}
            </Button>
          </DisplayButton>
        </div>
      </div>
    </Modal>
  )
}

export default MaterialModal
