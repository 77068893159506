import { Localization } from 'types'
import * as yup from 'yup'

export const initialValues: VacancyFilterForm = {
  hashcod: '',
  palavras_chave: '',
  raio_atuacao: 0,
  tipo_filtro: 'N',
  remoto: false,
}

export type ILocalizacaoForm = {
  estado: IEstados
  cidade: ICidade
  uf: string
}

export interface VacancyFilter {
  hashcod: string
  palavras_chave: string
  raio_atuacao: number
  tipo_filtro: string
  remoto: boolean
  localizacoes: Localization[]
}

export interface VacancyFilterForm {
  hashcod: string
  palavras_chave: string
  raio_atuacao: number
  tipo_filtro: string
  remoto: boolean
}

export interface ICidade {
  id: string
  nome: string
}

export interface IEstados {
  value: string
  uf: string
  title: string
}

export const marks = [
  {
    value: 0,
    label: '0 Km',
  },
  {
    value: 300,
    label: '300 Km',
  },
]

const req = 'Item obrigatório.'

export const schema = yup.object().shape({
  hashcod: yup.string().nullable(),
  palavras_chave: yup.string().nullable(),
  remoto: yup.boolean().required(req),
  tipo_filtro: yup
    .string()
    .test(
      'tipo_filtro',
      'Selecione atuação remota ou filtro por cidades',
      (value, global) =>
        value === 'L' || value === 'R' ? true : global.parent.remoto
    ),
})

export const locationFilterSchema = yup
  .array()
  .of(
    yup.object().shape({
      cidade: yup
        .object()
        .shape({
          id: yup.string().nullable(),
          nome: yup.string(),
        })
        .required(req),
      estado: yup
        .object()
        .shape({
          value: yup.string(),
          uf: yup.string(),
          title: yup.string(),
        })
        .required(req),
    })
  )
  .test('localizacoes', 'Estado e cidade obrigatório', (value, global) => {
    const teste1 = !!value?.length
    const teste =
      value?.every(({ cidade, estado }) => !!cidade?.nome && !!estado?.title) ||
      false
    return global.parent.tipo_filtro === 'L'
      ? teste1
        ? teste
        : false
      : global.parent.tipo_filtro === 'R'
      ? true
      : global.parent.remoto
  })

export const estados: IEstados[] = [
  { value: '12', uf: 'AC', title: 'Acre' },
  { value: '27', uf: 'AL', title: 'Alagoas' },
  { value: '16', uf: 'AP', title: 'Amapá' },
  { value: '13', uf: 'AM', title: 'Amazonas' },
  { value: '29', uf: 'BA', title: 'Bahia' },
  { value: '23', uf: 'CE', title: 'Ceará' },
  { value: '53', uf: 'DF', title: 'Distrito Federal' },
  { value: '32', uf: 'ES', title: 'Espírito Santo' },
  { value: '52', uf: 'GO', title: 'Goiás' },
  { value: '21', uf: 'MA', title: 'Maranhão' },
  { value: '51', uf: 'MT', title: 'Mato Grosso' },
  { value: '50', uf: 'MS', title: 'Mato Grosso do Sul' },
  { value: '31', uf: 'MG', title: 'Minas Gerais' },
  { value: '15', uf: 'PA', title: 'Pará' },
  { value: '25', uf: 'PB', title: 'Paraíba' },
  { value: '41', uf: 'PR', title: 'Paraná' },
  { value: '26', uf: 'PE', title: 'Pernambuco' },
  { value: '22', uf: 'PI', title: 'Piauí' },
  { value: '33', uf: 'RJ', title: 'Rio de Janeiro' },
  { value: '24', uf: 'RN', title: 'Rio Grande do Norte' },
  { value: '43', uf: 'RS', title: 'Rio Grande do Sul' },
  { value: '11', uf: 'RO', title: 'Rondônia' },
  { value: '14', uf: 'RR', title: 'Roraima' },
  { value: '42', uf: 'SC', title: 'Santa Catarina' },
  { value: '35', uf: 'SP', title: 'São Paulo' },
  { value: '28', uf: 'SE', title: 'Sergipe' },
  { value: '17', uf: 'TO', title: 'Tocantins' },
]
