import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { endOfDay, isAfter } from 'date-fns'
import moment from 'moment'

import {
  JobPreferenceRequest,
  JobPreferenceResponse,
  JobResponse,
} from '../../types/index'
import {
  APP_STORAGE_PREFIX,
  APP_API_URI,
  JOB_PREFERENCIES,
  INVITATION,
  DELETE_JOB_PREFERENCE,
  ADD_JOB_PREFERENCE,
  GET_JOBS_WITH_FILTER,
} from '../../utils/Constants'
import { StorageGetItem } from '../../utils/Storage'

export const findYourJob = createApi({
  reducerPath: `weFoundYourJob`,
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_API_URI}`,
    prepareHeaders: (headers, { getState }) => {
      const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))

      if (token) {
        headers.set('Authorization', `JWT ${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    getWeFoundYourJobPreferencies: builder.query<JobPreferenceResponse[], any>({
      query: () => `${JOB_PREFERENCIES}`,
    }),
    getWeFoundYourJobPreferenciesJobQuantity: builder.mutation<number, void>({
      queryFn: async (_, _queryApi, _extraOptions, fetchWithBQ) => {
        const preferencies = await fetchWithBQ({
          url: JOB_PREFERENCIES,
          method: 'GET',
        })

        if (!preferencies.data)
          return { error: preferencies.error as FetchBaseQueryError }

        const preferenceLengths = await Promise.all(
          (preferencies.data as JobPreferenceResponse[]).map(
            async (preference) => {
              let filter = ''

              if (preference.tipo_filtro === 'L') {
                preference.localizacoes.forEach((localizacao) => {
                  if (localizacao.estado !== '' && localizacao.cidade !== '') {
                    filter += `localizacao=${encodeURIComponent(
                      localizacao.estado
                    )},${encodeURIComponent(localizacao.cidade)}&`
                  } else if (
                    localizacao.estado !== '' &&
                    localizacao.cidade === ''
                  ) {
                    filter += `localizacao=${encodeURIComponent(
                      localizacao.estado
                    )}&`
                  }
                })
              }

              if (
                preference.tipo_filtro === 'R' &&
                preference.raio_atuacao > 0
              ) {
                filter += `raio_atuacao=${preference.raio_atuacao}&`
              }

              if (preference.palavras_chave) {
                filter += `palavra_chave=${encodeURIComponent(
                  preference.palavras_chave
                )}`
              }

              const result = await fetchWithBQ({
                url: `${GET_JOBS_WITH_FILTER}?${filter}`,
                method: 'GET',
              })

              if (!result.data) return 0

              return (result.data as JobResponse[]).filter(
                ({ limite_submissao }) => {
                  const limiteSubmissao = endOfDay(
                    moment(limite_submissao).toDate()
                  )
                  const dateNow = new Date()

                  return isAfter(limiteSubmissao, dateNow)
                }
              ).length
            }
          )
        )

        const lengthsSum = preferenceLengths.reduce(
          (acc, item) => acc + item,
          0
        )

        return { data: lengthsSum }
      },
    }),
    addJobPreference: builder.mutation<any, JobPreferenceRequest>({
      query(preference) {
        return {
          url: `${ADD_JOB_PREFERENCE}`,
          body: preference,
          method: 'POST',
        }
      },
    }),
    deleteJobPreference: builder.mutation<any, string>({
      query(hashcod) {
        return {
          url: `${DELETE_JOB_PREFERENCE}${hashcod}/`,
          method: 'DELETE',
        }
      },
    }),
    webFoundYourJobApplyForAJob: builder.mutation<
      any,
      { projeto: string; contratado: string }
    >({
      query(body) {
        return {
          url: `${INVITATION}`,
          body,
          method: 'POST',
        }
      },
    }),
  }),
})

export const {
  useGetWeFoundYourJobPreferenciesQuery,
  useGetWeFoundYourJobPreferenciesJobQuantityMutation,
  useWebFoundYourJobApplyForAJobMutation,
  useAddJobPreferenceMutation,
  useDeleteJobPreferenceMutation,
} = findYourJob
