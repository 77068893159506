import { store } from 'store'
import nodeApi from '../api'
import { setTerms } from '../slice'

const injectedNodeApi = nodeApi.injectEndpoints({
  endpoints: (build) => ({
    postTerm: build.mutation<any, any>({
      query: (newTerm) => ({
        method: 'POST',
        url: '/terms',
        body: newTerm,
      }),
    }),
    putTerm: build.mutation<any, any>({
      query: (updatedTerm) => ({
        method: 'PUT',
        url: `/terms/${updatedTerm?.id}`,
        body: updatedTerm,
      }),
    }),
    getCompanyTerms: build.query<any, any>({
      query: () => `/terms/company`,
      transformResponse: (response: any) => {
        store.dispatch(setTerms(response?.data))
        return response?.data
      },
    }),
  }),
})

export const {
  usePutTermMutation,
  usePostTermMutation,
  useGetCompanyTermsQuery,
  useLazyGetCompanyTermsQuery,
} = injectedNodeApi

export default injectedNodeApi
