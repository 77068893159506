import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import jobLogo from '../../../../img/job-logo.png'
import {
  useCheckEmailMutation,
  usePostSendCodeEmailMutation,
} from '../../../../store/CadNewUser'
import {
  useSignInMutation,
  useSignUpMutation,
} from '../../../../store/Auth/AuthAPI'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { StorageRemoveItem } from '../../../../utils/Storage'
import TemplateAppBar from '../../../../components/Templates/AppBar'
import { useUpdatePersonalDataMutation } from 'store/PersonalData'
import { ContractedUserRequest } from 'types'
import {
  AddressTab,
  AddressTabRef,
  DescriptionTab,
  CurriculumTabRef,
  CompanyDataTab,
  CompanyDataTabRef,
  UseTermsTab,
} from './tabs'
import CodeVerificationModal from './CodeVerificationModal'
import './styles.scss'
import { setLoading } from 'store/Layout/slice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'

const CompanySignUp = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loading = useSelector((state: RootState) => state.layout.loading)
  const { warningSnackbar, successSnackbar } = useCustomSnackbar()

  const [activeStep, setActiveStep] = React.useState(0)
  const [showModal, setShowModal] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [useTermsWasAccepted, setUseTermsWasAccepted] = useState(false)

  const [signIn, { isLoading: signInIsloading }] = useSignInMutation()
  const [postVerificationEmail, { isLoading: postVerificationEmailIsLoading }] =
    useCheckEmailMutation()
  const [
    postSendCodeEmail,
    { data: postSendCodeEmailData, isLoading: postSendCodeEmailIsLoading },
  ] = usePostSendCodeEmailMutation()
  const [signUp, { isLoading: signUpIsLoading }] = useSignUpMutation()

  const [updatePersonalData, { isLoading: updateUserPersonalDataIsLoading }] =
    useUpdatePersonalDataMutation()

  const personalDataTabRef = useRef<CompanyDataTabRef>(null)
  const addressTabRef = useRef<AddressTabRef>(null)
  const curriculumTabRef = useRef<CurriculumTabRef>(null)

  const checkEmail = async () => {
    if (!personalDataTabRef.current) {
      return warningSnackbar(
        'Erro ao carregar dados. Por favor, tente novamente mais tarde.'
      )
    }

    const { email } = personalDataTabRef.current.values

    try {
      const { result } = await postVerificationEmail(email).unwrap()

      if (result === true) {
        warningSnackbar('Email já cadastrado na nossa base de dados!')

        setShowModal(false)
        setActiveStep(0)
      } else {
        postSendCodeEmail({ email })
          .then(() => setShowModal(true))
          .catch(() =>
            warningSnackbar(
              'Falha ao enviar email com código. Por favor, tente mais tarde.'
            )
          )
      }
    } catch (error) {
      warningSnackbar('Falha ao verificar email. Por favor, tente mais tarde.')
    }
  }

  const onCodeSubmit = async () => {
    if (
      !personalDataTabRef.current ||
      !addressTabRef.current ||
      !curriculumTabRef.current
    ) {
      return warningSnackbar(
        'Erro ao carregar dados. Por favor, tente novamente mais tarde.'
      )
    }

    if (verificationCode !== postSendCodeEmailData.codigo)
      return warningSnackbar('Código inválido')

    const { email, senha } = personalDataTabRef.current.values

    const { result: signUpResult } = await signUp({
      email,
      password: senha,
      tipo: 'CONTRATANTE',
    }).unwrap()

    if (signUpResult === 'OK') {
      await signIn({
        email,
        password: senha,
      })

      const userInformation: ContractedUserRequest = {
        ...personalDataTabRef.current.values,
        ...addressTabRef.current.values,
        ...curriculumTabRef.current.values,
      }

      await updatePersonalData(userInformation)
    }

    successSnackbar('Cadastro realizado com sucesso!')

    setShowModal(false)

    navigate('/logout')
  }

  const handleNext = () => setActiveStep((current) => current + 1)

  const handleBack = () => setActiveStep((current) => current - 1)

  const resetSteps = () => setActiveStep(0)

  const steps = useMemo(
    () => [
      {
        label: 'Dados da Empresa',
        Component: <CompanyDataTab ref={personalDataTabRef} />,
      },
      {
        label: 'Endereço',
        Component: <AddressTab ref={addressTabRef} />,
      },
      {
        label: 'Descrição',
        Component: <DescriptionTab ref={curriculumTabRef} />,
      },
      {
        label: 'Termos de uso',
        Component: (
          <UseTermsTab
            accepted={useTermsWasAccepted}
            setAccepted={setUseTermsWasAccepted}
          />
        ),
      },
    ],
    [useTermsWasAccepted]
  )

  useEffect(() => {
    dispatch(
      setLoading(
        signInIsloading ||
          postVerificationEmailIsLoading ||
          postSendCodeEmailIsLoading ||
          signUpIsLoading ||
          updateUserPersonalDataIsLoading
      )
    )

    return () => {
      dispatch(setLoading(false))
    }
  }, [
    dispatch,
    signInIsloading,
    postVerificationEmailIsLoading,
    postSendCodeEmailIsLoading,
    signUpIsLoading,
    updateUserPersonalDataIsLoading,
  ])

  return (
    <>
      <TemplateAppBar />

      <div className="register-new-container">
        <div className="imagem-container">
          <img src={jobLogo} width="8%" />
        </div>

        <Box sx={{ width: '100%' }}>
          <>
            <Stepper
              activeStep={activeStep}
              alternativeLabel={true}
              style={{ margin: '2rem 0rem' }}
            >
              {steps.map((step) => {
                const stepProps: { completed?: boolean } = {}
                return (
                  <Step key={step.label} {...stepProps}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>

            {steps.map((item, index) => (
              <div
                key={item.label}
                role="tabpanel"
                hidden={activeStep !== index}
              >
                {item.Component}
              </div>
            ))}

            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Todos os passos foram completados
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button onClick={resetSteps}>Voltar ao primeiro passo</Button>
                </Box>
              </React.Fragment>
            ) : (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, color: 'black' }}
                  >
                    Voltar
                  </Button>

                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button
                    onClick={
                      activeStep === steps.length - 1
                        ? checkEmail
                        : () => {
                            switch (activeStep) {
                              case 0:
                                personalDataTabRef?.current
                                  ?.validate()
                                  .then(handleNext)
                                break
                              case 1:
                                addressTabRef?.current
                                  ?.validate()
                                  .then(handleNext)
                                break
                              case 2:
                                curriculumTabRef?.current
                                  ?.validate()
                                  .then(handleNext)
                                break

                              default:
                                break
                            }
                          }
                    }
                    disabled={
                      loading ||
                      (activeStep === steps.length - 1 && !useTermsWasAccepted)
                    }
                  >
                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                  </Button>
                </Box>
              </>
            )}
          </>
        </Box>

        <CodeVerificationModal
          code={verificationCode}
          onClose={() => setShowModal(false)}
          onCodeChange={setVerificationCode}
          onCodeSubmit={onCodeSubmit}
          open={showModal}
        />
      </div>
    </>
  )
}

export default CompanySignUp
