import styled, { css, keyframes } from 'styled-components'
import { IButton, IButtonLoading } from './types'
import { shade, lighten } from 'polished'
import { LoadingButton } from '@mui/lab'

const buttonTypes = {
  primary: css<IButton>`
    background-color: ${({ theme }) => theme.colors.button.primary};
    color: ${({ theme }) => theme.colors.text.primary};
    border: 1px solid ${({ theme }) => theme.colors.button.primary};

    ${({ disabled, theme }) =>
      !disabled &&
      css`
        &:hover {
          background-color: ${shade(0.15, theme.colors.button.primary)};
        }
      `}

    ${({ disabled, theme }) =>
      disabled &&
      css`
        background-color: ${theme.colors.base.weak}};
        cursor: not-allowed;
      `}

    ${({ loading, theme }) =>
      loading &&
      css`
        background-color: ${lighten(0.2, theme.colors.contrast.default)}};

        &:hover {
          background-color: ${lighten(0.2, theme.colors.contrast.default)};
        }
      `}

    svg {
      ${({ theme }) =>
        css`
          fill: ${theme.colors.text.primary};
        `}
    }

    ${({ ghost, theme, loading }) =>
      ghost &&
      css`
        background: none;
        color: ${theme.colors.contrast.default};
        border: 1px solid ${theme.colors.contrast.default};

        &:hover {
          background: none;
        }

        ${!loading &&
        css`
          &:hover {
            background: none;
            color: ${theme.colors.contrast.dark};
            border: 1px solid ${theme.colors.contrast.dark};
          }
        `}

        svg {
          fill: ${theme.colors.contrast.default};
        }
      `}

    ${({ text, theme }) =>
      text &&
      css`
        background: none;
        color: ${theme.colors.contrast.default};
        border: none;

        &:hover {
          background: none;
          color: ${theme.colors.contrast.dark};
        }
      `}
  `,
  secondary: css<IButton>`
    background-color: ${({ theme }) => theme.colors.button.secondary};
    color: ${({ theme }) => theme.colors.text.primary};
    border: unset;

    svg {
      ${({ theme }) =>
        css`
          fill: ${theme.colors.text.primary};
        `}
    }

    ${({ disabled, theme }) =>
      !disabled &&
      css`
        &:hover {
          background-color: ${shade(0.15, theme.colors.button.secondary)};
        }
      `}

    ${({ disabled, theme }) =>
      disabled &&
      css`
        background-color: ${lighten(0.02, theme.colors.contrast.lightBlue)}};
        color: ${lighten(0.2, theme.colors.text.primary)};
        cursor: not-allowed;

        svg {
          fill: ${lighten(0.2, theme.colors.text.primary)};
        }
      `}

    ${({ loading, theme }) =>
      loading &&
      css`
        background-color: ${lighten(0.02, theme.colors.contrast.lightBlue)}};
        color: ${lighten(0.2, theme.colors.text.primary)};

        &:hover {
          background-color: ${lighten(0.02, theme.colors.contrast.lightBlue)};
        }

        svg {
          fill: ${lighten(0.2, theme.colors.text.primary)};
        }
      `}
  `,
  success: css<IButton>`
    background-color: ${({ theme }) => theme.colors.button.success};
    color: ${({ theme }) => theme.colors.text.secondary};
    border: unset;

    ${({ disabled, theme }) =>
      !disabled &&
      css`
        &:hover {
          background-color: ${shade(0.15, theme.colors.button.success)};
        }
      `}

    ${({ disabled, theme }) =>
      disabled &&
      css`
      background-color: ${lighten(0.2, theme.colors.status.success)}};
      cursor: not-allowed;
    `}

  ${({ loading, theme }) =>
      loading &&
      css`
      background-color: ${lighten(0.2, theme.colors.status.success)}};

      &:hover {
        background-color: ${lighten(0.2, theme.colors.status.success)};
      }
    `}

  svg {
      ${({ theme }) =>
        css`
          fill: ${theme.colors.text.primary};
        `}
    }

    ${({ ghost, theme, loading }) =>
      ghost &&
      css`
        background: none;
        color: ${theme.colors.status.success};
        border: 1px solid ${theme.colors.status.success};

        &:hover {
          background: none;
        }

        ${!loading &&
        css`
          &:hover {
            background: none;
            color: ${shade(0.2, theme.colors.status.success)};
            border: 1px solid ${shade(0.2, theme.colors.status.success)};
          }
        `}

        svg {
          fill: ${theme.colors.status.success};
        }
      `}

    ${({ text, theme }) =>
      text &&
      css`
        background: none;
        color: ${theme.colors.status.success};
        border: none;

        &:hover {
          background: none;
          color: ${shade(0.2, theme.colors.status.success)};
        }
      `}
  `,
  danger: css<IButton>`
    background-color: ${({ theme }) => theme.colors.button.danger};
    color: ${({ theme }) => theme.colors.text.secondary};
    border: unset;

    ${({ disabled, theme }) =>
      !disabled &&
      css`
        &:hover {
          background-color: ${shade(0.15, theme.colors.button.danger)};
        }
      `}

    ${({ disabled, theme }) =>
      disabled &&
      css`
    background-color: ${lighten(0.2, theme.colors.status.danger)}};
    cursor: not-allowed;
  `}

${({ loading, theme }) =>
      loading &&
      css`
    background-color: ${lighten(0.2, theme.colors.status.danger)}};

    &:hover {
      background-color: ${lighten(0.2, theme.colors.status.danger)};
    }
  `}

svg {
      ${({ theme }) =>
        css`
          fill: ${theme.colors.text.primary};
        `}
    }

    ${({ ghost, theme, loading }) =>
      ghost &&
      css`
        background: none;
        color: ${theme.colors.status.danger};
        border: 1px solid ${theme.colors.status.danger};

        &:hover {
          background: none;
        }

        ${!loading &&
        css`
          &:hover {
            background: none;
            color: ${shade(0.2, theme.colors.status.danger)};
            border: 1px solid ${shade(0.2, theme.colors.status.danger)};
          }
        `}

        svg {
          fill: ${theme.colors.status.danger};
        }
      `}

    ${({ text, theme }) =>
      text &&
      css`
        background: none;
        color: ${theme.colors.status.danger};
        border: none;

        &:hover {
          background: none;
          color: ${shade(0.2, theme.colors.status.danger)};
        }
      `}
  `,
  warning: css<IButton>`
    background-color: ${({ theme }) => theme.colors.button.warning};
    color: ${({ theme }) => theme.colors.text.secondary};
    border: unset;

    ${({ disabled, theme }) =>
      !disabled &&
      css`
        &:hover {
          background-color: ${shade(0.15, theme.colors.button.warning)};
        }
      `}

    ${({ disabled, theme }) =>
      disabled &&
      css`
  background-color: ${lighten(0.2, theme.colors.status.warning)}};
  cursor: not-allowed;
`}

${({ loading, theme }) =>
      loading &&
      css`
  background-color: ${lighten(0.2, theme.colors.status.warning)}};

  &:hover {
    background-color: ${lighten(0.2, theme.colors.status.warning)};
  }
`}

svg {
      ${({ theme }) =>
        css`
          fill: ${theme.colors.text.primary};
        `}
    }

    ${({ ghost, theme, loading }) =>
      ghost &&
      css`
        background: none;
        color: ${theme.colors.status.warning};
        border: 1px solid ${theme.colors.status.warning};

        &:hover {
          background: none;
        }

        ${!loading &&
        css`
          &:hover {
            background: none;
            color: ${shade(0.2, theme.colors.status.warning)};
            border: 1px solid ${shade(0.2, theme.colors.status.warning)};
          }
        `}

        svg {
          fill: ${theme.colors.status.warning};
        }
      `}

    ${({ text, theme }) =>
      text &&
      css`
        background: none;
        color: ${theme.colors.status.warning};
        border: none;

        &:hover {
          background: none;
          color: ${shade(0.2, theme.colors.status.warning)};
        }
      `}
  `,
  info: css<IButton>`
    background-color: ${({ theme }) => theme.colors.button.info};
    color: ${({ theme }) => theme.colors.text.secondary};
    border: unset;

    ${({ disabled, theme }) =>
      !disabled &&
      css`
        &:hover {
          background-color: ${shade(0.2, theme.colors.contrast.minimum)};
        }
      `}

    ${({ disabled, theme }) =>
      disabled &&
      css`
  background-color: ${lighten(0.2, theme.colors.contrast.minimum)}};
  cursor: not-allowed;
`}

${({ loading, theme }) =>
      loading &&
      css`
  background-color: ${lighten(0.2, theme.colors.contrast.minimum)}};

  &:hover {
    background-color: ${lighten(0.2, theme.colors.contrast.minimum)};
  }
`}

svg {
      ${({ theme }) =>
        css`
          fill: ${theme.colors.contrast.default};
        `}
    }

    ${({ ghost, theme, loading }) =>
      ghost &&
      css`
        background: none;
        color: ${theme.colors.contrast.minimum};
        border: 1px solid ${theme.colors.contrast.minimum};

        &:hover {
          background: none;
        }

        ${!loading &&
        css`
          &:hover {
            background: none;
            color: ${shade(0.2, theme.colors.contrast.minimum)};
            border: 1px solid ${shade(0.2, theme.colors.contrast.minimum)};
          }
        `}

        svg {
          fill: ${theme.colors.contrast.minimum};
        }
      `}

    ${({ text, theme }) =>
      text &&
      css`
        background: none;
        color: ${theme.colors.contrast.minimum};
        border: none;

        &:hover {
          background: none;
          color: ${shade(0.2, theme.colors.contrast.minimum)};
        }
      `}
  `,
}

export const Container = styled.button<IButton>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.4s;
  cursor: pointer;
  min-height: 2.5rem;
  height: 100%;
  height: ${({ variant }) =>
      variant === 'large' ? 56 : variant === 'small' ? 24 : 32}
    px;
  padding: ${({ variant }) =>
      variant === 'large' ? 16 : variant === 'small' ? 8 : 10}
    px;
  font-size: ${({ variant }) =>
      variant === 'large' ? 14 : variant === 'small' ? 10 : 12}
    px;
  width: 100%;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 0)} px;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)} px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)} px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)} px;

  ${({ iconRight }) =>
    iconRight &&
    css`
      padding-right: 6px;
    `}

  ${({ iconLeft }) =>
    iconLeft &&
    css`
      padding-left: 6px;
    `}

  ${({ buttonType }) => buttonType && buttonTypes[buttonType]}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  svg {
    width: ${({ variant }) =>
        variant === 'large' ? 16 : variant === 'small' ? 10 : 14}
      px;
    margin-right: ${({ iconLeft, variant }) =>
        iconLeft && variant === 'large' ? 8 : variant === 'small' ? 4 : 6}
      px;
    margin-left: ${({ iconRight, variant }) =>
        iconRight && variant === 'large' ? 8 : variant === 'small' ? 4 : 6}
      px;
  }
`

const spin = keyframes`
  from {
  transform: rotate(0deg)
}
  to {
  transform: rotate(360deg)
}
`

interface ContentProps {
  isLoading: boolean
}

export const Content = styled.div<ContentProps>`
  ${({ isLoading }) =>
    isLoading &&
    css`
      z-index: -1;
    `}
`
