import styled from 'styled-components'

export const ApprovedQtyApplicants = styled.button<{ hasApproved: boolean }>`
  border: none;
  outline: none;
  cursor: initial;
  color: black;
  ${(props) =>
    props.hasApproved &&
    `
			text-decoration: underline;
			color: blue;
			cursor: pointer;
		`}
`
