import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FormikConfig, useFormik } from 'formik'
import { useDispatch } from 'react-redux'

import { INITIAL_VALUES } from './constants'
import {
  useGetLanguageLevelOptionsQuery,
  useGetLanguageOptionsQuery,
  useGetUserLanguagesQuery,
  useAddUserLanguageMutation,
  useDeleteUserLanguageMutation,
} from 'store/Profile'
import '../../../styles.scss'
import validationSchema from './validations'
import ClearIcon from '@mui/icons-material/Clear'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { LanguageRequest } from 'types'
import { useEffect } from 'react'
import { setLoading } from 'store/Layout/slice'

const LanguageTab = () => {
  const dispatch = useDispatch()
  const { warningSnackbar, successSnackbar } = useCustomSnackbar()
  const {
    data: userLanguages,
    isLoading: getUserLanguagesIsLoading,
    refetch,
  } = useGetUserLanguagesQuery()
  const { data: languageOptions, isLoading: getLanguageIsLoading } =
    useGetLanguageOptionsQuery()
  const { data: languageLevelOptions, isLoading: getLanguageLevelIsLoading } =
    useGetLanguageLevelOptionsQuery()
  const [addUserLanguage, { isLoading: addUserLanguageIsLoading }] =
    useAddUserLanguageMutation()
  const [deleteUserLanguage, { isLoading: deleteUserLanguageIsLoading }] =
    useDeleteUserLanguageMutation()

  const onSubmit: FormikConfig<LanguageRequest>['onSubmit'] = (
    values,
    { resetForm }
  ) => {
    addUserLanguage(values)
      .unwrap()
      .then(refetch)
      .then(() => resetForm())
      .then(() => {
        successSnackbar('Lingua adicionada com sucesso.')
      })
      .catch(() =>
        warningSnackbar(
          'Falha ao adicionar lingua. Tente novamente mais tarde.'
        )
      )
  }

  const { values, handleSubmit, setFieldValue, errors } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  })

  const handleDeleteUserLanguage = (hash: string) => {
    deleteUserLanguage(hash)
      .unwrap()
      .then(refetch)
      .then(() => {
        successSnackbar('Lingua apagada com sucesso.')
      })
      .catch(() =>
        warningSnackbar('Falha ao apagar lingua. Tente novamente mais tarde.')
      )
  }

  useEffect(() => {
    dispatch(
      setLoading(
        getUserLanguagesIsLoading ||
          getLanguageIsLoading ||
          getLanguageLevelIsLoading ||
          addUserLanguageIsLoading ||
          deleteUserLanguageIsLoading
      )
    )

    return () => {
      dispatch(setLoading(false))
    }
  }, [
    dispatch,
    getUserLanguagesIsLoading,
    getLanguageIsLoading,
    getLanguageLevelIsLoading,
    addUserLanguageIsLoading,
    deleteUserLanguageIsLoading,
  ])

  return (
    <form onSubmit={handleSubmit}>
      <div className="horinzontal">
        <div className="row" style={{ justifyContent: 'flex-start' }}>
          <div className="row" style={{ width: '70%', gap: 16 }}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={`idiomas`}
                  label="Idioma"
                  variant="standard"
                  error={!!errors.lingua}
                  helperText={errors.lingua}
                  margin="dense"
                />
              )}
              style={{ width: '48%' }}
              value={values.lingua}
              autoHighlight
              getOptionLabel={(option) =>
                languageOptions?.find((item) => item.hashcod === option)
                  ?.descricao ?? ''
              }
              onChange={(_, newValue) => setFieldValue('lingua', newValue)}
              options={languageOptions?.map((item) => item.hashcod) ?? []}
            />

            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nível"
                  variant="standard"
                  error={!!errors.nivel}
                  helperText={errors.nivel}
                  margin="dense"
                />
              )}
              style={{ width: '48%' }}
              value={values.nivel}
              disabled={!values.lingua}
              autoHighlight
              getOptionLabel={(option) =>
                languageLevelOptions?.find((item) => item.hashcod === option)
                  ?.descricao ?? ''
              }
              onChange={(_, newValue) => setFieldValue('nivel', newValue)}
              options={languageLevelOptions?.map((item) => item.hashcod) ?? []}
            />

            <div className="button-container">
              <Button variant="contained" type="submit">
                Adicionar
              </Button>
            </div>
          </div>
        </div>

        {userLanguages && userLanguages.length > 0 && (
          <>
            <Typography variant="subtitle2" component="div" marginTop={2}>
              Minhas linguas
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              marginTop={1}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {userLanguages.map(({ get_lingua, get_nivel, hashcod }) => (
                <Chip
                  key={hashcod}
                  color="primary"
                  onDelete={() => {
                    handleDeleteUserLanguage(hashcod)

                    return <ClearIcon />
                  }}
                  label={`${get_lingua} (${get_nivel})`}
                />
              ))}
            </Stack>
          </>
        )}
      </div>
    </form>
  )
}

export default LanguageTab
