import { Box, CircularProgress, Container, Typography } from '@mui/material'

type FullScreenLoaderProps = {
  text?: string
}

const FullScreenLoader = (props: FullScreenLoaderProps) => {
  return (
    <Container sx={{ height: '95vh' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        <CircularProgress style={{ color: '#274490' }} />
        <Typography mt={2} variant="h6" component="h6" className="text-loading">
          {props.text}
        </Typography>
      </Box>
    </Container>
  )
}

export default FullScreenLoader
