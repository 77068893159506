import React from 'react'

const ExceptionFooter = () => {
  return (
    <div className="exception-footer">
      <h4>JOB Connect</h4>

      <h6>Ⓒ{new Date().getFullYear()} JOB Connect</h6>
    </div>
  )
}

export default ExceptionFooter
