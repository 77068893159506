import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { FullScreenLoader } from '../../components/Atoms'
import { clearAuth } from 'utils/Storage'

import TemplateDefault from '../../components/Templates/Default'
import { clearState } from '../../store/actions'
import { setIsAuthenticated } from 'store/Auth/slice'
import { personalDataRedux } from 'store/PersonalData'

export const Logout = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		clearAuth()
		dispatch(clearState())
		dispatch(setIsAuthenticated(false))
		navigate('/login')
		dispatch(personalDataRedux.util.resetApiState())
		window.location.reload();
	}, [dispatch, navigate])

	return (
		<TemplateDefault>
			<>
				<Helmet>
					<title>JOB Connet | Logout</title>
				</Helmet>{' '}
				<FullScreenLoader text={'Saindo...'} />
			</>
		</TemplateDefault>
	)
}
