import styled from "styled-components"

export const Container = styled.div`
	display: flex;
	
	.react-pdf__Page__annotations  {
		display: none !important;
	}

	canvas {
    width: 100% !important;
  }
	
`

export const Wrapper = styled.div`
	display: flex; 
	align-items: center; 
	justify-content: center;
`