import React from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import { formatCurrency } from 'utils/currency'

export interface MaterialTableProps {
  data: {
    id: number
    [key: string]: any
  }[]
  columns: any
  setSelectedRows?: any
  selectedRows?: number[]
  multiSelectRows?: boolean
  hiddenCheckBoxes?: boolean
}

export const MaterialTable: React.FC<MaterialTableProps> = ({
  data,
  columns,
  selectedRows,
  setSelectedRows,
  hiddenCheckBoxes = false,
  multiSelectRows = false,
}) => {
  const isSelected = (id: number) => selectedRows?.includes(id)

  const handleClick = (id: number) => {
    if (!setSelectedRows) return

    const customSelectedRows = selectedRows || []

    if (isSelected(id))
      return setSelectedRows(selectedRows?.filter((row) => row !== id))

    if (multiSelectRows) return setSelectedRows([...customSelectedRows, id])

    setSelectedRows([id])
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', height: '100%' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!hiddenCheckBoxes && (
                <TableCell
                  key={'checkBox'}
                  style={{
                    minWidth: 50,
                    backgroundColor: 'whitesmoke',
                  }}
                ></TableCell>
              )}
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: 'whitesmoke',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => {
              const isItemSelected = isSelected(row?.id)
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <TableRow
                  hover
                  role="checkbox"
                  onClick={() => handleClick(row?.id)}
                  aria-checked={isItemSelected}
                  selected={isItemSelected}
                  tabIndex={-1}
                  key={row?.id}
                  style={{ cursor: 'pointer' }}
                >
                  {!row?.hiddenCheckBox && !hiddenCheckBoxes && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => {
                    const value = row[column.id]

                    const displayTableValue = () => {
                      if (column.format) return formatCurrency(value)

                      if (typeof value === 'boolean')
                        return value ? 'Sim' : 'Não'

                      return value
                    }

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {displayTableValue()}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default MaterialTable
