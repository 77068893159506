import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const locationApi = createApi({
  reducerPath: 'IBGE/Api/location',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://servicodados.ibge.gov.br/api/v1',
    prepareHeaders: (headers, { getState }) => {
      return headers
    },
  }),
  endpoints: () => ({}),
})

export default locationApi
