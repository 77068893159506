import { PartnerFormModel } from "domain/models/partner";
import { cpf } from "cpf-cnpj-validator";

export const partnerFormValidation = (partnerForm: PartnerFormModel) => {
  const errors: any = {};

  if (partnerForm.name.length === 0) errors.name = "Nome é obrigatório";
  if (partnerForm.cpf.length === 0 || !cpf.isValid(partnerForm.cpf))
    errors.cpf = "Insira um CPF válido";
  if (partnerForm.quota.length === 0)
    errors.quota = "A cota do sócio não deve exceder o valor restante";
  if (partnerForm?.hasProlabore) {
    if (partnerForm.pisNit.length === 0)
      errors.pisNit = "PIS/NIT é obrigatório";
    if (partnerForm.prolaboreValue.length === 0)
      errors.prolaboreValue = "Valor do pro-labore é obrigatório";
  }

  return errors;
};
