import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  FormHelperText,
  FormControl,
  Input as _Input,
  InputProps,
  InputLabel,
} from '@mui/material'

import { styled } from '@mui/material/styles'

const Input = styled(_Input)``

type IFormInputProps = {
  name: string
  label: string
} & InputProps

const InputText: FC<IFormInputProps> = ({ name, label, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <FormControl fullWidth variant="standard">
          <InputLabel id={`input-${name}`}> {label}</InputLabel>
          <Input
            {...field}
            fullWidth
            id={`input-${name}`}
            error={!!errors[name]}
            {...otherProps}
          />
          {/* <FormHelperText error={!!errors[name]}>
              {errors[name] ? errors[name].message : ''}
            </FormHelperText> */}
        </FormControl>
      )}
    />
  )
}

export default InputText
