import styled from 'styled-components'

export const Container = styled.article`
  display: flex;
  padding: 0.5rem 0;
`

export const Profile = styled.section`
  display: flex;
  align-items: center;
`

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  margin-left: 1rem;
`

export const Actions = styled.section`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`

export const SelectionProfessional = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: min-content; */
  /* padding: 0 0.5rem; */
  margin-right: 2rem;
  height: 100%;
  cursor: pointer;
`
