import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { endOfDay, isAfter, parseISO } from 'date-fns'
import _ from 'lodash'
import moment from 'moment'
import { InvitationResponse } from 'types'
import { JobResponse } from 'types/Job'
import {
	INVITATION_VACANCIES_PEDENT,
	INVITATION,
	APP_API_URI,
	APP_STORAGE_PREFIX,
	MY_APPLYCATIONS,
	MY_INVITATIONS,
	ACCEPT_INVITATION,
	RATINGCOMPANY,
	INVITATION_ACCEPT,
	USER_REQUEST_LEAVE,
	WORKER_CANCEL_LEAVE,
	WORKER_ACCEPT_LEAVE,
	UPDATE_APPLICATION,
	RESTART_INVITATION,
	RECRUITER_REFUSE_LEAVE,
} from '../../utils/Constants'
import { StorageGetItem } from '../../utils/Storage'

export const invitationVacanciesJob = createApi({
	reducerPath: 'weInvitateVacanciesJob',
	baseQuery: fetchBaseQuery({
		baseUrl: `${APP_API_URI}`,
		prepareHeaders: (headers, { getState }) => {
			const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))

			if (token) {
				headers.set('Authorization', `JWT ${token}`)
			}

			return headers
		},
	}),
	tagTypes: ['Invites', 'Suggestions', 'Applications'],
	endpoints: (builder) => ({
		getInvitationVacanciesPedent: builder.query({
			query: () => `${INVITATION_VACANCIES_PEDENT}`,
		}),
		getWeInvitateVacanciesJobQuantity: builder.query<number, void>({
			query: () => `${MY_INVITATIONS}`,
			transformResponse: (response: InvitationResponse[]) =>
				response.filter(
					(item) => item.status === 'P' && item.tipo === 'CONVITE'
				).length,
		}),
		getInvitations: builder.query<InvitationResponse[], void>({
			query: () => `${MY_INVITATIONS}`,
			transformResponse: (response: InvitationResponse[]) =>
				response.filter(
					(item) => item.status === 'P' && item.tipo === 'CONVITE'
				),
		}),
		getInvitationsStatusFinalizado: builder.query<JobResponse[], string>({
			query: () => `${MY_INVITATIONS}`,
			transformResponse: (invitations: InvitationResponse[]) => {
				const finishedInvitations = invitations.filter((invitation) => {
					const today = new Date()
						.toISOString()
						.slice(0, 10)
						.replace(
							(new Date().getDate() + 1).toString(),
							new Date().getDate().toString().padStart(2, '0')
						)

					console.table({
						limite: invitation.projeto.limite_submissao,
						today,
						invitation,
					})

					return (
						(invitation.projeto.data_final < today &&
							invitation.status === 'P') ||
						invitation.status === 'F'
					)
				})

				return finishedInvitations.map((invitation) => invitation.projeto)
				// return response
				//   .filter(
				//     (item) =>
				//       item.status === 'F' ||
				//       isAfter(
				//         endOfDay(moment(item.projeto.data_final).toDate()),
				//         new Date()
				//       )
				//   )
				//   .map((item) => item?.projeto),
			},
			providesTags: ['Invites'],
		}),
		getInvitationsStatusFinalizadoRated: builder.query({
			query: () => `${MY_INVITATIONS}`,
			transformResponse: (response: any) =>
				response.filter(
					(item) =>
						item.projeto.status === 'F' ||
						isAfter(
							endOfDay(moment(item.projeto.data_final).toDate()),
							new Date()
						)
				),
			providesTags: ['Invites'],
		}),
		getJobFinalizadoRated: builder.mutation({
			query: (hashcod) => ({
				url: `${RATINGCOMPANY}${hashcod}/`,
				method: 'GET',
			}),
		}),
		getInvitationsStatusAndamento: builder.query<InvitationResponse[], string>({
			query: () => `${MY_INVITATIONS}`,
			transformResponse: (response: InvitationResponse[]) => {
				return response
					.filter(
						(item) =>
							!item.status.includes('P') &&
							!item.status.includes('F') &&
							!item.status.includes('AE') &&
							!item.projeto.status.includes('F')
					)
					.filter((item) => {
						if (!item.projeto.data_final) return true

						return isAfter(
							endOfDay(moment(item.projeto.data_final).toDate()),
							new Date()
						)
					})

				// const inProgressInvitations = invitations
				//   .filter((invitation) => invitation.projeto.status !== 'F')
				//   .filter((invitation) => invitation.status === 'A')
				//   .filter((invitation) => {
				//     const today = new Date()
				//       .toISOString()
				//       .slice(0, 10)
				//       .replace(
				//         (new Date().getDate() + 1).toString(),
				//         new Date().getDate().toString().padStart(2, '0')
				//       )

				//     console.table({
				//       limite: invitation.projeto.data_final,
				//       today,
				//       invitation,
				//     })

				//     return invitation.projeto.data_final > today
				//   })

				// return inProgressInvitations
			},
		}),
		getInvitationsStatusAprovado: builder.query<JobResponse[], string>({
			query: () => `${MY_INVITATIONS}`,
			transformResponse: (response: InvitationResponse[]) =>
				response
					.filter((item) => item.status === 'A')
					.map((item) => item?.projeto),
		}),
		getInvitationsStatusRecusado: builder.query<JobResponse[], string>({
			query: () => `${MY_INVITATIONS}`,
			transformResponse: (response: InvitationResponse[]) =>
				response
					.filter((item) => item.status === 'R')
					.map((item) => item?.projeto),
		}),
		getApplycations: builder.query({
			query: () => `${MY_APPLYCATIONS}`,
		}),
		editInvitationVacancies: builder.mutation({
			query: (post) => ({
				url: `${INVITATION}`,
				method: 'POST',
				body: post,
			}),
		}),
		getJobCandidaturas: builder.query<JobResponse[], string>({
			query: () => `${MY_APPLYCATIONS}`,
			transformResponse: (response: InvitationResponse[]) =>
				response
					.filter(
						(item) =>
							item.status === 'P' || item.status === 'R' || item.status === 'AE'
						// item.status !== 'A' && item.status !== 'F'
					)
					.map((item) => item?.projeto),
		}),
		getJobCandidaturasFull: builder.query<InvitationResponse[], string>({
			query: () => `${MY_APPLYCATIONS}`,
			transformResponse: (response: InvitationResponse[]) =>
				response.filter(
					(item) =>
						item.status === 'P' || item.status === 'R' || item.status === 'AE'
					// item.status !== 'A' && item.status !== 'F'
				),
		}),
		acceptInvitationVacancies: builder.mutation({
			query: (hashcode) => ({
				url: `${ACCEPT_INVITATION}${hashcode}/`,
				method: 'PATCH',
				body: { aceitou: true },
			}),
		}),
		refuseInvitationVacancies: builder.mutation({
			query: (hashcode) => ({
				url: `${INVITATION}${hashcode}/`,
				method: 'delete',
			}),
		}),
		applyToVacancies: builder.mutation({
			query: (hashcode) => ({
				url: `${INVITATION}`,
				method: 'POST',
				body: { projeto: hashcode, tipo: 'CANDIDATURA' },
			}),
		}),
		quitVacancies: builder.mutation({
			query: (hashcod) => ({
				url: `${INVITATION}${hashcod}/`,
				method: 'DELETE',
			}),
		}),
		accepetApplyVacancies: builder.mutation({
			query: (hashcod) => ({
				url: `${INVITATION_ACCEPT}${hashcod}/`,
				method: 'PUT',
			}),
		}),
		leaveApplyVacancies: builder.mutation({
			query: (hashcod) => ({
				url: `${USER_REQUEST_LEAVE}${hashcod}/`,
				method: 'PUT',
			}),
			invalidatesTags: ['Invites'],
		}),
		deallocateVacancy: builder.mutation({
			query: (hashcod) => ({
				url: `${WORKER_ACCEPT_LEAVE}${hashcod}/`,
				method: 'PUT',
			}),
			invalidatesTags: ['Invites'],
		}),
		refuseOutVacancy: builder.mutation<any, string>({
			query: (hashcode) => ({
				url: `${RECRUITER_REFUSE_LEAVE}${hashcode}/`,
				method: 'PUT',
			}),
			invalidatesTags: ['Invites'],
		}),
		cancelLeave: builder.mutation({
			query: (hashcod) => ({
				url: `${WORKER_CANCEL_LEAVE}${hashcod}/`,
				method: 'PUT',
			}),
		}),
		updateApplication: builder.mutation<any, Record<string, string | null>>({
			query: ({ status = '', motivo = null, hashcode }) => {
				const body = {
					status,
					motivo_status: motivo,
				}

				return {
					url: `${UPDATE_APPLICATION}/${hashcode}/`,
					body,
					method: 'PUT',
				}
			},
			invalidatesTags: ['Applications'],
		}),
		getApplications: builder.query<
			InvitationResponse[],
			{ hashcode: string; status: string }
		>({
			query: ({ hashcode, status }) => {
				return {
					url: `${MY_APPLYCATIONS}&projeto=${hashcode}&status=${status}`,
				}
			},
			providesTags: ['Applications'],
		}),
		getAllInvites: builder.query<InvitationResponse[], string>({
			query: (hashcode) => ({
				url: `${MY_INVITATIONS}?projeto=${hashcode}`,
			}),
			transformResponse: (invitations: InvitationResponse[]) => {
				return invitations.filter(
					(invitation) =>
						invitation.status === 'A' ||
						invitation.status === 'SC' ||
						invitation.status === 'SR' ||
						invitation.status === 'SA'
				)
			},
			providesTags: ['Invites'],
		}),
		getApprovedInvitations: builder.query<InvitationResponse[], string>({
			query: (hashcode) => ({
				url: `${MY_INVITATIONS}?tipo=CONVITE&projeto=${hashcode}&status=A`,
			}),
		}),
		restartApplication: builder.mutation<any, string>({
			query: (hashcode) => ({
				url: `${RESTART_INVITATION}/${hashcode}/`,
				body: { motivo_status: null },
				method: 'PUT',
			}),
			invalidatesTags: ['Applications'],
		}),
		sendInvitation: builder.mutation<any, Record<string, string>>({
			query: ({ projectHashcode, professionalHashcode }) => ({
				url: `${INVITATION}`,
				method: 'POST',
				body: {
					projeto: projectHashcode,
					contratado: professionalHashcode,
					tipo: 'CONVITE',
				},
			}),
		}),
		getAllExitRequest: builder.query<string, Record<string, string>>({
			query: (hashcode) => ({
				url: `${MY_INVITATIONS}?${hashcode}&status=SC`,
			}),
		}),
		getCalendarInvites: builder.query<InvitationResponse[], void>({
			query: () => `${MY_INVITATIONS}?status=A`,
			transformResponse: (invitations: InvitationResponse[]) => {
				return invitations.filter(({ projeto }) => {
					const isProjectInProgress = projeto.status === 'E';
					const isProjectOff = !isAfter(endOfDay(parseISO(projeto.data_final)), new Date());

					return isProjectInProgress || isProjectOff
				})
			}
		}),
		getCandidaturas: builder.query<any, { hashcode: string, status: string }>({
			query: ({ status, hashcode }) => INVITATION + 'candidaturas-projeto/' + hashcode + '/?status=' + status
		})
	}),
})

export const {
	useLazyGetWeInvitateVacanciesJobQuantityQuery,
	useGetInvitationsStatusAndamentoQuery,
	useGetInvitationsStatusFinalizadoQuery,
	useGetInvitationsStatusFinalizadoRatedQuery,
	useGetJobFinalizadoRatedMutation,
	useGetInvitationsQuery,
	useLazyGetInvitationsQuery,
	useGetJobCandidaturasQuery,
	useGetJobCandidaturasFullQuery,
	useApplyToVacanciesMutation,
	useQuitVacanciesMutation,
	useAcceptInvitationVacanciesMutation,
	useRefuseInvitationVacanciesMutation,
	useAccepetApplyVacanciesMutation,
	useLeaveApplyVacanciesMutation,
	useCancelLeaveMutation,
	useDeallocateVacancyMutation,
	useUpdateApplicationMutation,
	useGetApplicationsQuery,
	useRestartApplicationMutation,
	useSendInvitationMutation,
	useGetAllInvitesQuery,
	useGetApprovedInvitationsQuery,
	useGetAllExitRequestQuery,
	useRefuseOutVacancyMutation,
	useGetCalendarInvitesQuery,
	useGetCandidaturasQuery
} = invitationVacanciesJob
