import { cnpj } from 'cpf-cnpj-validator'
import * as yup from 'yup'

const required_message = 'Este campo é obrigatório'
const email_message = 'O e-mail inserido está incorreto'
const cnpj_message = 'Este CNPJ é inválido'

export const validation_schema = yup.object({
  cnpj: yup
    .string()
    .test('cnpj_check', cnpj_message, (value) => {
      console.log(cnpj.isValid(cnpj.strip(value!)))
      return cnpj.isValid(cnpj.strip(value!))
    })
    .required(required_message),
  email: yup.string().email(email_message).required(required_message),
  company_billing: yup.string().required(required_message),
})

export const initial_values: TSchemaValidation = {
  cnpj: '',
  email: '',
  company_billing: '',
}

export type TSchemaValidation = yup.InferType<typeof validation_schema>
