import * as yup from 'yup'

import { REQUIRED_MSG } from 'utils/Constants'

const validationSchema = yup.object().shape({
	nome: yup.string().required(REQUIRED_MSG).trim(),
	email: yup.string().email("Email inválido").required(REQUIRED_MSG),
	pais: yup.string().required(REQUIRED_MSG),
	telefone: yup.string().min(11, 'Telefone inválido.').required(REQUIRED_MSG),
	imagem: yup.string().nullable().required(REQUIRED_MSG),
	senha: yup
		.string()
		.min(8, 'Mínimo de 8 caracteres.')
		.nullable()
		.required(REQUIRED_MSG),
	cnpj_cpf: yup.string().min(13, 'CNPJ inválido.').required(REQUIRED_MSG),
})

export default validationSchema
