import { Button, TextField } from '@mui/material'
import { useLazyCnpjData } from 'data/hooks/useLazyCnpjData'
import { getCnpjInfoBody } from 'data/utils/Cnpj/static'
import { cnpjMask } from 'data/utils/regex'
import React, { useContext, useEffect } from 'react'

import './styles.scss'

const BillClientInfo: React.FC = ({ BillContext }: any) => {
  const {
    selectedStep,
    billClientForm,
    setBillClientForm,
    handleChangeSelectedStep,
    handleChangeBillClientForm,
  } = useContext(BillContext)

  const handleNextStep = () => handleChangeSelectedStep(selectedStep + 1)
  const handlePreviousStep = () => handleChangeSelectedStep(selectedStep - 1)

  const [cnpjInfo] = useLazyCnpjData(billClientForm?.cpfCnpj)

  useEffect(() => {
    if (!cnpjInfo) return

    const cnpjInfoBody = getCnpjInfoBody(cnpjInfo)

    setBillClientForm({
      ...billClientForm,
      ...cnpjInfoBody,
    })
  }, [cnpjInfo])

  return (
    <div className="client-info">
      <p className="client-info-title">Informações do cliente</p>
      <div className="client-info-form">
        <p>Novo Cliente</p>
        <div className="client-info-form-fields">
          <div className="cnpj-fantasy-name">
            <TextField
              id="cnpj"
              label="CNPJ"
              variant="standard"
              placeholder="Apenas números"
              value={billClientForm?.cpfCnpj}
              onChange={(e) =>
                handleChangeBillClientForm('cpfCnpj', cnpjMask(e.target.value))
              }
            />
            <TextField
              id="social-reason"
              variant="standard"
              label="Razão Social"
              placeholder="Insira nome/razão social"
              value={billClientForm?.socialReason || ''}
              onChange={(e) =>
                handleChangeBillClientForm('socialReason', e.target.value)
              }
            />
          </div>
          <div className="phone-email">
            <TextField
              id="phone"
              type="tel"
              label="Telefone"
              variant="standard"
              placeholder="Número de telefone"
              value={billClientForm?.phone || ''}
              onChange={(e) =>
                handleChangeBillClientForm('phone', e.target.value)
              }
            />
            <TextField
              id="email"
              label="E-mail"
              variant="standard"
              placeholder="Digite um email válido"
              value={billClientForm?.email || ''}
              onChange={(e) =>
                handleChangeBillClientForm('email', e.target.value)
              }
            />
          </div>
        </div>
      </div>
      <div className="address-info-form">
        <p>Endereço</p>
        <div className="address-info-form-fields">
          <TextField
            id="cep"
            label="CEP"
            variant="standard"
            placeholder="Insira um cep para a busca"
            value={billClientForm?.cep || ''}
            onChange={(e) => handleChangeBillClientForm('cep', e.target.value)}
          />
          <div className="public-place-number">
            <TextField
              id="public-place"
              label="Logradouro"
              variant="standard"
              placeholder="Insira um endereço válido"
              value={billClientForm?.publicPlace || ''}
              onChange={(e) =>
                handleChangeBillClientForm('publicPlace', e.target.value)
              }
            />
            <TextField
              id="number"
              label="Número"
              variant="standard"
              placeholder="Apenas números"
              value={billClientForm?.number || ''}
              onChange={(e) =>
                handleChangeBillClientForm('number', e.target.value)
              }
            />
          </div>
          <div className="complement-district">
            <TextField
              id="complement"
              variant="standard"
              label="Complemento"
              placeholder="Sobrado, casa, apartamento..."
              value={billClientForm?.complement || ''}
              onChange={(e) =>
                handleChangeBillClientForm('complement', e.target.value)
              }
            />
            <TextField
              id="district"
              label="Bairro"
              variant="standard"
              placeholder="Insira o nome do bairro"
              value={billClientForm?.district || ''}
              onChange={(e) =>
                handleChangeBillClientForm('district', e.target.value)
              }
            />
          </div>
          <div className="state-city">
            <TextField
              id="state"
              label="Estado"
              variant="standard"
              value={billClientForm?.state || ''}
              onChange={(e) =>
                handleChangeBillClientForm('state', e.target.value)
              }
            />
            <TextField
              id="city"
              label="Município"
              variant="standard"
              value={billClientForm?.city || ''}
              onChange={(e) =>
                handleChangeBillClientForm('district', e.target.value)
              }
            />
          </div>
        </div>
      </div>
      <div className="bankslip-footer">
        <Button
          fullWidth
          variant="outlined"
          onClick={() => handlePreviousStep()}
        >
          Voltar
        </Button>
        <Button fullWidth variant="contained" onClick={() => handleNextStep()}>
          Confirmar
        </Button>
      </div>
    </div>
  )
}

export default BillClientInfo
