// import './styles.scss'
import CurrencyInput from '../../CurrencyInput'
import * as Yup from 'yup';

import React, { useMemo } from 'react'
import AddTaskIcon from '@mui/icons-material/AddTask'
import { Button, Divider, Grid, TextField } from '@mui/material'
import { AdditionalServiceFormModel } from 'domain/models/additionalService'
import { useFormik } from 'formik'
import Header from 'data/components/CompanyProfile/Header'
import styles from 'data/components/CompanyProfile/styles.module.css'
import { usePostAdditionalServiceMutation, usePutAdditionalServiceMutation } from 'store/NodeApi/additionalService/endpoint';
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar';
import { normalizeService } from 'utils/normalizer';
import { defaultDisplayFields } from 'data/utils/AdditionalService/static';

const SCHEMA_VALIDATION = Yup.object().shape({
	value: Yup.string().required('É necessario informar o valor'),
	service: Yup.string().required('É necessário informar o serviço'),
	description: Yup.string().required('É necessário informar a descrição do serviço'),
	payment_description: Yup.string().required('É necessário informar a descrição do pagamento'),
});

type Props = {
	serviceForm: AdditionalServiceFormModel,
	setDisplayField: Function
}

const Form: React.FC<Props> = ({
	serviceForm,
	setDisplayField
}) => {
	const [putService] = usePutAdditionalServiceMutation()
	const [postService] = usePostAdditionalServiceMutation()
	const toast = useCustomSnackbar();

	const form = useMemo(() => {
		if (!serviceForm?.id) {
			return {
				title: 'Adicionar serviço',
				button: 'Adicionar',
			}
		}

		return {
			title: 'Alterar serviço',
			button: 'Alterar',
		}
	}, [serviceForm?.id])

	const { values, handleChange, handleSubmit, errors } = useFormik<AdditionalServiceFormModel>({
		initialValues: {
			...serviceForm
		},
		validationSchema: SCHEMA_VALIDATION,
		validateOnChange: false,
		validateOnMount: false,
		onSubmit: async (service) => {
			const normalizedService = normalizeService(service);
			if (!normalizedService.id) {
				try {
					await postService(normalizedService).unwrap()
					toast.successSnackbar('Serviço criado com sucesso.')

				} catch (error) {
					toast.errorSnackbar('Erro ao criar serviço.')
				} {
					setDisplayField(defaultDisplayFields)
				}

				return;
			}

			try {
				await putService(normalizedService).unwrap();
				toast.successSnackbar('Serviço atualizado com sucesso.')

			} catch (error) {
				toast.successSnackbar('Erro ao atualizar o serviço.')
			} finally {
				setDisplayField(defaultDisplayFields)
			}
		}
	});

	return (
		<section className={styles.container} style={{ flex: 1 }}>
			<Header IconSlot={<AddTaskIcon />} title={form.title} />
			<Divider />
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid container item spacing={2}>
						<Grid item xs={10}>
							<TextField value={values.service} fullWidth variant='standard' error={!!errors.service} onChange={handleChange('service')} placeholder="Serviço" />
							{!!errors.service && <span style={{ color: 'red', fontSize: '0.8rem' }}>{errors.service}</span>}
						</Grid>
						<Grid item xs={2}>
							<CurrencyInput error={!!errors.value} value={values.value} id='currency-input' label='Valor do serviço' onChange={handleChange('value')} />
							{!!errors.value && <span style={{ color: 'red', fontSize: '0.8rem' }}>{errors.value}</span>}
						</Grid>
					</Grid>
					<Grid container item>
						<Grid item xs={12}>
							<TextField
								multiline
								variant='outlined'
								fullWidth
								rows={4}
								label="Descrição do Serviço"
								value={values.description}
								InputLabelProps={{ shrink: !!values.description }}
								onChange={handleChange('description')}
								error={!!errors.description}
							/>
							{!!errors.description && <span style={{ color: 'red', fontSize: '0.8rem' }}>{errors.description}</span>}
						</Grid>
					</Grid>
					<Grid container item>
						<Grid item xs={12}>
							<TextField
								multiline
								variant='outlined'
								fullWidth
								rows={4}
								label="Descrição do Pagamento"
								value={values.payment_description}
								InputLabelProps={{ shrink: !!values.payment_description }}
								onChange={handleChange('payment_description')}
								error={!!errors.payment_description}
							/>
							{!!errors.payment_description && <span style={{ color: 'red', fontSize: '0.8rem' }}>{errors.payment_description}</span>}
						</Grid>
					</Grid>
					<Grid container item>
						<Grid item xs={12}>
							<TextField
								variant='standard'
								fullWidth
								label="Tempo médio de entrega"
								value={values.average_delivery_time}
								InputLabelProps={{ shrink: !!values.average_delivery_time }}
								onChange={handleChange('average_delivery_time')}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							variant='outlined'
							type='submit'
						>{form.button}</Button>
					</Grid>
				</Grid>
			</form>
		</section>
	)
}

export default Form
