import {
	defaultAdditionalServiceForm,
	displayFormBody,
	displayListBody,
	defaultDisplayFields,
} from 'data/utils/AdditionalService/static'
import MaterialDialog from '../MaterialDialog'
import { DialogConfig } from 'domain/models/dialog'
import { defaultDialogState } from 'data/utils/Dialog/static'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import {
	AdditionalServiceFormModel,
	DisplayFields,
} from 'domain/models/additionalService'
import {
	AdditionalService,
	useDeleteAdditionalServiceMutation,
	useGetAdditionalServicesQuery,
	usePostAdditionalServiceMutation,
	usePutAdditionalServiceMutation,
} from 'store/NodeApi/additionalService/endpoint'

import React, {
	useState,
	ReactElement,
	createContext,
} from 'react'
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import { isCurrencyValid } from 'data/utils/SendNote/static'
import { FormOrList } from './FormOrList'

export const ServiceContext = createContext<any>(
	{}
)

const AdditionalServices: React.FC = () => {
	const [dialogConfig, setDialogConfig] =
		useState<DialogConfig>(defaultDialogState)
	const [displayFields, setDisplayFields] =
		useState<DisplayFields>(defaultDisplayFields)

	// const [services, setServices] = useState<AdditionalService[]>([])
	const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false)
	const [serviceForm, setServiceForm] = useState<AdditionalServiceFormModel>(
		defaultAdditionalServiceForm
	)
	const [serviceFormErrors, setServiceFormErrors] = useState<any>({})
	const [selectedServiceRows, setSelectedServiceRows] = useState<number[]>([])

	const { warningSnackbar, successSnackbar, errorSnackbar } =
		useCustomSnackbar()

	const [deleteService] = useDeleteAdditionalServiceMutation()
	const { data: services, refetch: refetchServices } =
		useGetAdditionalServicesQuery('')

	// useEffect(() => {
	// 	setServices(apiServices!)
	// }, [apiServices])

	const handleCloseDialog = () =>
		setDialogConfig({ ...dialogConfig, open: false })

	const handleOpen = () => setOpenSpeedDial(true)
	const handleClose = () => setOpenSpeedDial(false)

	const handleAddServiceAction = () => {
		setServiceForm(defaultAdditionalServiceForm)
		setDisplayFields(displayFormBody)

		handleClose()
	}

	const handleDisplayTable = () => {
		setDisplayFields(displayListBody)

		handleClose()
	}

	const removeServiceById = (removeId) => {
		return services?.data?.filter((service: any) => service.id !== removeId)
	}

	const getSelectedRowService = (selectedServiceId: number) => {
		return services?.data?.find((service: any) => service.id === selectedServiceId)
	}

	const additionalServiceFormValidation = (
		serviceForm: AdditionalServiceFormModel
	) => {
		const errors: any = {}

		if (serviceForm.service.length === 0)
			errors.service = 'O Nome do serviço é obrigatório'
		if (serviceForm.value.length === 0) errors.value = 'O Valor é obrigatório'
		if (serviceForm.description.length === 0)
			errors.description = 'A Descrição é obrigatória'

		return errors
	}

	const confirmRemoveService = () => {
		const selectedService = selectedServiceRows?.[0]

		deleteService(selectedService)
			.unwrap()
			.then(() => {
				successSnackbar('Serviço removido com sucesso')
				// setServices(removeServiceById(selectedService))
				setSelectedServiceRows([])
			})
			.catch((err) => {
				errorSnackbar('Não foi possível remover o serviço')
			})

		setDialogConfig(defaultDialogState)
	}

	const cancelDialogState = {
		open: true,
		onConfirm: confirmRemoveService,
		dialogBodyText: 'Deseja remover o serviço?',
	}

	const handleRemoveAction = () => {
		setDialogConfig(cancelDialogState)
	}

	const handleEditAction = () => {
		const selectedService = getSelectedRowService(selectedServiceRows[0])
		if (!selectedService) return

		const newServiceForm: AdditionalServiceFormModel = {
			id: String(selectedService.id),
			average_delivery_time: selectedService.average_delivery_time,
			description: selectedService.description,
			payment_description: selectedService.payment_description,
			service: selectedService.service,
			value: selectedService.value,
		}
		setServiceForm(newServiceForm)
		setDisplayFields(displayFormBody)

		handleClose()
	}

	const dialogProps = {
		open: dialogConfig?.open,
		onClose: handleCloseDialog,
		onConfirm: dialogConfig?.onConfirm,
		dialogBodyText: dialogConfig?.dialogBodyText,
	}

	const getDialActions = (): {
		icon: ReactElement
		name: string
		onClick: any
	}[] => {
		const newServiceBody = {
			icon: <SupportAgentIcon />,
			name: 'Novo serviço',
			onClick: handleAddServiceAction,
		}

		const seeServicesBody = {
			icon: <TableRowsOutlinedIcon />,
			name: 'Ver serviços',
			onClick: handleDisplayTable,
		}

		const editServiceBody = {
			icon: <EditIcon />,
			name: 'Editar serviço',
			onClick: handleEditAction,
		}
		const deleteServiceBody = {
			icon: <DeleteIcon />,
			name: 'Excluir serviço',
			onClick: handleRemoveAction,
		}

		const actions: any[] = [];
		const hasServiceSelected = getSelectedRowService(selectedServiceRows[0]);
		const isOnList = displayFields.serviceList;

		if (hasServiceSelected && isOnList) {
			actions.push(editServiceBody, deleteServiceBody)
			return actions;
		}

		if (!hasServiceSelected && isOnList) {
			actions.push(newServiceBody)
			return actions;
		}

		actions.push(seeServicesBody)
		return actions;
	}


	const contextValues = {
		services: services?.data!,
		serviceForm,
		displayFields,
		setDisplayFields,
		setServiceForm,
		serviceFormErrors,
		selectedServiceRows,
		setSelectedServiceRows,
	}

	return (
		<ServiceContext.Provider value={contextValues}>
			<MaterialDialog {...dialogProps} />
			<div style={{ display: 'flex', gap: '40px' }}>
				<div className="certificate-toolbar">
					<SpeedDial
						direction="down"
						onOpen={handleOpen}
						open={openSpeedDial}
						onClose={handleClose}
						icon={<SpeedDialIcon />}
						className="speed-dial-container"
						ariaLabel="SpeedDial tooltip example"
					>
						{getDialActions().map((action) => (
							<SpeedDialAction
								key={action.name}
								icon={action.icon}
								onClick={action.onClick}
								tooltipPlacement="right"
								tooltipTitle={action.name}
							/>
						))}
					</SpeedDial>
				</div>
				<FormOrList />
			</div>
		</ServiceContext.Provider>
	)
}

export default AdditionalServices
