import * as yup from 'yup'

import { MIN_PROJECTS_CARRIED_OUT, REQUIRED_MSG } from 'utils/Constants'

export const validationSchema = yup.object().shape({
  areaatuacao: yup.string().nullable().required(REQUIRED_MSG),
  moduloatuacao: yup.string().nullable().required(REQUIRED_MSG),
  nivel: yup.string().nullable().required(REQUIRED_MSG),
  projetos_executados: yup
    .number()
    .moreThan(0, MIN_PROJECTS_CARRIED_OUT)
    .required(REQUIRED_MSG),
  valor_hora: yup.string().required(REQUIRED_MSG),
})

export default validationSchema
