import { useLazyGetCnpjInfoQuery } from '../../store/PlugNotas/endpoint'
import React, { useEffect } from 'react'

import { cnpj } from 'cpf-cnpj-validator'

export const useLazyCnpjData = (cnpjValue: string) => {
  const [getCnpj, { data: cnpjInfo }] = useLazyGetCnpjInfoQuery()

  useEffect(() => {
    const isValidCnpj = cnpj.isValid(cnpjValue)

    if (isValidCnpj) getCnpj(cnpjValue)
  }, [cnpjValue, getCnpj])

  return [cnpjInfo]
}
