import styled from 'styled-components'

export const PasswordForget = styled.div`
  margin-bottom: 0px;
  margin-top: 13px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: rgba(41, 50, 65, 0.8);
  cursor: pointer;
`
export const CreateAccountText = styled.p`
  font-weight: 500;
  margin: 0;
  color: rgba(41, 50, 65, 0.5);
  margin-top: 40px;
`
export const CreateAccountLink = styled.p`
  color: #2170e7;
  font-weight: 500;
  cursor: pointer;
`
export const RowDivider = styled.div`
  display: flex;
  flex-direction: column;
`
