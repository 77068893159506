import { store } from 'store'
import nodeApi from '../api'
import { setPartners } from '../slice'

const partnerNodeApi = nodeApi.injectEndpoints({
    endpoints: (build) => ({
        postPartner: build.mutation<any, any>({
            query: (partnerBody) => ({
                method: 'POST',
                url: '/partners',
                body: partnerBody,
            }),
        }),
        getPartners: build.query<any, any>({
            query: () => `/partners/company`,
            transformResponse: (response: any) => {
                store.dispatch(setPartners(response?.data))
                return response?.data
            },
        }),
        deletePartner: build.mutation<any, any>({
            query: (partnerId) => ({
                method: 'DELETE',
                url: `/partners/${partnerId}`,
            }),
        }),
    }),
})

export const {
    useGetPartnersQuery,
    usePostPartnerMutation,
    useDeletePartnerMutation,
} = partnerNodeApi

export default partnerNodeApi
