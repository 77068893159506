import { useContext, useMemo } from "react"
import { ServiceContext } from ".."
import { serviceColumns } from "data/utils/AdditionalService/static"
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import MaterialTable from "data/components/MaterialTable"

export const ServiceList = () => {
	const { selectedServiceRows, setSelectedServiceRows, services } =
		useContext(ServiceContext)

	const serviceList = useMemo(() => {
		if (!Array.isArray(services) || !services.length) {
			return []
		}

		const newServices = [...services]

		newServices.sort((serviceA, serviceB) => {
			return (
				Number(serviceA.service.slice(0, 2).trim()) -
				Number(serviceB.service.slice(0, 2).trim())
			)
		})

		return newServices
	}, [services])

	const tableProps = {
		data: serviceList,
		columns: serviceColumns,
		selectedRows: selectedServiceRows,
		setSelectedRows: setSelectedServiceRows,
	}

	return (
		<div className="box-container">
			<div className="box-container-header flex-box">
				<AddBoxOutlinedIcon />
				<p>Serviços Adicionais</p>
			</div>
			<MaterialTable {...tableProps} />
		</div>
	)
}