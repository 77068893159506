import React, { useState, useEffect } from 'react'
import { AppBar, HeaderToolbar, LinkTutorial } from './styles'
import { RootState } from 'store'
import { selectSideBarState } from 'store/Layout/slice'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import {
	Typography,
	Menu,
	Rating,
	Avatar,
	Badge,
	Tooltip,
	MenuItem,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
} from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Grid from '@mui/material/Unstable_Grid2'
import { Button } from 'components/Atoms/Button'
import { sysAdmins } from 'data/utils/Admin/entity'
import {
	setPlanUserState,
	setPlanUserApllyQtdState,
} from 'store/PersonalData/slice'
import { connect, ConnectedProps } from 'react-redux'
import LinearProgress from '@mui/material/LinearProgress'

interface HeaderProps extends PropsFromRedux {
	handleToggleTheme: () => void
}

const DisplayProfileIcon = ({ handleOpenProfileOptions, openMenu }: any) => {
	const { loggedUser } = useSelector((state: RootState) => state.userState)

	const [photoUser, setPhotoUser] = useState<string | null>('')
	const [open, setOpen] = useState(false)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const handleClickOpen = () => {
		const tipo = localStorage
			.getItem('@JOB-CONNECT/authType')
			?.includes('CONTRATANTE')
		if (tipo) return
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		if (!loggedUser) return

		setPhotoUser(loggedUser.imagem)
		dispatch(setPlanUserState(loggedUser.plano))
		dispatch(setPlanUserApllyQtdState(loggedUser.qtde_oportunidades))
	}, [dispatch, loggedUser])

	let planoText
	switch (loggedUser?.plano) {
		case 0:
			planoText = 'Basic'
			break
		case 1:
			planoText = 'Advanced'
			break
		case 2:
			planoText = 'Premium'
			break
		case 3:
			planoText = 'Exclusive'
			break
		default:
			planoText = 'Carregando...'
	}

	function createData(
		name: string,
		perfilSug: number,
		candidaturasUser: number | string,
		dadosContato: number | string,
		ordBusca: number
	) {
		return { name, perfilSug, candidaturasUser, dadosContato, ordBusca }
	}

	const rowsPlans = [
		createData('Basic', 4, 5, 5, 4),
		createData('Advanced', 3, 10, 10, 3),
		createData('Premium', 2, 20, 20, 2),
		createData('Exclusive', 1, 'Ilimitado*', 'Ilimitado', 1),
	]

	const goToPlans = () => {
		navigate('/plans_contratado')
		setOpen(false)
	}

	const StyledBadge = styled(Badge)(({ theme }) => ({
		'& .MuiBadge-badge': {
			backgroundColor: '#44b700',
			color: '#44b700',
			boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
			'&::after': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '50%',
				animation: 'ripple 1.2s infinite ease-in-out',
				border: '1px solid currentColor',
				content: '""',
			},
		},
		'@keyframes ripple': {
			'0%': {
				transform: 'scale(.8)',
				opacity: 1,
			},
			'100%': {
				transform: 'scale(2.4)',
				opacity: 0,
			},
		},
	}))

	return (
		<Grid container spacing={2} sx={{ alignItems: 'center' }}>
			<Grid>
				<Box
					sx={{ display: 'flex', alignItems: 'end', cursor: 'pointer' }}
					onClick={handleClickOpen}
				>
					<Typography
						variant="subtitle1"
						display="block"
						sx={{ paddingRight: '10px' }}
					>
						Seu plano atual:
					</Typography>
					<Typography variant="h6" display="block">
						{planoText}
					</Typography>
				</Box>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{`Seu plano atual é o ${planoText}`}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<TableContainer>
								<Table size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<TableCell>Planos</TableCell>
											<TableCell align="right">Perfil sugerido</TableCell>
											<TableCell align="right">Candidaturas</TableCell>
											<TableCell align="right">Dados de contato</TableCell>
											<TableCell align="right">Ordem na busca</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rowsPlans.map((row) => (
											<TableRow
												key={row.name}
												sx={{
													'&:last-child td, &:last-child th': { border: 0 },
												}}
											>
												<TableCell component="th" scope="row">
													{row.name}
												</TableCell>
												<TableCell align="right">{row.perfilSug}º</TableCell>
												<TableCell align="right">
													{row.candidaturasUser}
												</TableCell>
												<TableCell align="right">{row.dadosContato}</TableCell>
												<TableCell align="right">{row.ordBusca}º</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => goToPlans()} autoFocus>
							Mudar de plano
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
			<Grid>
				<IconButton
					onClick={handleOpenProfileOptions}
					size="small"
					sx={{ ml: 2 }}
					aria-controls={openMenu ? 'account-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={openMenu ? 'true' : undefined}
				>
					{photoUser ? (
						<div className="barPerfil">
							<StyledBadge
								overlap="circular"
								anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
								variant="dot"
							>
								<Avatar
									alt="Remy Sharp"
									src={photoUser}
									imgProps={{
										style: { height: '100%', width: 'auto' },
									}}
								/>
							</StyledBadge>

							{typeof loggedUser?.pontuacao === 'number' && (
								<Rating
									name="read-only"
									value={loggedUser?.pontuacao}
									readOnly
									size="small"
								/>
							)}
						</div>
					) : (
						<div className="barPerfil">
							<StyledBadge
								overlap="circular"
								anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
								variant="dot"
							>
								<Avatar sx={{ width: 32, height: 32 }}>
									{loggedUser?.nome?.charAt(0)}
								</Avatar>
							</StyledBadge>
						</div>
					)}
				</IconButton>
			</Grid>
		</Grid>
	)
}

const DisplayHeaderMenu = ({
	handleProfileRoute,
	handleCloseMenu,
	openMenu,
	menuDisplayConfig,
}: any) => {
	const navigate = useNavigate()
	const user = useSelector((state: RootState) => state.userState.loggedUser)

	return (
		<Menu
			anchorEl={menuDisplayConfig}
			id="account-menu"
			open={openMenu}
			onClose={handleCloseMenu}
			onClick={handleCloseMenu}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: 'visible',
					filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
					mt: 1.5,
					'& .MuiAvatar-root': {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1,
					},
					'&:before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						top: 0,
						right: 14,
						width: 10,
						height: 10,
						bgcolor: 'background.paper',
						transform: 'translateY(-50%) rotate(45deg)',
						zIndex: 0,
					},
				},
			}}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
		>
			<MenuItem onClick={() => navigate('/profissional/perfil')}>
				<ListItemIcon>
					<PersonIcon fontSize="small" />
				</ListItemIcon>
				Perfil
			</MenuItem>

			<MenuItem onClick={() => navigate('/calendar')}>
				<ListItemIcon>
					<CalendarMonthIcon fontSize="small" />
				</ListItemIcon>
				Agenda
			</MenuItem>

			<MenuItem onClick={() => navigate('/logout')}>
				<ListItemIcon>
					<LogoutIcon fontSize="small" />
				</ListItemIcon>
				Logout
			</MenuItem>
		</Menu>
	)
}

const Header: React.FC<HeaderProps> = ({ sideBar, handleToggleTheme }) => {
	const loading = useSelector((state: RootState) => state.layout.loading)
	const [menuDisplayConfig, setMenuDisplayConfig] =
		useState<null | HTMLElement>(null)

	const openMenu = Boolean(menuDisplayConfig)

	const handleOpenProfileOptions = (event: React.MouseEvent<HTMLElement>) => {
		// setMenuDisplayConfig(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setMenuDisplayConfig(null)
	}

	const navigate = useNavigate()

	const handleProfileRoute = () => navigate('/profile')

	const profileIconProps = {
		openMenu,
		handleOpenProfileOptions,
	}

	const menuProps = {
		openMenu,
		handleCloseMenu,
		menuDisplayConfig,
		handleProfileRoute,
	}

	const { loggedUser } = useSelector((state: RootState) => state.userState)

	const isAdmin = loggedUser ? sysAdmins.includes(loggedUser?.email) : false

	return (
		<AppBar position="static" open={sideBar?.open}>
			<HeaderToolbar>
				{isAdmin && (
					<Box style={{ fontSize: '24px' }}>Menu do Administrador</Box>
				)}
				{!isAdmin && (
					<LinkTutorial
						href="https://www.youtube.com/@jobconnect3678/videos"
						target="_blank"
					>
						<PlayCircleOutlineIcon />{' '}
						<Typography variant="h6">Aprenda a usar a plataforma</Typography>
					</LinkTutorial>
				)}
				<DisplayProfileIcon
					{...profileIconProps}
					handleToggleTheme={handleToggleTheme}
				/>
				{/* <DisplayHeaderMenu {...menuProps} /> */}
			</HeaderToolbar>
			<Box sx={{ width: '100%' }}>
				<LinearProgress
					variant={loading ? 'indeterminate' : 'determinate'}
					value={0}
				/>
			</Box>
		</AppBar>
	)
}

const mapState = (state: RootState) => ({
	sideBar: selectSideBarState(state),
})

const connector = connect(mapState, {})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Header)
