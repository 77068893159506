import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { StorageGetItem } from '../../utils/Storage'
import { SearchProfessionalsForm } from '../../pages/Company/SearchProfessionals/types'

import {
  APP_STORAGE_PREFIX,
  APP_API_URI,
  APP_PREFIX,
  SEARCH_PROFESSIONALS,
  PENDING_APPLICATIONS_QUANTITY,
  PROFESSIONAL_PROFILE,
  PROFESSIONAL_QUANTITY,
} from '../../utils/Constants'
import { ProfessionalProfileResponse, ProfessionalResponse } from 'types'
import { isEmpty } from 'lodash'

export const professionalApi = createApi({
  reducerPath: `${APP_PREFIX}/Professional/api`,
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_API_URI}`,
    prepareHeaders: (headers) => {
      const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))
      if (token) {
        headers.set('Authorization', `JWT ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getPendingApplicationsQuantity: builder.query<number, void>({
      query: () => PENDING_APPLICATIONS_QUANTITY,
      transformResponse: (res: { quantidade: number }) => res.quantidade,
    }),
    searchProfessionals: builder.query<
      ProfessionalResponse[],
      SearchProfessionalsForm
    >({
      query: ({
        areaatuacao,
        descricao,
        languages,
        locationAreas,
        modulos_atuacao,
        niveis_experiencia,
        projetos_executados,
        valor_hora,
      }) => {
        let query = ''

        if (areaatuacao)
          query += `&${
            modulos_atuacao.length > 1 ? 'areas_atuacao' : 'area_atuacao'
          }=${areaatuacao}${modulos_atuacao.length > 1 ? ',' : ''}`

        if (!isEmpty(modulos_atuacao)) {
          query += `&${
            modulos_atuacao.length > 1 ? 'modulos_atuacao' : 'modulo_atuacao'
          }=`

          modulos_atuacao.forEach((modulo_atuacao) => {
            query += `${modulo_atuacao}${modulos_atuacao.length > 1 ? ',' : ''}`
          })
        }

        if (!isEmpty(niveis_experiencia)) {
          query += `&${
            modulos_atuacao.length > 1 ? 'niveis_experiencia' : 'nivel'
          }=`

          niveis_experiencia.forEach((nivel_experiencia) => {
            query += `${nivel_experiencia}${
              modulos_atuacao.length > 1 ? ',' : ''
            }`
          })
        }

        if (areaatuacao && modulos_atuacao.length === 1) {
          if (projetos_executados)
            query += `&projetos_realizados=${projetos_executados}`

          if (valor_hora) query += `&valor_hora=${valor_hora}`
        }

        if (!isEmpty(languages)) {
          const languagesQuery = languages.reduce(
            (locationQuery, { lingua, nivel }) => {
              return (locationQuery += `&lingua=${lingua},${nivel}`)
            },
            ''
          )

          query += languagesQuery
        }

        if (!isEmpty(locationAreas)) {
          const locationsQuery = locationAreas.reduce(
            (locationQuery, { city, stateUF }) => {
              const isAllCities = city === 'Todas'
              return (locationQuery += `&localizacao=${stateUF},${
                isAllCities ? '' : city
              }`)
            },
            ''
          )

          query += locationsQuery
        }

        if (descricao) query += `&descricao=${descricao}`

        return `${SEARCH_PROFESSIONALS}?${query}`
      },
    }),
    professionalProfile: builder.query<ProfessionalProfileResponse, string>({
      query: (hashcod) => `${PROFESSIONAL_PROFILE}${hashcod}/`,
    }),
    professionalsQuantity: builder.query<
      { numero_profissionais: number },
      string
    >({
      query: () => PROFESSIONAL_QUANTITY,
    }),
  }),
})

export const {
  useLazyGetPendingApplicationsQuantityQuery,
  useLazySearchProfessionalsQuery,
  useSearchProfessionalsQuery,
  useProfessionalProfileQuery,
  useProfessionalsQuantityQuery,
} = professionalApi
