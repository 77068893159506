import styled from 'styled-components'
import Paper from '@mui/material/Paper'

export const Container = styled(Paper)`
  padding: 10px 20px;
  // height: fit-content;
  overflow: auto;
  height: 100%;
  flex: 1;
`

export const JobDescription = styled.div`
  display: grid;
  gap: 10px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.text.paragraph};
`
