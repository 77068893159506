import * as S from './styles'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { encodeQueryParams, StringParam } from 'use-query-params'
import queryString from 'query-string'

const JobAttribute = ({
  name,
  value,
  whatsapp,
  whatsappNumber,
  whatsappText,
}: JobAttributeProps) => {
  const getWhatsappTextQueryParam = () => {
    const encodedQuery = encodeQueryParams(
      { text: StringParam },
      { text: whatsappText }
    )
    return `?${queryString.stringify(encodedQuery)}`
  }

  return (
    <S.JobAttribute>
      <S.JobAttributeName>{name}:</S.JobAttributeName>
      <span>{value}</span>
      <a
        style={{ display: 'flex' }}
        aria-label="Job WhatsApp"
        href={`https://wa.me/${whatsappNumber}${
          whatsappText
            ? getWhatsappTextQueryParam()
            : '?text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20da%20plataforma%20JOB%20Connect%21'
        }`}
        target="_blank"
      >
        {whatsapp ? (
          <>
            <WhatsAppIcon sx={{ color: 'green' }} /> {whatsapp}
          </>
        ) : null}
      </a>
    </S.JobAttribute>
  )
}

interface JobAttributeProps {
  name: string
  value?: React.ReactNode
  whatsapp?: React.ReactNode
  whatsappNumber?: string
  whatsappText?: string
}

export default JobAttribute
