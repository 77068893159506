import * as yup from 'yup'

import { REQUIRED_MSG } from 'utils/Constants'

const validationSchema = yup.object().shape({
	nome: yup.string().required(REQUIRED_MSG).trim(),
	pais: yup.string().required(REQUIRED_MSG),
	telefone: yup.string().min(11, 'Telefone inválido.').required(REQUIRED_MSG),
	imagem: yup.string().nullable(),
})

export default validationSchema
