import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
`

export const LoginContainer = styled.div`
  width: 30%;
  height: 100%;
  text-align: center;
  padding: 40px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex: 1 1 100%;
`
export const LogoContainer = styled.div`
  width: 125px;
  height: 125px;
  margin-left: -25px;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;

  Button {
    margin-top: 24px;
  }
`

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  align-self: center;
  align-items: center;
`
export const LoginFooterTittle = styled.h4`
  line-height: 22px;
  font-size: 1.5rem;
  margin: 0;
  color: rgba(41, 50, 65, 0.8);
`
export const LoginFooterText = styled.h6`
  line-height: 17px;
  margin: 0;
  margin-right: 32px;
  font-size: 1rem;
  color: rgba(41, 50, 65, 0.5);
  font-weight: 500; ;
`
