import './styles.scss'

import Step from '@mui/material/Step'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import StepLabel from '@mui/material/StepLabel'
import { StepIconProps } from '@mui/material/StepIcon'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import CurrencyInput from 'data/components/CurrencyInput'
import VideoLabelIcon from '@mui/icons-material/VideoLabel'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import React, { useState, createContext, useContext } from 'react'
import { ColorlibConnector, ColorlibStepIconRoot } from './styles'
import { Button, TextareaAutosize, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import BankBill from 'data/components/BankBill'
import BillClientInfo from 'data/components/BillClientInfo'
import {
  useGetAllBankAccountsQuery,
  usePostBankSlipMutation,
} from '../../store/PlugBoletos/endpoint'
import {
  getBankByCode,
  defaultTecnoSpeedAccountForm,
} from 'data/utils/BankAccount/static'
import { TecnoSpeedBankAccountModel } from 'domain/models/bankAccount'
import {
  defaultBillClientForm,
  defaultBillForm,
  getBankSlipBody,
} from 'data/utils/BankSlip/static'
import { BillClientForm, BillFormModel } from 'domain/models/bankslip'
import { } from 'components'

const steps = ['Dados da cobrança', 'Dados do cliente', 'Emitir']

const DisplaySendFinalInfo = () => {
  const {
    billForm,
    bankAccount,
    selectedStep,
    billClientForm,
    handleSendBankSlip,
    handleChangeSelectedStep,
  } = useContext(BillContext)

  const handlePreviousStep = () => handleChangeSelectedStep(selectedStep - 1)

  const bankInfoByCode = getBankByCode(bankAccount?.codigo_banco)

  const agencyWithDv = `${bankAccount?.agencia}-${bankAccount?.agencia_dv}`
  const accountWithDv = `${bankAccount?.conta}-${bankAccount?.conta_dv}`
  const bankInfo = `${bankAccount?.codigo_banco} - ${bankInfoByCode?.name}`

  return (
    <div className="bankslip-info">
      <p className="bankslip-info-title">Emitir</p>
      <div className="bankslip-general-info">
        <div className="bankslip-account-data">
          <p className="title">Seus dados</p>
          <p>{bankInfo}</p>
          <p>Agencia: {agencyWithDv}</p>
          <p>Conta: {accountWithDv}</p>
        </div>
        <div className="client-data">
          <p className="title">Dados do cliente</p>
          <p>{billClientForm?.socialReason}</p>
          <p>{billClientForm?.cpfCnpj}</p>
          <p>{billClientForm?.email}</p>
          <p>{billClientForm.phone}</p>
        </div>
      </div>
      <div className="bankslip-info-data">
        <p className="title">Dados da cobrança</p>
        <div className="value-date">
          <CurrencyInput
            id="bill-form"
            value={billForm.value}
            label="Valor da cobrança"
            disabled={true}
            onChange={(e) => { }}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              disabled={true}
              label="Vencimento"
              onChange={(e) => { }}
              inputFormat="DD/MM/YYYY"
              value={billForm.dueDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="description">
          <p>Descrição</p>
          <TextareaAutosize
            minRows={10}
            id="description"
            value={billForm.description}
            disabled={true}
          />
        </div>
      </div>
      <div className="bankslip-footer">
        <Button
          fullWidth
          variant="outlined"
          onClick={() => handlePreviousStep()}
        >
          Voltar
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={() => handleSendBankSlip()}
        >
          Gerar boleto
        </Button>
      </div>
    </div>
  )
}

const defaultContextInfo = {
  selectedStep: 0,
  billForm: defaultBillForm,
  resetBillForm: () => null,
  handleSendBankSlip: () => { },
  billClientForm: defaultBillClientForm,
  bankAccount: defaultTecnoSpeedAccountForm,
  handleChangeSelectedStep: (value: number) => null,
  handleChangeBillForm: (field: string, value: any) => null,
}

const BillContext = createContext(defaultContextInfo)

const BankSlip: React.FC = () => {
  const [billForm, setBillForm] = useState<BillFormModel>(defaultBillForm)
  const [billClientForm, setBillClientForm] = useState<BillClientForm>(
    defaultBillClientForm
  )
  const [selectedStep, setSelectedStep] = useState(0)

  const { data: bankAccounts } = useGetAllBankAccountsQuery('')
  const [postBankSlip] = usePostBankSlipMutation()

  const bankAccount: TecnoSpeedBankAccountModel =
    bankAccounts?.[0] || defaultTecnoSpeedAccountForm

  const accountConvention = bankAccount?.convenios?.[0]

  const handleChangeBillForm = (field: string, value: any): any => {
    setBillForm({ ...billForm, [field]: value })
  }

  const handleChangeBillClientForm = (field: string, value: any): any => {
    setBillClientForm({ ...billClientForm, [field]: value })
  }

  const handleChangeSelectedStep = (value: number): any => {
    if (value > 2 || value < 0) return

    setSelectedStep(value)
  }

  const handleSendBankSlip = () => {
    const bankSlipBody = getBankSlipBody({
      billForm,
      bankAccount,
      billClientForm,
      accountConvention,
    })

    postBankSlip(bankSlipBody)
  }

  const resetBillForm = (): any => {
    setBillForm(defaultBillForm)
  }

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props

    const icons: { [index: string]: React.ReactElement } = {
      1: <AttachMoneyIcon />,
      2: <GroupAddIcon />,
      3: <VideoLabelIcon />,
    }

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    )
  }

  const DisplayContentByStep = () => {
    const stepObject: any = {
      0: BankBill,
      1: BillClientInfo,
      2: DisplaySendFinalInfo,
    }

    const StepComponent = stepObject[selectedStep]

    return <StepComponent BillContext={BillContext} />
  }

  const contextValues = {
    billForm,
    bankAccount,
    selectedStep,
    resetBillForm,
    billClientForm,
    setBillClientForm,
    handleSendBankSlip,
    handleChangeBillForm,
    handleChangeSelectedStep,
    handleChangeBillClientForm,
  }

  return (
    <>
      <BillContext.Provider value={contextValues}>
        <div className="bankslip-container">
          <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper
              alternativeLabel
              activeStep={selectedStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
          {DisplayContentByStep()}
        </div>
      </BillContext.Provider>
    </>
  )
}

export default BankSlip
