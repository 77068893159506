import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from '../actions'

interface typeUserStore {
  typeUserValue?: string
}

export const initialState: typeUserStore = {
  typeUserValue: '',
}

export const TypeUserSlice = createSlice({
  name: 'typeUser',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    setTypeUser: (state, action: PayloadAction<string>) => {
      state.typeUserValue = action?.payload
    },
  },
})

export const { setTypeUser } = TypeUserSlice.actions

export default TypeUserSlice.reducer
