export const mock2 = [
  {
    hashcod: "5037095d1b74aa782a64",
    palavras_chave: "Teste",
    raio_atuacao: 0,
    tipo_filtro: "L",
    localizacoes: [
      {
        estado: "Acre",
        cidade: "Acrelândia",
        uf: "AC",
      },
      {
        estado: "Rio de Janeiro",
        cidade: "Duque de Caxias",
        uf: "RJ",
      },
      {
        estado: "São Paulo",
        cidade: "",
        uf: "SP",
      },
    ],
  },
  {
    hashcod: "e932120244897eed20bf",
    palavras_chave: "Mm",
    raio_atuacao: 0,
    tipo_filtro: "N",
    localizacoes: [],
  },
  {
    hashcod: "58fba81aa12cd4b8d1a3",
    palavras_chave: "Wm",
    raio_atuacao: 0,
    tipo_filtro: "N",
    localizacoes: [],
  },
  {
    hashcod: "275b165b0ccf11ec94bd",
    palavras_chave: "Comercial",
    raio_atuacao: 300,
    tipo_filtro: "N",
    localizacoes: [],
  },
];
