import styled from 'styled-components'

export const FooterContent = styled.div`
  height: 32px;
  width: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  background-color: white;
  color: black;
  border-top: 0.5px solid gray;
`

export const LoginFooterTittle = styled.h4`
  line-height: 22px;
  font-size: 1.2rem;
  margin: 0;
  color: black;
`
export const LoginFooterText = styled.h6`
  line-height: 17px;
  margin: 0;
  margin-right: 18px;
  font-size: 0.8rem;
  color: black;
  font-weight: 500;
`

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const StackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
