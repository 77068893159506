import './styles.scss'
import RequestedServices from 'data/components/RequestedServices'
import AdditionalServices from 'data/components/AdditionalServices'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import OnboardingController from 'data/components/OnboardingController'
interface TabPanelProps {
  index: number
  value: number
  children?: React.ReactNode
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="tab-panel-container"
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const tabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AdminArea: React.FC = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className="admin-area-container">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        className="admin-area-tabs"
      >
        <Tab
          className="admin-area-tab"
          label="Serviços Adicionais"
          {...tabProps(0)}
        />
        <Tab
          className="admin-area-tab"
          label="Serviços Solicitados"
          {...tabProps(1)}
        />
        <Tab className="admin-area-tab" label="Tabela Admin" {...tabProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AdditionalServices />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RequestedServices />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OnboardingController />
      </TabPanel>
    </div>
  )
}

export default AdminArea
