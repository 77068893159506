import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import ReplyIcon from '@mui/icons-material/Reply'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { LinearProgress } from '@mui/material'

export default function TemplateAppBar() {
  const loading = useSelector((state: RootState) => state.layout.loading)

  const navigate = useNavigate()

  return (
    <Box sx={{ flexDirection: 'column', flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: '#ffffff' }}>
        <Toolbar variant="dense" style={{ backgroundColor: '#274490' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => navigate(-1)}
          >
            <ReplyIcon />
          </IconButton>
        </Toolbar>

        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant={loading ? 'indeterminate' : 'determinate'}
            value={0}
          />
        </Box>
      </AppBar>
    </Box>
  )
}
