import { Route, Routes } from 'react-router-dom'
import moment from 'moment'

import routes from '../utils/Constants/routes'
import { Role } from '../utils/Enums/'
import { Login, Logout, NotFound, Dashboard, DashboardContratante } from 'pages'
import BankSlip from '../presentation/BankSlip'
import Onboarding from '../presentation/views/Onboarding'
import ListNote from '../presentation/views/ListNote'
import FavoriteVacancies from '../presentation/views/Vacancy/FavoriteVacancies'
import SelectTypeUser from '../presentation/views/User'
import BillingHistory from '../presentation/BillingHistory'
import SearchVacancies from '../presentation/views/Vacancy/SearchVacancies'
import MyApplications from '../presentation/views/Vacancy/MyApplications'
import MyInvitations from '../presentation/views/Vacancy/MyInvitations'
import Profile from '../pages/Profile'
import ProfessionalSignUp from '../presentation/views/User/ProfessionalSignUp'
import CompanySignUp from '../presentation/views/User/CompanySignUp'
import Vacancy from '../presentation/views/Vacancy'
import TechJobs from '../pages/Company/TechJobs'
import { Promotion } from '../pages/Promotion'
import { PlansContratado } from '../pages/PlansContratado'
import { PlansContabilidade } from '../pages/PlansContabilidade'
import { PlansContratante } from '../pages/PlansContratante'
import { RatingCompany } from '../pages/Rating'
import { RatingTech } from '../pages/RatingTech'
import { WeFoundYourJob } from '../pages/WeFoundYourJob'
import AuthRoute from './AuthRoute'
import SendNote from 'presentation/views/SendNote'
import WeFoundYourJobDetail from '../pages/WeFoundYourJobDetail'
import AccountingPage from 'presentation/views/AccountingPage'
import AdminArea from 'presentation/views/AdminArea'
import AdditionalServices from 'presentation/views/AdditionalServices'
import { Calendar } from 'pages/Calendar'
import { CalendarContratante } from 'pages/CalendarContratante'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import TechJobCreateAndUpdate from 'pages/Company/TechJobCreateAndUpdate'
import SearchProfessionals from 'pages/Company/SearchProfessionals'
import HuntingProcess from 'pages/Company/HuntingProcess'
import NotaFiscal from 'presentation/views/NotaFiscal'

const Routers = () => {
  const { role: userRole } = useSelector((state: RootState) => state.userState)
  moment.locale('pt-br')

  return (
    <Routes>
      {/* public routes */}
      <Route index element={<Login />} />
      <Route path={routes.LOGIN} element={<Login />} />
      <Route path={routes.USER_SELECT_TYPE} element={<SelectTypeUser />} />
      <Route path={routes.USER_REGISTER} element={<ProfessionalSignUp />} />
      <Route path={routes.RECRUITER_REGISTER} element={<CompanySignUp />} />
      <Route path={routes.NOT_FOUND} element={<NotFound />} />
      <Route path={routes.LOGOUT} element={<Logout />} />
      {/* contractor and professional routes with same url */}
      <Route
        element={<AuthRoute allowedRoles={[Role.PROFESSIONAL, Role.COMPANY]} />}
      >
        <Route
          path={routes.HOME}
          element={
            userRole === Role.COMPANY ? <DashboardContratante /> : <Dashboard />
          }
        />
      </Route>

      {/* admin routes */}
      <Route element={<AuthRoute allowedRoles={[Role.ADMIN]} />}>
        <Route path={routes.ADMIN} element={<AdminArea />} />
      </Route>

      {/* contractor routes */}
      <Route element={<AuthRoute allowedRoles={[Role.COMPANY]} />}>
        <Route path={routes.PLANS_CONTRATANTE} element={<PlansContratante />} />
        <Route path={routes.RH_PROFILE} element={<Profile />} />
        <Route path={routes.TECH_JOBS} element={<TechJobs />} />
        <Route
          path={routes.CALENDAR_CONTRATANTE}
          element={<CalendarContratante />}
        />
        <Route
          path={routes.TECH_JOB_CREATE}
          element={<TechJobCreateAndUpdate />}
        />
        <Route
          path={`${routes.TECH_JOB_UPDATE}/:hashcod`}
          element={<TechJobCreateAndUpdate />}
        />
        <Route
          path={routes.SEARCH_PROFESSIONALS}
          element={<SearchProfessionals />}
        />
        <Route path={routes.RATING_TECH} element={<RatingTech />} />
        <Route path={routes.PENDING_INVITE} element={<RatingTech />} />
        <Route path={routes.HUNTING_PROCESS} element={<HuntingProcess />} />
      </Route>

      {/* professional routes */}
      <Route element={<AuthRoute allowedRoles={[Role.PROFESSIONAL]} />}>
        <Route path={routes.RATING} element={<RatingCompany />} />
        <Route path={routes.ACCOUNTING} element={<AccountingPage />} />
        <Route path={routes.WE_FOUND_YOUR_JOB} element={<WeFoundYourJob />} />
        <Route path={routes.INVITATION_JOBS} element={<MyInvitations />} />
        <Route path={routes.SEARCH_VACANCIES} element={<SearchVacancies />} />
        <Route path={routes.VACANCIES} element={<Vacancy />} />
        <Route
          path={routes.ADDITIONAL_SERVICES}
          element={<AdditionalServices />}
        />
        <Route
          path={routes.FAVORITE_VACANCIES}
          element={<FavoriteVacancies />}
        />
        <Route
          path={`${routes.WE_FOUND_YOUR_JOB_DETAIL}/:hashcod`}
          element={<WeFoundYourJobDetail />}
        />
        <Route path={`${routes.CALENDAR}`} element={<Calendar />} />
        <Route path={routes.MY_APPLICATION} element={<MyApplications />} />
        <Route path={routes.PLANS_CONTRATADO} element={<PlansContratado />} />
        <Route
          path={routes.PLANS_CONTABILIDADE}
          element={<PlansContabilidade />}
        />
        <Route path={routes.PROFISSIONAL_PROFILE} element={<Profile />} />
        <Route path={routes.BANK_SLIP} element={<BankSlip />} />
        <Route path={routes.ONBOARDING} element={<Onboarding />} />
        {/* <Route path={routes.LIST_NOTES} element={<ListNote />} /> */}
        <Route path={routes.NOTA_FISCAL} element={<NotaFiscal />} />
        <Route path={routes.BILLING_HISTORY} element={<BillingHistory />} />
        {/* <Route path={routes.SEND_NOTE} element={<SendNote />} /> */}
        <Route path={routes.PROMOTION} element={<Promotion />} />
        <Route path={routes.LOGOUT} element={<Logout />} />
      </Route>

      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Routers
