import { MaterialTableColumn } from 'domain/models/materialColumn'
import { getBrazilianCurrency } from '../regex'

export const serviceTableColumns: readonly MaterialTableColumn[] = [
	{ id: 'service', label: 'Serviço', minWidth: 100 },
	{ id: 'description', label: 'Descrição', minWidth: 150 },
	{
		id: 'value',
		minWidth: 150,
		label: 'Valor',
		format: getBrazilianCurrency,
	},
	{
		minWidth: 150,
		label: 'Pagamento',
		id: 'payment_description',
	},
	{
		minWidth: 150,
		label: 'Tempo médio de entrega',
		id: 'average_delivery_time',
	},
	{
		label: '',
		minWidth: 200,
		id: 'options',
	},
]
