import React, { useEffect, useState } from 'react'
import { Box, Fab } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import MaterialDatePicker from 'data/components/MaterialDatePicker'

import './styles.scss'
import moment, { Moment } from 'moment'
//import { usePutResponsabilityStartDateMutation } from 'store/NodeApi/endpoint'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  useLazyGetNodeCompanyByEmailQuery,
  useLazyGetNodeCompanyQuery,
  usePutNodeCompanyMutation,
} from 'store/NodeApi/company/endpoint'

const defaultStartDate = ''
const getMonthYearDate = (date) => {
  return moment(date).format('MM/YYYY')
}

const ResponsibilityStart: React.FC = () => {
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Moment | string>(defaultStartDate)

  const [putNodeCompany] = usePutNodeCompanyMutation()
  const { successSnackbar, errorSnackbar } = useCustomSnackbar()

  const user = useSelector((state: RootState) => state.userState.loggedUser)
  const [getCompanyByEmail] = useLazyGetNodeCompanyByEmailQuery()

  useEffect(() => {
    getCompanyByEmail(user?.email!)
      .unwrap()
      .then(({ responsibility_start_date }) => {
        if (!responsibility_start_date) return

        const formattedData = moment(responsibility_start_date, 'MM/YYYY')
        setStartDate(formattedData)
      })
  }, [])

  const handleSave = () => {
    putNodeCompany({
      responsibility_start_date: getMonthYearDate(startDate),
    })
      .unwrap()
      .then(({ responsibility_start_date }) => {
        if (!responsibility_start_date) return

        const formattedData = moment(responsibility_start_date, 'MM/YYYY')

        setStartDate(formattedData)

        setShowSaveButton(false)
        successSnackbar('Dados alteados com sucesso!')
      })
      .catch(() => errorSnackbar('Erro ao alterar dados'))
  }

  const handleStartDate = (value: Moment) => {
    setStartDate(value)
    setShowSaveButton(true)
  }

  const DisplaySaveButton = () => {
    return (
      <Box>
        <Fab
          color="primary"
          aria-label="add"
          disabled={!showSaveButton}
          onClick={() => handleSave()}
        >
          <CheckIcon />
        </Fab>
      </Box>
    )
  }

  return (
    <div className="responsibility-start-container">
      <DisplaySaveButton />
      <div className="responsibility-start-box box-container">
        <div className="box-container-header flex-box">
          <p>Inicio de Responsabilidade</p>
        </div>
        <div className="responsibility-start-content">
          <p className="responsibility-content-title">
            A partir de qual mês a JOB Connect fica responsável pela
            contabilidade de sua empresa?
          </p>
          <MaterialDatePicker
            openTo="month"
            label="Inicio em"
            value={startDate}
            inputFormat="MM/YYYY"
            views={['year', 'month']}
            onChange={(newValue) => {
              handleStartDate(newValue)
            }}
          />
        </div>
        <p className="responsibility-content-footer">
          Até lá nenhuma mensalidade será cobrada. Mas lembre-se que só seremos
          responsáveis pela sua empresa assim que todos esses dados forem
          validados pela nossa equipe e entrarmos em contato com você.
        </p>
      </div>
    </div>
  )
}

export default ResponsibilityStart
