import nodeApi from '../api'

type TQuery = {
  username?: string
  pageSize: number
  page: number
}

type User = {
  id: number
  nome: string
  email: string
}

type TDataPagination = {
  currentPage: number
  totalPages: number
  users: Array<User>
}

const injectedNodeApi = nodeApi.injectEndpoints({
  endpoints: (build) => ({
    getAllUsers: build.query<TDataPagination, TQuery>({
      query: (queries) => {
        const query = new URLSearchParams(
          queries as unknown as Record<string, string>
        )

        return {
          url: `/users/all?` + query,
        }
      },
      transformResponse: (data: TDataPagination) => {
        return {
          ...data,
          users: data.users
            .map((user) => ({ ...user, name: user.nome }))
            .map(({ nome, ...user }) => user),
        } as any
      },
    }),
  }),
})

export const { useGetAllUsersQuery } = injectedNodeApi

export default injectedNodeApi
