import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TablePagination, Stack, Box } from '@mui/material'

import {
  useGetJobCandidaturasQuery,
  useGetJobCandidaturasFullQuery,
} from 'store/WeInvitateVacanciesJob'
import { VacancyItem } from '../VacancyItem'
import { JobResponse } from 'types'
import JobDetails from 'components/Molecules/JobDetails'
import { setLoading } from 'store/Layout/slice'
import SkeletonJob from 'components/Molecules/SkeletonJob'
import * as S from './styles'
import Pagination from 'components/Organisms/Pagination'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { PageParam, PageSizeParam } from 'utils/Constants'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { isEmpty } from 'lodash'
import { Column, JobList, Row } from '../style'

export default function MyApplications() {
  const dispatch = useDispatch()
  const { warningSnackbar } = useCustomSnackbar()
  const [query, setQuery] = useQueryParams({
    h: StringParam,
    p: PageParam,
    ps: PageSizeParam,
    t: NumberParam,
  })
  const { h: selectedJobHashcod, p: page, ps: pageSize, t: selectedTab } = query

  const [needToScroll, setNeedToScroll] = useState(!!selectedJobHashcod)

  const [selectedJob, setSelectedJob] = useState<JobResponse>()
  const {
    data: getJobCandidaturasQuery,
    isLoading,
    isSuccess,
    refetch,
  } = useGetJobCandidaturasQuery('')
  const { data: getJobCandidaturasFullQuery } =
    useGetJobCandidaturasFullQuery('')

  const paginatedData = useMemo(() => {
    return (
      getJobCandidaturasQuery?.slice(page * pageSize, (page + 1) * pageSize) ??
      []
    )
  }, [getJobCandidaturasQuery, page, pageSize])

  useEffect(() => {
    if (isLoading || !getJobCandidaturasQuery) return

    if (isEmpty(getJobCandidaturasQuery)) return setSelectedJob(undefined)

    if (selectedJobHashcod) {
      const foundItem = getJobCandidaturasQuery.find(
        ({ hashcod }) => hashcod === selectedJobHashcod
      )

      if (foundItem) setSelectedJob(foundItem)
      else {
        setSelectedJob(getJobCandidaturasQuery[0])
      }
    } else {
      setSelectedJob(getJobCandidaturasQuery[0])
    }
  }, [isLoading, selectedJobHashcod, getJobCandidaturasQuery, warningSnackbar])

  useEffect(() => {
    setQuery({ h: selectedJob?.hashcod })
  }, [selectedJob, setQuery])

  useEffect(() => {
    if (needToScroll && isSuccess && selectedJobHashcod) {
      document
        .getElementById(selectedJobHashcod)
        ?.scrollIntoView({ behavior: 'smooth' })

      setNeedToScroll(false)
    }
  }, [isSuccess, needToScroll, selectedJobHashcod])

  useEffect(() => {
    return () => setQuery({ h: undefined, p: undefined, ps: undefined })
  }, [setQuery])

  useEffect(refetch, [refetch, selectedTab])

  useEffect(() => {
    dispatch(setLoading(isLoading))
  }, [dispatch, isLoading])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [selectedJob])

  if (!getJobCandidaturasQuery?.length) {
    return <Box style={{ color: 'black' }}>Não há candidaturas ainda.</Box>
  }

  return (
    <>
      <Row>
        {isLoading ? (
          <SkeletonJob />
        ) : (
          <Column>
            <JobList>
              {paginatedData.map((item) => (
                <VacancyItem
                  id={item.hashcod}
                  key={item.hashcod}
                  item={item}
                  onClick={() => setSelectedJob(item)}
                  isSelected={selectedJob == item}
                />
              ))}
            </JobList>
          </Column>
        )}

        {selectedJob && (
          <JobDetails
            job={selectedJob}
            hasBtnApplyJob={false}
            isApplyJob={true}
            refetchData={refetch}
            dataJob={getJobCandidaturasFullQuery}
            index={
              getJobCandidaturasQuery?.findIndex(
                (item) => item.hashcod === selectedJob.hashcod
              ) as number
            }
          />
        )}
      </Row>

      <Pagination
        count={getJobCandidaturasQuery?.length ?? 0}
        page={isLoading ? 0 : page}
        onPageChange={(_, newPage) => setQuery({ p: newPage })}
        onRowsPerPageChange={(event) =>
          setQuery({ p: 0, ps: Number(event.target.value) })
        }
        rowsPerPage={pageSize}
      />
    </>
  )
}
