import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Role } from 'utils/Enums'
import {
  USER_TYPE_VERIFY,
  APP_API_URI,
  APP_STORAGE_PREFIX,
  USER_INFOS,
  USER_INFOS_CONTRATANTE,
  PROJECT_PEOPLE_SUGGESTION,
} from '../../utils/Constants'
import { StorageGetItem } from '../../utils/Storage'
import { ProfessionalProfileResponse, SuggestedProfessional } from 'types'

export const UserType = createApi({
  reducerPath: 'UserType',
  tagTypes: ['Suggestions1'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_API_URI}`,
    prepareHeaders: (headers, { getState }) => {
      const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))

      if (token) {
        headers.set('Authorization', `JWT ${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    getUserRole: builder.query<Role, void>({
      query: () => `${USER_TYPE_VERIFY}`,
      transformResponse: (response: { result: Role }) => {
        if (response.result === null) return Role.ADMIN

        return response.result
      },
    }),
    getUserInfos: builder.query({
      query: () => `${USER_INFOS}`,
    }),
    getContratanteInfos: builder.query({
      query: () => `${USER_INFOS}`,
    }),
    solicitarPlanoUser: builder.mutation({
      query: ({ emailValue, planoValue }) => ({
        url: `${USER_INFOS}`,
        method: 'PATCH',
        body: { email: emailValue, plano_solicitado: planoValue },
      }),
    }),
    solicitarPlanoUserContabilidade: builder.mutation({
      query: ({ emailValue, planoValue }) => ({
        url: `${USER_INFOS}`,
        method: 'PATCH',
        body: { email: emailValue, plano_contabilidade_solicitado: planoValue },
      }),
    }),
    solicitarPlanoUserContratante: builder.mutation({
      query: ({ emailValue, planoValue }) => ({
        url: USER_INFOS,
        method: 'PATCH',
        body: { email: emailValue, plano_solicitado: planoValue },
      }),
    }),
    trazerProfissionaisSugeridos: builder.query<
      SuggestedProfessional[],
      string
    >({
      query: (hashcode) => ({
        url: `${PROJECT_PEOPLE_SUGGESTION}/${hashcode}/`,
      }),
      providesTags: ['Suggestions1'],
    }),
  }),
})

export const {
  useLazyGetUserRoleQuery,
  useGetUserInfosQuery,
  useGetContratanteInfosQuery,
  useSolicitarPlanoUserMutation,
  useSolicitarPlanoUserContabilidadeMutation,
  useSolicitarPlanoUserContratanteMutation,
  useTrazerProfissionaisSugeridosQuery,
} = UserType
