import { Card } from '@mui/material'
import styled from 'styled-components'

export const StyledCardList = styled(Card)`
  border: ${(props) =>
    props.itemScope ? '2px solid #3D82AB !important' : 'none'};
  cursor: pointer;
  /* margin-top: 10px; */
  /* padding: 24px 10px 10px; */
  background: ${({ theme }) => theme.colors.card.background} !important;
  box-shadow: 1px 1px 15px 3px rgb(0 0 0 / 5%);
  border-radius: 8px;
  padding: 30px 15px 15px;
  max-height: 286px !important;
  /* margin-right: 25px !important; */
  /* margin-left: 25px !important; */
  position: relative;
`
export const StyledCardGrid = styled(Card)`
  border: ${(props) =>
    props.itemScope ? '2px solid #3D82AB !important' : 'none'};
  cursor: pointer;
  margin-top: 10px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`
export const TittleJobCard = styled.span`
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.title};
`
export const TittleJobCardGrid = styled.span`
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  color: #464df2;
`

export const LabelInfo = styled.label`
  color: #83888f;
  font-size: 14px;
`

export const ColumnDivider = styled.div`
  display: flex;
  flex-direction: column;
`

export const IconContainer = styled.div`
  float: left;

  text-align: center;
  padding: 0;
  margin-right: 10px;
  overflow: hidden;
`
export const FavoriteContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 15px;
`
export const StarContainer = styled.div`
  display: flex;
  color: #ff3555;
  align-items: center;
  position: absolute;
  left: 15px;
  bottom: 10px;
`
export const RowDivider = styled.div`
  display: flex;
  flex-direction: row;
`
