import { TecnoSpeedBankAccountModel } from 'domain/models/bankAccount'
import { store } from 'store'
import plugBoletosApi from './api'
import { setBankAccounts, setAssignorByCnpj } from './slice'

const injectedPlugBoletosApi = plugBoletosApi.injectEndpoints({
  endpoints: (build) => ({
    //Cedentes
    getAllAssignors: build.query<any, string>({
      query: () => '/cedentes',
    }),
    getAssignorByCnpj: build.query<any, string>({
      query: (cnpjInfo) => ({
        url: `/cedentes/?cpf_cnpj=${cnpjInfo}`,
      }),
      transformResponse: (response: any) => {
        store.dispatch(setAssignorByCnpj(response?._dados?.[0]))
        return response?._dados?.[0]
      },
    }),
    postAssignor: build.mutation<any, any>({
      query: (assignorInfo) => ({
        method: 'POST',
        url: '/cedentes',
        body: assignorInfo,
      }),
    }),

    //Conta
    getAllBankAccounts: build.query<TecnoSpeedBankAccountModel[], string>({
      query: () => '/cedentes/contas',
      transformResponse: (response: any) => {
        store.dispatch(setBankAccounts(response?._dados))
        return response?._dados
      },
    }),
    postBankAccount: build.mutation<any, any>({
      query: (bankAccountInfo) => ({
        method: 'POST',
        url: '/cedentes/contas',
        body: bankAccountInfo,
      }),
    }),
    deleteBankAccount: build.mutation<any, any>({
      query: (accountId) => ({
        method: 'DELETE',
        url: `/cedentes/contas/${accountId}`,
      }),
    }),

    //Convenio
    getAllConventions: build.query<any, string>({
      query: () => '/cedentes/contas/convenios',
    }),
    postConvention: build.mutation<any, any>({
      query: (conventionInfo) => ({
        method: 'POST',
        url: '/cedentes/contas/convenios',
        body: conventionInfo,
      }),
    }),
    deleteConvention: build.mutation<any, any>({
      query: (conventionId) => ({
        method: 'DELETE',
        url: `/cedentes/contas/convenios/${conventionId}`,
      }),
    }),

    //Boleto
    postBankSlip: build.mutation<any, any>({
      query: (bankSlipInfo) => ({
        method: 'POST',
        url: '/v1/boletos/lote',
        body: bankSlipInfo,
      }),
    }),
  }),
})

export const {
  usePostAssignorMutation,
  useGetAllAssignorsQuery,
  usePostConventionMutation,
  useGetAssignorByCnpjQuery,
  usePostBankAccountMutation,
  useGetAllBankAccountsQuery,
  useDeleteConventionMutation,
  usePostBankSlipMutation,
  useDeleteBankAccountMutation,
  useLazyGetAllConventionsQuery,
  useLazyGetAllBankAccountsQuery,
} = injectedPlugBoletosApi

export default injectedPlugBoletosApi
