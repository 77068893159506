import './styles.scss'

import React, { useState } from 'react'
// @ts-ignore
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import { Button, Checkbox, FormControlLabel } from '@mui/material'

import FullContract from '../../../assets/contracts/Contrato_Prestacao_Servico_Completo.pdf'
import SimplifiedContract from '../../../assets/contracts/Contrato_Prestacao_Servico_Resumo.pdf'
import {
  useGetCompanyTermsQuery,
  usePostTermMutation,
  usePutTermMutation,
} from 'store/NodeApi/term/endpoint'
import { useEffect } from 'react'
import MaterialDialog from '../MaterialDialog'
import { DialogConfig } from 'domain/models/dialog'
import { defaultDialogState } from 'data/utils/Dialog/static'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'

interface ServiceContractModel {
  id?: number
  name: string
  readAccepted: boolean
}

const ServiceContract: React.FC = () => {
  const [numPages, setNumPages] = useState(null)
  const [serviceContractTerm, setServiceContractTerm] =
    useState<ServiceContractModel>({ name: '', readAccepted: false })

  const [dialogConfig, setDialogConfig] =
    useState<DialogConfig>(defaultDialogState)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const { data: companyTerms, refetch: refetchTerms } =
    useGetCompanyTermsQuery('')
  const [postServiceContract] = usePostTermMutation()
  const [putServiceContract] = usePutTermMutation()

  const { successSnackbar, errorSnackbar } = useCustomSnackbar()

  useEffect(() => {
    if (!companyTerms?.[0]) return

    const serviceContract = companyTerms?.find(
      (term) => term.name === 'service_contract'
    )

    if (!serviceContract) return

    setServiceContractTerm({
      ...serviceContract,
      readAccepted: serviceContract?.read_accepted === 1,
    })
  }, [companyTerms, setServiceContractTerm])

  const handleChangeReadAccepted = () => {
    const newReadAccepted = !serviceContractTerm.readAccepted

    setServiceContractTerm({
      ...serviceContractTerm,
      readAccepted: newReadAccepted,
    })

    let postOrPutFunction = postServiceContract

    if (serviceContractTerm?.id) postOrPutFunction = putServiceContract

    const readAccepted = newReadAccepted ? '1' : '0'

    postOrPutFunction({
      id: serviceContractTerm?.id,
      name: 'service_contract',
      readAccepted,
    })
      .unwrap()
      .then(() => {
        successSnackbar('Dados alterados com sucesso')
        refetchTerms()
      })
      .catch(() => errorSnackbar('Erro ao alterar dados'))

    handleCloseDialog()
  }

  const handleAcceptedFlow = () => {
    const currentStatus = !serviceContractTerm?.readAccepted
    const message = currentStatus
      ? 'Você aceitou os termos do contrato'
      : 'Você recusou os termos do contrato'

    setDialogConfig({
      ...dialogConfig,
      open: true,
      onConfirm: handleChangeReadAccepted,
      dialogBodyText: message,
    })
  }

  const handleCloseDialog = () => {
    setDialogConfig({ ...dialogConfig, open: false })
  }

  const dialogProps = {
    open: dialogConfig?.open,
    onClose: handleCloseDialog,
    onConfirm: dialogConfig?.onConfirm,
    dialogBodyText: dialogConfig?.dialogBodyText,
  }

  return (
    <>
      <MaterialDialog {...dialogProps} />
      <div className="service-contract-container box-container">
        <div className="complete-service-contract-container">
          <div className="complete-contract-header box-container-header flex-box">
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
              <p>Contrato de Prestação de Serviços</p>
              <a href={FullContract} download="Contrato_JobConnect">
                <Button>Baixar</Button>
              </a>
            </div>
            <div className="simplified-service-contract-container box-container-header flex-box">
              <p>Resumo Contrato de Prestação de Serviços</p>
              <a
                href={SimplifiedContract}
                download="Contrato_Resumido_JobConnect"
              >
                <Button>Baixar</Button>
              </a>
            </div>
          </div>
          <div className="complete-contract">
            <Document
              file={FullContract}
              options={{ workerSrc: '/pdf.worker.js' }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  scale={1.8}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </div>
          <div className="complete-contract-footer">
            <FormControlLabel
              control={<Checkbox name="termsOfUse" color="primary" />}
              checked={serviceContractTerm?.readAccepted}
              value={serviceContractTerm?.readAccepted}
              label="Li e aceito os termos de uso"
              onChange={handleAcceptedFlow}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceContract
