import { Container, Modal, Slide } from './styles'
import { useApprovedApplicantsModal } from './hooks/useApprovedApplicantsModal'
import List from 'data/components/CompoundInvitations/List'
import ProfessionalCard from 'data/components/CompoundInvitations/ProfessionalCard'
import { Fragment } from 'react'
import { CircularProgress, Divider } from '@mui/material'
import ExitRequest from './ExitRequest'
import Professional from 'data/components/CompoundInvitations/Professional'
import { useGetAllInvitesQuery } from 'store/WeInvitateVacanciesJob'

interface ApprovedApplicantsModalProps {
	projectHashcode: string
	onClose: () => void
}

const ApprovedApplicantsModal = ({
	projectHashcode,
	onClose,
}: ApprovedApplicantsModalProps) => {
	const { data: list, isLoading } = useGetAllInvitesQuery(projectHashcode)
	const { professionalSelected, handleSelectProfessional } =
		useApprovedApplicantsModal()

	const isProfessionalSelected = !!Number.isInteger(professionalSelected)
	const professional = list?.[professionalSelected!]

	return (
		<Modal onClose={onClose} open>
			<Container>
				<Slide isProfessionalSelected={isProfessionalSelected}>
					<List title="Profissionais Aprovados" onClose={onClose}>
						{!isLoading && list?.map(({ contratado, hashcod, status }, index) => (
							<Fragment key={hashcod}>
								<ProfessionalCard
									professional={contratado}
									onSelectProfessional={handleSelectProfessional(index)}
								>
									<ExitRequest hashcode={hashcod} status={status} />
								</ProfessionalCard>
								<Divider />
							</Fragment>
						))}
						{!!isLoading && (
							<div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<CircularProgress />
							</div>
						)}
					</List>
					{isProfessionalSelected && professional && (
						<Professional
							hashcode={professional.contratado.hashcod}
							onUnselectProfessional={handleSelectProfessional(null)}
							title="Voltar aos profissionais aprovados"
						/>
					)}
				</Slide>
			</Container>
		</Modal>
	)
}

export default ApprovedApplicantsModal
