import { Card } from '@mui/material'
import styled, { css, keyframes } from 'styled-components'

export const AccountingPlansImage = styled.img`
  width: 100%;
  height: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Wrapper = styled.section`
  display: flex;
  gap: 1rem;
`

export const LinkBuyCertificate = styled.a`
  display: flex;
  align-items: center;
`

export const AppContainer = styled.div`
  width: 35%;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
`

export const CertificateBanner = styled.img`
  object-fit: contain;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  width: 100%;
`

export const BannerBenefitsContainer = styled.div <{ backgroundURL: string }> `
	display: flex;
	flex-direction: column;
	justify-content: center;
  z-index: 99 !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
	height: 100%;
	padding-left: 2rem;
  background-color: transparent !important;
	background-image: ${props => `url(${props.backgroundURL})`};
	background-size: 100% 100%;
	background-repeat: no-repeat;
  box-shadow: none !important;
  color: #fff !important;
`
export const List = styled.ul`
  padding-left: 1rem;
  margin-top: 1rem;
  list-style: disc;
`
