import {
  PostCompany,
  TaxRegimeTypes,
  CompanyBillingTypes,
  SpecialTaxRegimeTypes,
} from 'domain/models/company'
import {
  defaultAddressForm,
  getPortugueseAddressForm,
  getTecnoSpeedCityByName,
} from '../Address/static'

export const getTecnoSpeedValidCnaes = (cnaes: any) => {
  return (
    cnaes?.filter((activity: any) => activity.descricao !== 'Não informada') ||
    []
  )
}

export const getCompanyBillingStringByOptionValue = (value: any) => {
  const billingOptions = getCompanyBillingOptions()

  return (
    billingOptions?.find((billingOption) => billingOption.value === value)
      ?.label || ''
  )
}

export const getCompanyBillingValueByOptionString = (
  stringValue: any
): number => {
  const billingOptions = getCompanyBillingOptions()

  return (
    billingOptions?.find((billingOption) => billingOption.label === stringValue)
      ?.value || 0
  )
}

export const getCompanyBillingOptions = () => {
  return [
    {
      label: CompanyBillingTypes.firstOption,
      value: 0,
    },
    {
      label: CompanyBillingTypes.secondOption,
      value: 1,
    },
    {
      label: CompanyBillingTypes.thirdOption,
      value: 2,
    },
    {
      label: CompanyBillingTypes.fourthOption,
      value: 3,
    },
  ]
}

export const getTaxRegimeOptions = () => {
  return [
    {
      label: TaxRegimeTypes.nenhum,
      value: 0,
    },
    {
      label: TaxRegimeTypes.simplesNacional,
      value: 1,
    },
    {
      label: TaxRegimeTypes.simplesNacionalExcesso,
      value: 2,
    },
    {
      label: TaxRegimeTypes.normalLucroPresumido,
      value: 3,
    },
    {
      label: TaxRegimeTypes.normalLucroReal,
      value: 4,
    },
  ]
}

export const getSpecialTaxRegimeOptions = () => {
  return [
    {
      label: SpecialTaxRegimeTypes.semRegimeTributarioEspecial,
      value: 0,
    },
    {
      label: SpecialTaxRegimeTypes.MicroEmpresaMunicipal,
      value: 1,
    },
    {
      label: SpecialTaxRegimeTypes.Estimativa,
      value: 2,
    },
    {
      label: SpecialTaxRegimeTypes.SociedadeDeProfissionais,
      value: 3,
    },
    {
      label: SpecialTaxRegimeTypes.Cooperativa,
      value: 4,
    },
    {
      label: SpecialTaxRegimeTypes.MicroEmpresárioIndividual,
      value: 5,
    },
    {
      label: SpecialTaxRegimeTypes.MicroEmpresaOuPequenoPorte,
      value: 6,
    },
  ]
}

export const defaultCompanyFormModel = {
  cnpj: '',
  phone: '',
  email: '',
  legalNature: '',
  fantasyName: '',
  socialReason: '',
  taxRegime: '',
  nationalSimple: false,
  municipalRegistration: '',
  specialTaxRegime: '',
  companyBilling: 0,
  taxRegimeOptions: getTaxRegimeOptions(),
  companyBillingOptions: getCompanyBillingOptions(),
  specialTaxRegimeOptions: getSpecialTaxRegimeOptions(),
}

export const defaultPostCompanyBody: PostCompany = {
  email: '',
  cpfCnpj: '',
  telefone: '',
  razaoSocial: '',
  certificado: '',
  nomeFantasia: '',
  inscricaoMunicipal: '',
  simplesNacional: false,
  endereco: defaultAddressForm,
  regimeTributario: TaxRegimeTypes.nenhum,
  regimeTributarioEspecial: SpecialTaxRegimeTypes.semRegimeTributarioEspecial,
  nfse: {
    ativo: true,
    tipoContrato: 0,
    config: {
      producao: true, // ALTERAR QUANDO O SISTEMA SUBIR P PRODUÇÃO
      prefeitura: {
        login: '',
        senha: '',
      },
    },
  },
}

export const getPutCompanyForm = ({ postCompanyForm, company }) => {
  let putForm = {
    ...postCompanyForm,
    nfse: {
      ...company?.nfse,
      config: {
        ...company?.nfse?.config,
        ...postCompanyForm?.nfse?.config,
        rps: {
          ...company?.nfse?.config?.rps,
          ...postCompanyForm?.nfse?.config?.rps,
          numeracao: [
            {
              numero: 1,
              serie: 'RPS',
            },
          ],
        },
      },
    },
  }

  delete putForm?.nfse?.config?.rps?.numeracaoAutomatica

  return putForm
}

export const getPostCompanyForm = ({
  companyForm,
  addressForm,
  municipalAccess,
  tecnoSpeedCities,
  companyCertificate,
}): PostCompany => {
  const currentCity = getTecnoSpeedCityByName({
    tecnoSpeedCities,
    cityName: addressForm.city,
  })

  return {
    nfse: {
      ativo: true,
      tipoContrato: 0,
      config: {
        producao: true, // ALTERAR QUANDO O SISTEMA SUBIR P PRODUÇÃO
        prefeitura: {
          login: municipalAccess?.login,
          senha: municipalAccess?.password,
        },
      },
    },
    email: companyForm?.email,
    cpfCnpj: companyForm?.cnpj,
    telefone: companyForm?.phone,
    certificado: companyCertificate?.id,
    endereco: getPortugueseAddressForm({
      ...addressForm,
      codigoCidade: currentCity?.id,
    }),
    nomeFantasia: companyForm?.fantasyName,
    razaoSocial: companyForm?.socialReason,
    regimeTributario: companyForm?.taxRegime,
    simplesNacional: companyForm?.nationalSimple,
    inscricaoMunicipal: companyForm?.municipalRegistration,
    regimeTributarioEspecial: companyForm?.specialTaxRegime,
  }
}

export const getPostAssignorBody = (cnpjInfo: any) => {
  return {
    CedenteRazaoSocial: cnpjInfo?.razao_social,
    CedenteNomeFantasia: cnpjInfo?.fantasia,
    CedenteCPFCNPJ: cnpjInfo?.cpf_cnpj,
    CedenteEnderecoLogradouro: cnpjInfo?.endereco?.logradouro,
    CedenteEnderecoNumero: cnpjInfo?.endereco?.numero,
    CedenteEnderecoComplemento: cnpjInfo?.complemento,
    CedenteEnderecoBairro: cnpjInfo?.endereco?.bairro,
    CedenteEnderecoCEP: cnpjInfo?.endereco?.cep,
    CedenteTelefone: cnpjInfo?.telefone,
    CedenteEmail: cnpjInfo?.email,
  }
}
