import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  DeleteFavoriteResponse,
  EditFavoriteResponse,
  FavoriteResponse,
} from 'types'
import {
  DELETE_FAVORITE,
  ADD_FAVORITE,
  APP_API_URI,
  APP_STORAGE_PREFIX,
  APP_PREFIX,
} from '../../utils/Constants'
import { StorageGetItem } from '../../utils/Storage'

export const FavoriteVacancies = createApi({
  reducerPath: `${APP_PREFIX}/User/FavoriteVacancies`,
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_API_URI}`,
    prepareHeaders: (headers) => {
      const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))

      if (token) {
        headers.set('Authorization', `JWT ${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    getFavoriteVacancies: builder.query<FavoriteResponse[], any>({
      query: () => `${ADD_FAVORITE}`,
    }),
    editFavoriteVacancies: builder.mutation<EditFavoriteResponse, string>({
      query(id) {
        return {
          url: `${ADD_FAVORITE}`,
          method: 'POST',
          body: { projeto: id },
        }
      },
    }),
    deleteFavoriteVacancies: builder.mutation<DeleteFavoriteResponse, string>({
      query(id) {
        return {
          url: `${DELETE_FAVORITE}${id}/`,
          method: 'DELETE',
        }
      },
    }),
  }),
})

export const {
  useGetFavoriteVacanciesQuery,
  useEditFavoriteVacanciesMutation,
  useDeleteFavoriteVacanciesMutation,
} = FavoriteVacancies
