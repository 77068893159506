import { TablePagination, TablePaginationProps } from '@mui/material'
import { Container } from './styles'

const Pagination = (props: TablePaginationProps) => {
  return (
    <Container>
      <TablePagination
        component="div"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
        rowsPerPageOptions={[15, 50, 100]}
        labelRowsPerPage={'Linhas por página'}
        showFirstButton
        showLastButton
        className="pagination"
        {...props}
      />
    </Container>
  )
}

export default Pagination
