import { Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Container, Header, CloseButton, Content } from './styles'
import CloseIcon from '@material-ui/icons/Close'

interface ListProps {
  title: string
  onClose: () => void
  HeaderSlotComponent?: JSX.Element
}

const List = ({
  title,
  onClose,
  children,
  HeaderSlotComponent,
}: PropsWithChildren<ListProps>) => {
  return (
    <Container>
      <Header>
        <Typography fontSize="1.5rem">{title}</Typography>
        {HeaderSlotComponent}
        <CloseButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </CloseButton>
      </Header>
      <Content>{children}</Content>
    </Container>
  )
}

export default List
