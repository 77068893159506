import { Button, TextField } from '@mui/material'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { serviceTableColumns } from 'data/utils/AdditionalServiceTable/static'
import { defaultDialogState } from 'data/utils/Dialog/static'
import { AdditionalServiceListModel } from 'domain/models/additionalService'
import { DialogConfig } from 'domain/models/dialog'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useGetAdditionalServicesQuery } from 'store/NodeApi/additionalService/endpoint'
import {
	useGetRequestedServicesByEmailQuery,
	usePostServiceRequestMutation,
	useDeleteServiceRequestMutation,
} from 'store/NodeApi/serviceRequest/endpoint'
import MaterialDialog from '../MaterialDialog'
import MaterialModal from '../MaterialModal'
import MaterialTable from '../MaterialTable'
import RequestServiceModal from '../RequestServiceModal'

import './styles.scss'

// const AdditionalServicesTable: React.FC = () => null

const AdditionalServicesTable: React.FC = () => {
	const [serviceFilter, setServiceFilter] = useState('')
	const [services, setServices] = useState<any>([])
	const [selectedCancelService, setSelectedCancelService] = useState<any>()
	const [dialogConfig, setDialogConfig] = useState<any>(defaultDialogState)
	const [requestModal, setRequestModal] = useState<any>({
		open: false,
		selectedService: {},
	})

	const [openServiceRequestedModal, setOpenServiceRequestedModal] =
		useState(false)
	const user = useSelector((state: RootState) => state.userState.loggedUser)
	const [postServiceRequest] = usePostServiceRequestMutation()
	const { data: apiServices, refetch: refetchAdditionalServicesQuery } =
		useGetAdditionalServicesQuery('')
	const {
		data: requestedServicesByEmail,
		refetch: refetchRequestedServicesByEmail,
	} = useGetRequestedServicesByEmailQuery(user?.email, {
		skip: !user?.email,
	})
	const [deleteServiceRequest] = useDeleteServiceRequestMutation()

	const nodeCompany = useSelector(({ node }: RootState) => node?.nodeCompany)

	const { successSnackbar, errorSnackbar } = useCustomSnackbar()

	const handleRequestService = (
		selectedService: AdditionalServiceListModel
	) => {
		setRequestModal({
			open: true,
			selectedService,
		})
	}

	const handleCancelServiceRequest = (
		selectedService: AdditionalServiceListModel,
		serviceByEmail
	) => {
		const serviceToCancel = serviceByEmail?.find(
			(requestedService) =>
				requestedService?.serviceId === selectedService?.id &&
				!requestedService?.paid &&
				!requestedService?.noteIssued &&
				!requestedService?.done
		)

		if (serviceToCancel?.id) {
			deleteServiceRequest(serviceToCancel?.id)
				.unwrap()
				.then(() => {
					successSnackbar('Solicitação de serviço removida')
					refetchRequestedServicesByEmail()
				})
				.catch((err) => {
					errorSnackbar('Não foi possível remover a solicitação de serviço')
				})

			setSelectedCancelService({})
			setDialogConfig(defaultDialogState)
		} else {
			errorSnackbar('Este serviço está em andamento e não pode ser cancelado')
		}
	}

	const getRequestButtonConfig = (alreadyRequestedService: boolean) => {
		const alreadyRequestedBody = {
			text: 'Cancelar solicitação',
			onClick: handleCancelServiceRequest,
			buttonClass: 'light-grey-button',
		}

		const availableRequestBody = {
			text: 'Solicitar',
			onClick: handleRequestService,
			buttonClass: 'light-blue-button',
		}

		if (alreadyRequestedService) return alreadyRequestedBody

		return availableRequestBody
	}

	const handleCloseDialog = () => {
		setDialogConfig({ ...dialogConfig, open: false })
	}

	const removeSpecialCharacters = (str: any) => {
		return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
	}

	const orderServices = (buildedServices: any[] = []) => {
		const builded = buildedServices.map((service) => {
			const splittedArray = service?.service?.split('-') || []

			const serviceNumber = Number(splittedArray[0] || 0)
			return {
				...service,
				number: Number(serviceNumber),
			}
		})

		return builded.sort(function (a, b) {
			return a?.number - b?.number
		})
	}

	const buildServices = (
		apiServices: AdditionalServiceListModel[],
		serviceFilter: string
	) => {
		let servicesToBuild = apiServices

		const filterByName = (services: AdditionalServiceListModel[]) => {
			return services.filter(({ service }) => {
				const serviceLowerCase = removeSpecialCharacters(service.toLowerCase())
				const filterLowerCase = removeSpecialCharacters(
					serviceFilter.toLowerCase()
				)

				return serviceLowerCase.includes(filterLowerCase)
			})
		}

		if (serviceFilter) servicesToBuild = filterByName(apiServices)

		return servicesToBuild?.map((service) => {
			const alreadyRequestedService = requestedServicesByEmail?.find(
				(requestedService) =>
					requestedService?.serviceId === service?.id && !requestedService?.paid
			)

			const requestButtonConfig = getRequestButtonConfig(
				alreadyRequestedService
			)

			return {
				...service,
				service: service?.service,
				options: (
					<div
						style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
					>
						<Button
							fullWidth
							className={requestButtonConfig?.buttonClass}
							onClick={() =>
								requestButtonConfig?.onClick(service, requestedServicesByEmail)
							}
						>
							{requestButtonConfig?.text}
						</Button>
					</div>
				),
			}
		})
	}

	useEffect(() => {
		refetchAdditionalServicesQuery()
	}, [])

	useEffect(() => {
		const orderedServices = orderServices(apiServices?.data)

		const buildedServices = buildServices(orderedServices, serviceFilter)

		setServices(buildedServices)
	}, [apiServices?.data, serviceFilter, requestedServicesByEmail])

	const handleChangeServiceFilter = (value: string) => {
		setServiceFilter(value)
	}

	const tableProps = {
		data: services,
		hiddenCheckBoxes: true,
		columns: serviceTableColumns,
	}

	const handleCloseRequestModal = () => {
		setRequestModal({ ...requestModal, open: false })
	}

	const handleFinishServiceRequest = (requestForm: any) => {
		const serviceRequestBody = {
			paid: false,
			done: false,
			noteIssued: false,
			name: user?.nome,
			email: user?.email,
			phone: requestForm?.phone,
			companyId: nodeCompany?.id,
			serviceId: requestModal?.selectedService?.id,
		}

		postServiceRequest(serviceRequestBody)
			.unwrap()
			.then(() => {
				refetchRequestedServicesByEmail()
				successSnackbar('Serviço solicitado!')
			})
			.catch(() => errorSnackbar('Não foi possível solicitar o serviço'))

		openServiceRequestedModalAction()
	}

	const closeServiceRequestedModal = () => {
		setOpenServiceRequestedModal(false)
	}

	const openServiceRequestedModalAction = () => {
		setOpenServiceRequestedModal(true)
	}

	const getServiceRequestedModalBody = () => {
		return (
			<div className="service-request">
				<div className="service-request-header">
					<h1>Serviço solicitado com sucesso!!</h1>
				</div>
				<div className="service-request-body">
					<div className="service-request-message">Agora é com a gente!</div>
					<div className="service-request-message-line">
						Nossa equipe vai entrar em contato com você nos próximos dias.
					</div>
				</div>
			</div>
		)
	}

	const ServiceRequestedModal = () => {
		const modalProps = {
			open: openServiceRequestedModal,
			children: getServiceRequestedModalBody(),
			onConfirm: closeServiceRequestedModal,
			containerCustomClassName: 'service-request-modal-container',
		}

		return <MaterialModal {...modalProps} />
	}

	const requestServiceModalProps = {
		open: requestModal?.open,
		closeModal: handleCloseRequestModal,
		onConfirm: handleFinishServiceRequest,
		service: requestModal.selectedService,
	}

	const dialogProps = {
		open: dialogConfig?.open,
		onClose: handleCloseDialog,
		onConfirm: dialogConfig?.onConfirm,
		dialogBodyText: dialogConfig?.dialogBodyText,
	}

	return (
		<div className="additional-services-table-container">
			<MaterialDialog {...dialogProps} />
			<RequestServiceModal {...requestServiceModalProps} />
			<TextField
				id="service-filter"
				value={serviceFilter}
				label="Filtrar serviços"
				className="service-filter"
				placeholder="Insira o nome do serviço"
				onChange={(e) => handleChangeServiceFilter(e?.target?.value)}
			/>
			<ServiceRequestedModal />
			<div className="additional-services-table">
				<MaterialTable {...tableProps} />
			</div>
		</div>
	)
}

export default AdditionalServicesTable
