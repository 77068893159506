import { TableCell, TableCellProps } from '@mui/material'

interface CellProps extends React.PropsWithChildren<TableCellProps> {
  isColumn?: boolean
}

const Cell = ({ isColumn, ...props }: CellProps) => {
  return (
    <TableCell
      align="center"
      component={isColumn ? 'td' : 'th'}
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        ...(!isColumn && {
          wordBreak: 'break-word',
        }),
        ...(isColumn && {
          fontWeight: 600,
        }),
      }}
      {...props}
    />
  )
}

export default Cell
