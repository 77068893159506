const dark = {
  title: 'dark',
  
  colors: {
    primary: {
      default: '#293241',
      // light: '',
      // dark: ''
    },
    secondary: {
      default: '#3E4754',
      // light: '',
      // dark: ''
    },
    contrast: {
      default: '#1769aa',
      light: '#1769aa',
      lightBlue: '#1769aa',
      dark: '#1769aa',
      minimum: '#1769aa'
    },
    status: {
      primary: '#777BF1',
      secondary: '#69838f',
      success: '#C5E1A5',
      info: '#81D4FA',
      warning: '#FFE082',
      danger: '#F48FB1',
    },
    button: {
      primary: '#777BF1',
      secondary: '#69838f',
      success: '#C5E1A5',
      info: '#81D4FA',
      warning: '#FFE082',
      danger: '#F48FB1',
    },
    // brand: {
    //   primaryLight: '',
    //   secondary: ''
    // },
    // shade: {
    //   black: '',
    //   gray: ''
    // },
    text: {
      primary: '#ffffff',
      secondary: '#121212',
      title: '#EAEBEC',
      paragraph: "#EAEBEC",
      selected: '#777bf1',
    },

    base: {
      maximum: '#000000',
      strong: '#383838',
      middle: '#80848A',
      weak: '#C3CBD9',
      thin: '#3E4753',
      minimum: '#FFFFFF'
    },

    card: {
      background: '#293241',
    }
  }
}

export default dark
