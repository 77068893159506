import React from "react";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export interface Item {
  value: string;
  label: string;
}

export enum variantTypes {
  filled = "filled",
  outlined = "outlined",
  standard = "standard",
}

export interface MaterialSelectProps {
  id: string;
  label: string;
  value?: string;
  error?: boolean;
  options?: Item[];
  disabled?: boolean;
  className?: string;
  defaultValue?: string;
  variant?: variantTypes;
  onChange?: (e: SelectChangeEvent) => void;
}

const MaterialSelect: React.FC<MaterialSelectProps> = ({
  id = "",
  onChange,
  value = "",
  label = "",
  options = [],
  error = false,
  className = "",
  disabled = false,
  defaultValue = "",
  variant = variantTypes.standard,
}) => {
  return (
    <FormControl className={className} variant={variant} fullWidth>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        id={id}
        value={value}
        label={label}
        labelId={`${id}-label`}
        defaultValue={defaultValue}
        onChange={(e) => onChange && onChange(e)}
        disabled={disabled}
        error={error}
      >
        {options.map((option) => (
          <MenuItem key={option?.value} value={option?.value}>
            <em>{option?.label}</em>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MaterialSelect;
