import { useCallback, useEffect, useMemo, useState } from 'react'
import { Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material'
import { InvitationResponse, JobResponse } from 'types'
import JobDetails from 'components/Molecules/JobDetails'
import { useGetCalendarInvitesQuery, useGetInvitationsStatusAndamentoQuery } from 'store/WeInvitateVacanciesJob'
import { Column, JobList, Row } from 'presentation/views/Vacancy/style'
import { VacancyItem } from 'presentation/views/Vacancy/VacancyItem'

export const Calendar = () => {
	const [selectedYear, setSelectedYear] = useState<number>()
	const [selectedJob, setSelectedJob] = useState<InvitationResponse | undefined>(
		undefined
	)
	const { data, refetch, isSuccess } = useGetCalendarInvitesQuery()

	const invitesByYear = useMemo(
		() =>
			data?.filter(
				({ projeto }) =>
					Number(projeto.data_inicio.slice(0, 4)) === selectedYear
			),
		[data, selectedYear]
	)

	const availableYears = useMemo(() => {
		if (!data) return null

		return Array.from(
			new Set(
				data
					?.map((invite) => Number(invite.projeto.data_inicio.slice(0, 4)))
					.sort()
			)
		)
	}, [data])

	const handleSelectYear = useCallback((value: number) => {
		handleSelectJob(undefined)
		setSelectedYear(value)
	}, [])

	const handleSelectJob = (invitation: InvitationResponse | undefined) =>
		setSelectedJob(invitation)

	useEffect(() => {
		if (!data) return

		handleSelectYear(availableYears?.[0]!)
	}, [data, availableYears, handleSelectYear])

	if (!data?.length && isSuccess) {
		return <Box>Nào há vagas de emprego na sua agenda.</Box>
	}

	return (
		<>
			{!!availableYears?.length && (
				<FormControl fullWidth>
					<InputLabel>Vagas por ano</InputLabel>
					<Select
						label={<>Vagas por ano</>}
						defaultValue={availableYears?.[0]}
						value={selectedYear}
						onChange={(event) =>
							handleSelectYear(event?.target?.value! as number)
						}
						size="small"
					>
						{availableYears.map((year) => (
							<MenuItem style={{ zoom: '110%' }} value={year}>
								{year}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}

			<Row>
				{data && (
					<Column>
						<JobList>
							{invitesByYear?.map((invitation) => (
								<VacancyItem
									item={invitation.projeto}
									onClick={() => handleSelectJob(invitation)}
									isSelected={selectedJob == invitation}
								/>
							))}
						</JobList>
					</Column>
				)}

				{selectedJob && (
					<JobDetails
						job={selectedJob.projeto}
						hasBtnApplyJob={false}
						refetchData={refetch}
						isApplyJob
						isWorking={selectedJob.status === 'A'}
						dataJob={data}
						index={
							data?.findIndex(
								(item) => item.hashcod === selectedJob.hashcod
							) as number
						}
					/>
				)}
			</Row>
		</>
	)
}
