import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  APP_STORAGE_PREFIX,
  APP_API_URI,
  RATINGQTD,
  RATING,
  RATINGCOMPANY,
  RATINGTECH,
} from '../../utils/Constants'
import { StorageGetItem } from '../../utils/Storage'

export const ratingJob = createApi({
  reducerPath: 'weRatedJob',
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_API_URI}`,
    prepareHeaders: (headers, { getState }) => {
      const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))

      if (token) {
        headers.set('Authorization', `JWT ${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    getWeRatedJobQuantity: builder.query<number, void>({
      query: () => `${RATINGQTD}`,
      transformResponse: (response: { quantidade: number }) =>
        response.quantidade,
    }),
    getRating: builder.query({
      query: () => `${RATING}`,
    }),
    getRatingCompany: builder.query({
      query: (hashcod: any) => `${RATINGCOMPANY}${hashcod}/`,
    }),
    editRatingCompany: builder.mutation<any, { hashcod: any; body: any }>({
      query({ hashcod, body }) {
        return {
          url: `${RATINGCOMPANY}${hashcod}/`,
          method: 'PATCH',
          body,
        }
      },
    }),
    getRatingTech: builder.query({
      query: (hashcod: any) => `${RATINGTECH}${hashcod}/`,
    }),
    editRatingTech: builder.mutation<any, { hashcod: any; body: any }>({
      query({ hashcod, body }) {
        return {
          url: `${RATINGTECH}${hashcod}/`,
          method: 'PATCH',
          body,
        }
      },
    }),
  }),
})

export const {
  useLazyGetWeRatedJobQuantityQuery,
  useGetRatingQuery,
  useGetRatingCompanyQuery,
  useGetRatingTechQuery,
  useEditRatingCompanyMutation,
  useEditRatingTechMutation,
} = ratingJob
