import { Button } from '@mui/material'
import { GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid'

export const STATES = [
  { value: 'sp', label: 'São Paulo' },
  { value: 'mg', label: 'Minas Gerais' },
]

export const CITIES = [
  { value: 'Osasco', label: 'Osasco' },
  { value: 'Carapicuíba', label: 'Carapicuíba' },
]

export const OCCUPATION_AREAS = [
  { value: 'Desenvolvedor', label: 'Desenvolvedor' },
  { value: 'Consultor', label: 'Consultor' },
]

export const SPECIALIZATION_AREAS = [
  { value: 'React', label: 'React' },
  { value: 'PHP', label: 'PHP' },
]

export const EXPERIENCE_LEVELS = [
  { value: 'Senior', label: 'Senior' },
  { value: 'Junior', label: 'Junior' },
]

export const MARKS = [
  {
    value: 0,
    label: '0 Km',
  },
  {
    value: 300,
    label: '300 Km',
  },
]

export const EXPERIENCIES = [
  {
    id: 1,
    areaAtuacao: 'Desenvolvedor',
    especializacao: 'React',
    nivelExperiencia: 'Senior',
    projetos: 3,
    valorHora: 'R$ 80,00/h',
  },
]

export const COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 25 },
  {
    field: 'areaAtuacao',
    headerName: 'Área Atuação',
    width: 160,
    editable: false,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'especializacao',
    headerName: 'Especialização',
    width: 130,
    editable: false,
    headerAlign: 'left',
  },
  {
    field: 'nivelExperiencia',
    headerName: 'Experiência',
    type: 'number',
    width: 110,
    editable: false,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'projetos',
    headerName: 'Projetos',
    type: 'number',
    width: 90,
    editable: false,
    align: 'center',
    headerAlign: 'left',
  },
  {
    field: 'valorHora',
    headerName: 'ValorHora',
    width: 110,
    editable: false,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation() // don't select this row after clicking

        const api: GridApi = params.api
        const thisRow: Record<string, GridCellValue> = {}

        api
          .getAllColumns()
          .filter((c) => c.field !== '__check__' && !!c)
          .forEach(
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          )

        return alert(JSON.stringify(thisRow, null, 4))
      }

      return <Button onClick={onClick}>EXCLUIR</Button>
    },
  },
]
