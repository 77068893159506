import React from 'react'
import './styles.scss'
import { TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Moment } from 'moment'
import 'moment/locale/pt-br'

export interface MaterialDatePicker {
  views?: any
  openTo?: any
  label: string
  value: string | Moment
  inputFormat?: string
  disablePast?: boolean
  disableFuture?: boolean
  onChange: (e: any) => void
}

const MaterialDatePicker: React.FC<MaterialDatePicker> = ({
  value = '',
  label = 'Data',
  openTo = 'year',
  disablePast = false,
  disableFuture = false,
  inputFormat = 'DD/MM/YYYY',
  onChange = (value: any) => {},
  views = ['year', 'month', 'day'],
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
      <DesktopDatePicker
        label={label}
        value={value}
        views={views}
        openTo={openTo}
        inputFormat={inputFormat}
        disableFuture={disableFuture}
        disablePast={disablePast}
        disableHighlightToday
        onChange={(value) => onChange(value)}
        renderInput={(params) => (
          <TextField className="material-date-picker " {...params} />
        )}
      />
    </LocalizationProvider>
  )
}

export default MaterialDatePicker
