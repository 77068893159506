import * as yup from 'yup'
import { REQUIRED_MSG } from 'utils/Constants'

const validationSchema = yup.object().shape({
  cep: yup.string().min(8, 'CEP inválido.').required(REQUIRED_MSG),
  rua: yup.string(),
  numero: yup.string().required(REQUIRED_MSG),
  bairro: yup.string(),
  cidade: yup.string(),
  estado: yup.string(),
})

export default validationSchema
