import { CardContent } from '@mui/material'
import styled from 'styled-components'

export const TopicsWrapper = styled(CardContent)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const PlanTopic = styled.p`
  font-size: 1rem;
`
