import * as React from 'react'
import { Box, CircularProgress } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { useGetRatingQuery } from '../../store/Rating'
import { CompanyCard } from './CompanyCard'

import './styles.scss'

export const RatingCompany = () => {
  const {
    data,
    isLoading,
    isSuccess,
    refetch: refetchRating,
  } = useGetRatingQuery('')

  React.useEffect(() => refetchRating(), [refetchRating])

  return (
    <Box>
      <Grid2 container spacing={2}>
        {isSuccess &&
          data?.map((dataRating) => (
            <Grid2 xs={3}>
              <CompanyCard
                companyHash={dataRating?.hashcod}
                rating={dataRating}
                refetchRating={refetchRating}
                key={dataRating?.hashcod}
              />
            </Grid2>
          ))}

        {isSuccess && data.length === 0 && <h1>Não há avaliações pendentes</h1>}
        {isLoading && (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}
      </Grid2>
    </Box>
  )
}
