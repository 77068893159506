import { useState } from 'react'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Chip,
  Stack,
  Divider,
} from '@mui/material'
import { Button } from 'components/Atoms/Button'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ClearIcon from '@mui/icons-material/Clear'
import { useFormik } from 'formik'
import _ from 'lodash'
import CircularProgress from '@mui/material/CircularProgress'
import {
  initialValues,
  ICidade,
  estados,
  schema,
  VacancyFilter,
  marks,
  locationFilterSchema,
} from '../SearchVacancies/formObject'
import {
  useAddJobPreferenceMutation,
  useDeleteJobPreferenceMutation,
  useGetWeFoundYourJobPreferenciesQuery,
} from 'store/WeFoundYourJob'
import { JobPreferenceResponse, Localization } from 'types'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { getStateByUf } from 'utils'
import {
  Accordion,
  CityFilterAddButton,
  CityFilterContainer,
  Title,
} from './styles'
import { ILocationForm } from './types'

export default function Filters({ setSelectedFilter }: FilterProps) {
  const [showDeletePreferenceModal, setShowDeletePreferenceModal] =
    useState(false)
  const { warningSnackbar, successSnackbar } = useCustomSnackbar()
  const [preferenceToDelete, setPreferenceToDelete] =
    useState<JobPreferenceResponse>()
  const [cidades, setCidades] = useState<ICidade[]>([])
  const [locations, setLocations] = useState<Localization[]>([])
  const [filterFormOpen, setFilterFormOpen] = useState(false)

  const {
    data: preferencies,
    error: preferenciesError,
    isLoading: preferenciesIsLoading,
    isSuccess: preferenciesIsSuccess,
    refetch: refetchPreferencies,
  } = useGetWeFoundYourJobPreferenciesQuery('')

  const [addJobPreference] = useAddJobPreferenceMutation()
  const [deleteJobPreference] = useDeleteJobPreferenceMutation()

  const [submitted, setSubmitted] = useState(false)

  const handleClickOpen = (preferenceToDelete: JobPreferenceResponse) => {
    setPreferenceToDelete(preferenceToDelete)

    setShowDeletePreferenceModal(true)
  }

  const handleClose = () => {
    setShowDeletePreferenceModal(false)
  }

  const handleDeletePreference = () => {
    if (preferenceToDelete)
      deleteJobPreference(preferenceToDelete.hashcod)
        .unwrap()
        .then(refetchPreferencies)
        .then(() => successSnackbar('Preferência removida com sucesso'))
        .catch(() => warningSnackbar('Erro ao remover preferência'))

    setShowDeletePreferenceModal(false)
  }

  const getCitys = (codigo: string) => {
    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${codigo}/municipios`

    fetch(url)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        const cities = [{ id: '', nome: 'Todas' }, ...data]

        setCidades(cities)
      })
  }

  const changeCheck = (type: 'L' | 'R') => {
    if (values.tipo_filtro === type) {
      setFieldValue('tipo_filtro', 'N')
      setLocations([])
    } else {
      setFieldValue('tipo_filtro', type)
      setFilterFormOpen(true)
    }
  }

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    errors,
  } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues,
    onSubmit: () => {},
    validateOnChange: true,
    validateOnBlur: submitted,
    validateOnMount: true,
  })

  const {
    values: locationFilterValues,
    setFieldValue: setLocationFilterFieldValue,
    setValues: setLocationFilterFieldValues,
    errors: locationFilterErrors,
  } = useFormik<ILocationForm>({
    validationSchema: locationFilterSchema,
    enableReinitialize: true,
    initialValues: LOCATION_FILTER_DEFAULT_FIELDS,
    onSubmit: () => {},
    validateOnChange: submitted,
    validateOnBlur: submitted,
  })

  const addLocation = () => {
    const newLocation = {
      cidade: locationFilterValues.cidade.id
        ? locationFilterValues.cidade.nome
        : '',
      estado: locationFilterValues.estado.title,
      uf: locationFilterValues.estado.uf,
    }

    if (
      locations.some(
        (location) =>
          location.cidade === newLocation.cidade &&
          location.uf === newLocation.uf
      )
    ) {
      warningSnackbar('Essa cidade já foi adicionada')
    } else {
      setLocations((locations) => [...locations, newLocation])
      setLocationFilterFieldValues(LOCATION_FILTER_DEFAULT_FIELDS)
    }
  }

  const deleteLocation = (locationToDelete: Localization) => {
    setLocations((locations) => {
      const newLocations = locations.filter(
        (location) =>
          location.cidade !== locationToDelete.cidade &&
          location.uf !== locationToDelete.uf
      )

      if (_.isEmpty(newLocations)) setFieldValue('tipo_filtro', 'N')

      return newLocations
    })
  }

  const addPreference = async () => {
    const byCity = true
    const byKm = true

    let tipo_filtro = byCity ? 'L' : byKm ? 'R' : 'N'
    let palavras_chave = values.palavras_chave
    let raio_atuacao = values.raio_atuacao

    const mappedLocations = locations.map(({ cidade, uf }) => ({
      cidade,
      estado: getStateByUf(uf),
      uf,
    }))

    if (
      tipo_filtro === 'N' &&
      palavras_chave == '' &&
      !values.remoto &&
      mappedLocations.length === 0
    ) {
      warningSnackbar('Preencha ao menos um dos campos acima.')
    } else {
      let data = {
        tipo_filtro,
        palavras_chave,
        raio_atuacao,
        localizacoes: mappedLocations,
      }

      const hasPreference = preferencies?.find(
        (item) =>
          item.palavras_chave.toLowerCase() === palavras_chave.toLowerCase()
      )

      if (!hasPreference) {
        addJobPreference(data)
          .unwrap()
          .then(() => {
            successSnackbar('Alerta de Vaga de Emprego adicionado com sucesso.')

            refetchPreferencies()
          })
          .catch((error) => {
            if (error?.data[0] === 'NUMERO_PREFERENCIAS_EXCEDIDO') {
              warningSnackbar(
                'Você já possui 5 Alertas de Vagas de Emprego cadastrados, exclua um ou mais para poder cadastrar novos.'
              )
            } else {
              warningSnackbar(
                'Não foi possível adicionar o novo Alerta de Vaga de Emprego, tente novamente mais tarde.'
              )
            }
          })
      }
    }
  }

  const setPreference = () => {
    const objEnvio = {
      ...values,
      localizacoes: locations.map((location) => ({
        cidade: location.cidade,
        estado: location.estado,
        uf: location.uf,
      })),
    }

    setSelectedFilter(objEnvio)
  }

  const choosePreference = (preference: JobPreferenceResponse) => {
    const objEnvio = {
      ...preference,
      // NÃO FORNECIDO NA API
      remoto: false,
      localizacoes: preference.localizacoes.map((localizacao) => ({
        cidade: localizacao.cidade ? localizacao.cidade : '',
        estado: localizacao.estado,
        uf: localizacao.uf,
      })),
    }

    if (!_.isEmpty(objEnvio.localizacoes)) setFilterFormOpen(true)

    setSelectedFilter(objEnvio)
    setValues({
      hashcod: values.hashcod,
      remoto: false,
      palavras_chave: objEnvio.palavras_chave,
      raio_atuacao: objEnvio.raio_atuacao,
      tipo_filtro: objEnvio.tipo_filtro,
    })
  }

  return (
    <>
      <Accordion square={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
          <Title>Filtros</Title>
        </AccordionSummary>

        <AccordionDetails>
          <form onSubmit={handleSubmit}>
            <div className="container-modal">
              <TextField
                value={values.palavras_chave}
                onChange={handleChange}
                margin="dense"
                name="palavras_chave"
                label="Palavras-chave"
                variant="standard"
                placeholder="Buscar vagas de emprego por palavras-chave"
                fullWidth
                size="small"
              />

              {preferenciesIsLoading && <CircularProgress />}

              {!!preferenciesError && (
                <h3>Houve um erro ao recuperar a lista de preferências.</h3>
              )}

              {!!preferenciesIsSuccess && preferencies.length === 0 && (
                <h3>Não há preferências salvas.</h3>
              )}

              {preferenciesIsSuccess && preferencies.length > 0 && (
                <Stack
                  direction="row"
                  spacing={2}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  {preferencies.map((preference) => (
                    <Chip
                      key={preference.hashcod}
                      color="primary"
                      onDelete={() => {
                        handleClickOpen(preference)
                        return <ClearIcon />
                      }}
                      onClick={() => choosePreference(preference)}
                      label={preference.palavras_chave}
                    />
                  ))}
                </Stack>
              )}

              <div className="marginTop">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.remoto}
                      onChange={() => setFieldValue('remoto', !values.remoto)}
                      size="small"
                    />
                  }
                  label="Atuação remota"
                />
              </div>

              <CityFilterContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.tipo_filtro === 'L'}
                      onChange={() => changeCheck('L')}
                      size="small"
                    />
                  }
                  label="Filtro por cidades"
                />
                {!(values.tipo_filtro === 'L' && filterFormOpen) && (
                  <CityFilterAddButton
                    buttonType="primary"
                    onClick={() => {
                      if (values.tipo_filtro === 'L') setFilterFormOpen(true)
                      else changeCheck('L')
                    }}
                  >
                    +
                  </CityFilterAddButton>
                )}
              </CityFilterContainer>

              {locations.length > 0 && (
                <Stack
                  direction="row"
                  spacing={2}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  {locations.map((location) => (
                    <Chip
                      key={`${location.cidade}${location.uf}`}
                      color="primary"
                      onDelete={() => {
                        deleteLocation(location)

                        return <ClearIcon />
                      }}
                      label={`${location.cidade} (${location.uf})`}
                    />
                  ))}
                </Stack>
              )}

              {values.tipo_filtro === 'L' && filterFormOpen && (
                <div className="horizontal">
                  <div className="column-1and2">
                    <Autocomplete
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Estado"
                          error={!!locationFilterErrors.estado}
                          helperText={
                            locationFilterErrors.estado ||
                            !locationFilterValues.estado
                              ? 'Estado Obrigatório'
                              : ''
                          }
                          margin="dense"
                        />
                      )}
                      // @ts-ignore
                      value={locationFilterValues.estado}
                      disabled={values.tipo_filtro !== 'L'}
                      autoHighlight
                      getOptionLabel={(option) => option.title}
                      onChange={(_, newValue) => {
                        getCitys(newValue?.value || '')

                        setLocationFilterFieldValue(
                          `estado`,
                          newValue || undefined
                        )
                      }}
                      options={estados}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                    />
                  </div>

                  <div className="column-1and2">
                    <Autocomplete
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Cidade"
                          error={!!locationFilterErrors.cidade}
                          helperText={
                            locationFilterErrors.cidade ||
                            !locationFilterValues.cidade
                              ? 'Cidade Obrigatório'
                              : ''
                          }
                          margin="dense"
                        />
                      )}
                      // @ts-ignore
                      value={locationFilterValues.cidade}
                      disabled={!locationFilterValues.estado}
                      autoHighlight
                      getOptionLabel={(option) => option.nome}
                      onChange={(_, newValue) =>
                        setLocationFilterFieldValue(
                          `cidade`,
                          newValue || undefined
                        )
                      }
                      options={cidades}
                      isOptionEqualToValue={(option, value) =>
                        option?.nome === value?.nome
                      }
                    />
                  </div>

                  <div className="column-3">
                    <Button
                      buttonType="primary"
                      onClick={addLocation}
                      disabled={values.tipo_filtro !== 'L'}
                      style={{ marginRight: '1rem' }}
                    >
                      Adicionar
                    </Button>

                    <Button
                      buttonType="warning"
                      onClick={() => {
                        if (_.isEmpty(locations))
                          setFieldValue('tipo_filtro', 'N')

                        setFilterFormOpen(false)
                      }}
                      style={{ marginRight: '1rem' }}
                    >
                      Fechar
                    </Button>
                  </div>
                </div>
              )}

              {errors.tipo_filtro && (
                <label className="helper-box" style={{ minWidth: '100%' }}>
                  {`${errors.tipo_filtro}`}
                </label>
              )}

              <div className="center">
                <Button
                  buttonType="primary"
                  style={{ width: '50%' }}
                  disabled={!!_.size(errors)}
                  color="primary"
                  type="submit"
                  onClick={() => {
                    if (values.palavras_chave) addPreference()

                    setPreference()
                    setSubmitted(true)
                  }}
                >
                  Buscar
                </Button>
              </div>
            </div>
          </form>
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={showDeletePreferenceModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Apagar preferência?</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Essa ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>

          <Button
            buttonType="danger"
            onClick={handleDeletePreference}
            autoFocus
          >
            Apagar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface FilterProps {
  setSelectedFilter: (filter: VacancyFilter) => void
}

const LOCATION_FILTER_DEFAULT_FIELDS = {
  cidade: { id: '', nome: '' },
  estado: { title: '', uf: '', value: '' },
}
