export enum PersonTypes {
  LEGAL_PERSON = 'legalPerson',
  NATURAL_PERSON = 'naturalPerson',
}

export interface DisplayNoteFields {
  footer: boolean
  clientForm: boolean
  noteConfig: boolean
  addressForm: boolean
  serviceForm: boolean
  noteClientInfo: boolean
}

export interface FiscalNoteService {
  codigo: string
  codigoTributacao: string
  discriminacao: string
  cnae: string
  iss: {
    tipoTributacao: number
    exigibilidade: number
    aliquota: number
  }
  valor: {
    servico: number
    descontoCondicionado: number
    descontoIncondicionado: number
  }
}

export interface PostFiscalNote {
  idIntegracao: string
  enviarEmail: boolean
  prestador: {
    cpfCnpj: string
  }
  tomador: {
    cpfCnpj: string
    razaoSocial: string
    email: string
    telefone: {
      numero: string
    }
    endereco: {
      cep: string
      estado: string
      numero: string
      bairro: string
      logradouro: string
      codigoCidade: string
      tipoLogradouro: string
    }
  }
  servico: {
    codigo: string
    discriminacao: string
    cnae: string
    iss: {
      aliquota: number
    }
    valor: {
      servico: number
    }
  }[]
}

export interface NoteFormConfig {
  cpfCnpjSearch: string
  notInformClient: boolean
  enablePaymentEmission: boolean
  enableSendNotebyEmail: boolean
}

export interface ClientFormModel {
  name?: string
  phone: string
  email: string
  cpfCnpj: string
  socialReason: string
  personType: PersonTypes
  municipalRegistration?: string
  sendEmail?: boolean
}
export interface ServiceFormModel {
  cnae: string
  activityOptions: any
  serviceValue: string
  selectedActivity: string
  operatioNatureOptions: any
  serviceLiquidValue: string
  serviceDescription: string
  enableCulturalBooster: boolean
  selectedOperationNature: string
  aliquot: string
  serviceItemCode: string
  advancedOptions: {
    calcBase: string
    enableRetainISS: boolean
    municipalTaxValue: string
    conditionalDiscountValue: string
    inconditionalDiscountValue: string
  }
}
