import { TextFieldProps, TextField } from '@mui/material'

export const InputField = (props: TextFieldProps) => (
  <TextField
    {...props}
    InputLabelProps={{ shrink: !!props.value }}
    fullWidth
    size="small"
    margin="none"
    variant={props.variant || 'standard'}
    error={!!props.helperText}
    helperText={props.helperText}
  />
)
