import './styles.scss'

import React from 'react'
import { Button, SelectChangeEvent, TextField } from '@mui/material'
import MaterialDatePicker from '../MaterialDatePicker'
import MaterialSelect from '../MaterialSelect'
import { defaultDependentForm } from 'data/utils/Dependent/static'
import { DependentFormModel } from 'domain/models/dependent'
import { cpfMask } from 'data/utils/regex'
import moment from 'moment'

interface DependentFormProps {
  errorField?: string
  setDependentForm: any
  handleAddNewDependent?: any
  dependentForm: DependentFormModel
}

const DependentForm: React.FC<DependentFormProps> = ({
  errorField = '',
  handleAddNewDependent,
  setDependentForm = () => {},
  dependentForm = defaultDependentForm,
}) => {
  const handleChangeDependentForm = (field: string, value: any) => {
    let fieldValue = value

    if (field === 'cpf') fieldValue = cpfMask(value)
    //if (field === 'birthdate') fieldValue = moment(value).format('DD/MM/YYYY')

    setDependentForm({ ...dependentForm, [field]: fieldValue })
  }

  return (
    <div className="dependent-form box-container">
      <div className="box-container-header flex-box">
        <p>Adicionar dependente</p>
      </div>
      <div className="dependent-form-body flex-box">
        <div className="name-cpf">
          <TextField
            id="name"
            variant="standard"
            label="Nome"
            value={dependentForm?.name}
            placeholder="Insira o nome"
            error={errorField === 'name'}
            onChange={(e) =>
              handleChangeDependentForm('name', e?.target?.value)
            }
          />
          <TextField
            id="cpf"
            variant="standard"
            label="CPF"
            value={dependentForm?.cpf}
            placeholder="Insira o CPF"
            error={errorField === 'cpf'}
            onChange={(e) => handleChangeDependentForm('cpf', e?.target?.value)}
          />
        </div>
        <div className="birthdate-relationship">
          <MaterialDatePicker
            value={dependentForm?.birthdate}
            label="Selecione a data de nascimento"
            onChange={(newValue) => {
              handleChangeDependentForm('birthdate', newValue)
            }}
          />

          <MaterialSelect
            id="relationship-grade"
            label="Grau de parentesco"
            value={dependentForm?.relationshipGrade}
            options={dependentForm?.relationshipGradeOptions}
            onChange={(e: SelectChangeEvent) =>
              handleChangeDependentForm('relationshipGrade', e.target.value)
            }
          />
        </div>
        {handleAddNewDependent && (
          <Button
            className="light-blue-button"
            onClick={() => handleAddNewDependent()}
            style={{ width: '40%' }}
          >
            Cadastrar dependente
          </Button>
        )}
      </div>
    </div>
  )
}

export default DependentForm
