import React from 'react'
import { Helmet } from 'react-helmet-async'

type IPageTitle = {
  title: string
}

const PageTitle: React.FC<IPageTitle> = ({ title }) => {
  return (
    <Helmet>
      <title>JOB Connet | {title}</title>
    </Helmet>
  )
}

export default PageTitle
