import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from '../actions'

export const initialState: any = {
  cnaes: [],
  terms: [],
  error: null,
  partners: [],
  status: 'idle',
  dependents: [],
  nodeCompany: {},
  socialContracts: [],
  municipalAccesses: [],
  companyRequestForm: {},
  additionalServices: [],
}

export const nodeSlice = createSlice({
  name: 'nodeSlice',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    setNodeCompany: (state, action: PayloadAction<any>) => {
      state.nodeCompany = action?.payload ?? {}
    },
    setPartners: (state, action: PayloadAction<any>) => {
      state.partners = action?.payload
    },
    setDependents: (state, action: PayloadAction<any>) => {
      state.dependents = action?.payload
    },
    setTerms: (state, action: PayloadAction<any>) => {
      state.terms = action?.payload
    },
    setSocialContracts: (state, action: PayloadAction<any>) => {
      state.socialContracts = action?.payload
    },
    setOnboardingCompanyRequestForm: (state, action: PayloadAction<any>) => {
      state.companyRequestForm = action?.payload
    },
    setCnaes: (state, action: PayloadAction<any>) => {
      state.cnaes = action?.payload
    },
    setOnboardingCnaeForm: (state, action: PayloadAction<any>) => {
      state.cnaeForm = action?.payload
    },
    setAdditionalServices: (state, action: PayloadAction<any>) => {
      state.additionalServices = action?.payload
    },
    setMunicipalAccess: (state, action: PayloadAction<any>) => {
      state.municipalAccess = action?.payload
    },
  },
})

export const {
  setCnaes,
  setTerms,
  setPartners,
  setDependents,
  setNodeCompany,
  setSocialContracts,
  setMunicipalAccess,
  setOnboardingCnaeForm,
  setAdditionalServices,
  setOnboardingCompanyRequestForm,
} = nodeSlice.actions

export default nodeSlice.reducer
