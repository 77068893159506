import { CompanyDataTabForm } from './types'

export const INITIAL_VALUES: CompanyDataTabForm = {
  nome: '',
  email: '',
  pais: 'BR',
  telefone: '',
  senha: '',
  imagem: undefined,
  cnpj_cpf: '',
}
