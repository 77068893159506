import './styles.scss'
import MaterialDialog from '../MaterialDialog'
import { DialogConfig } from 'domain/models/dialog'
import { defaultDialogState } from 'data/utils/Dialog/static'

import GroupIcon from '@mui/icons-material/Group'
import PersonIcon from '@mui/icons-material/Person'
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  partnerColumns,
  defaultPartnerForm,
  displayFormBody,
  displayListBody,
} from 'data/utils/Partner/static'
import {
  Button,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import MaterialTable from '../MaterialTable'
import PartnerForm from '../PartnerForm'
import { defaultDisplayFields } from 'data/utils/Partner/static'
import { DisplayFields, PartnerFormModel } from 'domain/models/partner'
import DependentForm from '../DependentForm'
import DeleteIcon from '@mui/icons-material/Delete'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { defaultDependentForm } from 'data/utils/Dependent/static'
import DependentData from '../DependentData'
import { partnerFormValidation } from 'data/utils/Partner/validation'
import { dependentFormValidation } from 'data/utils/Dependent/validation'
import { DependentFormModel } from 'domain/models/dependent'
import {
  useDeletePartnerMutation,
  useGetPartnersQuery,
  usePostPartnerMutation,
} from 'store/NodeApi/partner/endpoint'

import moment from 'moment'
import { usePostDependentMutation } from 'store/NodeApi/dependent/endpoint'
import { isCurrencyValid } from 'data/utils/SendNote/static'

const AddedDependentList = () => {
  const { dependents, setDependents } = useContext(PartnerContext)

  const handleRemoveDependent = (dependentIndex = null) => {
    if (dependentIndex === null) {
      return
    }

    const dependentsCopy = [...dependents]
    dependentsCopy.splice(dependentIndex, 1)
    setDependents(dependentsCopy)
  }

  return (
    <div className="dependents-simple-list box-container">
      <div className="box-container-header flex-box">
        <p>Dependentes</p>
      </div>
      <div className="dependents-simple-list-body">
        {dependents.map((dependent, index) => (
          <div className="dependent-line" key={dependent?.cpf}>
            <div>{`${index + 1} - ${dependent.name}`}</div>
            <DeleteIcon onClick={() => handleRemoveDependent(index)} />
          </div>
        ))}
      </div>
    </div>
  )
}

const DisplayFormOrList = () => {
  const {
    partnerForm,
    displayFields,
    dependentForm,
    setPartnerForm,
    setDependentForm,
    handleAddNewDependent,
    partnerFormErrors,
    dependentFormErrors,
    handleAddNewPartner,
    partners,
  } = useContext(PartnerContext)

  const partnerErrorField = Object.keys(partnerFormErrors)[0]

  const partnerFormProps = {
    errorField: partnerErrorField,
    partnerForm,
    partners,
    setPartnerForm,
  }

  const dependentErrorField = Object.keys(dependentFormErrors)[0]

  const dependentProps = {
    dependentForm,
    setDependentForm,
    handleAddNewDependent,
    errorField: dependentErrorField,
  }

  if (displayFields.partnerForm)
    return (
      <div className="partner-form-container">
        <div style={{ display: 'flex' }}>
          <PartnerForm {...partnerFormProps} />
        </div>
        <div className="dependent-form-list">
          {partnerForm?.hasDependents && (
            <>
              <DependentForm {...dependentProps} />
              <AddedDependentList />
            </>
          )}
        </div>
        <Button
          className="light-blue-button add-partner-button"
          onClick={() => handleAddNewPartner()}
        >
          Adicionar
        </Button>
      </div>
    )

  return <PartnerList />
}

const PartnerList = () => {
  const { selectedPartnerRows, setSelectedPartnerRows, partners } =
    useContext(PartnerContext)

  const tableProps = {
    data: partners,
    columns: partnerColumns,
    selectedRows: selectedPartnerRows,
    setSelectedRows: setSelectedPartnerRows,
  }

  return (
    <div className="box-container">
      <div className="box-container-header flex-box">
        <GroupIcon />
        <p>Sócios</p>
      </div>
      <MaterialTable {...tableProps} />
    </div>
  )
}

export const PartnerContext: any = createContext({})

const PartnerData: React.FC = () => {
  const [dialogConfig, setDialogConfig] =
    useState<DialogConfig>(defaultDialogState)
  const [displayFields, setDisplayFields] =
    useState<DisplayFields>(defaultDisplayFields)

  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false)
  const [selectedPartnerRows, setSelectedPartnerRows] = useState<number[]>([])

  const [partnerForm, setPartnerForm] =
    useState<PartnerFormModel>(defaultPartnerForm)

  const [partners, setPartners] = useState<any>([])
  const [dependentForm, setDependentForm] =
    useState<DependentFormModel>(defaultDependentForm)
  const [dependents, setDependents] = useState<DependentFormModel[]>([])

  const [partnerFormErrors, setPartnerFormErrors] = useState<any>({})
  const [dependentFormErrors, setDependentFormErrors] = useState<any>({})

  const { warningSnackbar, successSnackbar, errorSnackbar } =
    useCustomSnackbar()

  const { data: apiPartners } = useGetPartnersQuery('')

  const [postPartner] = usePostPartnerMutation()
  const [deletePartner] = useDeletePartnerMutation()
  const [postDependents] = usePostDependentMutation()

  useEffect(() => {
    const partners = apiPartners?.map((partner: any) => {
      return {
        ...partner,
        pisNit: partner?.pis_nit,
        prolaboreValue: partner?.prolabore_value,
      }
    })

    setPartners(partners)
  }, [apiPartners])

  const handleCloseDialog = () =>
    setDialogConfig({ ...dialogConfig, open: false })

  const handleOpen = () => setOpenSpeedDial(true)
  const handleClose = () => setOpenSpeedDial(false)

  const resetDependentForm = () => {
    setDependentForm(defaultDependentForm)
  }

  const handleAddPartnerAction = () => {
    resetPartnerForm()
    setDisplayFields(displayFormBody)

    handleClose()
  }

  const handleDisplayTable = () => {
    setDisplayFields(displayListBody)

    handleClose()
  }

  const confirmRemovePartner = () => {
    const selectedPartner = selectedPartnerRows?.[0]

    deletePartner(selectedPartner)
      .unwrap()
      .then(() => {
        successSnackbar('Sócio removido com sucesso')
        setPartners([
          ...partners.filter((partner: any) => partner.id !== selectedPartner),
        ])
      })

    setDialogConfig(defaultDialogState)
  }

  const cancelDialogState = {
    open: true,
    dialogBodyText: 'Deseja remover o sócio?',
    cancelText: 'Cancelar',
    confirmText: 'Confirmar',
    onConfirm: confirmRemovePartner,
  }

  const partnerHasDependents = {
    open: true,
    dialogBodyText: 'Remova os dependentes antes de remover o sócio',
    cancelText: false,
    confirmText: 'Ok',
    onConfirm: () => setDialogConfig(defaultDialogState),
  }

  const handleRemoveAction = () => {
    const selectedPartner = selectedPartnerRows?.[0]
    const myDependents = dependents?.filter(
      (dependent) => dependent.partnerId === selectedPartner
    )

    if (myDependents?.length > 0) {
      setDialogConfig(partnerHasDependents)
    } else {
      setDialogConfig(cancelDialogState)
    }
  }

  const resetPartnerForm = () => {
    setPartnerForm(defaultPartnerForm)
  }

  const dialogProps = {
    open: dialogConfig?.open,
    onClose: handleCloseDialog,
    onConfirm: dialogConfig?.onConfirm,
    cancelText: dialogConfig?.cancelText || null,
    confirmText: dialogConfig?.confirmText || null,
    dialogBodyText: dialogConfig?.dialogBodyText,
  }

  const getDialActions = (): {
    icon: ReactElement
    name: string
    onClick: any
  }[] => {
    const newPartnerBody = {
      icon: <PersonIcon />,
      name: 'Novo sócio',
      onClick: handleAddPartnerAction,
    }

    const seePartnersBody = {
      icon: <TableRowsOutlinedIcon />,
      name: 'Ver sócios',
      onClick: handleDisplayTable,
    }

    const deletePartnerBody = {
      icon: <DeleteIcon />,
      name: 'Excluir sócio',
      onClick: handleRemoveAction,
    }

    let selectedBody = [newPartnerBody, seePartnersBody]

    if (selectedPartnerRows?.[0]) selectedBody.push(deletePartnerBody)

    return selectedBody
  }

  const handleAddNewPartner = () => {
    if (!isCurrencyValid(partnerForm?.prolaboreValue)) {
      errorSnackbar('Campo valor do prolabore inválido.')
      return
    }

    const partnerValidation = partnerFormValidation(partnerForm)

    const firstError = partnerValidation[Object.keys(partnerValidation)[0]]

    if (firstError) {
      setPartnerFormErrors(partnerValidation)
      warningSnackbar(firstError || 'Erro no formulário do sócio.')

      return
    }

    const findSameCpf = partners?.find(
      (partner) => partner?.cpf === partnerForm?.cpf
    )

    if (findSameCpf?.id) {
      warningSnackbar('Já existe um sócio com esse CPF')
      return
    }

    setPartnerFormErrors({})

    postPartner(partnerForm)
      .unwrap()
      .then((result) => {
        successSnackbar('Sócio adicionado!')

        const partnerId = result?.data?.id

        const updatedPartners = [...partners, { id: partnerId, ...partnerForm }]

        setPartners(updatedPartners)

        if (!dependents[0]) return

        const dependentsBody = dependents.map((dependent) => {
          return {
            partnerId,
            name: dependent.name,
            cpf: dependent.cpf,
            birthdate: moment(dependent.birthdate).format('YYYY-MM-DD'),
            relationshipGrade: dependent.relationshipGrade,
          }
        })

        resetPartnerForm()
        postDependents(dependentsBody)
      })
      .catch((err) => {
        const { cpf: partnerFormCpf } = partnerForm || {}

        const samePartnerCpf = partners?.some(
          (partner) => partner?.cpf === partnerFormCpf
        )

        if (samePartnerCpf)
          return errorSnackbar('Já existe um sócio com esse CPF')

        errorSnackbar('Não foi possível adicionar o sócio')
      })
  }

  const handleAddNewDependent = () => {
    const dependentValidation = dependentFormValidation(dependentForm)
    const firstError = dependentValidation[Object.keys(dependentValidation)[0]]

    if (firstError) {
      setDependentFormErrors(dependentValidation)
      warningSnackbar(firstError || 'Erro no formulário do dependente.')

      return
    }

    const sameCPFDependent = dependents.find(
      (dependent) => dependent.cpf === dependentForm.cpf
    )

    if (sameCPFDependent) {
      warningSnackbar('Já existe um dependente com esse CPF.')

      return
    }

    setDependentFormErrors({})
    setDependents([...dependents, dependentForm])
    successSnackbar('Dependente cadastrado com sucesso!')
    resetDependentForm()
  }

  const contextValues = {
    partners,
    dependents,
    partnerForm,
    setDependents,
    displayFields,
    dependentForm,
    setPartnerForm,
    setDependentForm,
    partnerFormErrors,
    dependentFormErrors,
    selectedPartnerRows,
    handleAddNewPartner,
    handleAddNewDependent,
    setSelectedPartnerRows,
  }

  const dependentProps = {
    dependents,
    setDependents,
    selectedPartnerRows,
  }

  return (
    <>
      <PartnerContext.Provider value={contextValues}>
        <MaterialDialog {...dialogProps} />
        <div className="partner-data-container">
          <div className="certificate-toolbar">
            <SpeedDial
              direction="down"
              onOpen={handleOpen}
              open={openSpeedDial}
              onClose={handleClose}
              icon={<SpeedDialIcon />}
              className="speed-dial-container"
              ariaLabel="SpeedDial tooltip example"
            >
              {getDialActions().map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  onClick={action.onClick}
                  tooltipPlacement="right"
                  tooltipTitle={action.name}
                />
              ))}
            </SpeedDial>
          </div>
          <DisplayFormOrList />
        </div>
        {displayFields.partnerList && <DependentData {...dependentProps} />}
      </PartnerContext.Provider>
    </>
  )
}

export default PartnerData
