import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import {
  Avatar,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  Chip,
} from '@mui/material'
import { InvitationResponse, JobResponse } from 'types'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { useNavigate } from 'react-router-dom'
import {
  useDeleteFavoriteVacanciesMutation,
  useEditFavoriteVacanciesMutation,
} from 'store/FavoriteVacancies'
import { Button } from 'components/Atoms/Button'
import {
  useAcceptInvitationVacanciesMutation,
  useRefuseInvitationVacanciesMutation,
  useApplyToVacanciesMutation,
  useQuitVacanciesMutation,
  useAccepetApplyVacanciesMutation,
  useLeaveApplyVacanciesMutation,
  useCancelLeaveMutation,
  useGetJobCandidaturasQuery,
  useDeallocateVacancyMutation,
} from 'store/WeInvitateVacanciesJob'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { setLoading } from 'store/Layout/slice'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import usePlanServices from 'hooks/usePlanServices'
import * as S from './styles'

export interface HeaderCardInfoProps {
  job: JobResponse
  isInvite?: boolean
  hasFinished?: boolean
  isApplyJob?: boolean
  isWorking?: boolean
  hasBtnApplyJob?: boolean
  dataVacation?: any
  dataJob?: any
  refetchData?: () => void
  index: number
}

const HeaderCardInfo: React.FC<HeaderCardInfoProps> = ({
  job,
  isInvite,
  hasFinished,
  isApplyJob,
  isWorking,
  hasBtnApplyJob,
  dataVacation,
  dataJob,
  refetchData,
  index,
}) => {
  const dispatch = useDispatch()
  const { warningSnackbar, successSnackbar, infoSnackbar } = useCustomSnackbar()
  const [editFavoriteVacancies, { isLoading: editFavoriteVacanciesIsLoading }] =
    useEditFavoriteVacanciesMutation()
  const [
    acceptInvitationVacancies,
    { isLoading: acceptInvitationVacanciesIsLoading },
  ] = useAcceptInvitationVacanciesMutation()
  const [
    refuseInvitationVacancies,
    { isLoading: refuseInvitationVacanciesIsLoading },
  ] = useRefuseInvitationVacanciesMutation()
  const [
    deleteFavoriteVacancies,
    { isLoading: deleteFavoriteVacanciesIsLoading },
  ] = useDeleteFavoriteVacanciesMutation()
  const [applyToVacancies, { isLoading: applyToVacanciesIsLoading }] =
    useApplyToVacanciesMutation()
  const [leaveApplyVacancies, { isLoading: leaveApplyVacanciesIsLoading }] =
    useLeaveApplyVacanciesMutation()
  const [deallocateVacancy, { isLoading: deallocateVacancyIsLoading }] =
    useDeallocateVacancyMutation()
  const [cancelLeave, { isLoading: cancelLeaveIsLoading }] =
    useCancelLeaveMutation()
  const [quitVacancies, { isLoading: quitVacanciesIsLoading }] =
    useQuitVacanciesMutation()
  const [acceptApplyVacancies, { isLoading: acceptApplyVacanciesIsLoading }] =
    useAccepetApplyVacanciesMutation()
  const [showModal, setShowModal] = useState(false)
  const [jobFullApply, setJobFullApply] = useState<InvitationResponse>()
  const navigate = useNavigate()
  const { updatePlanInfo } = usePlanServices()
  const {
    data: jobApplications,
    isFetching: jobApplicationsIsFetching,
    refetch: refetchJobApplications,
  } = useGetJobCandidaturasQuery('')

  const isAppliedJob = useMemo(() => {
    return jobApplications?.some((item) => item.hashcod === job.hashcod)
  }, [jobApplications, job])

  const addFavorite = (hashcod) => {
    editFavoriteVacancies(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Vaga de Emprego adicionada aos favoritos')
      })
      .catch(() => {
        warningSnackbar(
          'Erro ao adicionar aos favoritos. Tente novamente mais tarde.'
        )
      })
      .finally(refetchData)
  }

  const deleteFavorite = (hashcod) => {
    deleteFavoriteVacancies(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Vaga de Emprego removida dos favoritos')
      })
      .catch(() => {
        warningSnackbar('Erro ao remover favorito. Tente novamente mais tarde.')
      })
      .finally(refetchData)
  }

  const acceptInvitation = (hashcod: any) => {
    acceptInvitationVacancies(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Você aceitou o convite com sucesso!')
      })
      .then(refetchData)
      .catch(() => {
        warningSnackbar('Erro ao aceitar convite. Tente novamente mais tarde.')
      })
  }

  const refuseInvitation = (hashcod: any) => {
    refuseInvitationVacancies(hashcod)
      .unwrap()
      .then(() => {
        warningSnackbar('Você não aceitou o convite!')
      })
      .then(refetchData)
      .catch(() => {
        warningSnackbar(
          'Erro ao desistir do convite. Tente novamente mais tarde.'
        )
      })
  }

  const handleApplyToVacancies = (hashcod: any) => {
    applyToVacancies(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Você se candidatou a esta vaga de emprego, boa sorte!')
      })
      .then(() => {
        refetchData?.()
        updatePlanInfo()
        refetchJobApplications()
      })
      .catch(() => {
        warningSnackbar(
          'Você já recebeu um convite para esta vaga, acesse o Menu Convites'
        )
      })
  }

  const handleQuitVacancies = (hashcod: any) => {
    quitVacancies(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Você removeu a vaga de emprego')
      })
      .then(refetchData)
      .catch(() => {
        warningSnackbar(
          'Erro ao remover a vaga de emprego. Tente novamente mais tarde.'
        )
      })
  }

  const handleCancelVacancies = (hashcod: any) => {
    quitVacancies(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Você desistiu da candidatura')
      })
      .then(refetchData)
      .catch(() => {
        warningSnackbar(
          'Erro ao desistir da vaga de emprego. Tente novamente mais tarde.'
        )
      })
  }

  const handleAcceptApplyVacancies = (hashcod: any) => {
    acceptApplyVacancies(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Você aprovou a candidatura, parabéns!')
      })
      .then(refetchData)
      .catch(() => {
        warningSnackbar(
          'Erro ao aprovar a vaga de emprego. Tente novamente mais tarde.'
        )
      })
  }

  const handleQuitApplyVacancie = (hashcod: any) => {
    quitVacancies(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Você removeu a vaga de emprego')
      })
      .then(refetchData)
      .catch(() => {
        warningSnackbar(
          'Erro ao remover a vaga de emprego. Tente novamente mais tarde.'
        )
      })
  }

  const handleLeaveApplyVacancie = (hashcod: any) => {
    leaveApplyVacancies(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Você saiu da vaga de emprego')
      })
      .then(refetchData)
      .catch(() => {
        warningSnackbar(
          'Erro ao sair da vaga de emprego. Tente novamente mais tarde.'
        )
      })
  }

  const handleDeallocateVacancy = (hashcod: string) => {
    deallocateVacancy(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Você desalocou a vaga de emprego')
        infoSnackbar(
          'Você sera redirecionado (a) para avaliar o recrutador da vaga'
        )
        navigate('/rating')
      })
      .then(refetchData)
      .catch(() => {
        warningSnackbar(
          'Erro ao desalocar vaga de emprego. Tente novamente mais tarde.'
        )
      })
  }

  const handleCancelLeave = (hashcod: any) => {
    cancelLeave(hashcod)
      .unwrap()
      .then(() => {
        successSnackbar('Você cancelou a saída da vaga de emprego')
      })
      .then(refetchData)
      .catch(() => {
        warningSnackbar(
          'Erro ao cancelar saída da vaga de emprego. Tente novamente mais tarde.'
        )
      })
  }

  const invitateJob = _.find(dataVacation, function (inviteJob) {
    return inviteJob?.projeto?.hashcod === job?.hashcod
  })

  const invitateQuitJob = _.find(dataJob, function (inviteQuitob) {
    return inviteQuitob?.projeto?.hashcod === job?.hashcod
  })

  const planoUser = useSelector((state: RootState) => state?.personalData.plano)

  const openModalToUpgradePlan = () => {
    warningSnackbar('Você precisa fazer um upgrade no seu plano')
    setShowModal(true)
  }

  function createData(
    name: string,
    perfilSug: number,
    candidaturasUser: number | string,
    dadosContato: number | string,
    ordBusca: number
  ) {
    return { name, perfilSug, candidaturasUser, dadosContato, ordBusca }
  }

  const rowsPlans = [
    createData('Basic', 4, 5, 5, 4),
    createData('Advanced', 3, 10, 10, 3),
    createData('Premium', 2, 20, 20, 2),
    createData('Exclusive', 1, 'Ilimitado*', 'Ilimitado', 1),
  ]

  const goToPlans = () => {
    navigate('/plans_contratado')
    setShowModal(false)
  }

  let planoText
  switch (planoUser) {
    case 0:
      planoText = 'Basic'
      break
    case 1:
      planoText = 'Advanced'
      break
    case 2:
      planoText = 'Premium'
      break
    case 3:
      planoText = 'Exclusive'
      break
    default:
      planoText = 'Carregando...'
  }

  let planoApplyJob
  switch (planoUser) {
    case 0:
      planoApplyJob = 5
      break
    case 1:
      planoApplyJob = 10
      break
    case 2:
      planoApplyJob = 20
      break
    case 3:
      planoApplyJob = Infinity
      break
    default:
      planoApplyJob = 'Carregando...'
  }

  const copyToClipBoard = () => {
    const JOBC_URL = location.href.split('?')[0]
    const textToCopy = `
Vaga de Emprego: ${job.nome}
Vaga de Emprego Remota: ${job.alocacao_remota ? 'Home Office' : 'Não'}
Duração: De: ${job.data_inicio ?? 'Indeterminado'} | Até: ${
      job.data_final ?? 'Indeterminado'
    }
Dedicação diária: ${
      job.dedicacao_diaria ? job.dedicacao_diaria + 'Horas' : 'Indeterminado'
    }
Valor/Hora: ${job.pagamento ? 'R$ ' + job.pagamento + '/Hora' : 'À combinar'}
Tipo de Contratação: ${job.tipo_contratacao ?? 'Indeterminado'}
Número de profissionais envolvidos: ${
      job.numero_profissionais ?? 'Indeterminado'
    }
Sobre a Vaga de Emprego:
${job.sobre_projeto ?? 'Indeterminado'}

Em caso de dúvidas, entre em contato com recrutador

Sobre a Empresa: ${job.sobre_empresa ?? 'Indeterminado'}

*Veja esta e mais Vagas de Emprego de Tecnologia na Plataforma JOB Connect!* 
*Cadastre-se gratuitamente pelo link https://job.jobc.com.br
*e Baixe o aplicativo mobile nas lojas:*

Android:
http://bit.ly/2OmivxS

iOS:
https://apple.co/32UyW9W
`
    navigator.clipboard.writeText(textToCopy)
    successSnackbar('Vaga de Emprego copiada')
  }

  useEffect(() => {
    if (!isApplyJob) return

    setJobFullApply(
      _.find(
        dataJob as InvitationResponse[],
        (item) => item?.projeto?.hashcod === job?.hashcod
      )
    )
  }, [isApplyJob, dataJob, job])

  useEffect(() => {
    dispatch(
      setLoading(
        editFavoriteVacanciesIsLoading ||
          deleteFavoriteVacanciesIsLoading ||
          acceptInvitationVacanciesIsLoading ||
          refuseInvitationVacanciesIsLoading ||
          applyToVacanciesIsLoading ||
          quitVacanciesIsLoading ||
          acceptApplyVacanciesIsLoading ||
          leaveApplyVacanciesIsLoading ||
          cancelLeaveIsLoading ||
          jobApplicationsIsFetching
      )
    )
  }, [
    dispatch,
    editFavoriteVacanciesIsLoading,
    deleteFavoriteVacanciesIsLoading,
    acceptInvitationVacanciesIsLoading,
    refuseInvitationVacanciesIsLoading,
    applyToVacanciesIsLoading,
    quitVacanciesIsLoading,
    acceptApplyVacanciesIsLoading,
    leaveApplyVacanciesIsLoading,
    cancelLeaveIsLoading,
    jobApplicationsIsFetching,
  ])

  return (
    <S.Container>
      <S.ColumnDivider>
        <Grid item xs={3}>
          {job.empresa?.imagem ? (
            <S.StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <Avatar
                sx={{ width: 96, height: 96 }}
                alt="Remy Sharp"
                src={job.empresa.imagem}
              />
            </S.StyledBadge>
          ) : (
            <S.StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <Avatar sx={{ width: 64, height: 64 }}>{''}</Avatar>
            </S.StyledBadge>
          )}
        </Grid>

        {hasBtnApplyJob && planoApplyJob !== undefined && !isAppliedJob && (
          <S.ActionsContainer>
            {index < planoApplyJob ? (
              <Button
                loading={applyToVacanciesIsLoading}
                style={{ width: 150, height: 'fit-content' }}
                buttonType="primary"
                onClick={() => handleApplyToVacancies(job.hashcod)}
              >
                Candidatar-se
              </Button>
            ) : (
              <Button
                buttonType="warning"
                style={{ width: 150, height: 'fit-content' }}
                onClick={() => openModalToUpgradePlan()}
              >
                Candidatar-se
              </Button>
            )}
          </S.ActionsContainer>
        )}

        {isWorking &&
          jobFullApply?.status !== 'SA' &&
          jobFullApply?.status !== 'SC' &&
          jobFullApply?.status !== 'R' && (
            <S.ActionsContainer>
              <Button
                loading={leaveApplyVacanciesIsLoading}
                buttonType="info"
                onClick={() =>
                  handleLeaveApplyVacancie(invitateQuitJob.hashcod)
                }
              >
                Sair da vaga de emprego
              </Button>
            </S.ActionsContainer>
          )}

        {isApplyJob && jobFullApply?.status === 'AE' && (
          <S.ActionsContainer>
            <Chip
              label="Aprovado"
              color="success"
              style={{ marginBottom: '12px' }}
            />

            <Button
              loading={acceptApplyVacanciesIsLoading}
              buttonType="info"
              onClick={() =>
                handleAcceptApplyVacancies(invitateQuitJob.hashcod)
              }
            >
              Aceitar
            </Button>

            <Button
              loading={quitVacanciesIsLoading}
              buttonType="danger"
              onClick={() => handleQuitApplyVacancie(invitateQuitJob.hashcod)}
            >
              Recusar
            </Button>
          </S.ActionsContainer>
        )}

        {isApplyJob && jobFullApply?.status === 'P' && (
          <S.ActionsContainer>
            <Chip
              label="Pendente"
              color="warning"
              style={{ marginBottom: '12px' }}
            />

            <Button
              loading={quitVacanciesIsLoading}
              buttonType="danger"
              onClick={() => handleCancelVacancies(invitateQuitJob.hashcod)}
            >
              Cancelar
            </Button>
          </S.ActionsContainer>
        )}

        {isApplyJob && jobFullApply?.status === 'R' && (
          <S.ActionsContainer>
            <Chip
              label="Recusada"
              color="error"
              style={{ marginBottom: '12px' }}
            />

            <Button
              loading={quitVacanciesIsLoading}
              buttonType="danger"
              onClick={() => handleQuitVacancies(invitateQuitJob.hashcod)}
            >
              Remover
            </Button>
          </S.ActionsContainer>
        )}

        {jobFullApply?.status === 'SC' && (
          <S.ActionsContainer>
            <Chip label="Saída Solicitada" color="secondary" />
          </S.ActionsContainer>
        )}

        {isApplyJob && jobFullApply?.status === 'SA' && (
          <S.ActionsContainer>
            <Chip label="Saída aprovada" color="success" />

            <Button
              buttonType="success"
              onClick={() => handleDeallocateVacancy(invitateQuitJob.hashcod)}
            >
              Desalocar
            </Button>

            <Button
              loading={quitVacanciesIsLoading}
              buttonType="danger"
              onClick={() => handleCancelLeave(invitateQuitJob.hashcod)}
            >
              Cancelar
            </Button>
          </S.ActionsContainer>
        )}

        {isInvite && (
          <S.RowButtonDivider>
            <S.StyledButtonAccept>
              <Button
                loading={acceptInvitationVacanciesIsLoading}
                buttonType="success"
                onClick={() => acceptInvitation(invitateJob.hashcod)}
              >
                Aceitar
              </Button>
            </S.StyledButtonAccept>

            <S.StyledButtonRecuse>
              <Button
                loading={refuseInvitationVacanciesIsLoading}
                buttonType="danger"
                onClick={() => refuseInvitation(invitateJob.hashcod)}
              >
                Recusar
              </Button>
            </S.StyledButtonRecuse>
          </S.RowButtonDivider>
        )}
      </S.ColumnDivider>

      <S.Content>
        <S.JobInfo>
          <S.JobTittle>{job.nome}</S.JobTittle>

          <S.CompanyName>{job.empresa.nome}</S.CompanyName>
        </S.JobInfo>

        {hasFinished ? null : job?.favorito ? (
          <Tooltip title="Adicionar aos favoritos" placement="top">
            <S.FavoriteContainer>
              <StarIcon onClick={() => deleteFavorite(job.hashcod)} />
            </S.FavoriteContainer>
          </Tooltip>
        ) : (
          <Tooltip title="Adicionar aos favoritos" placement="top">
            <S.FavoriteContainer>
              <StarBorderIcon onClick={() => addFavorite(job.hashcod)} />
            </S.FavoriteContainer>
          </Tooltip>
        )}
      </S.Content>

      <S.CopyButtonContainer>
        <Tooltip title="Copiar vaga de emprego" placement="top">
          <Box onClick={copyToClipBoard} sx={{ cursor: 'pointer' }}>
            <ContentCopyIcon />
          </Box>
        </Tooltip>
      </S.CopyButtonContainer>

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Seu plano atual é o ${planoText}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Planos</TableCell>
                    <TableCell align="right">Perfil sugerido</TableCell>
                    <TableCell align="right">Candidaturas</TableCell>
                    <TableCell align="right">Dados de contato</TableCell>
                    <TableCell align="right">Ordem na busca</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsPlans.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.perfilSug}º</TableCell>
                      <TableCell align="right">
                        {row.candidaturasUser}
                      </TableCell>
                      <TableCell align="right">{row.dadosContato}</TableCell>
                      <TableCell align="right">{row.ordBusca}º</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={goToPlans} autoFocus>
            Mudar de plano
          </Button>
        </DialogActions>
      </Dialog>
    </S.Container>
  )
}

export default HeaderCardInfo
