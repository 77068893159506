const StorageGetItem = (key: string) => {
	const value = localStorage.getItem(key)

	if (!value) {
		return null
	}

	return JSON.parse(value)
}

const StorageSetItem = (key: string, value: string) => {
	localStorage.setItem(key, JSON.stringify(value))
}

const StorageRemoveItem = (key: string) => {
	localStorage.removeItem(key)
}

const StorageClear = () => {
	return localStorage.clear()
}

const clearAuth = () => {
	localStorage.removeItem('@JOB-CONNECT/authToken');
	localStorage.removeItem('@JOB-CONNECT/authType')
	localStorage.removeItem('default');
}

export { StorageGetItem, StorageSetItem, StorageRemoveItem, StorageClear, clearAuth }
