import { useCallback, useEffect, useState } from 'react'
import { PatternFormat } from 'react-number-format'
import { useFormik } from 'formik'
import {
  TextField,
  Autocomplete,
  Box,
  InputAdornment,
  Rating,
} from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import blankProfile from '../../../../../img/blank_profile.png'
import cameraImg from '../../../../../img/camera.png'

import { INITIAL_VALUES } from './constants'
import { COUNTRIES } from 'utils/Constants'
import { setLoading } from 'store/Layout/slice'
import { useUpdatePersonalDataMutation } from 'store/PersonalData'
import { useDispatch, useSelector } from 'react-redux'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { Button } from 'components/Atoms/Button'
import validationSchema from './validations'
import '../../../styles.scss'
import { pick } from 'lodash'
import { GeneralDataTabForm } from './types'
import { RootState } from 'store'

const GeneralDataTab = () => {
  const dispatch = useDispatch()
  const { warningSnackbar, successSnackbar } = useCustomSnackbar()
  const [updatePersonalData, { isLoading: updatePersonalDataIsLoading }] =
    useUpdatePersonalDataMutation()

  const { loading } = useSelector((state: RootState) => state.layout)
  const { loggedUser, refetchLoggedUser, userRating } = useSelector(
    (state: RootState) => state.userState
  )

  const [uploadedFile, setUploadedFile] = useState<File>()

  const onSubmit = useCallback(
    async (values: GeneralDataTabForm) => {
      updatePersonalData({ ...values, imagem: uploadedFile })
        .unwrap()
        .then(() => successSnackbar('Perfil alterado com sucesso'))
        .then(refetchLoggedUser)
        .then(() => setUploadedFile(undefined))
        .catch(() => {
          warningSnackbar(
            'Erro ao alterar o perfil. Por favor, tente novamente mais tarde'
          )
        })
    },
    [
      updatePersonalData,
      refetchLoggedUser,
      successSnackbar,
      uploadedFile,
      warningSnackbar,
    ]
  )

  const {
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    values,
  } = useFormik({
    validationSchema,
    initialValues: INITIAL_VALUES,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  })

  const getNumberFormat = useCallback(() => {
    const country = COUNTRIES.find((item) => item.code === values.pais)

    if (!country) return '(##) #####-####'

    return `+${country.phone} (##) #####-####`
  }, [values.pais])

  useEffect(() => {
    dispatch(setLoading(updatePersonalDataIsLoading))

    return () => {
      dispatch(setLoading(false))
    }
  }, [dispatch, updatePersonalDataIsLoading])

  useEffect(() => {
    if (!loggedUser) return

    setValues((current) => ({
      ...current,
      ...pick(loggedUser, ['email', 'imagem', 'nome', 'pais']),
      telefone: loggedUser.telefone.replace(/[\(\)-]|\s/g, ''),
    }))
  }, [loggedUser, setValues])

  return (
    <form onSubmit={handleSubmit}>
      <div className="box-all">
        <div className="horinzontal">
          <TextField
            disabled
            label="Email"
            value={loggedUser?.email}
            InputLabelProps={{ shrink: !!loggedUser?.email }}
            margin="dense"
          />

          <TextField
            name="nome"
            label="Nome"
            variant="standard"
            value={values.nome}
            onChange={handleChange}
            error={!!errors.nome}
            helperText={errors.nome}
            margin="dense"
          />

          <Autocomplete
            renderOption={(props, option) => {
              const country = COUNTRIES.find((item) => item.code === option)

              if (!country) return <></>

              return (
                <Box
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {country.label} ({country.code}) +{country.phone}
                </Box>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="País"
                name="pais"
                variant="standard"
                error={!!errors.pais}
                helperText={errors.pais}
                margin="dense"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: values.pais && (
                    <InputAdornment position="start">
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${values.pais.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${values.pais.toLowerCase()}.png 2x`}
                        alt=""
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            value={values.pais}
            autoHighlight
            getOptionLabel={(option) =>
              COUNTRIES.find((item) => item.code === option)?.label ?? ''
            }
            onChange={(_, newValue) =>
              setFieldValue('pais', newValue || undefined)
            }
            options={COUNTRIES.map((item) => item.code)}
          />

          <PatternFormat
            customInput={TextField}
            label="Whatsapp | Telefone"
            type="tel"
            variant="standard"
            name="telefone"
            value={values.telefone}
            onValueChange={({ value }) => setFieldValue('telefone', value)}
            error={!!errors.telefone}
            helperText={errors.telefone}
            margin="dense"
            format={getNumberFormat()}
            valueIsNumericString
          />
        </div>

        <div className="align">
          <label className="foto" htmlFor="input-img">
            <img
              className={`img_profile ${errors.imagem ? 'error' : 'normal'}`}
              src={values.imagem ? values.imagem : blankProfile}
            />
            <img className="camera-icon" src={cameraImg} />
          </label>

          <input
            type="file"
            accept="image/*"
            id="input-img"
            className="no-hidden"
            onChange={(event) => {
              if (!event.target.files) return

              setFieldValue(
                'imagem',
                URL.createObjectURL(event.target.files[0])
              )

              setUploadedFile(event.target.files[0])
            }}
          />

          <Rating
            name="read-only"
            value={userRating}
            readOnly
            icon={<StarIcon style={{ fontSize: '24px', color: 'gold' }} />}
            emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />}
          />
          <label className="helper-box">
            {errors.imagem && 'Foto é obrigatória'}
          </label>
        </div>
      </div>

      <Button
        loading={loading || updatePersonalDataIsLoading}
        type={'submit'}
        buttonType="primary"
        style={{ width: '25%' }}
      >
        Atualizar
      </Button>
    </form>
  )
}

export default GeneralDataTab
