import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import MailIcon from '@mui/icons-material/Mail'

const drawerWidth = 160

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

export const AppBar = styledMUI(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 5,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: 'white',
  ...(open && {
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export const HeaderToolbar = styled(Toolbar)`
  justify-content: space-between;
  padding-left: 100px !important;
  background-color: ${({ theme }) => theme.colors.primary.default};

  .toolBarOptions {
    color: white;
  }
  .barPerfil {
    display: grid;
    justify-items: center;
  }
`
export const StarBorderIconBadge = styled(StarBorderIcon)`
  color: ${({ theme }) => theme.colors.base.thin};
  cursor: pointer;
`

export const AssignmentTurnedInIconBadge = styled(AssignmentTurnedInIcon)`
  color: ${({ theme }) => theme.colors.base.thin};
  cursor: pointer;
`

export const MailIconBadge = styled(MailIcon)`
  color: ${({ theme }) => theme.colors.base.thin};
  cursor: pointer;
`

export const LinkTutorial = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 0.5px solid white;
  border-radius: 4px;
`
