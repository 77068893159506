import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

type Props = {
	isOpen: boolean;
	onClose: () => void;
}

const InactivityBlocker = ({ isOpen, onClose }: Props) => {
	const navigate = useNavigate();

	return (
		<Dialog open={isOpen}>
			<DialogTitle>
				Bloqueio por inatividade
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<p>Prezado recrutador, identificamos que não houveram publicações de vagas nos últimos 7 dias.</p>
					<p>Para voltar a fazer buscar pelos profissionais dentro da plataforma, publique pelo menos uma nova vaga, tornando a plataforma sempre ativa com cada vez mais profissionais disponíveis.</p>
					<p style={{ marginTop: '2rem' }}>A JOB Connect agradece a parceria e desejamos uma ótima experiência!</p>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancelar</Button>
				<Button variant='contained' onClick={() => navigate('/oportunidades/create')} autoFocus>
					Cadastrar Vaga
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default InactivityBlocker;