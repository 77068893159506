import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Stack } from '@mui/material'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'

import { useGetFavoriteVacanciesQuery } from '../../../../store/FavoriteVacancies'
import { VacancyItem } from '../VacancyItem'
import { JobResponse } from 'types'
import JobDetails from 'components/Molecules/JobDetails'
import { setLoading } from 'store/Layout/slice'
import SkeletonJob from 'components/Molecules/SkeletonJob'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import Pagination from 'components/Organisms/Pagination'
import { PageParam, PageSizeParam } from 'utils/Constants'
import * as S from './styles'
import { isEmpty } from 'lodash'
import { Column, JobList, Row } from '../style'

export default function FavoriteVacancies() {
  const dispatch = useDispatch()
  const { warningSnackbar } = useCustomSnackbar()
  const [selectedJob, setSelectedJob] = useState<JobResponse>()
  const { data, isLoading, isSuccess, refetch, status } =
    useGetFavoriteVacanciesQuery('')

  const [query, setQuery] = useQueryParams({
    h: StringParam,
    p: PageParam,
    ps: PageSizeParam,
    t: NumberParam,
  })
  const { h: selectedJobHashcod, p: page, ps: pageSize, t: selectedTab } = query

  const [needToScroll, setNeedToScroll] = useState(!!selectedJobHashcod)

  const paginatedData = useMemo(() => {
    return data?.slice(page * pageSize, (page + 1) * pageSize) ?? []
  }, [data, page, pageSize])

  useEffect(() => {
    if (isLoading || !data) return

    if (isEmpty(data)) return setSelectedJob(undefined)

    if (selectedJobHashcod) {
      const foundItem = data.find(
        ({ get_projeto }) => get_projeto.hashcod === selectedJobHashcod
      )?.get_projeto

      if (foundItem) setSelectedJob(foundItem)
      else {
        setSelectedJob(data[0]?.get_projeto)
      }
    } else {
      setSelectedJob(data[0]?.get_projeto)
    }
  }, [isLoading, selectedJobHashcod, data, warningSnackbar])

  useEffect(() => {
    setQuery({ h: selectedJob?.hashcod })
  }, [selectedJob, setQuery])

  useEffect(() => {
    if (needToScroll && isSuccess && selectedJobHashcod) {
      document
        .getElementById(selectedJobHashcod)
        ?.scrollIntoView({ behavior: 'smooth' })

      setNeedToScroll(false)
    }
  }, [isSuccess, needToScroll, selectedJobHashcod])

  useEffect(() => {
    return () => setQuery({ h: undefined, p: undefined, ps: undefined })
  }, [setQuery])

  useEffect(refetch, [refetch, selectedTab])

  useEffect(() => {
    dispatch(setLoading(isLoading || status === 'pending'))
  }, [dispatch, isLoading, status])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [selectedJob])

  if (!data?.length) {
    return (
      <Box style={{ color: 'black' }}>
        Você ainda não possui vagas favoritadas.
      </Box>
    )
  }

  return (
    <>
      <Row>
        {isLoading || status === 'pending' ? (
          <SkeletonJob />
        ) : (
          <Column>
            <JobList>
              {paginatedData.map((item) => (
                <VacancyItem
                  id={item.get_projeto.hashcod}
                  key={item.hashcod}
                  item={item.get_projeto}
                  onClick={() => setSelectedJob(item.get_projeto)}
                  isSelected={selectedJob == item.get_projeto}
                />
              ))}
            </JobList>
          </Column>
        )}

        {status !== 'pending' && selectedJob && (
          <JobDetails
            job={selectedJob}
            dataJob={data?.map((item) => item.get_projeto)}
            refetchData={refetch}
            hasBtnApplyJob={true}
            index={
              data?.findIndex(
                (item) => item.get_projeto.hashcod === selectedJob.hashcod
              ) as number
            }
          />
        )}
      </Row>

      <Pagination
        count={data?.length ?? 0}
        page={page}
        onPageChange={(_, newPage) => setQuery({ p: newPage })}
        onRowsPerPageChange={(event) =>
          setQuery({ p: 0, ps: Number(event.target.value) })
        }
        rowsPerPage={pageSize}
      />
    </>
  )
}
