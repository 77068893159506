import React from 'react'
import { AuthTemplate } from 'components'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'

import AdditionalServicesTable from 'data/components/AdditionalServicesTable'

const AdditionalServices: React.FC = () => {
	return (
		<div className="box-container">
			<div className="box-container-header flex-box">
				<AddBoxOutlinedIcon />
				<p>Serviços adicionais para minha empresa</p>
			</div>
			<div>
				<p style={{ color: 'black', fontSize: '19px' }}>
					Serviços adicionais que podem ser contratados para alterar informações
					sobre a sua empresa no contrato social, tais como adicionar sócios,
					mudança de endereço, alteração de atividade.
				</p>
				<p style={{ color: 'black', fontSize: '19px' }}>
					Além disso, também oferecemos documentos, relatórios, consultorias e
					regularização de pendências.
				</p>
			</div>
			<AdditionalServicesTable />
		</div>
	)
}

export default AdditionalServices
