import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { StorageGetItem, StorageSetItem } from '../../utils/Storage'
import { setUser } from './UserSlice'
import { IAuth } from '../../utils/Interfaces'

import {
  APP_STORAGE_PREFIX,
  APP_API_URI,
  USER_TYPE_VERIFY,
  APP_PREFIX,
} from '../../utils/Constants'

export const userApi = createApi({
  reducerPath: `${APP_PREFIX}/User/userApi`,
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_API_URI}`,
    prepareHeaders: (headers, { getState }) => {
      const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))
      if (token) {
        headers.set('Authorization', `JWT ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    verifyType: builder.query<IAuth, null>({
      query() {
        return {
          url: `${USER_TYPE_VERIFY}`,
          method: 'GET',
        }
      },
      transformResponse: (response: any) => {
        return response.result ?? []
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const user: Readonly<IAuth> = {
            email: '',
            role: '',
          }
          const result = data
          if (data) {
            StorageSetItem(
              `${APP_STORAGE_PREFIX}authType`,
              JSON.stringify(data)
            )
            Object.assign(user, { role: result })
          }
          dispatch(setUser(user))
        } catch (error) {}
      },
    }),
  }),
})
