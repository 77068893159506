import { useCallback, useState } from 'react'
import { useGetApplicationsQuery, useGetCandidaturasQuery } from 'store/WeInvitateVacanciesJob'

const useJobApplicationModal = (hashcode: string) => {
	const [status, setStatus] = useState('')
	const { data: applicantsList, refetch } = useGetCandidaturasQuery({
		hashcode,
		status,
	})
	const [selectedProfessional, setSelectedProfessional] = useState<
		number | null
	>(null)

	const handleSelectStatus = useCallback(
		(selectedStatus: string) => setStatus(selectedStatus),
		[]
	)

	const handleSelectProfessional = useCallback(
		(value: number | null) => () => {
			setSelectedProfessional(value)
		},
		[]
	)

	return {
		applicantsList,
		handleSelectProfessional,
		handleSelectStatus,
		selectedProfessional,
		status,
		updateApplicants: refetch,
	} as const
}

export { useJobApplicationModal }
