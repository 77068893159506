/*
 *  ENUMS GLOBALS
 */

/*
 *  SIDEBARTABTYPES
 */
export enum SideBarTabTypes {
  HOME = 'home',
  PERFIL_USER_CONTRATADO = 'perfil_contratado',
  VAGAS = 'vacancies',
  FINDYOURJOBS = 'findYourVacancies',
  INVITATION = 'invitation',
  CALENDAR = 'calendar',
  RATING = 'rating',
  TICKET = 'ticket',
  FISCAL_NOTE = 'fiscal_note',
  ACCOUNTING = 'accounting',
  PLANO_CONTABILIDADE = 'plano_contabilidade',
  ADQUIRA_CERTIFICADO = 'adquira_certificado',
  PROMOTION = 'promotion',
  WHATSAPP = 'whatsapp',
  ADDITIONAL_SERVICES = 'additional_services',
  PLANS_CONTRATADO = 'plans_contratado',
  PLANS_CONTRATANTE = 'plans_contratante',
}

/*
 *  ROLES
 */
export enum Role {
  COMPANY = 'CONTRATANTE',
  PROFESSIONAL = 'CONTRATADO',
  ADMIN = 'ADMINISTRADOR',
  EMPTY = '',
}
