import { RepublishJobForm } from './types'

export const INITIAL_VALUES: RepublishJobForm = {
  finalDate: null,
  submissionLimit: null,
}

export const STATUS = {
  '': 'Todas',
  P: 'Em Análise',
  A: 'Aprovado',
  F: 'Finalizado',
  R: 'Recusado',
  AE: 'Aguardando Confirmação do Profissional',
  SC: 'Solicita Saida - Profissional',
  SE: 'Solicita Saida - Recrutador',
  SR: 'Recusa Saida - Recrutador',
  SA: 'Aprova Saida - Recrutador',
}

export const PLANS = {
  0: 2,
  1: 9,
  2: 19,
  3: Number.MAX_SAFE_INTEGER,
}
