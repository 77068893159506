import { Card, CardActions, CardContent, Typography } from '@mui/material'
import { Button } from 'components/Atoms/Button'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { PlanTopic, TopicsWrapper } from './styles'

const PlanCard = ({ data, itemPlano, onClick }: PlanCardProps) => {
	const { infoSnackbar } = useCustomSnackbar()

	const planoAtual = data?.plano_contabilidade
	const planoSolicitado = data?.plano_contabilidade_solicitado

	return (
		<Card
			sx={{
				display: 'flex',
				flexDirection: 'column',
				borderRadius: '28px',
				height: '100%',
			}}
		>
			<h4
				translate="no"
				style={{
					padding: '12px',
					backgroundColor: '#274490',
					textAlign: 'center',
					color: 'white',
					fontSize: '2.125rem',
					fontWeight: 400,
				}}
			>
				{itemPlano.titulo}
			</h4>

			<Typography
				variant="h6"
				color="text.secondary"
				sx={{ marginTop: '8px', padding: '12px' }}
			>
				{itemPlano.subTitulo}
			</Typography>

			<TopicsWrapper>
				{itemPlano.descricao.map((item) => (
					<PlanTopic key={item}>{item}</PlanTopic>
				))}
			</TopicsWrapper>

			<Typography
				variant="h6"
				color="text.secondary"
				sx={{ textAlign: 'center' }}
			>
				{itemPlano.preco}
			</Typography>

			<CardActions
				sx={{
					width: '100%',
					display: 'block',
					padding: '18px',
					mt: 'auto',
				}}
			>
				{planoAtual === itemPlano.plano_contabilidade_solicitado && (
					<Button
						style={{
							width: '100%',
							border: 'none',
							borderTopLeftRadius: '24px',
							borderBottomRightRadius: '24px',
						}}
						buttonType="success"
						onClick={() => infoSnackbar('Esse já é o seu plano atual')}
					>
						Plano atual
					</Button>
				)}

				{planoSolicitado === itemPlano.plano_contabilidade_solicitado &&
					planoSolicitado !== planoAtual && (
						<Button
							style={{
								width: '100%',
								border: 'none',
								borderTopLeftRadius: '24px',
								borderBottomRightRadius: '24px',
							}}
							buttonType="warning"
							onClick={() => infoSnackbar('Você já solicitou este plano')}
						>
							Plano solicitado
						</Button>
					)}

				{itemPlano.plano_contabilidade_solicitado !== planoSolicitado &&
					itemPlano.plano_contabilidade_solicitado !== planoAtual && (
						<Button
							style={{
								width: '100%',
								marginTop: 'auto',
								border: 'none',
								borderTopLeftRadius: '24px',
								borderBottomRightRadius: '24px',
							}}
							buttonType="primary"
							onClick={onClick}
						>
							Solicitar plano
						</Button>
					)}
			</CardActions>
		</Card>
	)
}

interface PlanCardProps {
	data: any
	itemPlano: any
	onClick: () => void
}

export default PlanCard
