import MaterialModal from '../MaterialModal'
import './styles.scss'

import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { AdditionalServiceListModel } from 'domain/models/additionalService'

interface RequestServiceModalProps {
  open: boolean
  onConfirm?: any
  closeModal: any
  service: AdditionalServiceListModel
}

const defaultRequestForm = {
  email: '',
  phone: '',
}

const RequestServiceModal: React.FC<RequestServiceModalProps> = ({
  onConfirm,
  open = false,
  service = {},
  closeModal = () => {},
}) => {
  const [requestForm, setRequestForm] = useState(defaultRequestForm)

  const handleChangeField = (field: string, event: any) => {
    setRequestForm({ ...requestForm, [field]: event.target.value })
  }

  const getModalBody = () => {
    return (
      <div className="service-request">
        <div className="service-request-header">
          <h1>Solicitação de serviço</h1>
        </div>
        <div className="service-request-body">
          <div className="service-request-message">
            Você está solicitando o serviço <strong>{service?.service}</strong>
          </div>
          <div className="service-request-message-line">
            Antes de prosseguir, informe um telefone para contato:
          </div>
          <TextField
            id="telefone"
            value={requestForm?.phone}
            label="Telefone"
            placeholder="Insira o telefone para contato"
            onChange={(e) => handleChangeField('phone', e)}
          />
        </div>
      </div>
    )
  }

  const resetRequestForm = () => {
    setRequestForm(defaultRequestForm)
  }

  const handleConfirmWelcome = () => {
    onConfirm && onConfirm(requestForm)
    resetRequestForm()
    closeModal()
  }

  const modalProps = {
    open,
    onClose: closeModal,
    confirmText: 'Solicitar',
    children: getModalBody(),
    onConfirm: handleConfirmWelcome,
    blockConfirm: requestForm?.phone?.length > 10 ? false : true,
    containerCustomClassName: 'service-request-modal-container',
  }

  return <MaterialModal {...modalProps} />
}

export default RequestServiceModal
