export const getStateUf = (state) => {
  switch (state) {
    case 'Acre':
      return 'AC'
    case 'Alagoas':
      return 'AL'
    case 'Amapá':
      return 'AP'
    case 'Amazonas':
      return 'AM'
    case 'Bahia':
      return 'BA'
    case 'Ceará':
      return 'CE'
    case 'Distrito Federal':
      return 'DF'
    case 'Espírito Santo':
      return 'ES'
    case 'Goiás':
      return 'GO'
    case 'Maranhão':
      return 'MA'
    case 'Mato Grosso':
      return 'MT'
    case 'Mato Grosso do Sul':
      return 'MS'
    case 'Minas Gerais':
      return 'MG'
    case 'Pará':
      return 'PA'
    case 'Paraíba':
      return 'PB'
    case 'Paraná':
      return 'PR'
    case 'Pernambuco':
      return 'PE'
    case 'Piauí':
      return 'PI'
    case 'Rio de Janeiro':
      return 'RJ'
    case 'Rio Grande do Norte':
      return 'RN'
    case 'Rio Grande do Sul':
      return 'RS'
    case 'Rondônia':
      return 'RO'
    case 'Roraima':
      return 'RR'
    case 'Santa Catarina':
      return 'SC'
    case 'São Paulo':
      return 'SP'
    case 'Sergipe':
      return 'SE'
    case 'Tocantins':
      return 'TO'
    default:
      return state
  }
}

export const getStateByUf = (uf: string) => {
  switch (uf) {
    case 'AC':
      return 'Acre'
    case 'AL':
      return 'Alagoas'
    case 'AP':
      return 'Amapá'
    case 'AM':
      return 'Amazonas'
    case 'BA':
      return 'Bahia'
    case 'CE':
      return 'Ceará'
    case 'DF':
      return 'Distrito Federal'
    case 'ES':
      return 'Espírito Santo'
    case 'GO':
      return 'Goiás'
    case 'MA':
      return 'Maranhão'
    case 'MT':
      return 'Mato Grosso'
    case 'MS':
      return 'Mato Grosso do Sul'
    case 'MG':
      return 'Minas Gerais'
    case 'PA':
      return 'Pará'
    case 'PB':
      return 'Paraíba'
    case 'PR':
      return 'Paraná'
    case 'PE':
      return 'Pernambuco'
    case 'PI':
      return 'Piauí'
    case 'RJ':
      return 'Rio de Janeiro'
    case 'RN':
      return 'Rio Grande do Norte'
    case 'RS':
      return 'Rio Grande do Sul'
    case 'RO':
      return 'Rondônia'
    case 'RR':
      return 'Roraima'
    case 'SC':
      return 'Santa Catarina'
    case 'SP':
      return 'São Paulo'
    case 'SE':
      return 'Sergipe'
    case 'TO':
      return 'Tocantins'
    default:
      return uf
  }
}
