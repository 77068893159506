import { useEffect, useMemo } from 'react'
import { FormikConfig, useFormik } from 'formik'
import {
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { pick } from 'lodash'

import {
  useAddUserExperienceMutation,
  useDeleteExperienceMutation,
  useGetUserExperienciesQuery,
  useGetOperationAreaLevelOptionsQuery,
  useGetOperationAreaOptionsQuery,
} from 'store/PersonalData'
import * as C from 'components/Atoms/Button'
import * as S from './styles'
import { setLoading } from 'store/Layout/slice'
import { ExperienceRequest } from 'types'
import { DEFAULT_VALUES } from './constants'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import schemaValidation from './validations'
import '../../../styles.scss'
import { RootState } from 'store'

const ExperienceTab = () => {
  const dispatch = useDispatch()
  const loading = useSelector((state: RootState) => state.layout.loading)
  const { warningSnackbar, successSnackbar } = useCustomSnackbar()
  const {
    data: userExperiencies,
    isLoading: getExperienceIsLoading,
    refetch,
  } = useGetUserExperienciesQuery()

  const {
    data: operationAreaOptions,
    isLoading: operationsAreaOptionsIsLoading,
  } = useGetOperationAreaOptionsQuery()
  const {
    data: operationAreaLevelOptions,
    isLoading: operationsAreaLevelOptionsIsLoading,
  } = useGetOperationAreaLevelOptionsQuery()
  const [addUserExperience, { isLoading: addUserExperienceIsLoading }] =
    useAddUserExperienceMutation()
  const [deleteExperience, { isLoading: deleteExperienceIsLoading }] =
    useDeleteExperienceMutation()

  const onSubmit: FormikConfig<ExperienceRequest>['onSubmit'] = (
    values,
    { resetForm }
  ) => {
    addUserExperience(values)
      .unwrap()
      .then(() => successSnackbar('Experiência adicionada com sucesso'))
      .then(refetch)
      .then(() => resetForm())
      .catch(() => {
        warningSnackbar(
          'Erro ao adicionar experiência. Por favor, tente novamente mais tarde'
        )
      })
  }

  const { values, handleSubmit, setFieldValue, errors, setValues } =
    useFormik<ExperienceRequest>({
      validationSchema: schemaValidation,
      enableReinitialize: true,
      initialValues: DEFAULT_VALUES,
      onSubmit,
      validateOnChange: false,
    })

  const specializations = useMemo(() => {
    if (!operationAreaOptions || !values.areaatuacao) return []

    const area = operationAreaOptions?.find(
      (item) => item.hashcod === values.areaatuacao
    )

    return (
      area?.modulo_areaatuacao.filter((specialization) =>
        userExperiencies?.every(
          (experience) => experience.moduloatuacao !== specialization.hashcod
        )
      ) ?? []
    )
  }, [operationAreaOptions, userExperiencies, values.areaatuacao])

  const handleDeleteExperience = (hash: string) => {
    deleteExperience(hash)
      .unwrap()
      .then(() => successSnackbar('Experiência excluída com sucesso'))
      .then(refetch)
      .catch(() => {
        warningSnackbar(
          'Erro ao excluir experiência. Por favor, tente novamente mais tarde'
        )
      })
  }

  useEffect(() => {
    dispatch(
      setLoading(
        getExperienceIsLoading ||
          operationsAreaOptionsIsLoading ||
          operationsAreaLevelOptionsIsLoading ||
          addUserExperienceIsLoading ||
          deleteExperienceIsLoading
      )
    )

    return () => {
      dispatch(setLoading(false))
    }
  }, [
    dispatch,
    getExperienceIsLoading,
    operationsAreaOptionsIsLoading,
    operationsAreaLevelOptionsIsLoading,
    addUserExperienceIsLoading,
    deleteExperienceIsLoading,
  ])

  return (
    <form onSubmit={handleSubmit}>
      <div className="horinzontal">
        <Card>
          <CardContent>
            <Typography variant="h6" component="div">
              Adicionar experiência
            </Typography>

            <div className="row">
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Área de atuação"
                    variant="standard"
                    error={!!errors.areaatuacao}
                    helperText={errors.areaatuacao}
                    margin="dense"
                  />
                )}
                style={{ width: '32%' }}
                value={values.areaatuacao}
                autoHighlight
                getOptionLabel={(option) =>
                  operationAreaOptions?.find((item) => item.hashcod === option)
                    ?.descricao ?? ''
                }
                onChange={(_, newValue) => {
                  setFieldValue(`areaatuacao`, newValue)
                }}
                options={
                  operationAreaOptions?.map((item) => item.hashcod) ?? []
                }
              />

              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Especialização"
                    error={!!errors.moduloatuacao}
                    helperText={errors.moduloatuacao}
                    margin="dense"
                  />
                )}
                style={{ width: '32%' }}
                value={values.moduloatuacao}
                disabled={!values.areaatuacao}
                autoHighlight
                getOptionLabel={(option) =>
                  specializations?.find((item) => item.hashcod === option)
                    ?.descricao ?? ''
                }
                onChange={(_, newValue) =>
                  setFieldValue(`moduloatuacao`, newValue)
                }
                options={specializations?.map((item) => item.hashcod) ?? []}
              />

              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Nível de experiência"
                    error={!!errors.nivel}
                    helperText={errors.nivel}
                    margin="dense"
                  />
                )}
                style={{ width: '32%' }}
                value={values.nivel}
                autoHighlight
                getOptionLabel={(option) =>
                  operationAreaLevelOptions?.find(
                    (item) => item.hashcod === option
                  )?.descricao ?? ''
                }
                onChange={(_, newValue) => setFieldValue(`nivel`, newValue)}
                options={
                  operationAreaLevelOptions?.map((item) => item.hashcod) ?? []
                }
              />
            </div>

            <div className="row">
              <NumericFormat
                customInput={TextField}
                style={{ width: '32%' }}
                variant="standard"
                label="Projetos realizados (Quantidade)"
                value={values.projetos_executados}
                onValueChange={({ floatValue }) =>
                  setFieldValue(`projetos_executados`, floatValue)
                }
                error={!!errors.projetos_executados}
                helperText={errors.projetos_executados}
                margin="dense"
                decimalScale={0}
                allowNegative={false}
                placeholder={'Digitar (1,2,3,4,5,6)'}
              />

              <NumericFormat
                customInput={TextField}
                style={{ width: '32%' }}
                label="Valor da hora"
                variant="standard"
                value={values.valor_hora.replace('.', ',')}
                onValueChange={({ value }) =>
                  setFieldValue(`valor_hora`, value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                error={!!errors.valor_hora}
                helperText={errors.valor_hora}
                margin="dense"
                decimalScale={2}
                fixedDecimalScale
                decimalSeparator=","
                thousandSeparator="."
                allowNegative={false}
              />

              <div style={{ minWidth: '32%' }} />
            </div>
          </CardContent>

          <CardActions>
            <C.Button loading={loading} type={'submit'} buttonType="primary">
              Adicionar
            </C.Button>

            {(values.areaatuacao ||
              values.moduloatuacao ||
              values.nivel ||
              values.projetos_executados ||
              values.valor_hora) && (
              <C.Button
                onClick={() => {
                  setValues(DEFAULT_VALUES)
                }}
                buttonType="primary"
              >
                Limpar
              </C.Button>
            )}
          </CardActions>
        </Card>
      </div>

      {userExperiencies && (
        <>
          <S.SelectExperienceInfo>
            Selecione uma experiência para atualizar:
          </S.SelectExperienceInfo>

          <S.Container>
            {userExperiencies?.map((experience, index) => (
              <Card
                key={index}
                onClick={() => {
                  setValues(
                    pick(experience, [
                      'areaatuacao',
                      'moduloatuacao',
                      'nivel',
                      'valor_hora',
                      'projetos_executados',
                    ])
                  )
                }}
              >
                <CardHeader
                  action={
                    <IconButton
                      onClick={() => handleDeleteExperience(experience.hashcod)}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                  title={`Experiência ${index + 1}`}
                  titleTypographyProps={{ variant: 'h6' }}
                />

                <CardContent>
                  <S.RowDivider>
                    <S.ColumnDivider>
                      <S.CardTextInfos>
                        {experience.get_areaatuacao}
                      </S.CardTextInfos>

                      <S.CardTextInfos>
                        {experience.get_moduloatuacao}
                      </S.CardTextInfos>
                    </S.ColumnDivider>

                    <S.ColumnDivider>
                      <S.CardTextInfos>{experience.get_nivel}</S.CardTextInfos>

                      <S.CardTextInfos>
                        {`R$ ${experience.valor_hora}/h`}
                      </S.CardTextInfos>
                    </S.ColumnDivider>

                    <S.ColumnDivider>
                      <S.CardTextInfos style={{ alignSelf: 'center' }}>
                        {experience.projetos_executados}
                      </S.CardTextInfos>

                      <S.CardTextInfos>Projetos</S.CardTextInfos>
                    </S.ColumnDivider>
                  </S.RowDivider>
                </CardContent>
              </Card>
            ))}
          </S.Container>
        </>
      )}
    </form>
  )
}

export default ExperienceTab
