import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from '../actions'

interface weInvitateVacanciesJobStore {
  invitateQuantity?: number
}

export const initialState: weInvitateVacanciesJobStore = {
  invitateQuantity: undefined,
}

export const WeInvitateVacanciesJobSlice = createSlice({
  name: 'weInvitateVacanciesJob',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    setWeInvitateVacanciesJobQuantity: (
      state,
      action: PayloadAction<number>
    ) => {
      state.invitateQuantity = action?.payload
    },
  },
})

export const { setWeInvitateVacanciesJobQuantity } =
  WeInvitateVacanciesJobSlice.actions

export default WeInvitateVacanciesJobSlice.reducer
