import { useEffect, type FC } from 'react'
import { useFormik } from 'formik'
import { INVOICING } from 'utils/Constants'
import { TSchemaValidation, validation_schema, initial_values } from './schema'
import { InputField } from 'components/Atoms/InputField'
import { Select } from 'components/Atoms/Select'
import { Button, CircularProgress, Grid } from '@mui/material'

import './styles.scss'
import {
  useGetNodeCompanyByEmailQuery,
  usePostNodeCompanyMutation,
  usePutNodeCompanyMutation,
} from 'store/NodeApi/company/endpoint'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setNodeCompany } from 'store/NodeApi/slice'
import { PatternFormat } from 'react-number-format'
import { cnpj } from 'cpf-cnpj-validator'

const Company: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state?.userState?.loggedUser)
  const [store, { isLoading: storeIsLoading }] = usePostNodeCompanyMutation()
  const [update, { isLoading: updateIsLoading }] = usePutNodeCompanyMutation()
  const { data: company, refetch } = useGetNodeCompanyByEmailQuery(
    user?.email!,
    {
      skip: !user?.email!,
    }
  )

  const isLoading = storeIsLoading || updateIsLoading

  const storeCompany = (form: TSchemaValidation) => {
    if (!company) {
      return store(form)
        .unwrap()
        .then((response) => {
          toast.success(
            'Primeiro passo foi dado! Preencha o restante das abas.'
          )
          dispatch(setNodeCompany(response))
          refetch()
        })
        .catch(() =>
          toast.error(
            'Não foi possível fazer o cadastro dessa empresa, tente novamente mais tarde.'
          )
        )
    }

    update({
      cnpj: cnpj.strip(form.cnpj),
      company_billing: form.company_billing,
      email: form.email,
    })
      .unwrap()
      .then((response) => {
        toast.success('Dados atualizados com sucesso.')
        dispatch(setNodeCompany(response))
        refetch()
      })
      .catch(() =>
        toast.error(
          'Não foi possível atualizar o cadastro dessa empresa, tente novamente mais tarde.'
        )
      )
  }

  const { values, handleChange, errors, handleSubmit, ...formik } =
    useFormik<TSchemaValidation>({
      enableReinitialize: true,
      validationSchema: validation_schema,
      onSubmit: storeCompany,
      initialValues: { ...initial_values, ...company },
    })

  useEffect(() => {
    if (!user?.email) return
    formik.setFieldValue('email', user.email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email])

  return (
    <form className="form__company" onSubmit={handleSubmit}>
      <div style={{ flex: 1 }}>
        <Grid container mt={0}>
          <Grid item xs={12} md={4}>
            <PatternFormat
              label="CNPJ"
              name="cnpj"
              value={values.cnpj}
              onChange={handleChange}
              helperText={errors.cnpj}
              customInput={InputField}
              format="##.###.###/####-##"
            />
          </Grid>
        </Grid>
        <Grid container mt={!!errors.email ? 1 : 1.5}>
          <Grid item xs={12} md={4}>
            <InputField
              label="E-mail"
              name="email"
              value={values.email}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={12} md={4}>
            <Select
              list={INVOICING.map((value) => ({ label: value, value }))}
              value={values.company_billing}
              onChange={handleChange}
              label="Faturamento"
              name="company_billing"
              helperText={errors.company_billing}
            />
          </Grid>
        </Grid>
      </div>
      <Button
        variant="contained"
        className="form__submit"
        type="submit"
        endIcon={isLoading && <CircularProgress size={20} />}
        disabled={isLoading}
      >
        {company && !isLoading ? 'Atualizar' : 'Cadastrar'}
      </Button>
    </form>
  )
}

export default Company
