import { useEffect, useState } from 'react'
import { TextareaAutosize } from '@mui/material'
import { useFormik } from 'formik'

import validationSchema from './validations'

import * as C from 'components/Atoms/Button'
import '../../../styles.scss'
import { INITIAL_VALUES } from './constants'
import { useUpdatePersonalDataMutation } from 'store/PersonalData'
import { pick } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from 'store/Layout/slice'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { DescriptionTabForm } from './types'
import { RootState } from 'store'

const DescriptionTab = () => {
  const { warningSnackbar, successSnackbar } = useCustomSnackbar()
  const dispatch = useDispatch()
  const [uploadedFile, setUploadedFile] = useState<File>()

  const { loading } = useSelector((state: RootState) => state.layout)
  const { loggedUser, refetchLoggedUser } = useSelector(
    (state: RootState) => state.userState
  )

  const [editUser, { isLoading: editPersonalDataIsLoading }] =
    useUpdatePersonalDataMutation()

  const onSubmit = async (values: DescriptionTabForm) => {
    editUser({ ...values, curriculo_doc: uploadedFile })
      .unwrap()
      .then(() => {
        successSnackbar('Descrição alterada com sucesso')
      })
      .then(() => setUploadedFile(undefined))
      .then(refetchLoggedUser)
      .catch(() => {
        warningSnackbar(
          'Erro ao alterar descrição. Tente novamente mais tarde.'
        )
      })
  }

  const { values, handleSubmit, handleChange, errors, setValues } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  })

  useEffect(() => {
    if (!loggedUser) return

    setValues((current) => ({
      ...current,
      ...pick(loggedUser, ['email', 'descricao']),
    }))
  }, [loggedUser, setValues])

  useEffect(() => {
    dispatch(setLoading(editPersonalDataIsLoading))

    return () => {
      dispatch(setLoading(false))
    }
  }, [dispatch, editPersonalDataIsLoading])

  return (
    <form onSubmit={handleSubmit}>
      <div className="horizontal">
        <TextareaAutosize
          placeholder="Descrição da empresa"
          style={{
            width: '100%',
            resize: 'vertical',
            borderColor: errors.descricao ? 'red' : '',
          }}
          minRows={7}
          value={values.descricao}
          onChange={handleChange}
          name="descricao"
        />

        {errors.descricao && (
          <label className="helper-box" style={{ minWidth: '100%' }}>
            {`${errors.descricao}`}
          </label>
        )}
      </div>

      <C.Button
        loading={loading || editPersonalDataIsLoading}
        type={'submit'}
        buttonType="primary"
        style={{ width: 200, marginTop: '1.25rem' }}
      >
        Atualizar
      </C.Button>
    </form>
  )
}

export default DescriptionTab
