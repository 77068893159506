import { MouseEventHandler, useState } from 'react'
import { Avatar, Badge, CardProps, styled } from '@mui/material'
import moment from 'moment'
import { intervalToDuration, parseISO, formatDuration } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'

import { JobResponse } from 'types'
import * as S from './styles'

export interface VacancyItemListProps extends CardProps {
  item: JobResponse
  onClick?: MouseEventHandler<HTMLElement>
  isSelected?: boolean
}

export function VacancyItem({
  item,
  onClick,
  isSelected,
  ...props
}: VacancyItemListProps) {
  const [nameHr] = useState('')

  const formatLocal = (cidade: string, estado: string, pais: string) => {
    const cidadeTemp = cidade && !'Todas' ? `${cidade}, ` : ''
    const estadoTemp = estado ? `${estado}, ` : ''
    const paisTemp = pais ? `${pais}` : ''

    return `${cidadeTemp}${estadoTemp}${paisTemp}`
  }

  const getDuration = (dataInicio: string, dataFim: string) => {
    if (!dataInicio || !dataFim) return 'Indeterminado'

    const interval = intervalToDuration({
      start: parseISO(dataInicio),
      end: parseISO(dataFim),
    })

    return formatDuration(interval, {
      format: ['years', 'months', 'days'],
      delimiter: ', ',
      locale: pt,
    })
  }

  return (
    <S.StyledCardList
      variant="outlined"
      onClick={onClick}
      itemScope={isSelected}
      {...props}
    >
      <S.Container key={`vaga-${item.hashcod}`}>
        <S.IconContainer>
          {item.empresa?.imagem ? (
            <StyledBadge
              style={{ marginTop: '5px' }}
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <Avatar
                sx={{ width: 84, height: 84 }}
                alt="Remy Sharp"
                src={item.empresa.imagem}
              />
            </StyledBadge>
          ) : (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <Avatar sx={{ width: 64, height: 64 }}>
                {nameHr?.charAt(0)}
              </Avatar>
            </StyledBadge>
          )}
        </S.IconContainer>

        <S.ColumnDivider>
          <S.TittleJobCard>{item.nome}</S.TittleJobCard>

          <S.RowDivider>
            <S.LabelInfo>{item.empresa.nome} /&nbsp;</S.LabelInfo>
            <S.LabelInfo>
              {formatLocal(item.cidade, item.estado, item.empresa.pais)} /&nbsp;
            </S.LabelInfo>
            {item.tipo_contratacao && (
              <S.LabelInfo> {item.tipo_contratacao}</S.LabelInfo>
            )}
          </S.RowDivider>

          <S.LabelInfo>
            Cadastrada: {moment(item.data_cad).format('DD/MM/yyyy')}
          </S.LabelInfo>

          <S.LabelInfo>
            Submissão: {moment(item.limite_submissao).format('DD/MM/yyyy')}
          </S.LabelInfo>

          <S.LabelInfo>
            Duração: {getDuration(item.data_inicio, item.data_final)}
          </S.LabelInfo>
        </S.ColumnDivider>
      </S.Container>
    </S.StyledCardList>
  )
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))
