import { Skeleton, Paper, Box } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'

const SkeletonJob = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2 container spacing={2}>
        <Grid2 xs={4}>
          <Box>
            <Skeleton variant="rectangular" width={400} height={180} />
            <Skeleton
              variant="rectangular"
              width={400}
              height={180}
              sx={{ marginTop: '24px' }}
            />
            <Skeleton
              variant="rectangular"
              width={400}
              height={180}
              sx={{ marginTop: '24px' }}
            />
            <Skeleton
              variant="rectangular"
              width={400}
              height={180}
              sx={{ marginTop: '24px' }}
            />
          </Box>
        </Grid2>
        <Grid2 xs={8}>
          <Paper sx={{ padding: '12px' }}>
            <Skeleton variant="circular" width={95} height={95} />
            <Skeleton
              variant="rectangular"
              width={180}
              height={28}
              sx={{ borderRadius: '12px', marginTop: '18px' }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: '1rem', marginTop: '18px' }}
            />
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
          </Paper>
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default SkeletonJob
