import { useEffect, useState } from 'react'
import { Tabs, Box } from '@mui/material'

import SearchVacancies from './SearchVacancies'
import FavoriteVacancies from './FavoriteVacancies'
import MyApplications from './MyApplications'
import InProgressVacancies from './InProgressVacancies'
import FinishedVacancies from './FinishedVacancies'
import {
	NumberParam,
	StringParam,
	useQueryParams,
	withDefault,
} from 'use-query-params'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { PageParam, PageSizeParam } from 'utils/Constants'
import * as S from './style'
import ChosenPanel from 'pages/Company/TechJobs/ChosenPanel'

export default function Vacancy() {
	const { warningSnackbar } = useCustomSnackbar()
	const [query, setQuery] = useQueryParams({
		h: StringParam,
		p: PageParam,
		ps: PageSizeParam,
		t: withDefault(NumberParam, 0),
	})
	const { t: selectedTabQP } = query

	const [isSelectedTabQueryParamInvalid] = useState(
		![0, 1, 2, 3, 4].includes(selectedTabQP)
	)
	const [selectedTab, setSelectedTab] = useState(
		isSelectedTabQueryParamInvalid ? 0 : selectedTabQP
	)

	const handleChangeTabs = (_, value: number) => {
		setQuery({ h: undefined, p: undefined, ps: undefined })
		setSelectedTab(value)
		setQuery({ t: value })
	}

	useEffect(() => {
		if (isSelectedTabQueryParamInvalid) {
			setTimeout(() => setQuery({ t: 0 }), 500)

			warningSnackbar('Aba não encontrada')
		}
	}, [isSelectedTabQueryParamInvalid, setQuery, warningSnackbar])

	return (
		<S.Container>
			<S.TabOptionsContainer
				sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
			>
				<Tabs value={selectedTab} onChange={handleChangeTabs}>
					<S.Tab label="Buscar vagas de emprego" />
					<S.Tab label="Favoritas" />
					<S.Tab label="Em andamento" />
					<S.Tab label="Candidaturas" />
					<S.Tab label="Finalizadas" />
				</Tabs>
			</S.TabOptionsContainer>

			<ChosenPanel is={selectedTab}>
				<SearchVacancies />
				<FavoriteVacancies />
				<InProgressVacancies />
				<MyApplications />
				<FinishedVacancies />
			</ChosenPanel>
		</S.Container>
	)
}

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
	return (
		<div role="tabpanel" hidden={value !== index} {...other}>
			{value === index && children}
		</div>
	)
}
