import { useCallback, useState } from 'react'
import { useTrazerProfissionaisSugeridosQuery } from 'store/UserType'

const useJobSuggestionsModal = (hashcode: string) => {
  const [selectedProfessional, setSelectedProfessional] = useState<
    number | null
  >(null)
  const {
    data: suggestedProfessionals,
    refetch: updateSuggestedProfessionals,
  } = useTrazerProfissionaisSugeridosQuery(hashcode)

  const handleSelectProfessional = useCallback(
    (value: number | null) => () => setSelectedProfessional(value),
    []
  )

  return {
    suggestedProfessionals,
    handleSelectProfessional,
    selectedProfessional,
    updateSuggestedProfessionals,
  } as const
}

export { useJobSuggestionsModal }
