import styled from 'styled-components'
import { Tab as TabMUI } from '@mui/material'
import { Box } from '@mui/material'

export const Tab = styled(TabMUI)`
  color: ${({ theme }) => theme.colors.text.paragraph} !important;

  &.Mui-selected {
    color: #1976d2 !important;
  }
`
export const TabOptionsContainer = styled(Box)`
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 10;
`

export const SelectedTabContainer = styled(Box)`
  margin-top: 16px;
`

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

// ----------------------------- //
export const Row = styled.div`
  gap: 20px;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 1rem;
  overflow: auto;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  gap: 1rem;
`

export const JobList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
