import { store } from 'store'
import nodeApi from '../api'
import { setNodeCompany } from '../slice'
import { INodeResponse } from 'types/api'

type TCompany = {
  id: number
  cnpj: string
  address: string
  email: string
  new_company_name: string
  new_company_activities: string
  company_billing: string
  responsibility_start_date: string
  created_at: string
  updated_at: string
  certificate_id: any
}

type TRequest = {
  data: TCompany
  status: string
}

type NodeCompany = {
  id: number
  cnpj: string
  address: string
  email: string
  new_company_name: string
  new_company_activities: string
  company_billing: string
  responsibility_start_date: string
  created_at: string
  updated_at: string
  certificate_id: string
}

const injectedNodeApi = nodeApi.injectEndpoints({
  endpoints: (build) => ({
    postNodeCompany: build.mutation<NodeCompany, any>({
      query: (companyBody) => ({
        method: 'POST',
        url: '/companies',
        body: companyBody,
      }),
      transformResponse: (response: INodeResponse<NodeCompany>) =>
        response?.data,
    }),
    putNodeCompany: build.mutation<NodeCompany, Partial<NodeCompany>>({
      query: (putBody) => ({
        method: 'PATCH',
        url: `/companies`,
        body: putBody,
      }),
      transformResponse: (response: INodeResponse<NodeCompany>) =>
        response?.data,
    }),
    getNodeCompany: build.query<any, any>({
      query: (cnpj) => `/companies/cnpj/${cnpj}`,
      transformResponse: (response: any) => {
        store.dispatch(setNodeCompany(response?.data))
        return response?.data
      },
    }),
    getNodeCompanyByCnpj: build.query<any, any>({
      query: (cnpj) => `/companies/cnpj/${cnpj}`,
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    getNodeCompanyByEmail: build.query<NodeCompany, string>({
      query: (email) => `/companies/email/${email}`,
      transformResponse: (response: INodeResponse<NodeCompany>) => {
        store.dispatch(setNodeCompany(response?.data))
        return response?.data as NodeCompany
      },
    }),
  }),
})

export const {
  useGetNodeCompanyQuery,
  useLazyGetNodeCompanyQuery,
  usePutNodeCompanyMutation,
  usePostNodeCompanyMutation,
  useLazyGetNodeCompanyByEmailQuery,
  useGetNodeCompanyByEmailQuery,
  useGetNodeCompanyByCnpjQuery,
  useLazyGetNodeCompanyByCnpjQuery,
} = injectedNodeApi

export default injectedNodeApi
