import { Button, CircularProgress, TextField, Typography } from '@mui/material'
import { Container, ButtonsContainer } from './styles'
import { useRef, useState } from 'react'
import { useUpdateApplicationMutation } from 'store/WeInvitateVacanciesJob'
import { searchVacanciesRedux } from 'store/SearchVacancies'
import { useDispatch } from 'react-redux'

interface AcceptOrRefuseApplicationProps {
  applicationStatus: string
  hashcode: string
  onDismissProfessional: () => void
}

const AcceptOrRefuseApplication = ({
  applicationStatus,
  onDismissProfessional,
  hashcode,
}: AcceptOrRefuseApplicationProps) => {
  const [isRefuse, setIsRefuse] = useState(false)
  const [reason, setReason] = useState('')
  const dispatch = useDispatch()
  const [updateApplication, { isLoading }] = useUpdateApplicationMutation()
  if (applicationStatus !== 'P') return null

  const handleIsRefuse = (isToRefuse: boolean) => () => {
    setIsRefuse(isToRefuse)
    setReason('')
  }

  const handleApplicationStatus =
    (status: 'R' | 'AE', motivo: string | null = null) =>
    () => {
      updateApplication({
        hashcode,
        status,
        motivo,
      }).then(() => {
        setReason('')
        onDismissProfessional()
        dispatch(searchVacanciesRedux.util.invalidateTags(['Projects']))
      })
    }

  if (isLoading) {
    return (
      <Container>
        <CircularProgress size={24} />
        <Typography>Sua resposta esta sendo enviada</Typography>
      </Container>
    )
  }

  if (isRefuse) {
    return (
      <Container>
        <Typography>Qual o motivo da recusa?</Typography>
        <TextField
          onChange={(event) => setReason(event.target.value)}
          value={reason}
          margin="dense"
          variant="standard"
          size="small"
          placeholder="Escreva o motivo"
        />
        <ButtonsContainer>
          <Button variant="text" onClick={handleIsRefuse(false)} size="small">
            Cancelar
          </Button>
          <Button
            disabled={!reason.length}
            variant="contained"
            size="small"
            style={{ backgroundColor: '#ff4b52' }}
            onClick={handleApplicationStatus('R', reason)}
          >
            Ok
          </Button>
        </ButtonsContainer>
      </Container>
    )
  }

  return (
    <Container>
      <Typography>Deseja aceitar a candidatura?</Typography>
      <ButtonsContainer>
        <Button
          size="small"
          variant="contained"
          style={{ backgroundColor: '#11c26d' }}
          onClick={handleApplicationStatus('AE')}
        >
          Aceitar
        </Button>
        <Button
          size="small"
          variant="contained"
          style={{ backgroundColor: '#ff4b52' }}
          onClick={handleIsRefuse(true)}
        >
          Recusar
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

export default AcceptOrRefuseApplication
