import { useState, createContext, useContext, ReactNode, Dispatch, SetStateAction } from "react";
import defaultTheme from '../styles/theme';
import darkTheme from '../styles/dark';
import { ThemeProvider } from "styled-components";

interface AppThemeContextProps{
  toggleTheme(themeTitle: string): void;
}

const ThemeContext = createContext<AppThemeContextProps>({} as AppThemeContextProps);

interface AppThemeProviderProps {
  children: ReactNode;
}

const AppThemeProvider = ({ children }: AppThemeProviderProps) => {
  const [theme, setTheme] = useState(defaultTheme);
  
  const toggleTheme = (themeTitle: string) => {
    setTheme(themeTitle === "default" ? darkTheme : defaultTheme);
  };
  

  return (
    <ThemeContext.Provider
    value={{ toggleTheme }}
  >
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </ThemeContext.Provider>
  );
};

const useAppTheme = (): AppThemeContextProps => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useAppTheme must be used within a AppThemeProvider");
  }

  return context;
};

export { AppThemeProvider, useAppTheme };
