import React from 'react'

type LogoProps = {
  alt?: string
  src?: string
  width?: string
  height?: string
}

const Logo = (props: LogoProps) => {
  return (
    <img
      src={process.env.PUBLIC_URL + '/assets/images/job-logo.png'}
      alt="logo"
      width={props.width ? props.width : 'auto'}
      height={props.height ? props.height : '100%'}
    />
  )
}

export default Logo
