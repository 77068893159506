import { store } from 'store'
import nodeApi from '../api'
import { setDependents } from '../slice'

const dependentEndpoints = nodeApi.injectEndpoints({
    endpoints: (build) => ({
        postDependent: build.mutation<any, any>({
            query: (partnerBody) => ({
                method: 'POST',
                url: '/dependents',
                body: partnerBody,
            }),
        }),

        getDependents: build.query<any, any>({
            query: (partnerId) => `/dependents/partner/${partnerId}`,
            transformResponse: (response: any) => {
                store.dispatch(setDependents(response?.data))
                return response?.data
            },
        }),

        deleteDependent: build.mutation<any, any>({
            query: (dependentId) => ({
                method: 'DELETE',
                url: `/dependents/${dependentId}`,
            }),
        }),
    }),
})

export const {
    useGetDependentsQuery,
    usePostDependentMutation,
    useLazyGetDependentsQuery,
    useDeleteDependentMutation,
} = dependentEndpoints

export default dependentEndpoints
