import React, { useEffect, useMemo } from 'react'
import List from '@mui/material/List'
import { useNavigate, useLocation } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import LogoutIcon from '@mui/icons-material/Logout'
import { Skeleton, Stack, Box } from '@mui/material'
import './styles.scss'
import {
	selectSideBarState,
	setSelectedSideBarTab,
	showSideBar,
} from '../../../store/Layout/slice'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'

import { Drawer } from './styles'
import { RootState } from '../../../store'

import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux'
import { sideBarMenu } from '../../../utils/Menus'

import { StorageGetItem } from '../../../utils/Storage'
import { APP_STORAGE_PREFIX } from '../../../utils/Constants'

import jobLogo from '../../../assets/images/job-logo-white.png'
import SideBarItem from './SidebarItem'
import { SidebarRoutes } from 'utils/Constants/routes'
import { sysAdmins } from 'data/utils/Admin/entity'

interface MainSidebarProps extends PropsFromRedux { }

const MainSidebar: React.FC<MainSidebarProps> = ({ sideBar, showSideBar }) => {
	const location = useLocation()
	const dispatch = useDispatch()
	const authenticated = JSON.parse(
		StorageGetItem(`${APP_STORAGE_PREFIX}authToken`)
	)
	const navigate = useNavigate()
	const { successSnackbar } = useCustomSnackbar()
	const userRole = useSelector((state: RootState) => state.userState.role)

	const { loggedUser } = useSelector((state: RootState) => state.userState)

	const handleOpenSidebar = () => {
		if (!sideBar?.open) showSideBar(true)
	}

	const handleCloseSidebar = () => {
		if (sideBar?.open) showSideBar(false)
	}

	const FilteredSideBarItems = useMemo(
		() => sideBarMenu.filter((item) => item.roleAccess === userRole),
		[userRole]
	)

	useEffect(() => {
		dispatch(setSelectedSideBarTab(location.pathname as SidebarRoutes))
	}, [dispatch, location.pathname])

	// const isAdmin = loggedUser ? sysAdmins.includes(loggedUser?.email) : false

	return (
		<>
			<Drawer
				PaperProps={{ style: { position: 'static' } }}
				open={sideBar?.open}
				variant="permanent"
				anchor="left"
				className="sidebar-list-container"
				onMouseOver={handleOpenSidebar}
				onMouseOut={handleCloseSidebar}
			>
				<div className="img-container">
					<img src={jobLogo} alt="logo" />
				</div>

				<List>
					{userRole !== '' ? (
						FilteredSideBarItems.map((item, index) => (
							<SideBarItem
								key={index}
								handleCloseSidebar={handleCloseSidebar}
								item={item}
								selected={item.route === sideBar.selected}
							/>
						))
					) : (
						<Stack
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							<Box sx={{ display: 'flex' }}>
								<Skeleton
									variant="circular"
									width={32}
									height={32}
									sx={{ marginRight: '10px' }}
								/>
								<Skeleton variant="text" width={100} height={24} />
							</Box>
							<Box sx={{ display: 'flex' }}>
								<Skeleton
									variant="circular"
									width={32}
									height={32}
									sx={{ marginRight: '10px' }}
								/>
								<Skeleton variant="text" width={100} height={24} />
							</Box>
							<Box sx={{ display: 'flex' }}>
								<Skeleton
									variant="circular"
									width={32}
									height={32}
									sx={{ marginRight: '10px' }}
								/>
								<Skeleton variant="text" width={100} height={24} />
							</Box>
							<Box sx={{ display: 'flex' }}>
								<Skeleton
									variant="circular"
									width={32}
									height={32}
									sx={{ marginRight: '10px' }}
								/>
								<Skeleton variant="text" width={100} height={24} />
							</Box>
							<Box sx={{ display: 'flex' }}>
								<Skeleton
									variant="circular"
									width={32}
									height={32}
									sx={{ marginRight: '10px' }}
								/>
								<Skeleton variant="text" width={100} height={24} />
							</Box>
							<Box sx={{ display: 'flex' }}>
								<Skeleton
									variant="circular"
									width={32}
									height={32}
									sx={{ marginRight: '10px' }}
								/>
								<Skeleton variant="text" width={100} height={24} />
							</Box>
							<Box sx={{ display: 'flex' }}>
								<Skeleton
									variant="circular"
									width={32}
									height={32}
									sx={{ marginRight: '10px' }}
								/>
								<Skeleton variant="text" width={100} height={24} />
							</Box>
						</Stack>
					)}
				</List>

				<Tooltip title="Sair">
					<div style={{ textAlign: 'center' }}>
						<IconButton
							style={{
								color: 'white',
								width: 'fit-content',
								textAlign: 'center',
							}}
							aria-label="sair"
							onClick={() => {
								navigate('/logout')
								successSnackbar('Logout realizado com sucesso')
							}}
						>
							<LogoutIcon>Sair</LogoutIcon>
						</IconButton>
					</div>
				</Tooltip>
			</Drawer>
		</>
	)
}

const mapState = (state: RootState) => ({
	sideBar: selectSideBarState(state),
})

const mapDispatch = {
	showSideBar,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(MainSidebar)
