import './styles.scss'

import React from 'react'
import AddTaskIcon from '@mui/icons-material/AddTask'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'

export interface AdditionalServiceFormProps {
  errorField?: string
  requestedServiceForm: any
  setRequestedServiceForm: any
  handeEditServiceForm?: any
}

const RequestedServiceForm: React.FC<AdditionalServiceFormProps> = ({
  requestedServiceForm,
  handeEditServiceForm,
  setRequestedServiceForm = () => {},
}) => {
  const handleChangeServiceForm = (field: string, value: any) => {
    setRequestedServiceForm({ ...requestedServiceForm, [field]: value })
  }

  return (
    <div className="requested-service-form box-container">
      <div className="box-container-header flex-box">
        <AddTaskIcon />
        <p>Editar solicitação de serviço</p>
      </div>
      <div className="requested-service-form-body flex-box">
        <div className="requested-service-name-email">
          <TextField
            id="name"
            variant="standard"
            fullWidth
            label="Nome"
            value={requestedServiceForm?.name}
            disabled={true}
          />
          <TextField
            id="email"
            variant="standard"
            fullWidth
            label="Email"
            value={requestedServiceForm?.email}
            disabled={true}
          />
        </div>
        <div className="requested-service-phone">
          <TextField
            id="service"
            variant="standard"
            fullWidth
            disabled={true}
            label="Serviço"
            value={requestedServiceForm?.service}
          />
          <TextField
            id="phone"
            variant="standard"
            fullWidth
            label="Telefone"
            value={requestedServiceForm?.phone}
            disabled={true}
          />
        </div>
        <div className="requested-done-paid-note-issued">
          <FormControlLabel
            control={<Checkbox checked={requestedServiceForm?.done} />}
            value={requestedServiceForm?.done}
            onChange={() =>
              handleChangeServiceForm('done', !requestedServiceForm?.done)
            }
            label="Atendido"
          />
          <FormControlLabel
            control={<Checkbox checked={requestedServiceForm?.paid} />}
            value={requestedServiceForm?.paid}
            onChange={() =>
              handleChangeServiceForm('paid', !requestedServiceForm?.paid)
            }
            label="Pago"
          />
          <FormControlLabel
            control={<Checkbox checked={requestedServiceForm?.noteIssued} />}
            value={requestedServiceForm?.noteIssued}
            onChange={() =>
              handleChangeServiceForm(
                'noteIssued',
                !requestedServiceForm?.noteIssued
              )
            }
            label="Nota Emitida"
          />
        </div>
        {handeEditServiceForm && (
          <Button
            className="light-blue-button"
            onClick={() => handeEditServiceForm()}
          >
            Alterar solicitação
          </Button>
        )}
      </div>
    </div>
  )
}

export default RequestedServiceForm
