import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { FormikConfig, useFormik } from 'formik'
import {
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { NumericFormat } from 'react-number-format'
import { some } from 'lodash'

import {
  useGetOperationAreaLevelOptionsQuery,
  useGetOperationAreaOptionsQuery,
} from 'store/PersonalData'
import { ExperienceRequest } from 'types'
import { DEFAULT_VALUES } from './constants'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import schemaValidation from './validations'
import { Button } from 'components/Atoms/Button'
import { ExperienceTabForm, ExperienceTabRef } from './types'
import * as S from './styles'
import '../../../styles.scss'
import { useDispatch } from 'react-redux'
import { setLoading } from 'store/Layout/slice'

const ExperienceTab = (_, ref: React.Ref<ExperienceTabRef>) => {
  const { warningSnackbar } = useCustomSnackbar()
  const dispatch = useDispatch()

  const [selectedExperiencies, setSelectedExperiencies] = useState<
    ExperienceRequest[]
  >([])

  const {
    data: operationAreaOptions,
    isLoading: operationsAreaOptionsIsLoading,
  } = useGetOperationAreaOptionsQuery()
  const {
    data: operationAreaLevelOptions,
    isLoading: operationsAreaLevelOptionsIsLoading,
  } = useGetOperationAreaLevelOptionsQuery()

  const onSubmit: FormikConfig<ExperienceTabForm>['onSubmit'] = (
    values,
    { resetForm }
  ) => {
    setSelectedExperiencies((current) => [
      ...current,
      {
        ...values,
        projetos_executados: Number(values.projetos_executados) ?? 0,
      },
    ])

    resetForm()
  }

  const { values, handleSubmit, setFieldValue, errors, setValues } =
    useFormik<ExperienceTabForm>({
      validationSchema: schemaValidation,
      enableReinitialize: true,
      initialValues: DEFAULT_VALUES,
      onSubmit,
      validateOnChange: false,
    })

  const specializations = useMemo(() => {
    if (!operationAreaOptions || !values.areaatuacao) return []

    const area = operationAreaOptions?.find(
      (item) => item.hashcod === values.areaatuacao
    )

    return (
      area?.modulo_areaatuacao.filter((specialization) =>
        selectedExperiencies.every(
          (experience) => experience.moduloatuacao !== specialization.hashcod
        )
      ) ?? []
    )
  }, [operationAreaOptions, selectedExperiencies, values.areaatuacao])

  const validate = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        if (some(values, (item) => !!item)) {
          reject()
          warningSnackbar('Limpe o formulário ou adicione um item.')
        } else {
          resolve()
        }
      }),
    [values, warningSnackbar]
  )

  const removeExperience = (index: number) => {
    setSelectedExperiencies((current) => {
      const cloned = [...current]
      cloned.splice(index, 1)

      return cloned
    })
  }

  useImperativeHandle(ref, () => ({ selectedExperiencies, validate }), [
    selectedExperiencies,
    validate,
  ])

  useEffect(() => {
    dispatch(
      setLoading(
        operationsAreaOptionsIsLoading || operationsAreaLevelOptionsIsLoading
      )
    )

    return () => {
      dispatch(setLoading(false))
    }
  }, [
    dispatch,
    operationsAreaOptionsIsLoading,
    operationsAreaLevelOptionsIsLoading,
  ])

  return (
    <form onSubmit={handleSubmit}>
      <div className="horinzontal">
        <Card>
          <CardContent>
            <Typography variant="h6" component="div">
              Adicionar experiência
            </Typography>

            <div className="row">
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Área de atuação"
                    variant="standard"
                    error={!!errors.areaatuacao}
                    helperText={errors.areaatuacao}
                    margin="dense"
                  />
                )}
                style={{ width: '32%' }}
                value={values.areaatuacao}
                autoHighlight
                getOptionLabel={(option) =>
                  operationAreaOptions?.find((item) => item.hashcod === option)
                    ?.descricao ?? ''
                }
                onChange={(_, newValue) => {
                  setFieldValue(`areaatuacao`, newValue)
                  setFieldValue(`moduloatuacao`, null)
                }}
                options={
                  operationAreaOptions?.map((item) => item.hashcod) ?? []
                }
              />

              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Especialização"
                    error={!!errors.moduloatuacao}
                    helperText={errors.moduloatuacao}
                    margin="dense"
                  />
                )}
                style={{ width: '32%' }}
                value={values.moduloatuacao}
                disabled={!values.areaatuacao}
                autoHighlight
                getOptionLabel={(option) =>
                  specializations?.find((item) => item.hashcod === option)
                    ?.descricao ?? ''
                }
                onChange={(_, newValue) =>
                  setFieldValue(`moduloatuacao`, newValue)
                }
                options={specializations?.map((item) => item.hashcod) ?? []}
              />

              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Nível de experiência"
                    error={!!errors.nivel}
                    helperText={errors.nivel}
                    margin="dense"
                  />
                )}
                style={{ width: '32%' }}
                value={values.nivel}
                autoHighlight
                getOptionLabel={(option) =>
                  operationAreaLevelOptions?.find(
                    (item) => item.hashcod === option
                  )?.descricao ?? ''
                }
                onChange={(_, newValue) => setFieldValue(`nivel`, newValue)}
                options={
                  operationAreaLevelOptions?.map((item) => item.hashcod) ?? []
                }
              />
            </div>

            <div className="row">
              <NumericFormat
                customInput={TextField}
                style={{ width: '32%' }}
                variant="standard"
                label="Projetos realizados (Quantidade)"
                value={values.projetos_executados}
                onValueChange={({ value }) =>
                  setFieldValue(`projetos_executados`, value)
                }
                error={!!errors.projetos_executados}
                helperText={errors.projetos_executados}
                margin="dense"
                decimalScale={0}
                allowNegative={false}
                placeholder={'Digitar (1,2,3,4,5,6)'}
              />

              <NumericFormat
                customInput={TextField}
                style={{ width: '32%' }}
                label="Valor da hora"
                variant="standard"
                value={values.valor_hora.replace('.', ',')}
                onValueChange={({ value }) =>
                  setFieldValue(`valor_hora`, value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                error={!!errors.valor_hora}
                helperText={errors.valor_hora}
                margin="dense"
                decimalScale={2}
                fixedDecimalScale
                decimalSeparator=","
                thousandSeparator="."
                allowNegative={false}
              />

              <div style={{ minWidth: '32%' }} />
            </div>
          </CardContent>

          <CardActions>
            <Button type={'submit'} buttonType="primary">
              Adicionar
            </Button>

            {(values.areaatuacao ||
              values.moduloatuacao ||
              values.nivel ||
              values.projetos_executados ||
              values.valor_hora) && (
              <Button
                onClick={() => {
                  setValues(DEFAULT_VALUES)
                }}
                buttonType="primary"
              >
                Limpar
              </Button>
            )}
          </CardActions>
        </Card>
      </div>

      {selectedExperiencies && (
        <>
          <S.SelectExperienceInfo>
            Experiências adicionadas:
          </S.SelectExperienceInfo>

          <S.Container>
            {selectedExperiencies?.map((experience, index) => {
              const operationAreaName = operationAreaOptions?.find(
                (item) => item.hashcod === experience.areaatuacao
              )?.descricao
              const operationAreaLevelName = operationAreaLevelOptions?.find(
                (item) => item.hashcod === experience.nivel
              )?.descricao
              const specializationName = specializations.find(
                (item) => item.hashcod === experience.moduloatuacao
              )?.descricao

              return (
                <Card
                  key={`${experience.areaatuacao}${experience.moduloatuacao}`}
                >
                  <CardHeader
                    action={
                      <IconButton onClick={() => removeExperience(index)}>
                        <CloseIcon />
                      </IconButton>
                    }
                    title={`Experiência ${index + 1}`}
                    titleTypographyProps={{ variant: 'h6' }}
                  />

                  <CardContent>
                    <S.RowDivider>
                      <S.ColumnDivider>
                        <S.CardTextInfos>{operationAreaName}</S.CardTextInfos>

                        <S.CardTextInfos>
                          {operationAreaLevelName}
                        </S.CardTextInfos>
                      </S.ColumnDivider>

                      <S.ColumnDivider>
                        <S.CardTextInfos>{specializationName}</S.CardTextInfos>

                        <S.CardTextInfos>
                          {`R$ ${experience.valor_hora}/h`}
                        </S.CardTextInfos>
                      </S.ColumnDivider>

                      <S.ColumnDivider>
                        <S.CardTextInfos style={{ alignSelf: 'center' }}>
                          {experience.projetos_executados}
                        </S.CardTextInfos>

                        <S.CardTextInfos>Projetos</S.CardTextInfos>
                      </S.ColumnDivider>
                    </S.RowDivider>
                  </CardContent>
                </Card>
              )
            })}
          </S.Container>
        </>
      )}
    </form>
  )
}

export default forwardRef(ExperienceTab)

export type { ExperienceTabRef }
