import { Button, Typography } from '@mui/material'
import { Container, Chip } from './styles'
import { STATUS } from '../../constants'
import { useRestartApplicationMutation } from 'store/WeInvitateVacanciesJob'
import { useDispatch } from 'react-redux'
import { searchVacanciesRedux } from 'store/SearchVacancies'

interface ApplicationStatusProps {
  applicationStatus: string
  projectStatus: string
  hashcode: string
  reason: null | string
}

const ApplicationStatus = ({
  projectStatus,
  applicationStatus,
  hashcode,
  reason,
}: ApplicationStatusProps) => {
  const dispatch = useDispatch()
  const [restartApplication] = useRestartApplicationMutation()

  const isProjectPublished = projectStatus === 'P'
  const isApplicationPending = applicationStatus === 'P'
  const canRestarApplication = isProjectPublished && !isApplicationPending

  const handleRestartApplication = () => {
    restartApplication(hashcode).then(() => {
      dispatch(searchVacanciesRedux.util.invalidateTags(['Projects']))
    })
  }

  return (
    <Container>
      <Typography lineHeight={1.1} fontSize="0.95rem">
        Status da candidatura
      </Typography>
      <Chip>{STATUS[applicationStatus]}</Chip>
      {!!reason && <Typography>Motivo: {reason}</Typography>}
      {canRestarApplication && (
        <Button
          onClick={handleRestartApplication}
          size="small"
          variant="contained"
          style={{
            backgroundColor: 'orange',
            color: 'white',
          }}
        >
          Reiniciar Candidatura
        </Button>
      )}
    </Container>
  )
}

export default ApplicationStatus
