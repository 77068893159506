import { Grid, Box } from '@mui/material'
import React from 'react'
import Logo from '../../Atoms/Logo'
import LoginForm from '../../Molecules/LoginForm'
import * as S from './styles'

const BoxLogin: React.FC = () => {
  return (
    <S.Container>
      <S.LoginContainer>
        <S.LogoContainer>
          <Logo />
        </S.LogoContainer>

        <S.InputContainer>
          <LoginForm />
        </S.InputContainer>
      </S.LoginContainer>

      <S.FooterContent>
        <S.LoginFooterText>
          Ⓒ{new Date().getFullYear()} Copyright
        </S.LoginFooterText>
        <S.LoginFooterTittle translate="no">JOB Connect</S.LoginFooterTittle>
      </S.FooterContent>
    </S.Container>
  )
}

export default BoxLogin
