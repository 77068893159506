import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { RootState } from "store";

type Props = {
	children: JSX.Element
	isExclusive?: boolean
	empty?: boolean;
}

const IsExclusive = ({ children, empty, isExclusive = false }: Props) => {
	const user = useSelector((state: RootState) => state.userState.loggedUser)
	if (!isExclusive) return children;

	if (user?.plano === 3) return children;

	if (empty) return <></>

	return (
		<Typography>
			Para obter os dados de contato do profissional, obtenha o plano {' '}
			<NavLink to="/plans_contratante" style={{ color: 'blue' }}>
				EXCLUSIVE
			</NavLink>.
		</Typography>
	)
}

export default IsExclusive;