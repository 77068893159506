import { Badge } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
  align-items: flex-start;
  margin-top: 16px;
  margin-bottom: 15px;
`

export const JobInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const JobTittle = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.paragraph};
`

export const CompanyName = styled.span`
  color: ${({ theme }) => theme.colors.text.paragraph};
`

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

export const FavoriteContainer = styled.div`
  display: flex;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
    color: ${({ theme }) => theme.colors.text.paragraph};
  }
`

export const ActionsContainer = styled.div`
  display: grid;
  gap: 8px;
`
export const StyledButtonAccept = styled.div`
  margin-top: -20px;

  button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 6px;
  }
`
export const StyledButtonRecuse = styled.div`
  margin-top: -20px;
  button {
    border: 1px solid #d32f2f;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 6px;
  }
`

export const ColumnDivider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`
export const RowButtonDivider = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const CopyButtonContainer = styled.div`
  margin-left: auto;
`
