import { RelationshipGrade } from "domain/models/dependent";
import { MaterialTableColumn } from "domain/models/materialColumn";
import moment from "moment";

export const dependentColumns: readonly MaterialTableColumn[] = [
  { id: "name", label: "Nome", minWidth: 170 },
  { id: "cpf", label: "CPF", minWidth: 100 },
  {
    id: "birthdate",
    label: "Data de nascimento",
    minWidth: 170,
  },
  {
    id: "relationshipGradeText",
    label: "Parentesco",
    minWidth: 170,
  },
];

export const relationshipGradeTranslation = {
  CHILDREN: "Filho (a)",
  PARENT: "Pai/Mãe",
  PARTNER: "Parceiro",
};

export const getRelationshipOptions = () => {
  return Object.keys(RelationshipGrade)?.map((key) => ({
    value: RelationshipGrade?.[key],
    label: relationshipGradeTranslation[key],
  }));
};

export const getRelationshipText = (relationshipGrade = "") => {
  return relationshipGradeTranslation[relationshipGrade.toUpperCase()];
};

export const defaultDependentForm = {
  cpf: "",
  name: "",
  birthdate: moment().toString(),
  relationshipGrade: RelationshipGrade.CHILDREN,
  relationshipGradeOptions: getRelationshipOptions(),
};

export const defaultDisplayFields = {
  dependentForm: false,
  dependentList: true,
};

export const displayListBody = {
  dependentList: true,
  dependentForm: false,
};

export const displayFormBody = {
  dependentList: false,
  dependentForm: true,
};
