import { ReactElement, cloneElement } from 'react'
import styles from './styles.module.css'

type Props = {
  title: string
  IconSlot: ReactElement
}

const Header = ({ title, IconSlot }: Props) => {
  return (
    <header className={styles.header}>
      {cloneElement(IconSlot, {
        fontSize: 'large',
        style: { color: '#1876d1' },
      })}
      <span className={styles.header__title}>{title}</span>
    </header>
  )
}

export default Header
