import HomeIcon from '@mui/icons-material/Home'
import ListAltIcon from '@mui/icons-material/ListAlt'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import WorkIcon from '@mui/icons-material/Work'
import PersonIcon from '@mui/icons-material/Person'
import CampaignIcon from '@mui/icons-material/Campaign'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import MailIcon from '@mui/icons-material/Mail'
import VerifiedIcon from '@mui/icons-material/Verified'
import SearchIcon from '@mui/icons-material/Search'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import HailIcon from '@mui/icons-material/Hail'

import { Role } from '../Enums'
import { sideBarMenuItem } from '../Interfaces'

import FingerprintIcon from '@mui/icons-material/Fingerprint'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'

import SidebarRoutes from '../Constants/routes'

export const sideBarMenu: sideBarMenuItem[] = [
  {
    route: SidebarRoutes.HOME,
    text: 'Menu principal',
    MenuIcon: HomeIcon,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    text: 'Perfil',
    route: SidebarRoutes.PROFISSIONAL_PROFILE,
    MenuIcon: PersonIcon,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    text: 'Vagas de Emprego',
    MenuIcon: WorkIcon,
    route: SidebarRoutes.VACANCIES,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    badge: {
      attribute: 'jobQuantity',
      store: 'weFindYourJob',
    },
    text: 'Encontramos sua vaga de emprego',
    MenuIcon: AssignmentTurnedInIcon,
    route: SidebarRoutes.WE_FOUND_YOUR_JOB,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    badge: {
      attribute: 'invitateQuantity',
      store: 'weInvitationVacanciesJob',
    },
    text: 'Convites',
    MenuIcon: MailIcon,
    route: SidebarRoutes.INVITATION_JOBS,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    text: 'Agenda',
    MenuIcon: CalendarMonthIcon,
    route: SidebarRoutes.CALENDAR,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    badge: {
      attribute: 'ratingQuantity',
      store: 'weRatingJob',
    },
    text: 'Avaliações Pendentes',
    MenuIcon: StarBorderIcon,
    route: SidebarRoutes.PENDING_RATINGS,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    text: 'Promoções',
    route: SidebarRoutes.PROMOTION,
    MenuIcon: CampaignIcon,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    text: 'Contabilidade',
    MenuIcon: ListAltIcon,
    route: SidebarRoutes.ACCOUNTING,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    text: 'Serviços adicionais',
    MenuIcon: AddBoxOutlinedIcon,
    route: SidebarRoutes.ADDITIONAL_SERVICES,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    text: 'Planos da Plataforma',
    MenuIcon: VerifiedIcon,
    route: SidebarRoutes.PLANS_CONTRATADO,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    text: 'Planos de Contabilidade',
    route: SidebarRoutes.PLANS_CONTABILIDADE,
    MenuIcon: VerifiedIcon,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    text: 'Adquira Certificado Digital',
    route: () =>
      window.open('https://jobc.com.br/certificado-digital/', '_blank'),
    MenuIcon: FingerprintIcon,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    text: 'Entrar em contato',
    route: () =>
      window.open(
        'https://wa.me/5511941944294?text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20da%20plataforma%20JOB%20Connect%21',
        '_blank'
      ),
    MenuIcon: WhatsAppIcon,
    roleAccess: Role.PROFESSIONAL,
  },
  {
    route: SidebarRoutes.HOME,
    text: 'Dashboard do Recrutador',
    MenuIcon: HomeIcon,
    roleAccess: Role.COMPANY,
  },
  {
    text: 'Perfil corporativo',
    route: SidebarRoutes.RH_PROFILE,
    MenuIcon: PersonIcon,
    roleAccess: Role.COMPANY,
  },
  {
    badge: {
      attribute: 'pendingApplicationsQuantity',
      store: 'professional',
    },
    text: 'Vagas de Emprego de Tecnologia',
    route: SidebarRoutes.TECH_JOBS,
    MenuIcon: WorkIcon,
    roleAccess: Role.COMPANY,
  },
  {
    text: 'Buscar Profissionais',
    route: SidebarRoutes.SEARCH_PROFESSIONALS,
    MenuIcon: SearchIcon,
    roleAccess: Role.COMPANY,
  },
  // {
  //   text: 'Convites Pendentes',
  //   route: SidebarRoutes.PENDING_INVITE,
  //   MenuIcon: PersonAddIcon,
  //   roleAccess: Role.COMPANY,
  // },
  {
    text: 'Agenda de alocações',
    route: SidebarRoutes.CALENDAR_CONTRATANTE,
    MenuIcon: CalendarMonthIcon,
    roleAccess: Role.COMPANY,
  },
  {
    badge: {
      attribute: 'ratingQuantity',
      store: 'weRatingJob',
    },
    text: 'Avaliações Pendentes',
    MenuIcon: StarBorderIcon,
    route: SidebarRoutes.PENDING_RATINGS_TECH,
    roleAccess: Role.COMPANY,
  },
  {
    text: 'Planos da Plataforma',
    MenuIcon: VerifiedIcon,
    route: SidebarRoutes.PLANS_CONTRATANTE,
    roleAccess: Role.COMPANY,
  },
  {
    text: 'Entrar em contato',
    route: () =>
      window.open(
        'https://wa.me/5511941944294?text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20da%20plataforma%20JOB%20Connect%21',
        '_blank'
      ),
    MenuIcon: WhatsAppIcon,
    roleAccess: Role.COMPANY,
  },
  {
    text: 'Processo de Hunting',
    route: SidebarRoutes.HUNTING_PROCESS,
    // route: '/home',
    MenuIcon: HailIcon,
    roleAccess: Role.COMPANY,
  },
]
