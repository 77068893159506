import { TechJobCreateAndUpdateForm } from './types'

export const INITIAL_VALUES: TechJobCreateAndUpdateForm = {
	alocacao_remota: false,
	cidade: '',
	data_final: null,
	data_inicio: null,
	dedicacao_diaria: '',
	estado: '',
	limite_submissao: null,
	nome: '',
	numero_profissionais: 1,
	pagamento: '',
	sobre_empresa: '',
	sobre_projeto: '',
	tipo_contratacao: '',
	areaatuacao: null,
	moduloatuacao: null,
	nivel: null,
	cliente: '',
}
