import { useEffect, useRef, useState } from 'react'
import { Button, TextareaAutosize } from '@mui/material'
import { useFormik } from 'formik'
import FileSaver from 'file-saver'
var fileDownload = require('js-file-download')

import validationSchema from './validations'

import * as C from 'components/Atoms/Button'
import '../../../styles.scss'
import { INITIAL_VALUES } from './constants'
import { useUpdatePersonalDataMutation } from 'store/PersonalData'
import { pick } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from 'store/Layout/slice'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { CurriculumTabForm } from './types'
import { RootState } from 'store'

const CurriculumTab = () => {
  const { warningSnackbar, successSnackbar } = useCustomSnackbar()
  const dispatch = useDispatch()
  const inputRef = useRef<any>()
  const [uploadedFile, setUploadedFile] = useState<File>()

  const { loading } = useSelector((state: RootState) => state.layout)
  const { loggedUser, refetchLoggedUser } = useSelector(
    (state: RootState) => state.userState
  )

  const [editUser, { isLoading: editPersonalDataIsLoading }] =
    useUpdatePersonalDataMutation()

  const onSubmit = async (values: CurriculumTabForm) => {
    editUser({ ...values, curriculo_doc: uploadedFile })
      .unwrap()
      .then(() => {
        successSnackbar('Dados do currículo alterado com sucesso')
      })
      .then(() => setUploadedFile(undefined))
      .then(refetchLoggedUser)
      .catch(() => {
        warningSnackbar(
          'Erro ao alterar dados do currículo. Tente novamente mais tarde.'
        )
      })
  }

  const downloadFile = async () => {
    if (uploadedFile) {
      fileDownload(uploadedFile, uploadedFile.name)
    } else {
      if (!values.curriculo_doc) return

      const [, fileName] =
        values.curriculo_doc.match(/(?:documentos\/)(.*)/) ?? []

      FileSaver.saveAs(values.curriculo_doc, fileName)
    }
  }

  const openInput = () => {
    inputRef.current.click()
  }

  const { values, handleSubmit, handleChange, errors, setValues } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  })

  useEffect(() => {
    if (!loggedUser) return

    setValues((current) => ({
      ...current,
      ...pick(loggedUser, ['email', 'curriculo_doc', 'descricao']),
    }))
  }, [loggedUser, setValues])

  useEffect(() => {
    dispatch(setLoading(editPersonalDataIsLoading))

    return () => {
      dispatch(setLoading(false))
    }
  }, [dispatch, editPersonalDataIsLoading])

  return (
    <form onSubmit={handleSubmit}>
      <div className="horizontal">
        <TextareaAutosize
          placeholder="Copie e cole seu currículo. Este campo é muito importante para a análise dos recrutadores"
          style={{
            width: '100%',
            resize: 'vertical',
            borderColor: errors.descricao ? 'red' : '',
          }}
          minRows={7}
          value={values.descricao}
          onChange={handleChange}
          name="descricao"
        />

        {errors.descricao && (
          <label className="helper-box" style={{ minWidth: '100%' }}>
            {`${errors.descricao}`}
          </label>
        )}

        <div
          style={{
            marginBottom: '1rem',
            minWidth: '100%',
          }}
        />

        <Button
          onClick={openInput}
          variant="outlined"
          color="primary"
          style={{ minWidth: 200 }}
        >
          Enviar Arquivo (.pdf, .doc ou .docx)
        </Button>

        {values.curriculo_doc && (
          <Button
            onClick={downloadFile}
            variant="outlined"
            color="primary"
            style={{ marginLeft: 20 }}
          >
            Baixar arquivo de currículo enviado
          </Button>
        )}

        <input
          type="file"
          accept=".pdf, .doc, .docx"
          id="button-input-file"
          className="no-hidden"
          onChange={(event) => {
            if (!event.target.files) return

            setUploadedFile(event.target.files[0])
          }}
          ref={inputRef}
        />
      </div>

      <C.Button
        loading={loading || editPersonalDataIsLoading}
        type={'submit'}
        buttonType="primary"
        style={{ width: 200, marginTop: '1.25rem' }}
      >
        Atualizar
      </C.Button>
    </form>
  )
}

export default CurriculumTab
