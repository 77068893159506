import { Container, ModalApplications, Slide } from './styles'
import { Fragment } from 'react'
import { InvitationResponse } from 'types'
import { useJobApplicationModal } from './hooks/useJobApplicationModal'
import List from 'data/components/CompoundInvitations/List'
import ProfessionalCard from 'data/components/CompoundInvitations/ProfessionalCard'
import { Divider, Typography } from '@mui/material'
import Professional from 'data/components/CompoundInvitations/Professional'
import AcceptOrRefuseApplication from './AcceptOrRefuseApplication'
import ApplicationStatus from './ApplicationStatus'
import StatusSelect from './StatusSelect'
import { STATUS } from '../constants'
import ExitRequest from 'pages/CalendarContratante/Row/ApprovedApplicantsModal/ExitRequest'

interface JobApplicationModalProps {
  hashcode: string
  onClose: () => void
}

export interface ApplicationInfo {
  applicationHashcode: string
  applicant: InvitationResponse['contratado']
  status?: string
  motivo?: string | null
}

const JobApplicationModal = ({
  hashcode,
  onClose,
}: JobApplicationModalProps) => {
  const {
    selectedProfessional,
    applicantsList,
    handleSelectProfessional,
    handleSelectStatus,
    status,
    updateApplicants,
  } = useJobApplicationModal(hashcode)
  const isProfessionalSelected = !!Number.isInteger(selectedProfessional)
  const professional = applicantsList?.[selectedProfessional!]

  return (
    <ModalApplications open onClose={onClose}>
      <Container>
        <Slide isProfessionalSelected={isProfessionalSelected}>
          <List
            title="Candidaturas"
            onClose={onClose}
            HeaderSlotComponent={
              <StatusSelect
                onChangeStatus={(status) => handleSelectStatus(status)}
                status={status}
              />
            }
          >
            {!!applicantsList?.length &&
              applicantsList?.map(
                (
                  { contratado, status, projeto, hashcod, motivo_status },
                  index
                ) => (
                  <Fragment key={hashcod}>
                    <ProfessionalCard
                      professional={contratado}
                      onSelectProfessional={handleSelectProfessional(index)}
                    >
                      <ApplicationStatus
                        reason={motivo_status}
                        applicationStatus={status}
                        projectStatus={projeto.status}
                        hashcode={hashcod}
                      />
                    </ProfessionalCard>
                    <Divider style={{ marginRight: '1.5rem' }} />
                  </Fragment>
                )
              )}
            {!applicantsList?.length && (
              <Typography ml={1} mt={1}>
                Não foram encontrados resultados para o Status de Candidatura:{' '}
                {STATUS[status]}{' '}
              </Typography>
            )}
          </List>
          {isProfessionalSelected && (
            <Professional
              hashcode={professional!.contratado.hashcod}
              onUnselectProfessional={handleSelectProfessional(null)}
              title="Voltar as candidaturas"
              SlotCardContainer={
                <>
                  <AcceptOrRefuseApplication
                    onDismissProfessional={handleSelectProfessional(null)}
                    applicationStatus={professional!.status}
                    hashcode={professional!.hashcod}
                  />
                  <ExitRequest
                    hashcode={professional!.hashcod}
                    status={professional!.status}
                    updateSuggestions={() => {
                      updateApplicants()
                      handleSelectProfessional(null)()
                    }}
                  />
                </>
              }
            />
          )}
        </Slide>
      </Container>
    </ModalApplications>
  )
}

export default JobApplicationModal
