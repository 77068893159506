import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Stack, Typography } from '@mui/material'
import { StringParam, useQueryParams } from 'use-query-params'

import { useGetInvitationsQuery } from 'store/WeInvitateVacanciesJob'
import { VacancyItem } from '../VacancyItem'
import { JobResponse } from 'types'
import JobDetails from 'components/Molecules/JobDetails'
import { setLoading } from 'store/Layout/slice'
import SkeletonJob from 'components/Molecules/SkeletonJob'
import Pagination from 'components/Organisms/Pagination'
import { PageParam, PageSizeParam } from 'utils/Constants'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import * as S from './styles'
import { Column, JobList, Row } from '../style'

export default function MyInvitations() {
  const dispatch = useDispatch()
  const { warningSnackbar } = useCustomSnackbar()
  const [query, setQuery] = useQueryParams({
    h: StringParam,
    p: PageParam,
    ps: PageSizeParam,
  })
  const { h: selectedJobHashcod, p: page, ps: pageSize } = query

  const [needToScroll, setNeedToScroll] = useState(!!selectedJobHashcod)

  const { data, isLoading, isSuccess, refetch } = useGetInvitationsQuery()

  const [selectedJob, setSelectedJob] = useState<JobResponse | null>(null)

  const paginatedData = useMemo(() => {
    return data?.slice(page * pageSize, (page + 1) * pageSize) ?? []
  }, [data, page, pageSize])

  useEffect(() => {
    if (isLoading || !data || !!selectedJob) return

    if (selectedJobHashcod) {
      const foundItem = data.find(
        ({ projeto }) => projeto.hashcod === selectedJobHashcod
      )

      if (foundItem) setSelectedJob(foundItem.projeto)
      else {
        setSelectedJob(data[0]?.projeto ?? null)
        warningSnackbar('Vaga de Emprego não encontrada')
      }
    } else {
      setSelectedJob(data[0]?.projeto ?? null)
    }
  }, [isLoading, selectedJob, selectedJobHashcod, data, warningSnackbar])

  useEffect(() => {
    if (selectedJob) setQuery({ h: selectedJob.hashcod })
  }, [selectedJob, setQuery])

  useEffect(() => {
    if (needToScroll && isSuccess && selectedJobHashcod) {
      document
        .getElementById(selectedJobHashcod)
        ?.scrollIntoView({ behavior: 'smooth' })

      setNeedToScroll(false)
    }
  }, [isSuccess, needToScroll, selectedJobHashcod])

  useEffect(() => {
    return () => setQuery({ h: undefined, p: undefined, ps: undefined })
  }, [setQuery])

  useEffect(() => {
    dispatch(setLoading(isLoading))
  }, [dispatch, isLoading])

  if (!data?.length) {
    return (
      <Box style={{ color: 'black' }}>Ainda não há convites disponíveis.</Box>
    )
  }

  return (
    <>
      <Row>
        {isLoading ? (
          <SkeletonJob />
        ) : (
          <Column>
            <JobList>
              {paginatedData.map((item) => (
                <VacancyItem
                  id={item.projeto.hashcod}
                  key={item.hashcod}
                  item={item.projeto}
                  onClick={() => setSelectedJob(item.projeto)}
                  isSelected={selectedJob == item.projeto}
                />
              ))}
            </JobList>
          </Column>
        )}

        {!!paginatedData.length && selectedJob && (
          <JobDetails
            job={selectedJob}
            isInvite={true}
            dataVacation={data}
            refetchData={refetch}
          />
        )}
      </Row>

      <Pagination
        count={data?.length ?? 0}
        page={isLoading ? 0 : page}
        onPageChange={(_, newPage) => setQuery({ p: newPage })}
        onRowsPerPageChange={(event) =>
          setQuery({ p: 0, ps: Number(event.target.value) })
        }
        rowsPerPage={pageSize}
      />
    </>
  )
}
