import PageTitle from 'components/Atoms/PageTitle'
import ExceptionFooter from '../../components/Molecules/ExceptionFooter'
import ExceptionContent from '../../components/Molecules/ExceptionContent'
import './style.scss'

export const NotFound = () => {
  return (
    <>
      <PageTitle title={'Página não encontrada'} />

      <main className="exception-body error">
        <div className="exception-wrapper">
          <ExceptionContent
            image={'error'}
            alt={'page notfound'}
            title={`Página não encontrada`}
          />
          <ExceptionFooter />
        </div>
      </main>
    </>
  )
}
