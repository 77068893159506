import { useNavigate } from 'react-router-dom'
import Grid2 from '@mui/material/Unstable_Grid2'
import screenApp from '../../assets/images/screeapp.png'
import certificadoDigital from '../../assets/images/certificadodigital.jpeg'
import BannerJOBC from '../../assets/images/BannerJOBC.jpeg'
import PROFESSIONAL_SERVICES from './../../assets/contracts/portfolio_profissionais.pdf'

import {
	Typography,
	Box,
	Paper,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Chip,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Button } from 'components/Atoms/Button'
import { useJobsQuantityQuery } from 'store/SearchVacancies'
import { PlansContratado } from 'pages/PlansContratado'
import CheckIcon from '@mui/icons-material/Check'
import WorkIcon from '@mui/icons-material/Work'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import routes from 'utils/Constants/routes'
import { PlansContabilidade } from 'pages/PlansContabilidade'
import {
	AppContainer,
	BannerBenefitsContainer,
	CertificateBanner,
	Container,
	LinkBuyCertificate,
	List,
	Wrapper,
} from './styles'
import Services from '../../data/components/Services'

const bannerItems = [
	'CENTENAS DE VAGAS DE TECNOLOGIA PUBLICADAS TODOS OS DIAS',
	'SERVIÇOS DE CONTABILIDADE 100% DIGITAL',
	'VENDA DE CERTIFICADO DIGITAL e-CNPJ E e-CPF',
	'EMISSOR DE NOTA FISCAL PARA PESSOA JURÍDICA',
	'DESCONTOS EM TREINAMENTOS E MUITO MAIS...',
]

export const Dashboard = () => {
	const navigate = useNavigate()
	const user = useSelector((state: RootState) => state.userState.loggedUser)

	const { data: jobsQuantity, isLoading } = useJobsQuantityQuery()

	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<Card style={{ position: 'relative', height: 250 }}>
					<BannerBenefitsContainer backgroundURL={BannerJOBC}>
						<Typography fontSize={20} >
							O PORTAL DO PROFISSIONAL DE TECNOLOGIA
						</Typography>
						<List>
							{bannerItems.map((bannerItem) => (
								<li>{bannerItem}</li>
							))}
						</List>
					</BannerBenefitsContainer>
				</Card>

				<Typography variant="h6" sx={{ margin: '1rem 0rem', color: 'black' }}>
					Olá <strong style={{ fontSize: '22px' }}>{user?.nome ?? ''}</strong>,
					seja bem-vindo a plataforma JOB Connect. Estamos com{' '}
					{isLoading ? (
						<CircularProgress size={18} />
					) : (
						<Chip
							clickable
							variant="outlined"
							color="primary"
							style={{ borderRadius: '30px' }}
							icon={<WorkIcon color='primary' />}
							label={<strong>{jobsQuantity?.quantidade}</strong>}
						/>
					)} vagas de emprego aberta
				</Typography>
				<Container>
					<Wrapper>
						<AppContainer>
							<Button
								onClick={() => navigate(routes.ACCOUNTING)}
								style={{
									border: 'none',
									height: '70px',
									borderTopLeftRadius: '24px',
									borderTopRightRadius: '24px',
									backgroundColor: '#274490'
								}}
							>
								Emita suas notas fiscais pelo emissor gratuitamente
							</Button>
							<CardMedia
								component="img"
								height="220"
								image={screenApp}
								alt="Download apps"
								style={{ objectFit: 'contain' }}
							/>
							<CardContent>
								<Typography
									gutterBottom
									variant="h5"
									component="div"
									textAlign="center"
									style={{ color: 'rgba(0, 0, 0, 0.6)' }}
								>
									Baixe o aplicativo...
								</Typography>

								<Typography
									variant="subtitle1"
									color="text.secondary"
									textAlign="center"
								>
									e comece a usufruir da plataforma, são dezenas de vagas de
									emprego publicadas todos os dias. Busque vagas de emprego,
									compartilhe com os amigos e faça parte da plataforma mais
									eficiente do mercado!
								</Typography>
							</CardContent>
							<CardActions sx={{ padding: '18px' }}>
								<Button
									fullWidth
									style={{
										backgroundColor: '#274490',
										border: 'none',
										borderTopLeftRadius: '24px',
										borderBottomRightRadius: '24px',
									}}
									onClick={() =>
										window.open(
											'https://play.google.com/store/apps/details?id=br.com.leaderaplicativos.jobconnect',
											'_blank'
										)
									}
								>
									Google Play
								</Button>

								<Button
									fullWidth
									style={{
										backgroundColor: '#274490',
										border: 'none',
										borderTopLeftRadius: '24px',
										borderBottomRightRadius: '24px',
									}}
									onClick={() =>
										window.open(
											'https://apps.apple.com/br/app/job-connect/id1450264994',
											'_blank'
										)
									}
								>
									Apple Store
								</Button>
							</CardActions>
						</AppContainer>
						<LinkBuyCertificate
							target="_blank"
							href="https://jobc.com.br/certificado-digital/"
							style={{ display: 'flex', alignItems: 'center', flex: 1 }}
						>
							<CertificateBanner
								src={certificadoDigital}
								alt="Certificado digital"
							/>
						</LinkBuyCertificate>
					</Wrapper>
					<Services file={PROFESSIONAL_SERVICES} />
					<Grid2 xs={12}>
						<Paper sx={{ padding: '18px' }}>
							<Typography
								variant="h6"
								gutterBottom
								sx={{
									textAlign: 'center',
									marginBottom: '20px',
									textTransform: 'uppercase',
								}}
							>
								Aumente a visibilidade do seu perfil aumentando suas chances de
								fechar as melhores{' '}
								<a
									style={{
										cursor: 'pointer',
										textDecoration: 'underline',
										fontSize: '20px',
										color: '#464DF2',
										fontWeight: '600',
									}}
									onClick={() => navigate('/vacancies')}
								>
									vagas de emprego
								</a>{' '}
								através dos nossos planos
							</Typography>
							<PlansContratado />
						</Paper>
					</Grid2>

					<Grid2 xs={12}>
						<Paper
							sx={{
								padding: '18px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%',
							}}
						>
							<Typography gutterBottom variant="h5" component="div">
								A contabilidade especializada para profissionais de tecnologia
							</Typography>

							<Typography gutterBottom variant="subtitle1" component="div">
								Economia, tranquilidade e praticidade de uma contabilidade
								online e 100% digital
							</Typography>

							<Box style={{ marginBottom: '18px' }}>
								<Typography
									gutterBottom
									variant="body2"
									component="div"
									sx={{ display: 'flex' }}
								>
									<CheckIcon
										color="success"
										fontSize="small"
										sx={{ marginRight: '6px' }}
									/>
									Emita seus boletos de forma rápida e simples.
								</Typography>

								<Typography
									gutterBottom
									variant="body2"
									component="div"
									sx={{ display: 'flex' }}
								>
									<CheckIcon
										color="success"
										fontSize="small"
										sx={{ marginRight: '6px' }}
									/>
									Emita suas notas fiscais pelo emissor automático
									gratuitamente.
								</Typography>

								<Typography
									gutterBottom
									variant="body2"
									component="div"
									sx={{ display: 'flex' }}
								>
									<CheckIcon
										color="success"
										fontSize="small"
										sx={{ marginRight: '6px' }}
									/>
									Tenha sua contabilidade 100% online e acesso a um serviço
									completo e digital.
								</Typography>

								<Typography
									gutterBottom
									variant="body2"
									component="div"
									sx={{ display: 'flex' }}
								>
									<CheckIcon
										color="success"
										fontSize="small"
										sx={{ marginRight: '6px' }}
									/>
									Abertura de empresa gratuita na adesão de um dos planos
									anuais.
								</Typography>

								<Typography
									gutterBottom
									variant="body2"
									component="div"
									sx={{ display: 'flex' }}
								>
									<CheckIcon
										color="success"
										fontSize="small"
										sx={{ marginRight: '6px' }}
									/>
									Economia real: planos a partir de R$95/mês.
								</Typography>
							</Box>

							<div style={{ width: '100%' }}>
								<Grid2 container spacing={2} justifyContent="center">
									<Grid2 xs={4}>
										<Button
											style={{
												backgroundColor: '#274490',
												border: 'none',
												borderTopLeftRadius: '24px',
												borderBottomRightRadius: '24px',
											}}
											onClick={() =>
												window.open(
													'https://jobc.com.br/conheca-nossos-servicos/',
													'_blank'
												)
											}
										>
											Abrir empresa grátis
										</Button>
									</Grid2>

									<Grid2 xs={4}>
										<Button
											style={{
												backgroundColor: '#274490',
												border: 'none',
												borderTopLeftRadius: '24px',
												borderBottomRightRadius: '24px',
											}}
											onClick={() =>
												window.open(
													'https://jobc.com.br/trocar-de-contador/',
													'_blank'
												)
											}
										>
											Trocar de contador
										</Button>
									</Grid2>
								</Grid2>
							</div>
						</Paper>
					</Grid2>

					<Grid2 xs={12}>
						<Card>
							<CardContent>
								<PlansContabilidade />
							</CardContent>
						</Card>
					</Grid2>
				</Container>
				{/* </Grid2> */}
			</Box>
		</>
	)
}
