import { getSnakeCaseApiRequestedServices } from 'data/utils/ServiceRequest/entity'
import nodeApi from '../api'

const injectedNodeApi = nodeApi.injectEndpoints({
  endpoints: (build) => ({
    postServiceRequest: build.mutation<any, any>({
      query: (serviceRequest) => ({
        method: 'POST',
        body: serviceRequest,
        url: '/serviceRequests',
      }),
      transformResponse: (response: any) => response?.data,
    }),
    putServiceRequest: build.mutation<any, any>({
      query: (updatedServiceRequest) => ({
        method: 'PUT',
        body: updatedServiceRequest,
        url: `/serviceRequests/${updatedServiceRequest?.id}`,
      }),
      transformResponse: (response: any) => response?.data,
    }),
    getRequestedServices: build.query<any, any>({
      query: () => `/serviceRequests`,
      transformResponse: (response: any) => {
        return getSnakeCaseApiRequestedServices(response?.data)
      },
    }),
    getRequestedServicesByEmail: build.query<any, any>({
      query: (searchEmail: string) => `/serviceRequests/${searchEmail}`,
      transformResponse: (response: any) => {
        return getSnakeCaseApiRequestedServices(response?.data)
      },
    }),

    deleteServiceRequest: build.mutation<any, any>({
      query: (serviceRequestId) => ({
        method: 'DELETE',
        url: `/serviceRequests/${serviceRequestId}`,
      }),
      transformResponse: (response: any) => response?.data,
    }),
  }),
})

export const {
  useGetRequestedServicesQuery,
  usePutServiceRequestMutation,
  usePostServiceRequestMutation,
  useDeleteServiceRequestMutation,
  useGetRequestedServicesByEmailQuery,
} = injectedNodeApi

export default injectedNodeApi
