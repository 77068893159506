import React, { useState, useEffect } from 'react'
import { Box, CircularProgress, Avatar } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { useGetRatingQuery } from '../../store/Rating'
import { CompanyCardTech } from './CompanyCard'

import './styles.scss'

export const RatingTech = () => {
  const {
    data,
    isLoading,
    isSuccess,
    refetch: refetchRating,
  } = useGetRatingQuery('')

  useEffect(() => {
    refetchRating()
  }, [refetchRating])

  return (
    <Box>
      <Grid2 container spacing={2}>
        {isSuccess &&
          data?.map((dataRating) => (
            <Grid2 xs={4}>
              <CompanyCardTech
                companyHash={dataRating?.hashcod}
                name={dataRating?.contratado?.nome}
                imagem={dataRating?.contratado?.imagem}
                rating={dataRating}
                refetchRating={refetchRating}
                key={dataRating?.hashcod}
              />
            </Grid2>
          ))}

        {isSuccess && data.length === 0 && <h1>Não há avaliações pendentes</h1>}
        {isLoading && (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}
      </Grid2>
    </Box>
  )
}
