import { Button, Divider, Link, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useProfessionalProfileQuery } from 'store/Professional/api'
import {
	Container,
	Header,
	BackButton,
	Wrapper,
	CardContainer,
	ContainerInfo,
	Info,
	CommonContainer,
	Topic,
	ContainerDownloadCurriculum,
} from './styles'
import ProfessionalCard from '../ProfessionalCard'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { formatPhone } from 'utils'
import WhatsAppIcon from '../../../../assets/images/whatsapp.png'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import FileSaver from 'file-saver'
import IsExclusive from './IsExclusive'

interface ProfessionalProps {
	hashcode: string
	title: string
	onUnselectProfessional: () => void
	SlotCardContainer?: JSX.Element
	isExclusive?: boolean
}

const Professional = ({
	isExclusive,
	hashcode,
	title,
	onUnselectProfessional,
	children,
	SlotCardContainer,
}: PropsWithChildren<ProfessionalProps>) => {
	const { data: professional } = useProfessionalProfileQuery(hashcode)
	const { warningSnackbar } = useCustomSnackbar()
	if (!professional) return null

	const downloadFile = async () => {
		if (!professional) return

		if (!professional.curriculo_doc) {
			warningSnackbar('Profissional não enviou arquivo de currículo')
			return
		}

		const [, fileName] =
			professional.curriculo_doc.match(/(?:documentos\/)(.*)/) ?? []

		FileSaver.saveAs(professional.curriculo_doc, fileName)
	}

	const formatForWhatsApp = (phone: string) => {
		return phone.replaceAll(/\(?\)?\s?\-?/g, '').trim()
	}

	return (
		<Container>
			<Header>
				<BackButton onClick={onUnselectProfessional}>
					<ArrowBackIosIcon />
				</BackButton>
				<Typography fontSize="1.1rem">{title}</Typography>
			</Header>
			<Wrapper>
				<CardContainer>
					<ProfessionalCard professional={professional} />
					{SlotCardContainer}
				</CardContainer>
				<Divider />
			</Wrapper>
			<ContainerInfo>
				<Info>
					{!!professional?.experiencia_usuario.length && (
						<CommonContainer>
							<Topic>Experiência:</Topic>
							<div>
								{professional?.experiencia_usuario.map((item) => (
									<Typography fontSize="0.9rem">
										{item.get_areaatuacao} - {item.get_moduloatuacao} -{' '}
										{item.get_nivel} - R$ {item.valor_hora} -{' '}
										{item.projetos_executados}{' '}
										{item.projetos_executados === 1 ? 'projeto' : 'projetos'}
									</Typography>
								))}
							</div>
						</CommonContainer>
					)}
					{!!professional?.atuacao_usuario.length && (
						<CommonContainer>
							<Topic>Local de Atuação:</Topic>
							<div>
								{professional?.atuacao_usuario.map((item) => (
									<Typography fontSize="0.9rem">
										{item.estado}/{item.cidade || 'Todas'}
									</Typography>
								))}
							</div>
						</CommonContainer>
					)}
					{!!professional?.lingua_usuario.length && (
						<CommonContainer>
							<Topic>Idiomas:</Topic>
							<div>
								{professional?.lingua_usuario.map((item) => (
									<Typography fontSize="0.9rem">
										{item.get_lingua} - {item.get_nivel}
									</Typography>
								))}
							</div>
						</CommonContainer>
					)}
					{!!professional?.email && (
						<CommonContainer>
							<Topic>Email:</Topic>
							<IsExclusive isExclusive={isExclusive}>
								<Link href={'mailto:' + professional.email}>
									{professional.email}
								</Link>
							</IsExclusive>
						</CommonContainer>
					)}
					{!!professional?.telefone && (
						<CommonContainer>
							<Topic>Telefone:</Topic>
							<IsExclusive isExclusive={isExclusive}>
								<Typography fontSize="0.9rem">
									+{professional.get_ddi} {formatPhone(professional.telefone)}{' '}
									<Link
										href={
											'https://wa.me/' +
											formatForWhatsApp(professional.get_whatsapp) +
											'?text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20da%20plataforma%20JOB%20Connect%21'
										}
										target="_blank"
									>
										<img src={WhatsAppIcon} alt="" style={{ width: '15px' }} />
									</Link>
								</Typography>
							</IsExclusive>
						</CommonContainer>
					)}
				</Info>
				{children}
			</ContainerInfo>
			<IsExclusive empty isExclusive={isExclusive}>
				<ContainerDownloadCurriculum>
					<Button variant="contained" onClick={downloadFile}>
						Baixar currículo
					</Button>
				</ContainerDownloadCurriculum>
			</IsExclusive>
			<CommonContainer style={{ padding: '0 1rem 1rem', }}>
				<Topic>Currículo:</Topic>
				<IsExclusive isExclusive={isExclusive}>
					<div
						style={{ wordBreak: 'break-word' }}
						dangerouslySetInnerHTML={{
							__html: professional?.descricao?.replace(/\n/g, '<br />')!,
						}}
					/>
				</IsExclusive>
			</CommonContainer>
		</Container>
	)
}

export default Professional
