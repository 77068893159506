import styled from 'styled-components'
import { Button } from 'components/Atoms/Button'
import { Accordion as MUIAccordion } from '@mui/material'

export const CityFilterContainer = styled.div`
  display: flex;
`

export const CityFilterAddButton = styled(Button)`
  width: 35px;
`

export const Title = styled.label`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #4a4a4a;
`

export const Accordion = styled(MUIAccordion)`
  z-index: 1;
`
