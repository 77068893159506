import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from '../actions'

export const initialState: any = {
  company: {},
  companyActivities: [],
  cnpjInfo: {},
  certificates: [],
  companyForm: {},
  status: 'idle',
  error: null,
}

export const plugNotasSlice = createSlice({
  name: 'plugNotas',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    setCompany: (state, action: PayloadAction<any>) => {
      state.company = action?.payload
    },
    setCompanyProfileForm: (state, action: PayloadAction<any>) => {
      state.companyProfileForm = action?.payload
    },
    setCompanyActivities: (state, action: PayloadAction<any>) => {
      state.companyActivities = action?.payload
    },
    setCnpjInfo: (state, action: PayloadAction<any>) => {
      state.cnpjInfo = action?.payload
    },
    setCertificates: (state, action: PayloadAction<any>) => {
      state.certificates = action?.payload
    },
  },
})

export const {
  setCompany,
  setCnpjInfo,
  setCertificates,
  setCompanyActivities,
  setCompanyProfileForm,
} = plugNotasSlice.actions

export default plugNotasSlice.reducer
