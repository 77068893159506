import { useLocation, Navigate, Outlet } from 'react-router-dom'

import { AuthTemplate } from 'components'
import { Role } from '../utils/Enums'
import { RootState } from 'store'
import { useSelector } from 'react-redux'

const AuthRoute = ({ allowedRoles }: { allowedRoles: Array<Role> }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth)
  const location = useLocation()
  const { role: userRole } = useSelector((state: RootState) => state.userState)

  if (!isAuthenticated || !userRole)
    return <Navigate to="/login" state={{ from: location }} replace />

  if (!allowedRoles.some((allowedRole) => userRole === allowedRole))
    return <Navigate to="/not-found" state={{ from: location }} replace />

  return (
    <AuthTemplate>
      <Outlet />
    </AuthTemplate>
  )
}

export default AuthRoute
