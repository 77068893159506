import { PropsWithChildren } from 'react'
import { ContractedUser, ProfessionalProfileResponse } from 'types'
import {
	Container,
	Information,
	Profile,
	Actions,
	SelectionProfessional,
} from './styles'
import { Avatar, Rating, Typography } from '@mui/material'
import PersonIcon from '@material-ui/icons/Person'

interface ProfessionalCardProps {
	professional: ContractedUser | ProfessionalProfileResponse
	onSelectProfessional?: () => void
}

const ProfessionalCard = ({
	professional,
	onSelectProfessional,
	children,
}: PropsWithChildren<ProfessionalCardProps>) => {
	const isOneProjectMade = professional?.projetos_realizados === 1
	const isOneProjectInProgress = professional?.projetos_andamento === 1

	return (
		<Container>
			<Profile>
				<Avatar src={professional?.imagem} style={{ width: '80px', height: '80px' }}>
					{!professional?.imagem && (<strong style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>{professional?.nome?.slice(0, 1)}</strong>)}
				</Avatar>
				<Information>
					<Rating value={professional?.pontuacao} readOnly size="small" />
					<Typography fontWeight={600} lineHeight={1.1}>
						{professional?.nome}
					</Typography>
					<Typography lineHeight={1.1}>
						Reside em: {professional?.cidade}/{professional?.estado}
					</Typography>
					<Typography lineHeight={1.1}>
						{professional?.projetos_andamento}{' '}
						{isOneProjectInProgress ? 'projeto' : 'projetos'} em andamento
					</Typography>
					<Typography lineHeight={1.1}>
						{professional?.projetos_realizados}{' '}
						{isOneProjectMade ? 'projeto realizado' : 'projetos realizados'}
					</Typography>
				</Information>
			</Profile>
			<Actions>
				{children}
				{!!onSelectProfessional && (
					<SelectionProfessional onClick={onSelectProfessional}>
						<PersonIcon style={{ width: 60, height: 60, color: '#3f8ef6' }} />
						<Typography fontWeight={600} color="#3f8ef6">
							Ver profissional
						</Typography>
					</SelectionProfessional>
				)}
			</Actions>
		</Container>
	)
}

export default ProfessionalCard
