import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'

import {
  ContractedUserRequest,
  ContractedUserResponse,
  ExperienceRequest,
  ExperienceResponse,
  OperationAreaLevelOptionResponse,
  OperationAreaOptionResponse,
  OperationAreaRequest,
  OperationAreaResponse,
} from 'types'
import {
  APP_STORAGE_PREFIX,
  APP_API_URI,
  APP_API_VERSION,
  OPERATION_RADIUS,
  USER_OPERATION_AREA,
  OPERATION_AREA,
  USER_EXPERIENCE,
  OPERATION_AREA_LEVELS,
} from 'utils/Constants'
import { StorageGetItem } from 'utils/Storage'

export const personalDataRedux = createApi({
  reducerPath: 'personalDataRedux',
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_API_URI}`,
    prepareHeaders: (headers) => {
      const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))

      if (token) headers.set('Authorization', `JWT ${token}`)

      return headers
    },
  }),
  endpoints: (builder) => ({
    getPersonalData: builder.query<ContractedUserResponse, void>({
      query: () => `${APP_API_VERSION}/usuario/update/`,
    }),
    getUpdatedPersonalData: builder.mutation({
      query: () => `${APP_API_VERSION}/usuario/update/`,
    }),
    updatePersonalData: builder.mutation<any, ContractedUserRequest>({
      queryFn: async (
        { curriculo_doc, imagem, ...others },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const body = new FormData()

        if (curriculo_doc) body.append('curriculo_doc', curriculo_doc)

        if (imagem) body.append('imagem', imagem)

        Object.entries(others).forEach(([key, value]) => {
          if (value) body.append(key, String(value))
        })

        const result = await fetchWithBQ({
          url: `${APP_API_VERSION}/usuario/update/`,
          method: 'PATCH',
          body,
        })

        return result.data
          ? { data: result.data }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getUserExperiencies: builder.query<ExperienceResponse[], void>({
      query: () => `${USER_EXPERIENCE}`,
    }),
    addUserExperience: builder.mutation<ExperienceResponse, ExperienceRequest>({
      query: (post) => ({
        url: `${USER_EXPERIENCE}`,
        method: 'POST',
        body: post,
      }),
    }),
    deleteExperience: builder.mutation<void, string>({
      query: (hash) => ({
        url: `${USER_EXPERIENCE}${hash}/`,
        method: 'DELETE',
      }),
    }),
    editOperationRadius: builder.mutation<any, number>({
      query: (radius) => ({
        url: OPERATION_RADIUS,
        method: 'PUT',
        body: { raio_atuacao: radius },
      }),
    }),
    addOperationArea: builder.mutation<void, OperationAreaRequest>({
      query: ({ isRemote, stateUF, city }) => {
        const body = new FormData()

        if (isRemote) {
          body.append('alocacao_remota', String(isRemote))
          body.append('estado', '')
          body.append('cidade', '')
        } else {
          body.append('estado', stateUF)
          body.append('cidade', city === 'Todas' ? '' : city)
        }

        return {
          url: USER_OPERATION_AREA,
          method: 'POST',
          body,
        }
      },
    }),
    getUserOperationAreas: builder.query<OperationAreaResponse[], void>({
      query: () => `${USER_OPERATION_AREA}`,
    }),
    getOperationAreaOptions: builder.query<OperationAreaOptionResponse[], void>(
      {
        query: () => `${OPERATION_AREA}`,
      }
    ),
    getOperationAreaLevelOptions: builder.query<
      OperationAreaLevelOptionResponse[],
      void
    >({
      query: () => `${OPERATION_AREA_LEVELS}`,
    }),
    deleteUserOperationArea: builder.mutation<void, string>({
      query: (hash) => ({
        url: `${USER_OPERATION_AREA}${hash}/`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetPersonalDataQuery,
  useGetUpdatedPersonalDataMutation,
  useUpdatePersonalDataMutation,
  useGetUserExperienciesQuery,
  useAddUserExperienceMutation,
  useDeleteExperienceMutation,
  useEditOperationRadiusMutation,
  useAddOperationAreaMutation,
  useGetUserOperationAreasQuery,
  useGetOperationAreaOptionsQuery,
  useDeleteUserOperationAreaMutation,
  useGetOperationAreaLevelOptionsQuery,
} = personalDataRedux
