import * as yup from 'yup'

import { REQUIRED_MSG } from 'utils/Constants'

const validationSchema = yup.object().shape({
  lingua: yup.string().nullable().required(REQUIRED_MSG),
  nivel: yup.string().nullable().required(REQUIRED_MSG),
})

export default validationSchema
