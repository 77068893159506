import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Skeleton,
} from '@mui/material'
import { useGetProjectsToCalendarQuery } from 'store/SearchVacancies'
import Row from './Row'
import { useGetCalendarInvitesQuery } from 'store/WeInvitateVacanciesJob'
import { useMemo } from 'react'

export const CalendarContratante = () => {
	const { data: projetos, isLoading } = useGetProjectsToCalendarQuery()
	function SkeletonRowItem() {
		return (
			<>
				<TableRow>
					<TableCell>
						<Skeleton variant="circular" width={24} height={24} />
					</TableCell>
					<TableCell>
						<Skeleton variant="rectangular" width={210} height={18} />
					</TableCell>
					<TableCell>
						<Skeleton variant="rectangular" width={210} height={18} />
					</TableCell>
					<TableCell>
						<Skeleton variant="rectangular" width={210} height={18} />
					</TableCell>
					<TableCell>
						<Skeleton variant="rectangular" width={210} height={18} />
					</TableCell>
					<TableCell>
						<Skeleton variant="rectangular" width={210} height={18} />
					</TableCell>
				</TableRow>
			</>
		)
	}

	function CollapsibleTable() {
		return (
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow>
							<TableCell />
							<TableCell>Vagas de emprego</TableCell>
							<TableCell>Data de cadastrado</TableCell>
							<TableCell>Data de ínicio</TableCell>
							<TableCell>Data limite de submissão</TableCell>
							<TableCell>Data de término</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading && SkeletonRowItem()}
						{projetos?.map((projeto) => (
							<Row key={projeto.hashcod} projeto={projeto} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}

	return <>{CollapsibleTable()}</>
}
