import { useContext, useCallback } from 'react'

import DropDown from '../../Atoms/DropDown'
import { Box, Button, Grid, Typography } from '@mui/material'
import InputMask from 'react-input-mask'

import InputText from '../../Atoms/InputText'
import { ProfileContext } from '../../../store/Profile/Professional/Context'
import '../globalMolecules.scss'

const AddressForm = () => {
  const { formValues, handleNext, handleBack } = useContext(ProfileContext)

  const { cep, rua, numero, bairro, cidade, estado } = formValues

  const isError = useCallback(
    () =>
      Object.keys({ cep, rua, numero, bairro, cidade, estado }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, cep, rua, numero, bairro, cidade, estado]
  )

  return (
    <>
      <Typography variant="h6" component="div" gutterBottom>
        Endereço
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <InputMask
            mask="99.999-9999"
            value={cep.value}
            disabled={false}
            maskChar=""
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.target.value
                ? { ...formValues, cep: event.target.value }
                : null
            }}
          >
            {() => (
              <InputText
                label="CEP"
                name="cep"
                placeholder="Informe seu cep"
                value={cep.value}
                // changeHandler={handleChange}
                // error={!!cep.error}
                // helperText={cep.error}
                required={cep.required}
              />
            )}
          </InputMask>
        </Grid>

        <Grid item xs={12} md={6}>
          <InputText
            label="Rua"
            name="rua"
            placeholder="Informe sua rua"
            value={rua?.value ? rua?.value : ''}
            // changeHandler={handleChange}
            // error={!!rua.error}
            // helperText={rua.error}
            required={rua.required}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputText
            label="Número"
            name="numero"
            placeholder="Informe o número"
            value={numero?.value ? numero?.value : ''}
            // changeHandler={handleChange}
            // error={!!numero.error}
            // helperText={numero.error}
            required={numero.required}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <InputText
            label="Bairro"
            name="bairro"
            placeholder="Informe o bairro"
            value={bairro?.value ? bairro?.value : ''}
            // changeHandler={handleChange}
            // error={!!bairro.error}
            // helperText={bairro.error}
            required={bairro.required}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputText
            label="Cidade"
            name="cidade"
            placeholder="Informe a cidade"
            value={cidade?.value ? cidade?.value : ''}
            // changeHandler={handleChange}
            // error={!!cidade.error}
            // helperText={cidade.error}
            required={cidade.required}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          {/* s */}
        </Grid>
      </Grid>

      <Typography variant="subtitle1" component="div" gutterBottom>
        *O endereço cadastrado aqui é usado como base para o seu raio de
        atuação.
      </Typography>

      <Grid container spacing={2}>
        <Grid container xs={12} md={11} justifyContent="center">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button onClick={handleBack} sx={{ mr: 1 }} className={'btn-back'}>
              Voltar
            </Button>

            <Button
              variant="contained"
              sx={{ mt: 3, ml: 1 }}
              disabled={isError()}
              color="primary"
              onClick={!isError() ? handleNext : () => null}
            >
              Próximo
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default AddressForm
