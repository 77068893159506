import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

interface MaterialDialogProps {
  open: boolean
  cancelText?: any
  onClose: () => void
  onConfirm: () => void
  confirmText?: string
  dialogBodyText?: string
  dialogTitleText?: string
}

const MaterialDialog: React.FC<MaterialDialogProps> = ({
  open,
  onClose,
  onConfirm,
  dialogBodyText,
  cancelText = 'Cancelar',
  confirmText = 'Confirmar',
  dialogTitleText = 'Confirmação',
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitleText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogBodyText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancelText && <Button onClick={onClose}>{cancelText}</Button>}
          {confirmText && (
            <Button
              className="light-blue-button"
              onClick={onConfirm}
              variant="outlined"
              autoFocus
            >
              {confirmText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default MaterialDialog
