const defaultTheme = {
  title: 'default',
  
  colors: {
    primary: {
      default: '#274490',
      // light: '',
      // dark: ''
    },
    secondary: {
      default: '#6f85bd',
      // light: '',
      // dark: ''
    },
    contrast: {
      default: '#1769aa',
      light: '#1769aa',
      lightBlue: '#1769aa',
      dark: '#1769aa',
      minimum: '#1769aa'
    },
    status: {
      primary: '#464DF2',
      secondary: '#607D8B',
      success: '#689F38',
      info: '#0288D1',
      warning: '#FBC02D',
      danger: '#D32F2F',
    },
    button: {
      primary: '#464DF2',
      secondary: '#607D8B',
      success: '#689F38',
      info: '#0288D1',
      warning: '#FBC02D',
      danger: '#D32F2F',
    },
    // brand: {
    //   primaryLight: '',
    //   secondary: ''
    // },
    // shade: {
    //   black: '',
    //   gray: ''
    // },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
      title: '#464df2',
      paragraph: "#121212",
      selected: '#464DF2',
    },

    base: {
      maximum: '#000000',
      strong: '#383838',
      middle: '#80848A',
      weak: '#C3CBD9',
      thin: '#f1f4f5',
      minimum: '#FFFFFF',
    },

    card: {
      background: '#ffffff',
    }
  }
}

export default defaultTheme
