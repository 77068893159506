import { useContext } from "react"
import { ServiceContext } from ".."
import AdditionalServiceForm from "data/components/AdditionalServiceForm"
import { ServiceList } from "../List"
import Form from "../Form"

export const FormOrList = () => {
	const {
		serviceForm,
		displayFields,
		setDisplayFields,
	} = useContext(ServiceContext)


	if (displayFields.serviceForm)
		return <Form serviceForm={serviceForm} setDisplayField={setDisplayFields} />

	return <ServiceList />
}