import { useCallback, useEffect } from 'react'
import { TextField, MenuItem } from '@mui/material'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { useFormik } from 'formik'

import { BRAZIL_STATES } from 'utils/Constants'
import { INITIAL_VALUES } from './constants'
import { setLoading } from 'store/Layout/slice'
import { useUpdatePersonalDataMutation } from 'store/PersonalData'
import { useDispatch, useSelector } from 'react-redux'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { ContractedUserRequest } from 'types'
import { Button } from 'components/Atoms/Button'
import validationSchema from './validations'
import '../../../styles.scss'
import { pick } from 'lodash'
import { RootState } from 'store'

const AddressTab = () => {
	const dispatch = useDispatch()
	const { warningSnackbar, successSnackbar } = useCustomSnackbar()
	const [editUser, { isLoading: editPersonalDataIsLoading }] =
		useUpdatePersonalDataMutation()

	const { loading } = useSelector((state: RootState) => state.layout)
	const { loggedUser, refetchLoggedUser } = useSelector(
		(state: RootState) => state.userState
	)

	const onSubmit = useCallback(
		(values: ContractedUserRequest) => {
			if (!values.cidade) return

			editUser(values)
				.unwrap()
				.then(() => {
					successSnackbar('Endereço alterado com sucesso')
				})
				.then(refetchLoggedUser)
				.catch(() => {
					warningSnackbar('Erro ao alterar o enderço')
				})
		},
		[editUser, refetchLoggedUser, successSnackbar, warningSnackbar]
	)

	const { errors, handleChange, handleSubmit, setValues, values } = useFormik({
		validationSchema,
		initialValues: INITIAL_VALUES,
		onSubmit,
		validateOnChange: false,
		validateOnBlur: false,
	})

	useEffect(() => {
		if (!loggedUser) return

		setValues((current) => ({
			...current,
			...pick(loggedUser, [
				'email',
				'bairro',
				'cidade',
				'estado',
				'cep',
				'numero',
				'rua',
			]),
		}))
	}, [loggedUser, setValues])

	useEffect(() => {
		dispatch(setLoading(editPersonalDataIsLoading))

		return () => {
			dispatch(setLoading(false))
		}
	}, [dispatch, editPersonalDataIsLoading])

	useEffect(() => {
		if (values.cep?.length !== 8) return
			; (async () => {
				const VIA_CEP_URL = `https://viacep.com.br/ws/${values.cep}/json/`
				try {
					const data = await fetch(VIA_CEP_URL)
					const response = await data.json()
					if (!!response.erro) {
						setValues({
							...values,
							rua: '',
							bairro: '',
							cidade: '',
							estado: '',
							numero: '',
						})
						throw new Error()
					}

					setValues({
						...values,
						rua: response.logradouro,
						bairro: response.bairro,
						cidade: response.localidade,
						estado: response.uf,
					})
				} catch (error) {
					warningSnackbar('CEP não encontrado.')
				}
			})()
		// eslint-disable-next-line
	}, [values.cep, setValues, warningSnackbar])

	return (
		<form onSubmit={handleSubmit}>
			<div className="horinzontal">
				<PatternFormat
					customInput={TextField}
					label="CEP"
					variant="standard"
					value={values.cep?.replace('-', '')}
					onValueChange={({ value }) => handleChange('cep')(value)}
					error={!!errors.cep}
					helperText={errors.cep}
					margin="dense"
					format={'#####-###'}
					valueIsNumericString
				/>
				<div className="row">
					<TextField
						style={{ width: '78%' }}
						name="rua"
						label="Rua"
						disabled
						variant="standard"
						value={values.rua}
						onChange={handleChange}
						error={!!errors.rua}
						helperText={errors.rua}
						margin="dense"
					/>

					<NumericFormat
						customInput={TextField}
						style={{ width: '20%' }}
						label="Número"
						variant="standard"
						name="numero"
						value={values.numero}
						onValueChange={({ value }) => handleChange('numero')(value)}
						error={!!errors.numero}
						helperText={errors.numero}
						margin="dense"
						allowNegative={false}
						decimalScale={0}
					/>
				</div>

				<TextField
					name="bairro"
					label="Bairro"
					value={values.bairro}
					disabled
					variant="standard"
					onChange={handleChange}
					error={!!errors.bairro}
					helperText={errors.bairro}
					margin="dense"
				/>

				<div className="row">
					<TextField
						style={{ width: '78%' }}
						name="cidade"
						label="Cidade"
						variant="standard"
						value={values.cidade}
						disabled
						onChange={handleChange}
						error={!!errors.cidade}
						helperText={errors.cidade}
						margin="dense"
					/>

					<TextField
						style={{ width: '20%' }}
						select
						name="estado"
						label="Estado"
						value={values.estado}
						variant="standard"
						onChange={handleChange}
						disabled
						error={!!errors.estado}
						helperText={errors.estado}
						margin="dense"
					>
						{BRAZIL_STATES.map((option) => (
							<MenuItem key={`est-${option.value}`} value={option.uf}>
								{option.uf}
							</MenuItem>
						))}
					</TextField>
				</div>
			</div>

			<Button
				loading={loading || editPersonalDataIsLoading}
				type={'submit'}
				buttonType="primary"
				style={{ width: '25%', marginTop: 16 }}
			>
				Atualizar
			</Button>
		</form>
	)
}

export default AddressTab
