import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from '../actions'
import { ContractedUserResponse, ExperienceResponse } from 'types'
import { Role } from 'utils/Enums'
import { Auth } from '../../utils/@Types'
import { IAuthState } from '../../utils/Interfaces'

const initialState: IAuthState = {
	isLoggedOut: false,
	loggedUser: null,
	refetchLoggedUser: null,
	user: null,
	selectedExperience: null,
	role: Role.EMPTY,
	userRating: 0,
}

export const userSlice = createSlice({
	initialState,
	name: 'JOB-Connect/userSlice',
	extraReducers: (builder) => builder.addCase(clearState, () => initialState),
	reducers: {
		setLogout: (state, action: PayloadAction<boolean>) => {
			state.isLoggedOut = action.payload
		},
		logout: () => initialState,
		setUser: (state, action: PayloadAction<Auth>) => {
			state.user = action.payload
		},
		setLoggedUser: (state, action: PayloadAction<ContractedUserResponse>) => {
			state.loggedUser = action.payload
		},
		setRefetchLoggedUser: (state, action: PayloadAction<() => void>) => {
			state.refetchLoggedUser = action.payload
		},
		setSelectedExperience: (
			state,
			action: PayloadAction<ExperienceResponse | null>
		) => {
			state.selectedExperience = action.payload
		},
		setUserRole: (state, action: PayloadAction<Role>) => {
			state.role = action.payload
		},
		setUserRating: (state, action: PayloadAction<number>) => {
			state.userRating = action.payload
		},
	},
})

export default userSlice.reducer

export const {
	logout,
	setLoggedUser,
	setRefetchLoggedUser,
	setUser,
	setSelectedExperience,
	setUserRole,
	setUserRating,
	setLogout
} = userSlice.actions
