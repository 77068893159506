import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'

import { sideBarMenuItem } from '../../../../utils/Interfaces'
import { RootState } from 'store'
import { Badge, Tooltip } from '@mui/material'
import routes from 'utils/Constants/routes'

const SideBarItem = ({
	handleCloseSidebar,
	item,
	selected,
}: SideBarItemProps): JSX.Element => {
	const quantityStore = useSelector((state: RootState) =>
		item.badge ? state[item.badge.store] : undefined
	)
	const navigate = useNavigate()
	const [isMouseOver, setIsMouseOver] = useState(false)

	const { badge, MenuIcon, text, options, route } = item

	const handleOnMouseOver = () => {
		isMouseOver ? setIsMouseOver(true) : setIsMouseOver(false)
	}

	const handleLinkRoute = (route: string | Function | undefined) => {
		if (!route) return

		if (typeof route === 'string') navigate(route)
		else route()
	}

	if (route === routes.HUNTING_PROCESS) {
		return (
			<ListItem
				disablePadding
				key={text + selected}
				className="list-item"
				onMouseOver={handleOnMouseOver}
				onMouseOut={handleCloseSidebar}
			>
				<Tooltip
					title="Disponível em breve..."
					arrow
					PopperProps={{
						modifiers: [
							{
								name: 'offset',
								options: {
									offset: [0, -20]
								}
							}
						]
					}}
				>
					<ListItemButton
						sx={{ borderLeftColor: selected ? 'white !important' : '' }}
						onClick={() => { }}
					>
						<ListItemIcon>
							{!!badge && !!quantityStore ? (
								<Badge
									badgeContent={quantityStore[badge.attribute]}
									color="primary"
								>
									<MenuIcon />
								</Badge>
							) : (
								<MenuIcon />
							)}
						</ListItemIcon>

						<ListItemText
							sx={{ fontSize: 18, whiteSpace: 'initial' }}
							primary={text}
						/>
					</ListItemButton>
				</Tooltip>
				{isMouseOver && (
					<div className="selected-sub-itens">
						{options?.map(({ text, MenuIcon, route }) => (
							<ListItemButton key={text} onClick={() => handleLinkRoute(route)}>
								<ListItemIcon>
									<MenuIcon />
								</ListItemIcon>

								<ListItemText primary={text} />
							</ListItemButton>
						))}
					</div>
				)}
			</ListItem>
		)
	}

	return (
		<ListItem
			disablePadding
			key={text + selected}
			className="list-item"
			onMouseOver={handleOnMouseOver}
			onMouseOut={handleCloseSidebar}
		>
			<ListItemButton
				sx={{ borderLeftColor: selected ? 'white !important' : '' }}
				onClick={() => handleLinkRoute(route)}
			>
				<ListItemIcon>
					{!!badge && !!quantityStore ? (
						<Badge
							badgeContent={quantityStore[badge.attribute]}
							color="primary"
						>
							<MenuIcon />
						</Badge>
					) : (
						<MenuIcon />
					)}
				</ListItemIcon>

				<ListItemText
					sx={{ fontSize: 18, whiteSpace: 'initial' }}
					primary={text}
				/>
			</ListItemButton>

			{isMouseOver && (
				<div className="selected-sub-itens">
					{options?.map(({ text, MenuIcon, route }) => (
						<ListItemButton key={text} onClick={() => handleLinkRoute(route)}>
							<ListItemIcon>
								<MenuIcon />
							</ListItemIcon>

							<ListItemText primary={text} />
						</ListItemButton>
					))}
				</div>
			)}
		</ListItem>
	)
}

interface SideBarItemProps {
	handleCloseSidebar: () => void
	item: sideBarMenuItem
	selected: boolean
}

export default SideBarItem
