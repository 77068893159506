import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from '../actions'

interface WeFoundYourJobStore {
  jobQuantity?: number
}

export const initialState: WeFoundYourJobStore = {
  jobQuantity: undefined,
}

export const WeFoundYourJobSlice = createSlice({
  name: 'weFoundYourJob',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    setWeFoundYourJobQuantity: (state, action: PayloadAction<number>) => {
      state.jobQuantity = action?.payload
    },
  },
})

export const { setWeFoundYourJobQuantity } = WeFoundYourJobSlice.actions

export default WeFoundYourJobSlice.reducer
