import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from '../actions'

import { RootState } from '../index'
import { BrazilStateModel } from './types'

export interface StatesModel {
  brazilStates: BrazilStateModel[]
}

export interface LocationModel {
  states: StatesModel
}

export const defaultLocationStates = {
  brazilStates: [],
}

export const initialState: LocationModel = {
  states: defaultLocationStates,
}

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    getBrazilStates: (state, action: PayloadAction<BrazilStateModel[]>) => {
      state.states.brazilStates = action?.payload
    },
  },
})

export const { getBrazilStates } = locationSlice.actions

export const selectBrazilStates = (state: RootState) =>
  state.location.states.brazilStates

export default locationSlice.reducer
