import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from 'store/Layout/slice'
import { useGetUpdatedPersonalDataMutation } from 'store/PersonalData'
import {
  setPlanUserState,
  setPlanUserApllyQtdState,
} from 'store/PersonalData/slice'

const usePlanServices = () => {
  const dispatch = useDispatch()
  const [getUpdatedPersonalData] = useGetUpdatedPersonalDataMutation()
  const { warningSnackbar, successSnackbar } = useCustomSnackbar()

  const updatePlanInfo = () => {
    dispatch(setLoading(true))

    getUpdatedPersonalData('')
      .unwrap()
      .then((response) => {
        const { plano, qtde_oportunidades } = response

        dispatch(setPlanUserState(plano))
        dispatch(setPlanUserApllyQtdState(qtde_oportunidades))
      })
      .catch(() => {
        warningSnackbar(
          'Erro ao atualizar informações de plano. Tente novamente mais tarde.'
        )

        dispatch(setLoading(false))
      })
  }

  return { updatePlanInfo }
}

export default usePlanServices
