import { AddressTabForm } from './types'

export const INITIAL_VALUES: AddressTabForm = {
  bairro: '',
  cep: '',
  cidade: '',
  estado: '',
  numero: '',
  rua: '',
}
