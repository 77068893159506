import { getTecnoSpeedValidCnaes } from 'data/utils/Company/static'
import { store } from '..'
import plugNotasApi from './api'
import {
  setCertificates,
  setCnpjInfo,
  setCompany,
  setCompanyActivities,
} from './slice'
import { Company } from './types'

export interface TecnoSpeedCity {
  id: number
  uf: string
  nome: string
}

export interface PostCertificateBody {
  arquivo: string
  senha: string
  email?: string
}

export type Certificate = {
  id: string
  nome: string
  hash: string
  vencimento: string
  cnpj: string
}

type ResponseCertificate = {
  data: {
    id: string
  }
}

type ClientNote = {
  cpfCnpj: string
  email: string
  razaoSocial: string
  endereco: Address
}

type Address = {
  codigoPais: string
  descricaoPais: string
  cep: string
  tipoLogradouro: string
  numero: string
  bairro: string
  logradouro: string
  codigoCidade: string
  estado: string
}

const injectedPlugNotasApi = plugNotasApi.injectEndpoints({
  endpoints: (build) => ({
    //Auxiliares
    getCities: build.query<TecnoSpeedCity[], string | void>({
      query: () => '/nfse/cidades',
    }),
    getCepInfo: build.query<any, string>({
      query: (cep) => `/cep/${cep}`,
    }),
    getCnpjInfo: build.query<any, string>({
      query: (cnpj) => `/cnpj/${cnpj}`,
      transformResponse: (response: any) => {
        const cnaes = getTecnoSpeedValidCnaes(response?.atividades)

        store.dispatch(setCnpjInfo(response))
        store.dispatch(setCompanyActivities(cnaes))

        return response
      },
    }),
    getCNPJ: build.query<Company, string>({
      query: (cnpj) => `/cnpj/${cnpj}`,
    }),
    //Certificates
    getCertificate: build.query<Certificate, string>({
      query: (certificateId) => '/certificado/' + certificateId,
      providesTags: ['CERTIFICATE'],
    }),
    getCertificates: build.query<any, any>({
      query: () => '/certificado',
      transformResponse: (response: any) => {
        store.dispatch(setCertificates(response))
        return response
      },
    }),
    postCertificate: build.mutation<ResponseCertificate, FormData>({
      query: (certificateInfo) => ({
        method: 'POST',
        url: '/certificado',
        body: certificateInfo,
      }),
      invalidatesTags: ['CERTIFICATE'],
    }),
    putCertificate: build.mutation<ResponseCertificate, any>({
      query: (body) => ({
        method: 'PUT',
        url: `/certificado/${body?.id}`,
        body: body?.newCertificate,
        contentType: 'multipart/form-data',
      }),
    }),
    deleteCertificate: build.mutation<any, any>({
      query: (certiticateId) => ({
        method: 'DELETE',
        url: `/certificado/${certiticateId}`,
      }),
      invalidatesTags: ['CERTIFICATE'],
    }),
    //Empresa
    getCompany: build.query<any, string>({
      query: (cnpj) => `/empresa/${cnpj}`,
      transformResponse: (response) => {
        store.dispatch(setCompany(response))
        return response
      },
    }),
    getCompanies: build.query<any, any>({
      query: () => `/empresa`,
    }),
    deleteCompany: build.mutation<any, string>({
      query: (cnpj) => {
        return {
          url: `/empresa/${cnpj}`,
          method: 'DELETE',
        }
      },
    }),
    postCompany: build.mutation<any, any>({
      query: (companyInfo) => ({
        method: 'POST',
        url: '/empresa',
        body: companyInfo,
      }),
      invalidatesTags: ['CERTIFICATE'],
    }),
    putCompany: build.mutation<any, any>({
      query: (companyInfo) => ({
        method: 'PATCH',
        url: `/empresa/${companyInfo?.cpfCnpj}`,
        body: companyInfo,
      }),
    }),
    //Tomador
    getNoteClient: build.query<ClientNote | any, any>({
      query: (cnpj) => `/nfse/tomador/${cnpj}`,
    }),
    postNoteClient: build.mutation<any, any>({
      query: (noteClientInfo) => ({
        method: 'POST',
        url: '/nfse/tomador',
        body: noteClientInfo,
      }),
    }),
    //Notas
    getNotesByPeriod: build.query<any, any>({
      query: (periodInfo) => ({
        method: 'GET',
        url: `/nfse/consultar/periodo?cpfCnpj=${periodInfo.cpfCnpj}&dataInicial=${periodInfo.initialDate}&dataFinal=${periodInfo.finalDate}`,
      }),
    }),
    postNfse: build.mutation<any, any>({
      query: (noteInfo) => ({
        url: '/nfse',
        method: 'POST',
        body: noteInfo,
      }),
    }),
    sendNoteByEmail: build.mutation<any, any>({
      query: ({ noteId, sendEmailInfo }) => ({
        method: 'POST',
        body: sendEmailInfo,
        url: `/nfse/email/${noteId}`,
      }),
    }),
    getNotePdf: build.query<any, any>({
      query: (noteId) => ({
        method: 'GET',
        url: `/nfse/pdf/${noteId}`,
        responseHandler: (response) => response.arrayBuffer(),
      }),
    }),
    postCancelNote: build.mutation<any, any>({
      query: (noteId) => ({
        method: 'POST',
        url: `/nfse/cancelar/${noteId}`,
      }),
    }),
    consultarNotaEmitida: build.query<any, number>({
      query: (noteId) => ({
        url: '/nfse/' + noteId
      })
    })
  }),
})

export const {
  useGetCitiesQuery,
  useGetCepInfoQuery,
  useLazyGetCitiesQuery,
  useGetNotePdfQuery,
  useLazyGetNotePdfQuery,
  useGetCnpjInfoQuery,
  usePostNfseMutation,
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  usePutCompanyMutation,
  usePostCompanyMutation,
  useGetCertificatesQuery,
  useGetCertificateQuery,
  useLazyGetCnpjInfoQuery,
  useGetNotesByPeriodQuery,
  usePostNoteClientMutation,
  usePutCertificateMutation,
  useLazyGetNoteClientQuery,
  usePostCancelNoteMutation,
  usePostCertificateMutation,
  useDeleteCertificateMutation,
  useGetCNPJQuery,
  useLazyGetCNPJQuery,
  useDeleteCompanyMutation,
  useLazyConsultarNotaEmitidaQuery
} = injectedPlugNotasApi

export default injectedPlugNotasApi
