import { Typography } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: 100%;
`

export const Header = styled.header`
  padding: 1rem 1rem 0.5rem;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #ccc;
`

export const BackButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
`

export const Wrapper = styled.div`
  padding: 0 1rem;
`

export const CardContainer = styled.div`
  display: flex;
`

export const ContainerInfo = styled.section`
  display: flex;
  padding: 0.5rem 1rem;
`

export const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const CommonContainer = styled.section`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
`

export const Topic = styled(Typography)`
  font-weight: 600 !important;
  color: #000;
  margin-bottom: 0.5rem;
  font-size: 1.1rem !important;
`

export const ContainerDownloadCurriculum = styled.div`
  display: flex;
  justify-content: center;
`
