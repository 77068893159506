import React, { createContext, useReducer } from 'react'
import { initialValues } from './initialValues'

const isText = RegExp(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/i)
const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
const isPhone = RegExp(/^\D?(\d{2})\D?\D?(\d{4,6})\D?(\d{4})$/) // us
const isZip = RegExp(/^([\d]{2})([\d]{3})([\d]{3})|^[\d]{2}.[\d]{3}-[\d]{3}$/) // us
const isNumber = RegExp(/^\d+$/)
const isPassword = RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,12}$/)

export declare type ValidationSchema = Record<
  string,
  {
    value?: any
    error?: string
    required?: boolean
    validate?:
      | 'text'
      | 'number'
      | 'email'
      | 'phone'
      | 'senha'
      | 'cep'
      | 'checkbox'
      | 'select'
    minLength?: number
    maxLength?: number
    helperText?: string
  }
>

type ContextProps = {
  activeStep: number
  formValues: ValidationSchema
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean
  ) => void
  handleNext: () => void
  handleBack: () => void
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ProfileContext = createContext<ContextProps>({
  activeStep: 0,
  formValues: initialValues,
  handleChange() {},
  handleNext() {},
  handleBack() {},
  handleFileChange() {},
})

interface ProviderProps {
  children: React.ReactNode
}

type State = {
  activeStep: number
  formValues: ValidationSchema
}

type Action =
  | { type: 'increase' }
  | { type: 'decrease' }
  | { type: 'form-value'; name: string; fieldValue: any }
  | { type: 'form-error'; name: string; error: string }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'increase':
      return {
        ...state,
        activeStep: state.activeStep + 1,
      }
    case 'decrease':
      return {
        ...state,
        activeStep: state.activeStep - 1,
      }
    case 'form-value':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            value: action.fieldValue,
          },
        },
      }
    case 'form-error':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            error: action.error,
          },
        },
      }

    default:
      return state
  }
}

export function StepsProvider({ children }: ProviderProps) {
  const [{ activeStep, formValues }, dispatch] = useReducer(reducer, {
    activeStep: 0,
    formValues: initialValues,
  })

  // Proceed to next step
  const handleNext = () => dispatch({ type: 'increase' })
  // Go back to prev step
  const handleBack = () => dispatch({ type: 'decrease' })

  // Handle form change
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean
  ) => {
    const { type, name, value } = event.target
    const fieldValue = type === 'checkbox' ? checked : value

    dispatch({ type: 'form-value', name, fieldValue })

    const fieldName = initialValues[name]
    if (!fieldName) return

    const { required, validate, minLength, maxLength, helperText } = fieldName

    let error = ''

    if (required && !fieldValue) error = 'Este campo é obrigatório'
    if (minLength && value && value.length < minLength)
      error = `Mínimo ${minLength} caracteres permitido.`
    if (maxLength && value && value.length > maxLength)
      error = 'Máximo de caracteres excedido!'
    if (validate) {
      switch (validate) {
        case 'text':
          if (value && !isText.test(value))
            error = helperText || 'Este campo aceita apenas texto.'
          break

        case 'number':
          if (value && !isNumber.test(value))
            error = helperText || 'Este campo aceita apenas números.'
          break

        case 'email':
          if (value && !isEmail.test(value))
            error = helperText || 'Informe um email válido.'
          break

        case 'phone':
          if (value && !isPhone.test(value))
            error =
              helperText || 'Informe o numero corretamente. e: xx xxxxx-xxxx'
          break

        case 'senha':
          if (value && !isPassword.test(value))
            error = helperText || 'Tamanho mínimo 6 caracteres.'
          break

        case 'cep':
          if (value && !isZip.test(value))
            error = helperText || 'Informe seu CEP corretamente'
          break

        case 'checkbox':
          if (!checked) error = helperText || 'Selecione um ou mais item'
          break

        case 'select':
          if (!value) error = helperText || 'Selecione um item'
          break

        default:
          break
      }
    }

    dispatch({ type: 'form-error', name, error })
  }

  // Handle form file change
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]

    // if(fileObj){
    //   dispatch({ type: 'form-value', event.target.name, 'value' })
    // }

    dispatch({ type: 'form-value', name: 'image', fieldValue: 'value-file' })
  }

  return (
    <ProfileContext.Provider
      value={{
        activeStep,
        formValues,
        handleChange,
        handleNext,
        handleBack,
        handleFileChange,
      }}
    >
      <div className="mui-step-form">{children}</div>
    </ProfileContext.Provider>
  )
}
