import { Fragment } from 'react'
import {
  Card,
  CardActions,
  Grid,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from '@mui/material'
import { useGetPromotionQuery } from '../../store/Promotion'

import './styles.scss'

export const Promotion = () => {
  const { data, error, isLoading } = useGetPromotionQuery('')

  return (
    <Fragment>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {data?.map((promotions) => (
          <Grid item xs={3}>
            <Card
              className="promotion-card"
              sx={{ maxWidth: 345 }}
              key={promotions.hashcod}
            >
              <div>
                <CardMedia
                  className="style-img"
                  component="img"
                  height="120"
                  image={promotions.imagem}
                  alt="green iguana"
                />

                <CardContent>
                  <Typography
                    className="promotion-title"
                    gutterBottom
                    variant="h5"
                    component="div"
                  >
                    {promotions.titulo}
                  </Typography>

                  <Typography
                    className="promotion-description"
                    variant="body2"
                    color="text.secondary"
                  >
                    {promotions.descricao}
                  </Typography>
                </CardContent>
              </div>

              <CardActions>
                <Button
                  className="promotion-button"
                  size="small"
                  target="_blank"
                  href={promotions.link}
                >
                  Aproveitar a promoção
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  )
}
