import {
  Avatar,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  Rating,
  Stack,
  Typography,
} from '@mui/material'
import { ProfessionalResponse } from 'types'

const ProfessionalCard = ({
  disabled,
  imagem,
  nome,
  estado,
  cidade,
  projetos_andamento,
  projetos_realizados,
  onClick,
  pontuacao,
}: ProfessionalCardProps) => (
  <Card>
    <CardActionArea disabled={disabled} onClick={onClick}>
      <CardContent>
        <Stack direction="row" spacing={2}>
          <Avatar
            sx={{
              width: 96,
              height: 96,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
            alt="Remy Sharp"
            src={imagem}
          >
            {imagem ? undefined : nome.charAt(0)}
          </Avatar>

          <div>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              textOverflow={'ellipsis'}
            >
              {nome}
            </Typography>

            <Rating value={pontuacao} readOnly size="small" />

            <Typography variant="body2" color="text.secondary" mb="8px">
              Reside em: {estado}/{cidade}
            </Typography>

            <Typography variant="body2" color="text.secondary" mb="8px">
              {projetos_andamento} projetos em andamento
            </Typography>

            <Typography variant="body2" color="text.secondary" mb="8px">
              {projetos_realizados} projetos realizados
            </Typography>
          </div>
        </Stack>
      </CardContent>
    </CardActionArea>
  </Card>
)

interface ProfessionalCardProps
  extends Pick<
      ProfessionalResponse,
      | 'imagem'
      | 'nome'
      | 'estado'
      | 'cidade'
      | 'projetos_andamento'
      | 'projetos_realizados'
      | 'pontuacao'
    >,
    Pick<CardActionAreaProps, 'onClick' | 'disabled'> {}

export default ProfessionalCard
