import styled from 'styled-components'
import { BREAKPOINT } from 'styles/constants'

export const Container = styled.div`
  padding: 0 2rem 2rem 2rem;
  display: flex;
  flex: 1;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  width: 100%;

  .container-horinzontal {
    display: contents;
  }

  .itens-container {
    width: 100%;
  }

  .container-icon-favorite {
    color: #ff3555;
    font-size: 42px;
    cursor: pointer;
  }
  .container-icon-favorite:hover {
    color: #1565c0;
  }

  .vaga-container-list {
    max-height: 1000px;
    background: ${({ theme }) => theme.colors.base.thin};
    padding: 10px 25px 10px 25px;

    > * {
      margin-bottom: 12px;
    }

    @media (min-width: ${BREAKPOINT.MD}px) {
      overflow-y: auto;
    }

    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  .vaga-container-list:hover {
    ::-webkit-scrollbar {
      width: 0.6rem;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }

  .vaga-container {
    .item-container {
      height: 200px;
      display: flex;
      flex-direction: row;

      .image-container-empresa {
        display: flex;
        margin-left: 2rem;
        align-items: center;
      }

      .container-datail-vaga {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 2rem;
      }
    }
  }
`

export const ColumnDivider = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
export const RowDivider = styled.div`
  gap: 20px;
  flex: 1;
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
`
export const ContainerTablePagination = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      padding: 0; 
      font-size: 14px;
    }
  }
`
