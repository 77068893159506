import styled from 'styled-components'
import Box from '@mui/material/Box'

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 1rem 1rem 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.base.thin};
`
