import * as yup from 'yup'

import { REQUIRED_MSG } from 'utils/Constants'
import { Moment } from 'moment'
import { isAfter, isBefore } from 'date-fns'

const validationSchema = yup.object().shape({
	nome: yup.string().required(REQUIRED_MSG),
	limite_submissao: yup
		.mixed<Moment>()
		.test(
			'invalid-first-date',
			'Data de submissão inválida',
			(value) => !!value && value.isValid()
		)
		.test(
			'invalid-dates',
			'Data final da candidatura deve ser igual ou anterior à data final de alocação',
			function compare(lastDateSubmission) {
				const lastAllocationDate = this.parent.data_final

				if (!lastAllocationDate?._isValid) return true
				return !!lastDateSubmission?.isSameOrBefore(lastAllocationDate)
			}
		)
		.test(
			'maximum-30-days',
			'Data final para candidatura não pode ser maior que 30 dias',
			function compare(submissionDate) {
				const today = new Date();
				const todayPass30days = new Date(new Date(today).setDate(today.getDate() + 30));

				return isBefore(submissionDate?.toDate()!, todayPass30days);
			})
		.required(REQUIRED_MSG)
		.typeError('Data limite de submissão inválida'),
	data_inicio: yup.mixed<Moment>().required(REQUIRED_MSG),
	data_final: yup.mixed<Moment>().typeError('data final inválida'),
	alocacao_remota: yup.boolean().required(REQUIRED_MSG),
	estado: yup.string().test('req-state', REQUIRED_MSG, function (value) {
		return this.parent.alocacao_remota || !!value
	}),
	cidade: yup.string().test('req-city', REQUIRED_MSG, function (value) {
		return this.parent.alocacao_remota || !!value
	}),
	dedicacao_diaria: yup.string().required(REQUIRED_MSG),
	pagamento: yup.string().nullable(),
	tipo_contratacao: yup.string().required(REQUIRED_MSG),
	numero_profissionais: yup.number().required(REQUIRED_MSG).min(1, "É necessário no mínimo 1 profissional para a vaga"),
	sobre_projeto: yup.string().required(REQUIRED_MSG),
	sobre_empresa: yup.string().required(REQUIRED_MSG),
})

export default validationSchema
