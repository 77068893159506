import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { StringParam, useQueryParams } from 'use-query-params'
import { Stack } from '@mui/material'
import { isEmpty } from 'lodash'

import { useGetSearchJobsQuery } from 'store/SearchVacancies'
import Filters from '../Filters'
import { VacancyItem } from '../VacancyItem'
import { JobResponse } from '../../../../types/index'
import JobDetails from 'components/Molecules/JobDetails'
import { setLoading } from 'store/Layout/slice'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import SkeletonJob from 'components/Molecules/SkeletonJob'
import Pagination from 'components/Organisms/Pagination'
import { PageParam, PageSizeParam } from 'utils/Constants'
import * as S from './styles'
import { Column, JobList, Row } from '../style'

const SearchVacancies = () => {
  const dispatch = useDispatch()
  const { warningSnackbar } = useCustomSnackbar()
  const [query, setQuery] = useQueryParams({
    h: StringParam,
    p: PageParam,
    ps: PageSizeParam,
  })
  const { h: selectedJobHashcod, p: page, ps: pageSize } = query

  const [needToScroll, setNeedToScroll] = useState(!!selectedJobHashcod)

  const [selectedJob, setSelectedJob] = useState<JobResponse>()
  const [selectedFilter, setSelectedFilter] = useState<any>('')
  const { data, isSuccess, isLoading, refetch, status } =
    useGetSearchJobsQuery(selectedFilter)

  const dataVagas = useMemo(
    () => data?.filter((item) => !item.estou_na_vaga && item.status === 'P'),
    [data]
  )

  const filteredData = useMemo(() => {
    if (selectedFilter === '') return dataVagas

    return dataVagas?.filter((item) => {
      return (
        item.nome
          .toLowerCase()
          .includes(selectedFilter.palavras_chave.toLowerCase()) &&
        (selectedFilter.remoto
          ? item.alocacao_remota === selectedFilter.remoto
          : true)
      )
    })
  }, [dataVagas, selectedFilter])

  const paginatedData = useMemo(() => {
    return filteredData?.slice(page * pageSize, (page + 1) * pageSize) ?? []
  }, [filteredData, page, pageSize])

  useEffect(() => {
    if (isLoading || !filteredData) return

    if (isEmpty(filteredData)) return setSelectedJob(undefined)

    if (selectedJobHashcod) {
      const foundItem = filteredData.find(
        ({ hashcod }) => hashcod === selectedJobHashcod
      )

      if (foundItem) setSelectedJob(foundItem)
      else {
        setSelectedJob(filteredData[0])
      }
    } else {
      setSelectedJob(filteredData[0])
    }
  }, [isLoading, selectedJobHashcod, filteredData, warningSnackbar])

  useEffect(() => {
    setQuery({ h: selectedJob?.hashcod })
  }, [selectedJob, setQuery])

  useEffect(() => {
    if (needToScroll && isSuccess && selectedJobHashcod) {
      document
        .getElementById(selectedJobHashcod)
        ?.scrollIntoView({ behavior: 'smooth' })

      setNeedToScroll(false)
    }
  }, [isSuccess, needToScroll, selectedJobHashcod])

  useEffect(() => {
    return () => setQuery({ h: undefined, p: undefined, ps: undefined })
  }, [setQuery])

  useEffect(() => {
    dispatch(setLoading(isLoading || status === 'pending'))

    return () => {
      dispatch(setLoading(false))
    }
  }, [dispatch, isLoading, status])

  return (
    <>
      <Row>
        {isLoading ? (
          <SkeletonJob />
        ) : (
          <Column>
            <Filters setSelectedFilter={setSelectedFilter}></Filters>

            {!!data?.length && (
              <>
                <JobList>
                  {paginatedData.map((item) => (
                    <VacancyItem
                      id={item.hashcod}
                      key={item.hashcod}
                      item={item}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                        setSelectedJob(item)
                      }}
                      isSelected={selectedJob?.hashcod == item.hashcod}
                    />
                  ))}
                </JobList>
              </>
            )}
          </Column>
        )}

        {selectedJob && (
          <JobDetails
            job={selectedJob}
            hasBtnApplyJob={true}
            dataJob={dataVagas}
            index={
              paginatedData?.findIndex(
                (item) => item.hashcod === selectedJob.hashcod
              ) as number
            }
            refetchData={refetch}
          />
        )}
      </Row>

      <Pagination
        count={filteredData?.length ?? 0}
        page={isLoading ? 0 : page}
        onPageChange={(_, newPage) => setQuery({ p: newPage })}
        onRowsPerPageChange={(event) =>
          setQuery({ p: 0, ps: Number(event.target.value) })
        }
        rowsPerPage={pageSize}
      />
    </>
  )
}

export default SearchVacancies
