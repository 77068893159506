import React from 'react'
import MyResponsibilities from '../MyResponsibilities'
import ResponsibilityStart from '../ResponsibilityStart'

import './styles.scss'

const Responsibilities: React.FC = () => {
  return (
    <div className="responsabilities-container">
      <ResponsibilityStart />
      <MyResponsibilities />
    </div>
  )
}

export default Responsibilities
