import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material'
import NextWeekIcon from '@mui/icons-material/NextWeek'
import LogoutIcon from '@mui/icons-material/Logout'
import EditIcon from '@mui/icons-material/Edit'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DifferenceIcon from '@mui/icons-material/Difference'
import DeleteIcon from '@mui/icons-material/Delete'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import moment from 'moment'
import validationSchema from './validations'

import { StripedTableRow } from 'components'
import { JobResponse } from 'types'
import {
	useRepublishJobMutation,
	useSetJobProgressMutation,
	useSetJobFinishedStatusMutation,
	useDeleteJobMutation,
	searchVacanciesRedux,
} from 'store/SearchVacancies'
import { useEffect, useMemo, useState } from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import { useFormik } from 'formik'
import { RepublishJobForm } from './types'
import { INITIAL_VALUES } from './constants'
import { useDispatch } from 'react-redux'
import { setLoading } from 'store/Layout/slice'
import { useNavigate } from 'react-router-dom'
import routes from 'utils/Constants/routes'
import Cell from './Cell'
import JobApplicationModal from './JobApplicationModal'
import JobSuggestionsModal from './JobSuggestionsModal'
import { compareAsc, compareDesc, parse, parseISO } from 'date-fns'
import { store } from 'store'

const DataTable = ({ data, type, refetchData }: DataTableProps) => {
	const [projectHashcode, setProjectHashcode] = useState('')
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [setJobProgress, { isLoading: setJobProgressIsLoading }] =
		useSetJobProgressMutation()
	const [setJobFinishedStatus, { isLoading: setJobFinishedStatusIsLoading }] =
		useSetJobFinishedStatusMutation()
	const [republishJob, { isLoading: republishJobIsLoading }] =
		useRepublishJobMutation()
	const [deleteJob, { isLoading: deleteJobIsLoading }] = useDeleteJobMutation()
	const { warningSnackbar, successSnackbar, infoSnackbar } = useCustomSnackbar()
	const [jobToRepublishHashcod, setJobToRepublishHashcod] = useState<string>()
	const [showModalToRepublishJob, setShowModalToRepublishJob] = useState(false)
	const [hashcodeSuggestions, setHashcodeSuggestions] = useState('')

	const jobs = useMemo(() => {
		if (!data.length) return []
		const response = [...data].sort((firstJob, secondJob) => {
			const prevDate = parseISO(firstJob.data_cad)
			const nextDate = parseISO(secondJob.data_cad)

			return compareDesc(prevDate, nextDate)
		})

		return response
	}, [data])

	const handleApplicationModal = (hashcode: string) => {
		setProjectHashcode(hashcode)
	}

	const handleCloseApplicationModal = () => {
		setProjectHashcode('')
	}

	const handleRepublishJob = ({
		finalDate,
		submissionLimit,
	}: RepublishJobForm) => {
		if (!finalDate || !submissionLimit)
			warningSnackbar('Preencha corretamente as datas')
		else {
			dispatch(setLoading(true))

			republishJob({
				finalDate: finalDate.toDate(),
				hashcod: jobToRepublishHashcod as string,
				submissionLimit: submissionLimit.toDate(),
			})
				.unwrap()
				.then(refetchData)
				.then(() => {
					successSnackbar('Oportunidade republicada com sucesso!')
					setShowModalToRepublishJob(false)
				})
				.catch((err) => {
					warningSnackbar(err.data?.error)
				})
		}
	}

	const putJobInProgress = (item: JobResponse) => {
		dispatch(setLoading(true))

		setJobProgress({
			hashcod: item.hashcod,
			status: 'E',
		})
			.unwrap()
			.then(refetchData)
			.then(() => {
				successSnackbar('Oportunidade colocada em andamento com sucesso!')
			})
			.catch((err) => {
				if (err.data[0] === 'PROFISSIONAIS_EXCEDEM_PROJETO')
					warningSnackbar('Profissionais excedem projeto.')
				else if (err.data[0] === 'PROFISSIONAIS_EXCEDEM_PROJETO_PENDENTES')
					warningSnackbar('Profissionais excedem projetos pendentes.')
				else
					warningSnackbar(
						'Erro ao colocar oportunidade em andamento. Tente novamente mais tarde.'
					)
			})
	}

	const closeJob = (item: JobResponse) => {
		dispatch(setLoading(true))

		setJobFinishedStatus({
			hashcod: item.hashcod,
			status: 'F',
		})
			.unwrap()
			.then(refetchData)
			.then(() => {
				successSnackbar('Oportunidade encerrada com sucesso!')
				infoSnackbar(
					'Você será redirecionado (a) para avaliar os profissionais que estavam na vaga.	'
				)
				navigate('/rating-tech')
			})
			.catch(() => {
				warningSnackbar(
					'Erro ao encerrar vaga de emprego. Tente novamente mais tarde.'
				)
			})
	}

	const handleDeleteJob = (hashcod: string) => {
		deleteJob(hashcod)
			.unwrap()
			// .then(refetchData)
			.then(() => {
				successSnackbar('Oportunidade apagada com sucesso!')
			})
			.catch(() => {
				warningSnackbar(
					'Erro ao apagar oportunidade. Tente novamente mais tarde.'
				)
			})
	}

	const onSubmit = (values: RepublishJobForm) => {
		handleRepublishJob(values)
	}

	const { errors, handleSubmit, setFieldValue, setValues, values } =
		useFormik<RepublishJobForm>({
			validationSchema,
			initialValues: INITIAL_VALUES,
			onSubmit,
			validateOnChange: false,
			validateOnBlur: false,
		})

	useEffect(() => {
		dispatch(
			setLoading(
				setJobProgressIsLoading ||
				setJobFinishedStatusIsLoading ||
				republishJobIsLoading ||
				deleteJobIsLoading
			)
		)

		return () => {
			dispatch(setLoading(false))
		}
	}, [
		dispatch,
		setJobProgressIsLoading,
		setJobFinishedStatusIsLoading,
		republishJobIsLoading,
		deleteJobIsLoading,
	])

	if (!jobs) return null

	return (
		<>
			<Table
				sx={{
					minWidth: 650,
					display: 'flex',
					flexDirection: 'column',
					marginTop: 4,
					maxHeight: 'calc(100% - 101px - 202px)',
					boxShadow: '3px 3px 0px 0px rgba(0,0,0,0.07)',
				}}
			>
				<TableHead
					style={{
						position: 'sticky',
						top: 0,
						background: '#fffffe',
					}}
				>
					<TableRow style={{ display: 'flex' }}>
						<Cell isColumn>Visualizar</Cell>
						<Cell isColumn>Copiar para Publicar</Cell>
						<Cell isColumn>Descrição da vaga de emprego</Cell>
						<Cell isColumn>Cliente</Cell>
						<Cell isColumn>Data Final de Candidaturas</Cell>
						<Cell isColumn>Data Final da Vaga de Emprego</Cell>
						<Cell isColumn>Profissionais Sugeridos</Cell>
						<Cell isColumn>Candidaturas</Cell>
						{type === 'published' && (
							<>
								<Cell isColumn>Colocar Em Andamento</Cell>
							</>
						)}
						{type === 'inProgress' && (
							<Cell isColumn>Encerrar vaga de emprego</Cell>
						)}
						{type === 'finished' && <Cell isColumn>Republicar</Cell>}

						{type !== 'finished' && <Cell isColumn>Editar</Cell>}
						{type !== 'inProgress' && <Cell isColumn>Apagar</Cell>}
					</TableRow>
				</TableHead>
				<TableBody style={{ height: '100%', overflow: 'auto' }}>
					{jobs.map((item, index) => (
						<StripedTableRow
							key={item.hashcod + index}
							sx={{
								'&:last-child td, &:last-child th': { border: 0 },
								display: 'flex',
							}}
						>
							<Cell>
								<button
									onClick={() =>
										navigate(`${routes.TECH_JOB_UPDATE}/${item.hashcod}`, {
											state: 'view',
										})
									}
								>
									<RemoveRedEyeIcon
										style={{ cursor: 'pointer', color: '#1876d1' }}
									/>
								</button>
							</Cell>
							<Cell>
								<button
									onClick={() =>
										navigate(`${routes.TECH_JOB_UPDATE}/${item.hashcod}`, {
											state: 'copy',
										})
									}
								>
									<DifferenceIcon
										style={{ cursor: 'pointer', color: '#1876d1' }}
									/>
								</button>
							</Cell>
							<Cell>{item.nome}</Cell>

							<Cell>{item.cliente}</Cell>

							<Cell>{moment(item.limite_submissao).format('DD/MM/YYYY')}</Cell>

							<Cell>
								{item.data_final
									? moment(item.data_final).format('DD/MM/YYYY')
									: 'indeterminado'}
							</Cell>

							<Cell>
								<Chip
									onClick={() => setHashcodeSuggestions(item.hashcod)}
									disabled={!item.numero_profissionais_sugeridos}
									label={item.numero_profissionais_sugeridos}
									color="primary"
									style={{
										...(item.solicitacoes_pendentes_convite && {
											background: '#ff4b52',
										}),
									}}
								/>
							</Cell>

							<Cell>
								<Chip
									onClick={() => handleApplicationModal(item.hashcod)}
									label={item.numero_candidaturas}
									style={{
										...(item.candidaturas_pendentes && {
											background: '#ff4b52',
										}),
									}}
									color="primary"
									disabled={!item.numero_candidaturas}
								/>
							</Cell>

							{type === 'published' && (
								<Cell>
									<IconButton
										color="primary"
										onClick={() => putJobInProgress(item)}
										disabled={
											item.numero_profissionais_alocados <
											item.numero_profissionais
										}
										style={
											item.numero_profissionais_alocados <
												item.numero_profissionais
												? {}
												: { color: '#11c26d' }
										}
									>
										<NextWeekIcon />
									</IconButton>
								</Cell>
							)}

							{type === 'inProgress' && (
								<Cell>
									<IconButton
										color="primary"
										onClick={() => closeJob(item)}
										disabled={!item.pode_encerrar}
										style={!!item.pode_encerrar ? { color: '#11c26d' } : {}}
									>
										<LogoutIcon />
									</IconButton>
								</Cell>
							)}

							{type === 'finished' && (
								<Cell>
									<IconButton
										color="primary"
										onClick={() => {
											setJobToRepublishHashcod(item.hashcod)

											setValues({
												finalDate: item.data_final
													? moment(item.data_final)
													: null,
												submissionLimit: item.limite_submissao
													? moment(item.limite_submissao)
													: null,
											})

											setShowModalToRepublishJob(true)
										}}
									>
										<AutorenewIcon />
									</IconButton>
								</Cell>
							)}

							{type !== 'finished' && (
								<Cell>
									<IconButton
										color="primary"
										onClick={() =>
											navigate(`${routes.TECH_JOB_UPDATE}/${item.hashcod}`)
										}
									>
										<EditIcon />
									</IconButton>
								</Cell>
							)}

							{type !== 'inProgress' && (
								<Cell>
									<IconButton
										color="primary"
										onClick={() => handleDeleteJob(item.hashcod)}
									>
										<DeleteIcon />
									</IconButton>
								</Cell>
							)}
						</StripedTableRow>
					))}
				</TableBody>
			</Table>

			<Dialog
				open={showModalToRepublishJob}
				onClose={() => setShowModalToRepublishJob(false)}
			>
				<form onSubmit={handleSubmit}>
					<DialogTitle id="alert-dialog-title">
						Selecione as novas datas
					</DialogTitle>

					<DialogContent>
						<DialogContentText id="alert-dialog-description"></DialogContentText>

						<Stack spacing={3} sx={{ mt: '16px', minWidth: '300px' }}>
							<DesktopDatePicker
								label="Data final"
								inputFormat="DD/MM/YYYY"
								value={values.finalDate}
								onChange={(date) => setFieldValue('finalDate', date)}
								minDate={moment(new Date())}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!!errors.finalDate}
										helperText={errors.finalDate}
									/>
								)}
							/>

							<DesktopDatePicker
								minDate={moment(new Date())}
								label="Data limite para submissão"
								inputFormat="DD/MM/YYYY"
								value={values.submissionLimit}
								onChange={(date) => setFieldValue('submissionLimit', date)}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!!errors.submissionLimit}
										helperText={errors.submissionLimit}
									/>
								)}
							/>
						</Stack>
					</DialogContent>

					<DialogActions>
						<Button onClick={() => setShowModalToRepublishJob(false)}>
							Cancelar
						</Button>

						<Button type="submit" autoFocus>
							Confirmar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			{!!projectHashcode && (
				<JobApplicationModal
					hashcode={projectHashcode}
					onClose={handleCloseApplicationModal}
				/>
			)}

			{!!hashcodeSuggestions && (
				<JobSuggestionsModal
					onClose={() => setHashcodeSuggestions('')}
					projectHashcode={hashcodeSuggestions}
				/>
			)}
		</>
	)
}

interface DataTableProps {
	data: JobResponse[]
	type: 'published' | 'inProgress' | 'finished'
	refetchData: () => void
}

export default DataTable
