import { Card, CardActions, Grid, Typography } from '@mui/material'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import {
	useGetUserInfosQuery,
	useSolicitarPlanoUserMutation,
} from '../../store/UserType'

import PROFESSIONAL_SERVICES from '../../assets/contracts/portfolio_profissionais.pdf'

import { Button } from 'components/Atoms/Button'
import { Container, PlanTopic, TopicsWrapper } from './styles'
import Services from 'data/components/Services'
import { useLocation } from 'react-router-dom'

export const PlansContratado = () => {
	const { pathname } = useLocation();
	const { data, refetch } = useGetUserInfosQuery('')
	const [solicitarPlanoUser] = useSolicitarPlanoUserMutation()
	const { successSnackbar, infoSnackbar } = useCustomSnackbar()

	const planoAtual = data?.plano
	const planoSolicitado = data?.plano_solicitado

	const handleSolicitarPlanoUser = (emailValue, planoValue, link) => {
		solicitarPlanoUser({ emailValue, planoValue })
			.unwrap()
			.then(() => {
				successSnackbar('Parabéns, você solicitou um novo plano')
			})
			.then(() => {
				refetch()
			})
			.then(() => {
				link && window.open(link)
			})
	}

	return (
		<Container>
			{pathname !== '/home' && (
				<><Services file={PROFESSIONAL_SERVICES} /> <div style={{ paddingTop: '1rem' }}></div></>
			)}

			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, mt: 2 }}>
				{plansContratado?.map((itemPlano, index) => (
					<Grid key={itemPlano.titulo} item xs={3}>
						<Card
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
								borderRadius: '28px',
							}}
							key={index}
						>
							<h4
								translate="no"
								style={{
									padding: '12px',
									backgroundColor: '#274490',
									textAlign: 'center',
									color: 'white',
									fontSize: '2.125rem',
									fontWeight: 400,
								}}
							>
								{itemPlano.titulo}
							</h4>

							<TopicsWrapper>
								{itemPlano.descricao.map((item) => (
									<PlanTopic key={item}>{item}</PlanTopic>
								))}
							</TopicsWrapper>
							<Typography
								variant="h6"
								color="text.secondary"
								sx={{ marginTop: 6, textAlign: 'center' }}
							>
								{itemPlano.preco}
							</Typography>

							<CardActions sx={{ width: '100%', padding: '18px', mt: 'auto' }}>
								{planoAtual === itemPlano.plano_solicitado && (
									<Button
										style={{
											width: '100%',
											border: 'none',
											borderTopLeftRadius: '24px',
											borderBottomRightRadius: '24px',

										}}
										buttonType="success"
										onClick={() => infoSnackbar('Esse já é o seu plano atual')}
									>
										Plano atual
									</Button>
								)}

								{planoSolicitado === itemPlano.plano_solicitado &&
									planoSolicitado !== planoAtual && (
										<Button
											style={{
												width: '100%',
												border: 'none',
												borderTopLeftRadius: '24px',
												borderBottomRightRadius: '24px',
											}}
											buttonType="warning"
											onClick={() =>
												infoSnackbar('Você já solicitou este plano')
											}
										>
											Plano solicitado
										</Button>
									)}

								{itemPlano.plano_solicitado !== planoSolicitado &&
									itemPlano.plano_solicitado !== planoAtual && (
										<Button
											style={{
												width: '100%',
												border: 'none',
												borderTopLeftRadius: '24px',
												borderBottomRightRadius: '24px',
											}}
											buttonType="primary"
											onClick={() =>
												handleSolicitarPlanoUser(
													data.email,
													itemPlano.plano_solicitado,
													itemPlano.link
												)
											}
										>
											Solicitar plano
										</Button>
									)}
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	)
}

const plansContratado = [
	{
		titulo: 'Basic',
		descricao: [
			'Seu perfil será sugerido como “quarta opção” para todas as vagas de emprego publicadas pelos recrutadores dentro do seu perfil.',
			'Você poderá se candidatar para até “5 vagas de emprego que desejar por mês”.',
			'Terá acesso aos dados de contato para “até 5 recrutadores por mês”, como Whatsapp, Telefone e e-mail.',
			'Seu perfil aparecerá como “quarta opção” nas buscas feitas pelos recrutadores no banco de talentos.',
			'Acesso ilimitado ao emissor de NFS-e automático pela plataforma, mais fácil e rápido!',
		],
		preco: 'Custo: Gratuito',
		plano_solicitado: 0,
	},
	{
		titulo: 'Advanced',
		descricao: [
			'Seu perfil será sugerido como “terceira opção” para todas as vagas de emprego publicadas pelos recrutadores dentro do seu perfil.',
			'Você poderá se candidatar para até “10 vagas de emprego que desejar por mês”.',
			'Terá acesso aos dados de contato para “até 10 recrutadores por mês”, como Whatsapp, Telefone e e-mail.',
			'Seu perfil aparecerá como “terceira opção” nas buscas feitas pelos recrutadores no banco de talentos.',
			'Acesso ilimitado ao emissor de NFS-e automático pela plataforma, mais fácil e rápido!',
		],
		preco: 'Custo: R$ 9,90/mês',
		plano_solicitado: 1,
		link: 'https://jobc.com.br/product/advanced-profissional/',
	},
	{
		titulo: 'Premium',
		descricao: [
			'Seu perfil será sugerido como “segunda opção” para todas as vagas de emprego publicadas pelos recrutadores dentro do seu perfil.',
			'Você poderá se candidatar para até “20 vagas de emprego que desejar por mês”.',
			'Terá acesso aos dados de contato para “até 20 recrutadores por mês”, como Whatsapp, Telefone e e-mail.',
			'Seu perfil aparecerá como “segunda opção” nas buscas feitas pelos recrutadores no banco de talentos.',
			'Acesso ilimitado ao emissor de NFS-e automático pela plataforma, mais fácil e rápido!',
		],
		preco: 'Custo: R$ 19,90/mês',
		plano_solicitado: 2,
		link: 'https://jobc.com.br/product/premium-profissional/',
	},
	{
		titulo: 'Exclusive',
		descricao: [
			'Seu perfil será sugerido como “primeira opção” para todas as vagas de emprego publicadas pelos recrutadores dentro do seu perfil.',
			'Você poderá se candidatar em todas as vagas de emprego que desejar',
			'Acesso aos dados de contato de todos os recrutadores, como Whatsapp, telefone e e-mail.',
			'Seu perfil aparecerá como “primeira opção” nas buscas de profissionais pelos recrutadores.',
			'Acesso ilimitado ao emissor de NFS-e automático pela plataforma, mais fácil e rápido!',
		],
		preco: 'Custo: R$ 29,90/mês',
		plano_solicitado: 3,
		link: 'https://jobc.com.br/product/exclusive-profissional/',
	},
]
