import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
`

export const Header = styled.header`
  padding: 1rem;
  /* border-bottom: 0.5px solid #ccc; */
  display: flex;
  /* gap: 1.5rem; */
  position: relative;
  border-bottom: 1.5px solid #ccc;
`

export const CloseButton = styled.button`
  outline: none;
  border: none;
  background: none;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
`

export const Content = styled.section`
  padding-left: 1rem;
  height: 100%;
  overflow-y: auto;
`
