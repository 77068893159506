import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, store } from "store";
import { setLogout } from "store/User/UserSlice";
import { clearAuth } from "utils/Storage";

const ExpiredSession = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const isLoggedOut = useSelector((state: RootState) => state.userState.isLoggedOut)
	const isLoginScreen = pathname === '/login' || pathname === '/'

	const handleLogout = useCallback(() => {
		navigate('/logout');
	}, [navigate])

	useEffect(() => {
		if (isLoggedOut && isLoginScreen) {
			store.dispatch(setLogout(false))
			clearAuth();
		}
	}, [isLoggedOut, isLoginScreen])

	if (!isLoggedOut || isLoginScreen) return null;

	return (
		<Dialog open>
			<DialogTitle>Sua sessão expirou</DialogTitle>
			<DialogContent>
				Você efetuou login em outro local,
				com isso sua sessão foi expirada,
				caso deseje continuar navegando por aqui,
				efetue login novamente.
			</DialogContent>
			<DialogActions>
				<Button onClick={handleLogout}>Efetuar login</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ExpiredSession;