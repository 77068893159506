import { CardContent } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  img {
    height: 80px;
    width: 100%;
  }
`

export const Topics = styled(CardContent)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const Topic = styled.p`
  font-size: 1rem;
`
