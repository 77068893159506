import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import { useCallback, useState } from 'react'
import ChevronLeftRounded from '@mui/icons-material/ChevronLeftRounded';
import IconButton from '@mui/material/IconButton';;
import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded';
import { Container, Wrapper } from './styles';

type Props = {
	file: any
}

const Services = ({ file }: Props) => {
	const [page, setPage] = useState(1);
	const [numberOfPages, setNumberOfPages] = useState(null);

	const handleLoadDocument = useCallback(({ numPages }) => setNumberOfPages(numPages), [])

	return (
		<Container>
			<Wrapper>
				<IconButton onClick={() => setPage(page => page - 1)} disabled={page === 1} color='primary'>
					<ChevronLeftRounded fontSize='large' />
				</IconButton>
			</Wrapper>
			<div style={{ flex: 1 }}>
				<Document file={file} onLoadSuccess={handleLoadDocument}>
					<Page pageNumber={page} height={600} />
				</Document>
			</div>
			<Wrapper>
				<IconButton onClick={() => setPage(page => page + 1)} disabled={page === numberOfPages} color='primary'>
					<ChevronRightRounded fontSize='large' />
				</IconButton>
			</Wrapper>
		</Container>
	)
}

export default Services;