import {
  Container,
  CancelIcon,
  CheckCircleIcon,
  MarkunreadIcon,
  ExitToAppIcon,
  Wrapper,
  RemoveCircleOutlineIcon,
} from './styles'
import { useSendInvitationMutation } from 'store/WeInvitateVacanciesJob'
import { CircularProgress, Typography } from '@mui/material'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'

interface InviteStatusProps {
  professionalHashcode: string
  projectHashcode: string
  updateSuggestions: () => void
  status?: string
}

const InviteStatus = ({
  professionalHashcode,
  projectHashcode,
  updateSuggestions,
  status,
}: InviteStatusProps) => {
  const { errorSnackbar } = useCustomSnackbar()
  const [sendInvite, { isLoading }] = useSendInvitationMutation()
  const hasInvited = status === 'P'
  const isApproved = status === 'A' || status === 'SR'
  const hasRequestOut = status === 'SC'
  const isOut = status === 'F'
  const canInvite = status === undefined
  const isOutApproved = status === 'SA'

  const handleSendInvite = () => {
    sendInvite({ projectHashcode, professionalHashcode })
      .unwrap()
      .then(() => updateSuggestions())
      .catch(() =>
        errorSnackbar('Este profissional já se candidatou a esta vaga')
      )
  }

  if (isLoading) {
    return (
      <Container>
        <Wrapper>
          <Typography textAlign="center">
            Seu convite esta sendo enviado
          </Typography>
          <CircularProgress />
        </Wrapper>
      </Container>
    )
  }

  if (hasRequestOut) {
    return (
      <Container>
        <Wrapper>
          <ExitToAppIcon iconColor="orange" />
          <Typography fontWeight="bold" color="orange" textAlign="center">
            O candidato solicitou saída
          </Typography>
        </Wrapper>
      </Container>
    )
  }

  return (
    <Container>
      {hasInvited && (
        <Wrapper>
          <MarkunreadIcon iconColor="#008001" />
          <Typography fontWeight="bold" color="#008001">
            Já Convidado
          </Typography>
        </Wrapper>
      )}
      {isOut && (
        <Wrapper>
          <CancelIcon iconColor="#ff4b52" />
          <Typography fontWeight="bold" color="#ff4b52" textAlign="center">
            Profissional saiu da vaga
          </Typography>
        </Wrapper>
      )}
      {isOutApproved && (
        <Wrapper>
          <RemoveCircleOutlineIcon iconColor="#120a8f" />
          <Typography fontWeight="bold" color="#120a8f" textAlign="center">
            Aguardando o profissional desalocar
          </Typography>
        </Wrapper>
      )}
      {isApproved && (
        <Wrapper>
          <CheckCircleIcon iconColor="#3f8ef6" />
          <Typography fontWeight="bold" color="#3f8ef6">
            Aceitou
          </Typography>
        </Wrapper>
      )}
      {canInvite && (
        <Wrapper hasOnClick onClick={handleSendInvite}>
          <MarkunreadIcon iconColor="#3f8ef6" />
          <Typography fontWeight="bold" color="#3f8ef6">
            Enviar Convite
          </Typography>
        </Wrapper>
      )}
    </Container>
  )
}

export default InviteStatus
