import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthModel } from '../../utils/Interfaces'
import { clearState } from '../actions'
import { StorageGetItem } from 'utils/Storage'
import { APP_STORAGE_PREFIX } from 'utils/Constants'

export const initialState: AuthModel = {
  isAuthenticated: !!JSON.parse(
    StorageGetItem(`${APP_STORAGE_PREFIX}authToken`)
  ),
}

export const authSlice = createSlice({
  name: 'JOB-Connect/auth',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
  },
})

export const { setIsAuthenticated } = authSlice.actions

export default authSlice.reducer
