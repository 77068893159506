// ROTAS DE ITENS DO SIDEBAR
const SidebarRoutes = {
  HOME: '/home',
  PROFISSIONAL_PROFILE: '/profissional/perfil',
  VACANCIES: '/vacancies',
  WE_FOUND_YOUR_JOB: '/we-found-your-job',
  INVITATION_JOBS: '/invitation-jobs',
  CALENDAR: '/calendar',
  PENDING_RATINGS: '/rating',
  PENDING_RATINGS_TECH: '/rating-tech',
  PROMOTION: '/promotion',
  ACCOUNTING: '/accounting',
  PLANS_CONTRATADO: '/plans_contratado',
  PLANS_CONTABILIDADE: '/plans_contabilidade',
  RH_PROFILE: '/rh/perfil',
  PLANS_CONTRATANTE: '/plans_contratante',
  ADDITIONAL_SERVICES: '/additional-services',
} as const

// ROUTES
const routes = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  NOT_FOUND: '/not-found',
  SEND_NOTE: '/send-note',
  SEARCH_VACANCIES: '/search-vacancies',
  RATING: '/rating',
  RATING_TECH: '/rating-tech',
  WE_FOUND_YOUR_JOB_DETAIL: '/we-found-your-job-detail',
  MY_APPLICATION: '/my-applycation',
  MY_INVITATIONS: '/my-invitations',
  FAVORITE_VACANCIES: '/favorite-vacancies',
  BANK_SLIP: '/bank-slip',
  LIST_NOTES: '/list-notes',
  NOTA_FISCAL: '/nota-fiscal',
  BILLING_HISTORY: '/billing-history',
  USER_SELECT_TYPE: '/select-type-user',
  USER_REGISTER: '/user-register',
  USER_UPDATE: '/user-update',
  ONBOARDING: '/onboarding',
  RECRUITER_REGISTER: '/recruiter-register',
  TECH_JOBS: '/oportunidades',
  HUNTING_PROCESS: '/hunting-process',
  CALENDAR_CONTRATANTE: '/calendar-contratante',
  TECH_JOB_CREATE: '/oportunidades/create',
  TECH_JOB_UPDATE: '/oportunidades/update',
  SEARCH_PROFESSIONALS: '/search-professionals',
  PENDING_INVITE: '/pending-invite',
  ADMIN: '/admin',
  ...SidebarRoutes,
} as const

type SidebarRouteKeys = keyof typeof SidebarRoutes

export type SidebarRoutes = (typeof SidebarRoutes)[SidebarRouteKeys]

export default routes
