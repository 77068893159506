import { Button, TextareaAutosize, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useContext } from "react";
import CurrencyInput from "../CurrencyInput";

const BankBill: React.FC = ({ BillContext }: any) => {
  const {
    billForm,
    selectedStep,
    resetBillForm,
    handleChangeBillForm,
    handleChangeSelectedStep,
  } = useContext(BillContext);

  const handleCleanForm = () => resetBillForm();

  const handleNextStep = () => {
    handleChangeSelectedStep(selectedStep + 1);
  };

  return (
    <div className="send-bankslip">
      <p className="send-bankslip-title">Cobrar por boleto</p>

      <div className="send-bankslip-form">
        <div className="value-datepicker-container">
          <CurrencyInput
            id="bill-form"
            value={billForm.value}
            label="Valor da cobrança"
            onChange={(value) => handleChangeBillForm("value", value)}
          />

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Data de vencimento"
              inputFormat="DD/MM/YYYY"
              value={billForm.dueDate}
              onChange={(e) => handleChangeBillForm("dueDate", e as any)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="description-container">
          <p>Descrição da cobrança</p>
          <TextareaAutosize
            minRows={10}
            id="description"
            value={billForm.description}
            placeholder="Insira a descrição da cobrança"
            onChange={(e) =>
              handleChangeBillForm("description", e.target.value as string)
            }
          />
        </div>
        <div className="bankslip-footer">
          <Button
            fullWidth
            variant="outlined"
            onClick={() => handleCleanForm()}
          >
            Cancelar
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => handleNextStep()}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BankBill;
