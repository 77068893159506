import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

// import FloatingWhatsApp from 'react-floating-whatsapp'
import theme from 'styles/theme'
import { store } from './store'

import { HelmetProvider } from 'react-helmet-async'
import {
	CssBaseline,
	createTheme,
	ThemeProvider as MUIThemeProvider,
} from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { ToastContainer } from 'react-toastify'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import Routers from 'routes/Router'
import GlobalStyles from './styles/global'
import { AppThemeProvider } from 'hooks/useTheme'
import 'react-toastify/dist/ReactToastify.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import ExpiredSession from 'data/components/ExpiredSession'

function App() {
	return (
		<MUIThemeProvider theme={MUITheme}>
			<AppThemeProvider>
				<SnackbarProvider maxSnack={3}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<HelmetProvider>
							<QueryParamProvider adapter={ReactRouter6Adapter}>
								<CssBaseline />
								<GlobalStyles />
								<ToastContainer />
								<Routers />
								<ExpiredSession />
							</QueryParamProvider>
						</HelmetProvider>
					</LocalizationProvider>
				</SnackbarProvider>
			</AppThemeProvider>
		</MUIThemeProvider>
	)
}

const MUITheme = createTheme({
	typography: {
		fontFamily: 'Source Sans Pro',
	},
})

export default App
