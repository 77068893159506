import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from '../actions'

interface weRatingJobStore {
  ratingQuantity?: number
}

export const initialState: weRatingJobStore = {
  ratingQuantity: undefined,
}

export const WeRatedJobReducer = createSlice({
  name: 'weRatedJob',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    setWeRatedJobQuantity: (state, action: PayloadAction<number>) => {
      state.ratingQuantity = action?.payload
    },
  },
})

export const { setWeRatedJobQuantity } = WeRatedJobReducer.actions

export default WeRatedJobReducer.reducer
