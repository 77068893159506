import { useState } from 'react'
import './styles.scss'
import { Paper, Box, Rating, Typography, CircularProgress } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import {
  useGetRatingCompanyQuery,
  useEditRatingCompanyMutation,
} from 'store/Rating'
import { Button } from 'components/Atoms/Button'

interface CompanyCardProps {
  companyHash: string
  rating: any
  refetchRating: any
}

export const CompanyCard = ({
  companyHash,
  rating,
  refetchRating,
}: CompanyCardProps) => {
  const [editRatingCompany] = useEditRatingCompanyMutation()
  const {
    data: ratingItem,
    isSuccess,
    refetch,
  } = useGetRatingCompanyQuery(companyHash)
  const { successSnackbar } = useCustomSnackbar()

  const [valueLocalizacao, setValueLocalizacao] = useState<number | null>(0)
  const [valueGestao, setValueGestao] = useState<number | null>(0)
  const [valueRelacionamento, setValueRelacionamento] = useState<number | null>(
    0
  )
  const [valuePagamento, setValuePagamento] = useState<number | null>(0)
  const [hoverLocalizacao, setHoverLocalizacao] = useState(-1)
  const [hoverGestao, setHoverGestao] = useState(-1)
  const [hoverRelacionamento, setHoverRelacionamento] = useState(-1)
  const [hoverPagamento, setHoverPagamento] = useState(-1)

  const labelsRating: { [index: string]: string } = {
    1: 'Péssimo',
    2: 'Ruim',
    3: 'Mediano',
    4: 'Bom',
    5: 'Excelente +',
  }

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labelsRating[value]}`
  }

  return (
    <Box>
      <Paper sx={{ padding: '18px' }}>
        <Typography gutterBottom variant="h5" component="div">
          {rating?.projeto?.empresa?.nome}
        </Typography>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ marginBottom: '18px' }}
        >
          {rating?.projeto?.nome}
        </Typography>
        <Typography component="legend" variant="body1">
          Localização
        </Typography>
        {isSuccess ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rating
                name="hover-feedback"
                style={{ zoom: '110%' }}
                getLabelText={getLabelText}
                value={ratingItem?.contratante_pergunta1}
                onChangeActive={(event, newHover) => {
                  setHoverLocalizacao(newHover)
                }}
                icon={<StarIcon style={{ fontSize: '24px', color: 'gold' }} />}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                }
                onChange={(event, newValue) => {
                  editRatingCompany({
                    hashcod: rating?.hashcod,
                    body: {
                      contratante_pergunta1: newValue,
                    },
                  })
                    .unwrap()
                    .then(() => {
                      successSnackbar('Localização avaliada com sucesso')
                    })
                    .then(() => {
                      refetch()
                    })
                }}
              />
              {valueLocalizacao !== null && (
                <Box sx={{ ml: 2 }}>
                  {
                    labelsRating[
                      hoverLocalizacao !== -1
                        ? hoverLocalizacao
                        : valueLocalizacao
                    ]
                  }
                </Box>
              )}
            </Box>

            <Typography
              component="legend"
              variant="body1"
              sx={{ marginTop: '12px' }}
            >
              Gestão
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rating
                name="hover-feedback"
                style={{ zoom: '110%' }}
                getLabelText={getLabelText}
                value={ratingItem?.contratante_pergunta2}
                onChangeActive={(event, newHover) => {
                  setHoverGestao(newHover)
                }}
                icon={<StarIcon style={{ fontSize: '24px', color: 'gold' }} />}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                }
                onChange={(event, newValue) => {
                  editRatingCompany({
                    hashcod: rating?.hashcod,
                    body: {
                      contratante_pergunta2: newValue,
                    },
                  })
                    .unwrap()
                    .then(() => {
                      successSnackbar('Gestão avaliada com sucesso')
                    })
                    .then(() => {
                      refetch()
                    })
                }}
              />
              {valueGestao !== null && (
                <Box sx={{ ml: 2 }}>
                  {labelsRating[hoverGestao !== -1 ? hoverGestao : valueGestao]}
                </Box>
              )}
            </Box>
            <Typography
              component="legend"
              variant="body1"
              sx={{ marginTop: '12px' }}
            >
              Relacionamento
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rating
                name="hover-feedback"
                style={{ zoom: '110%' }}
                getLabelText={getLabelText}
                value={ratingItem?.contratante_pergunta3}
                onChangeActive={(event, newHover) => {
                  setHoverRelacionamento(newHover)
                }}
                icon={<StarIcon style={{ fontSize: '24px', color: 'gold' }} />}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                }
                onChange={(event, newValue) => {
                  editRatingCompany({
                    hashcod: rating?.hashcod,
                    body: {
                      contratante_pergunta3: newValue,
                    },
                  })
                    .unwrap()
                    .then(() => {
                      successSnackbar('Relacionamento avaliada com sucesso')
                    })
                    .then(() => {
                      refetch()
                    })
                }}
              />
              {valueRelacionamento !== null && (
                <Box sx={{ ml: 2 }}>
                  {
                    labelsRating[
                      hoverRelacionamento !== -1
                        ? hoverRelacionamento
                        : valueRelacionamento
                    ]
                  }
                </Box>
              )}
            </Box>
            <Typography
              component="legend"
              variant="body1"
              sx={{ marginTop: '12px' }}
            >
              Pagamento
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rating
                name="hover-feedback"
                style={{ zoom: '110%' }}
                getLabelText={getLabelText}
                value={ratingItem?.contratante_pergunta4}
                onChangeActive={(event, newHover) => {
                  setHoverPagamento(newHover)
                }}
                icon={<StarIcon style={{ fontSize: '24px', color: 'gold' }} />}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55, fontSize: '24px' }} />
                }
                onChange={(event, newValue) => {
                  editRatingCompany({
                    hashcod: rating?.hashcod,
                    body: {
                      contratante_pergunta4: newValue,
                    },
                  })
                    .unwrap()
                    .then(() => {
                      successSnackbar('Pagamento avaliada com sucesso')
                    })
                    .then(() => {
                      refetch()
                    })
                }}
              />
              {valuePagamento !== null && (
                <Box sx={{ ml: 2 }}>
                  {
                    labelsRating[
                      hoverPagamento !== -1 ? hoverPagamento : valuePagamento
                    ]
                  }
                </Box>
              )}
            </Box>
            <Button
              style={{ marginTop: '18px' }}
              buttonType="primary"
              onClick={() => {
                editRatingCompany({
                  hashcod: rating?.hashcod,
                  body: {
                    contratante_avaliado: true,
                  },
                })
                  .unwrap()
                  .then(() => {
                    successSnackbar('Avaliação enviada com sucesso')
                  })
                  .then(() => {
                    refetch()
                  })
                  .then(() => {
                    refetchRating()
                  })
              }}
            >
              Enviar avaliação
            </Button>
          </>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}
      </Paper>
    </Box>
  )
}
