import { Button, CircularProgress, Typography } from '@mui/material'
import { Container, ButtonsContainer } from './styles'
import {
  useDeallocateVacancyMutation,
  useRefuseOutVacancyMutation,
} from 'store/WeInvitateVacanciesJob'
import { searchVacanciesRedux } from 'store/SearchVacancies'
import { UserType } from 'store/UserType'
import { useDispatch } from 'react-redux'

interface ExitRequestProps {
  hashcode?: string
  status?: string
  updateSuggestions?: () => void
}

const ExitRequest = ({
  hashcode,
  status,
  updateSuggestions,
}: ExitRequestProps) => {
  const dispatch = useDispatch()
  const [acceptProfessionalOut, { isLoading: isAcceptOutLoading }] =
    useDeallocateVacancyMutation()
  const [refuseProfessionalOut, { isLoading: isRefuseOutLoading }] =
    useRefuseOutVacancyMutation()

  const isExitRequestLoading = isAcceptOutLoading || isRefuseOutLoading

  const handleRefuseProfessionalOut = (hashcode: string) => {
    refuseProfessionalOut(hashcode)
      .unwrap()
      .then(() => {
        updateSuggestions?.()
        dispatch(searchVacanciesRedux.util.invalidateTags(['Projects']))
      })
  }

  const handleAcceptProfessionalOut = (hashcode: string) => {
    acceptProfessionalOut(hashcode)
      .unwrap()
      .then(() => {
        updateSuggestions?.()
        dispatch(searchVacanciesRedux.util.invalidateTags(['Projects']))
      })
  }

  if (status !== 'SC' || !hashcode) return null

  if (isExitRequestLoading) {
    return (
      <Container>
        <CircularProgress size={24} />
        <Typography>Sua resposta esta sendo enviada</Typography>
      </Container>
    )
  }

  return (
    <Container>
      <>
        <Typography>Aceitar saída do candidato?</Typography>
        <ButtonsContainer>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleAcceptProfessionalOut(hashcode)}
            style={{ background: 'rgb(17, 194, 109)' }}
          >
            Aceitar
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleRefuseProfessionalOut(hashcode)}
            style={{ background: 'rgb(255, 75, 82)' }}
          >
            Recusar
          </Button>
        </ButtonsContainer>
      </>
    </Container>
  )
}

export default ExitRequest
