import styled from 'styled-components'

export const JobAttribute = styled.div``

export const JobAttributeName = styled.span`
  display: block;
  font-weight: 500;
  font-size: 1.1rem;
  justify-content: center;
`
