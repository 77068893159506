import { LayoutRouteProps } from 'react-router-dom'

import { DefaultTheme } from 'styled-components'
import Box from '@mui/material/Box'

import { Header, MainSidebar } from '../../../components/Molecules'
import { usePersistedState } from 'hooks/usePersistedState'
import dark from 'styles/dark'
import '../../../assets/scss/styles-admin.scss'
import defaultTheme from 'styles/theme'
import { useAppTheme } from 'hooks/useTheme'
import { ContentContainer } from 'data/components/style'
import * as S from './styles'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLoggedUser,
  setRefetchLoggedUser,
  setUserRating,
} from 'store/User/UserSlice'
import { setLoading } from 'store/Layout/slice'
import { useGetPersonalDataQuery } from 'store/PersonalData'
import { RootState } from 'store'
import { useGetNodeCompanyByEmailQuery } from 'store/NodeApi/company/endpoint'
import { useGetAssignorByCnpjQuery } from 'store/PlugBoletos/endpoint'
import { useGetWeFoundYourJobPreferenciesJobQuantityMutation } from 'store/WeFoundYourJob'
import {
  useLazyGetInvitationsQuery,
  useLazyGetWeInvitateVacanciesJobQuantityQuery,
} from 'store/WeInvitateVacanciesJob'
import { useLazyGetWeRatedJobQuantityQuery } from 'store/Rating'
import { setWeFoundYourJobQuantity } from 'store/WeFoundYourJob/slice'
import { setWeInvitateVacanciesJobQuantity } from 'store/WeInvitateVacanciesJob/slice'
import { setWeRatedJobQuantity } from 'store/Rating/slice'
import { Role } from 'utils/Enums'
import { useLazyGetProjectsQuery } from 'store/SearchVacancies'
import { useLazyGetPendingApplicationsQuantityQuery } from 'store/Professional/api'
import { setPendingApplicationsQuantity } from 'store/Professional/slice'

const AuthTemplate = ({ children }: LayoutRouteProps) => {
  const dispatch = useDispatch()
  const { role: userRole, user } = useSelector(
    (state: RootState) => state.userState
  )
  const {
    currentData: loggedUser,
    isLoading: loggedUserIsLoading,
    refetch: refetchLoggedUser,
  } = useGetPersonalDataQuery()
  const [
    getWeFoundYourJobPreferenciesJobQuantity,
    { isLoading: getWeFoundYourJobPreferenciesJobQuantityIsLoading },
  ] = useGetWeFoundYourJobPreferenciesJobQuantityMutation()
  const [
    getWeInvitateVacanciesJobQuantity,
    { isLoading: getWeInvitateVacanciesJobQuantityIsLoading },
  ] = useLazyGetWeInvitateVacanciesJobQuantityQuery()
  const [getWeRatedJobQuantity, { isLoading: getWeRatedJobQuantityIsLoading }] =
    useLazyGetWeRatedJobQuantityQuery()
  const [getInvitations, { isLoading: getInvitationsIsLoading }] =
    useLazyGetInvitationsQuery()
  const [getProjects, { isLoading: getProjectsIsLoading }] =
    useLazyGetProjectsQuery()
  const [
    getPendingApplicationsQuantity,
    { isLoading: getPendingApplicationsQuantityIsLoading },
  ] = useLazyGetPendingApplicationsQuantityQuery()

  const nodeCompany = useSelector(
    (state: RootState) => state?.node?.nodeCompany
  )

  const userCnpj = nodeCompany?.cnpj

  useGetNodeCompanyByEmailQuery(loggedUser?.email!, {
    skip: !loggedUser?.email,
  })
  useGetAssignorByCnpjQuery(userCnpj, { skip: !userCnpj })

  const [theme, setTheme] = usePersistedState<DefaultTheme>(
    'default',
    defaultTheme
  )
  const { toggleTheme } = useAppTheme()

  const handleToggleTheme = () => {
    setTheme(theme.title === 'default' ? dark : defaultTheme)

    toggleTheme(theme.title)
  }

  useEffect(() => {
    if (userRole === Role.PROFESSIONAL) {
      getWeFoundYourJobPreferenciesJobQuantity()
        .unwrap()
        .then((res) => dispatch(setWeFoundYourJobQuantity(res)))

      getWeInvitateVacanciesJobQuantity()
        .unwrap()
        .then((res) => dispatch(setWeInvitateVacanciesJobQuantity(res)))

      getWeRatedJobQuantity()
        .unwrap()
        .then((res) => dispatch(setWeRatedJobQuantity(res)))
    } else if (userRole === Role.COMPANY) {
      getPendingApplicationsQuantity()
        .unwrap()
        .then((res) => dispatch(setPendingApplicationsQuantity(res)))
      getWeRatedJobQuantity()
        .unwrap()
        .then((res) => dispatch(setWeRatedJobQuantity(res)))
    }
  }, [
    dispatch,
    getWeFoundYourJobPreferenciesJobQuantity,
    getWeInvitateVacanciesJobQuantity,
    getWeRatedJobQuantity,
    getPendingApplicationsQuantity,
    userRole,
  ])

  useEffect(() => {
    dispatch(setUserRating(loggedUser?.pontuacao ?? 0))
  }, [dispatch, user])

  useEffect(() => {
    dispatch(
      setLoading(
        loggedUserIsLoading ||
          getWeFoundYourJobPreferenciesJobQuantityIsLoading ||
          getWeInvitateVacanciesJobQuantityIsLoading ||
          getWeRatedJobQuantityIsLoading ||
          getInvitationsIsLoading ||
          getProjectsIsLoading ||
          getPendingApplicationsQuantityIsLoading
      )
    )

    return () => {
      dispatch(setLoading(false))
    }
  }, [
    dispatch,
    loggedUserIsLoading,
    getWeFoundYourJobPreferenciesJobQuantityIsLoading,
    getWeInvitateVacanciesJobQuantityIsLoading,
    getWeRatedJobQuantityIsLoading,
    getInvitationsIsLoading,
    getProjectsIsLoading,
    getPendingApplicationsQuantityIsLoading,
  ])

  useEffect(() => {
    if (loggedUser) {
      dispatch(setLoggedUser(loggedUser))
      dispatch(setRefetchLoggedUser(refetchLoggedUser))
    }
  }, [dispatch, loggedUser, refetchLoggedUser])

  return (
    <Box className="app-container">
      <S.RowWrapper>
        <MainSidebar />
        <S.StackWrapper>
          <Header handleToggleTheme={handleToggleTheme} />
          <ContentContainer component="main">{children}</ContentContainer>
          <S.FooterContent>
            <S.LoginFooterText>
              Ⓒ{new Date().getFullYear()} Copyright
            </S.LoginFooterText>
            <S.LoginFooterTittle translate="no">
              JOB Connect
            </S.LoginFooterTittle>
          </S.FooterContent>
        </S.StackWrapper>
      </S.RowWrapper>
    </Box>
  )
}

export default AuthTemplate
