import { API_TOKEN_AUTH, APP_API_VERSION } from '../../utils/Constants'
import {
  APP_API_URI,
  APP_STORAGE_PREFIX,
  APP_PREFIX,
  USER_FORGET_PASSWORD,
  USER_FORGET_PASSWORD_CODE,
  USER_FORGET_PASSWORD_CODE_SEND,
} from '../../utils/Constants'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { StorageSetItem } from '../../utils/Storage'
import { userApi } from '../User/UserApi'
import {
  SignInRequest,
  SignInResponse,
  SignUpRequest,
  SignUpResponse,
} from 'types'
import { setIsAuthenticated } from './slice'

export const authApi = createApi({
  reducerPath: `${APP_PREFIX}/Auth/authApi`,
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_API_URI}`,
  }),
  endpoints: (builder) => ({
    forgetPassword: builder.mutation<any, string>({
      query(email) {
        return {
          url: `${USER_FORGET_PASSWORD}`,
          method: 'POST',
          body: { email },
        }
      },
    }),
    forgetPasswordCode: builder.mutation<any, { code; email }>({
      query({ code, email }) {
        return {
          url: `${USER_FORGET_PASSWORD_CODE}`,
          method: 'POST',
          body: { codigo: code, email },
        }
      },
    }),
    forgetPasswordCodeSend: builder.mutation<
      any,
      {
        password: string
        code: string
        email: string
      }
    >({
      query({ password, code, email }) {
        return {
          url: `${USER_FORGET_PASSWORD_CODE_SEND}`,
          method: 'POST',
          body: { password, codigo: code, email },
        }
      },
    }),
    signIn: builder.mutation<SignInResponse, SignInRequest>({
      query(data) {
        return {
          url: `${API_TOKEN_AUTH}`,
          method: 'POST',
          body: data,
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          if (data) {
            StorageSetItem(
              `${APP_STORAGE_PREFIX}authToken`,
              JSON.stringify(data?.token)
            )
            await dispatch(userApi.endpoints.verifyType.initiate(null))
            await dispatch(setIsAuthenticated(true))
          }
        } catch (error) {}
      },
    }),
    signUp: builder.mutation<SignUpResponse, SignUpRequest>({
      query: (body) => ({
        url: `${APP_API_VERSION}/usuario/cadastro/`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useSignInMutation,
  useSignUpMutation,
  useForgetPasswordMutation,
  useForgetPasswordCodeMutation,
  useForgetPasswordCodeSendMutation,
} = authApi
