import './styles.scss'
import MaterialDialog from '../MaterialDialog'
import { DialogConfig } from 'domain/models/dialog'
import { defaultDialogState } from 'data/utils/Dialog/static'

import GroupIcon from '@mui/icons-material/Group'
import PersonIcon from '@mui/icons-material/Person'
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  displayFormBody,
  displayListBody,
  getRelationshipText,
} from 'data/utils/Dependent/static'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import MaterialTable from '../MaterialTable'
import { defaultDisplayFields } from 'data/utils/Dependent/static'
import { DependentFormModel, DisplayFields } from 'domain/models/dependent'
import DependentForm from '../DependentForm'
import {
  defaultDependentForm,
  dependentColumns,
} from 'data/utils/Dependent/static'
import {
  useDeleteDependentMutation,
  useLazyGetDependentsQuery,
  usePostDependentMutation,
} from 'store/NodeApi/dependent/endpoint'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import moment from 'moment'

const DisplayFormOrList = () => {
  const {
    displayFields,
    dependentForm,
    setDependentForm,
    selectedPartnerRows,
    handleAddNewDependent,
  } = useContext(DependentContext)

  const dependentProps = {
    dependentForm,
    setDependentForm,
    handleAddNewDependent,
  }

  if (displayFields.dependentForm && selectedPartnerRows?.[0])
    return (
      <div className="add-dependent-form">
        <DependentForm {...dependentProps} />
      </div>
    )

  return <DependentList />
}

const DependentList = () => {
  const { selectedDependentRows, setSelectedDependentRows, dependents } =
    useContext(DependentContext)

  const tableProps = {
    data: dependents,
    columns: dependentColumns,
    selectedRows: selectedDependentRows,
    setSelectedRows: setSelectedDependentRows,
  }

  return (
    <div className="box-container">
      <div className="box-container-header flex-box">
        <GroupIcon />
        <p>Dependentes</p>
      </div>
      <div
        className="box-container-header flex-box"
        style={{ fontSize: '16px', height: '30px', borderBottom: 'none' }}
      >
        Selecione um sócio para ver seus dependentes
      </div>
      <MaterialTable {...tableProps} />
    </div>
  )
}

export const DependentContext: any = createContext({})

interface DependentProps {
  selectedPartnerRows: any
  dependents: any
  setDependents: any
}
const DependentData: React.FC<DependentProps> = ({
  selectedPartnerRows,
  dependents,
  setDependents,
}) => {
  const [dialogConfig, setDialogConfig] =
    useState<DialogConfig>(defaultDialogState)
  const [displayFields, setDisplayFields] =
    useState<DisplayFields>(defaultDisplayFields)

  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false)
  const [selectedDependentRows, setSelectedDependentRows] = useState<number[]>(
    []
  )

  const { successSnackbar, errorSnackbar } = useCustomSnackbar()

  const [dependentForm, setDependentForm] =
    useState<DependentFormModel>(defaultDependentForm)

  const [getDependents] = useLazyGetDependentsQuery()

  const [deleteDependent] = useDeleteDependentMutation()

  const [postDependents] = usePostDependentMutation()

  const handleCloseDialog = () =>
    setDialogConfig({ ...dialogConfig, open: false })

  const handleOpen = () => setOpenSpeedDial(true)
  const handleClose = () => setOpenSpeedDial(false)

  const handleAddDependentAction = () => {
    setDisplayFields(displayFormBody)

    handleClose()
  }

  const handleDisplayTableAction = () => {
    setDisplayFields(displayListBody)
    setDependentForm(defaultDependentForm)

    handleClose()
  }

  const dialogProps = {
    open: dialogConfig?.open,
    onClose: handleCloseDialog,
    onConfirm: dialogConfig?.onConfirm,
    dialogBodyText: dialogConfig?.dialogBodyText,
  }

  useEffect(() => {
    const getAllDependents = async () => {
      const apiDependents: any = await getDependents(
        selectedPartnerRows?.[0]
      ).unwrap()

      const newDependents = apiDependents?.map((dependent: any) => {
        return {
          ...dependent,
          relationshipGradeText: getRelationshipText(
            dependent.relationship_grade
          ),
          birthdate: moment(dependent.birthdate).format('DD/MM/YYYY'),
          partnerId: dependent?.partner_id,
          relationshipGrade: dependent?.relationship_grade,
        }
      })

      setDependents(newDependents)
    }

    if (!selectedPartnerRows?.[0]) {
      setDependents([])
    } else getAllDependents()
  }, [selectedPartnerRows])

  const resetDependentForm = () => {
    setDependentForm(defaultDependentForm)
  }

  const handleAddNewDependent = () => {
    const partnerId = selectedPartnerRows?.[0]

    const alreadyAddedDependent =
      dependents?.find((dependent) => dependent.cpf === dependentForm.cpf) ||
      false

    if (alreadyAddedDependent) {
      errorSnackbar('Dependente já cadastrado')
    } else {
      const dependentBody = {
        partnerId,
        name: dependentForm.name,
        cpf: dependentForm.cpf,
        birthdate: moment(dependentForm.birthdate).format('YYYY-MM-DD'),
        relationshipGrade: dependentForm.relationshipGrade,
      }

      postDependents([dependentBody])
        .unwrap()
        .then(() => {
          setDependents([...dependents, dependentBody])
          successSnackbar('Dependente cadastrado com sucesso!')
          resetDependentForm()
        })
        .catch(() => {
          errorSnackbar('Erro ao cadastrar dependente')
        })
    }
  }

  const confirmRemoveDependent = () => {
    const selectedDependent = selectedDependentRows?.[0]

    deleteDependent(selectedDependent)
      .unwrap()
      .then(() => {
        successSnackbar('Dependente removido com sucesso')
        setDependents([
          ...dependents.filter(
            (dependent: any) => dependent.id !== selectedDependent
          ),
        ])
      })

    setDialogConfig(defaultDialogState)
  }

  const cancelDialogState = {
    open: true,
    dialogBodyText: 'Deseja remover o dependente?',
    onConfirm: confirmRemoveDependent,
  }

  const handleRemoveAction = () => {
    setDialogConfig(cancelDialogState)
  }

  const getDialActions = (): {
    icon: ReactElement
    name: string
    onClick: any
  }[] => {
    const newDependentBody = {
      icon: <PersonIcon />,
      name: 'Novo dependente',
      onClick: handleAddDependentAction,
    }

    const seeDependentsBody = {
      icon: <TableRowsOutlinedIcon />,
      name: 'Ver dependentes',
      onClick: handleDisplayTableAction,
    }

    const deleteDependentBody = {
      icon: <DeleteIcon />,
      name: 'Excluir dependente',
      onClick: handleRemoveAction,
    }

    let selectedBody = [seeDependentsBody]

    if (selectedPartnerRows?.[0])
      selectedBody = [...selectedBody, newDependentBody]

    if (selectedDependentRows?.[0])
      selectedBody = [...selectedBody, deleteDependentBody]

    return selectedBody
  }

  const contextValues = {
    dependents,
    displayFields,
    dependentForm,
    setDependentForm,
    handleAddNewDependent,
    selectedPartnerRows,
    selectedDependentRows,
    setSelectedDependentRows,
  }

  return (
    <DependentContext.Provider value={contextValues}>
      <MaterialDialog {...dialogProps} />
      <div className="dependent-data-container">
        <div className="dependent-data-toolbar">
          <SpeedDial
            direction="down"
            onOpen={handleOpen}
            open={openSpeedDial}
            onClose={handleClose}
            icon={<SpeedDialIcon />}
            className="speed-dial-container"
            ariaLabel="SpeedDial tooltip example"
          >
            {getDialActions().map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                onClick={action.onClick}
                tooltipPlacement="right"
                tooltipTitle={action.name}
              />
            ))}
          </SpeedDial>
        </div>
        <DisplayFormOrList />
      </div>
    </DependentContext.Provider>
  )
}

export default DependentData
