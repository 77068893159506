import { TecnoSpeedCity } from '../../../store/PlugNotas/endpoint'

export const defaultAddressForm = {
  cep: '',
  city: '',
  state: '',
  number: '',
  district: '',
  complement: '',
  cityOptions: [],
  publicPlace: '',
  stateOptions: [],
}

export const getTecnoSpeedCityByName = ({ tecnoSpeedCities, cityName }) => {
  if (tecnoSpeedCities?.length === 0 || cityName?.length === 0) return

  const currentCity =
    tecnoSpeedCities?.filter((city: TecnoSpeedCity) => {
      const normalizedCurrentCity = city.nome?.normalize('NFD')

      if (!normalizedCurrentCity) return

      const replacedCurrentCity = normalizedCurrentCity?.replace(
        /\p{Diacritic}/gu,
        ''
      )

      if (!replacedCurrentCity) return

      const newCityName = cityName
        ?.normalize('NFD')
        ?.replace(/\p{Diacritic}/gu, '')

      return replacedCurrentCity?.toLowerCase() === newCityName?.toLowerCase()
    })[0] || {}

  return currentCity
}

export const getPortugueseAddressForm = (addressForm: any) => {
  return {
    cep: addressForm?.cep,
    tipoLogradouro: 'Rua',
    numero: addressForm?.number,
    bairro: addressForm?.district,
    logradouro: addressForm?.logradouro,
    codigoCidade: addressForm?.codigoCidade,
    estado: addressForm?.uf || addressForm?.state,
  }
}
