import { useEffect, useRef } from 'react'
import { NOTA_FISCAL_URL } from 'utils/Constants'

const NotaFiscal = () => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('@JOB-CONNECT/authToken')!)
    window.addEventListener('message', (message) => {
      if (message.origin === NOTA_FISCAL_URL) {
        iframeRef.current?.contentWindow?.postMessage(token, '*')
      }
    })
  }, [])

  return (
    <iframe
      ref={iframeRef}
      src={NOTA_FISCAL_URL}
      name="IFRAME"
      style={{ border: 'none', flex: 1, display: 'flex' }}
    />
  )
}

export default NotaFiscal
