import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    /* padding: 0; */
    box-sizing: border-box;
    outline: 0;
    font-size: 16px;
  }

  html, body, #__next {
    height: 100%;
    touch-action: pan-y pinch-zoom;
  }

  html {
    overflow-x: hidden;
    scroll-behavior: smooth;
    max-width: 100%;
  }

  body {
    background: ${(props) => props.theme.colors.base.thin};
    color: ${(props) => props.theme.colors.text.primary};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    max-width: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  p {
    line-height: 1.4em;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .none {
    display: none !important;
  }

  .wrapper{
    display: flex;
    flex: 1;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
  }

  .container{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .content{
    display: flex;
    flex: 1;
    float: left;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    padding: 0 10px;

    @media(min-width: 768px) {
      width: 92%;
    }
  }

  .contentWithCarousel{
    display: flex;
    flex: 1;
    float: left;
    flex-direction: column;
    width: 100%;
    max-width: 1310px;
    padding: 0 10px;

    @media(min-width: 768px) {
      width: 92%;
    }
  }

  .full-content{
    width: 100%;
    position: relative;

    .rec-item-wrapper {
      padding: 0!important;
    }

    .rec-carousel {
      .prev {
        position: absolute;
        top: 45%;
        left: 5px;
      }
      .next {
        position: absolute;
        top: 45%;
        right: 5px;
      }

      a {
        width: 100%;
      }
    }
  }

  .product-link {
    text-decoration: none;
    color: inherit;
  }
`

export default GlobalStyles
