import React, { forwardRef, useCallback, useImperativeHandle } from 'react'
import { TextareaAutosize } from '@mui/material'
import { useFormik } from 'formik'

import validationSchema from './validations'
import '../../../styles.scss'
import { INITIAL_VALUES } from './constants'
import { DescriptionTabRef } from './types'
import { isEmpty } from 'lodash'

const DescriptionTab = (_, ref: React.Ref<DescriptionTabRef>) => {
  const { values, handleChange, errors, validateForm } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    onSubmit: () => {},
    validateOnChange: false,
    validateOnBlur: false,
  })

  const validate = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        validateForm().then((errors) => {
          if (isEmpty(errors)) resolve()
          else reject()
        })
      }),
    [validateForm]
  )

  useImperativeHandle(ref, () => ({ validate, values }), [validate, values])

  return (
    <div className="horizontal">
      <TextareaAutosize
        placeholder="Descrição da empresa"
        style={{
          width: '100%',
          resize: 'vertical',
          borderColor: errors.descricao ? 'red' : '',
        }}
        minRows={7}
        value={values.descricao}
        onChange={handleChange}
        name="descricao"
      />

      {errors.descricao && (
        <label className="helper-box" style={{ minWidth: '100%' }}>
          {`${errors.descricao}`}
        </label>
      )}
    </div>
  )
}

export default forwardRef(DescriptionTab)

export type { DescriptionTabRef as CurriculumTabRef }
