import React, { useState, useEffect } from 'react'
import { Box, Checkbox, Fab, FormControlLabel } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

import './styles.scss'
import { DialogConfig } from 'domain/models/dialog'
import { defaultDialogState } from 'data/utils/Dialog/static'
import MaterialDialog from '../MaterialDialog'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import {
  useLazyGetCompanyTermsQuery,
  usePostTermMutation,
  usePutTermMutation,
} from 'store/NodeApi/term/endpoint'

const MyResponsibilities: React.FC = () => {
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)
  const [dialogConfig, setDialogConfig] =
    useState<DialogConfig>(defaultDialogState)
  const [myResponsibilities, setMyResponsibilities] = useState<any>({
    readAccepted: false,
  })

  const { successSnackbar, errorSnackbar } = useCustomSnackbar()

  const [postMyResponsibilities] = usePostTermMutation()
  const [putMyResponsibilities] = usePutTermMutation()

  const [getCompanyTerms] = useLazyGetCompanyTermsQuery()

  useEffect(() => {
    getCompanyTerms('')
      .unwrap()
      .then((companyTerms) => {
        if (!companyTerms?.[0]) return

        const myResponsibilitiesObj = companyTerms?.find(
          (term) => term.name === 'my_responsibilities'
        )

        if (!myResponsibilities) return

        setMyResponsibilities({
          ...myResponsibilitiesObj,
          readAccepted: myResponsibilitiesObj?.read_accepted === 1,
        })
      })
  }, [])

  const handleSave = () => {
    let postOrPutFunction = postMyResponsibilities

    if (myResponsibilities?.id) postOrPutFunction = putMyResponsibilities

    const readAccepted = myResponsibilities?.readAccepted ? '1' : '0'

    postOrPutFunction({
      id: myResponsibilities?.id,
      name: 'my_responsibilities',
      readAccepted,
    })
      .unwrap()
      .then(() => {
        successSnackbar('Dados alterados com sucesso')
        setShowSaveButton(false)
      })
      .catch(() => errorSnackbar('Erro ao alterar dados'))
  }

  const DisplaySaveButton = () => {
    return (
      <Box>
        <Fab
          color="primary"
          aria-label="add"
          disabled={!showSaveButton}
          onClick={() => handleSave()}
        >
          <CheckIcon />
        </Fab>
      </Box>
    )
  }

  const handleCloseDialog = () => {
    setDialogConfig({ ...dialogConfig, open: false })
  }

  const handleChangeReadAccepted = () => {
    const newReadAccepted = !myResponsibilities?.readAccepted

    setMyResponsibilities({
      ...myResponsibilities,
      readAccepted: newReadAccepted,
    })

    handleCloseDialog()
    setShowSaveButton(true)
  }

  const handleAcceptedFlow = () => {
    setDialogConfig({
      ...dialogConfig,
      open: true,
      onConfirm: handleChangeReadAccepted,
      dialogBodyText:
        'Tem certeza que deseja alterar o aceite das suas responsabilidades?',
    })
  }

  const dialogProps = {
    open: dialogConfig?.open,
    onClose: handleCloseDialog,
    onConfirm: dialogConfig?.onConfirm,
    dialogBodyText: dialogConfig?.dialogBodyText,
  }

  return (
    <>
      <MaterialDialog {...dialogProps} />
      <div className="my-responsibility-container">
        <DisplaySaveButton />
        <div className="my-responsibilities-box box-container">
          <div className="box-container-header flex-box">
            <p>Minhas responsabilidades</p>
          </div>
          <div className="my-responsibilities-content">
            <p
              className="responsabilities-subtitle"
              style={{ fontSize: '19px' }}
            >
              Se você chegou até aqui, e finalizou seu cadastro, parabéns!
            </p>
            <p>
              Agora você poderá emitir suas notas fiscais gratuitamente e
              boletos através da plataforma de forma fácil e rápida,
            </p>
            <p
              className="responsabilities-subtitle"
              style={{ fontSize: '19px' }}
            >
              Minhas responsabilidades no caso de adquirir um de nossos planos
              de contabilidade
            </p>
            <p>
              Caso você assine um dos nossos planos de contabilidade digital,
              você receberá um e-mail com informações importantes sobre
              responsabilidades na rotina contábil da sua empresa. Fique atento!
            </p>
            <p>
              Nossa equipe de contadores validará suas informações e entraremos
              em contato por e-mail caso seja necessário!
            </p>
            <p
              className="responsabilities-subtitle"
              style={{ fontSize: '17px' }}
            >
              Algumas coisas importantes pra saber já:
            </p>
            <p>
              A partir de agora você poderá emitir sua Nota Fiscal pela
              plataforma, se sua prefeitura permitir este tipo de integração, ou
              importar através da solicitação feita através da nossa ferramenta
              Gestta, para que sempre gere seus impostos de forma automática sem
              você precisar se preocupar com nada no caso de cliente da
              contabilidade.
            </p>
            <p>
              As guias dos seus impostos serão disponibilizadas também através
              da nossa ferramenta Gestta. Mantenha seu e-mail de cadastro sempre
              atualizado, é por lá que enviaremos notificações. Caso altere
              qualquer dado de acesso de prefeitura por exemplo, não esqueça de
              atualizar na plataforma, pois sem eles não teremos acesso de sua
              prefeitura, e isso implicará na emissão de suas notas fiscais e
              cálculos de impostos.
            </p>
            <p>
              Se esta trocando de contador, solicite ao seu contador anterior, o
              seu documento de transferencia de contador, os Livros Contábeis e
              o Balanço Patrimonial em formato digital, e nos envie para
              contabilidade@jobc.com.br para que seja possível dar início a sua
              contabilidade pela JOB Connect.
            </p>
            <p>
              Desde já agradecemos pela confiança e ficamos a disposição em
              qualquer um dos nossos canais de atendimento. Seja bem vindo a
              contabilidade digital especializada para profissionais de
              tecnologia.
            </p>
            <p>JOB Connect.</p>

            <FormControlLabel
              control={<Checkbox name="termsOfUse" color="primary" />}
              checked={myResponsibilities?.readAccepted}
              value={myResponsibilities?.readAccepted}
              label="Li e aceito as minhas responsabilidades"
              onChange={handleAcceptedFlow}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default MyResponsibilities
