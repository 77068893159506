import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	TextField,
	Box,
	Button,
	Tabs,
	Tab,
	Grid,
	FormLabel,
} from '@mui/material'

import { useFormik } from 'formik'
import { setLoading } from 'store/Layout/slice'
import { INITIAL_VALUES } from './constants'
import { SearchJobForm } from './types'
import DataTable from './DataTable'
import { useNavigate } from 'react-router-dom'
import routes from 'utils/Constants/routes'
import { TabOptionsContainer } from './style'
import ChosenPanel from './ChosenPanel'
import { useDiscriminitadedProjects } from 'hooks/useDiscriminatedProjects'

const TechJobs = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [selectedTab, setSelectedTab] = useState(0)
	const [filters, setFilters] = useState<SearchJobForm>(INITIAL_VALUES)
	const { data, isError, refetch, isFetching } = useDiscriminitadedProjects(filters);

	const onSubmit = (values: SearchJobForm) => {
		setFilters(values)
	}

	const { handleChange, handleSubmit, values } = useFormik({
		initialValues: INITIAL_VALUES,
		onSubmit,
		validateOnChange: false,
		validateOnBlur: false,
	})

	useEffect(() => {
		dispatch(setLoading(isFetching))

		return () => {
			dispatch(setLoading(false))
		}
	}, [dispatch, isFetching])

	if (isError) return <div>falha ao carregar oportunidades</div>

	if (!data) return <div>carregando</div>

	return (
		<>
			<Box>
				<Box
					component="form"
					noValidate
					autoComplete="off"
					onSubmit={handleSubmit}
					sx={{ mb: '16px' }}
				>
					<Grid item xs={12}>
						<FormLabel>Selecione os Filtros</FormLabel>
					</Grid>

					<Box component="div" sx={{ display: 'flex', mb: '8px' }}>
						<TextField
							name="name"
							label="Descrição"
							value={values.name}
							onChange={handleChange}
							margin="dense"
							sx={{ flex: 1 }}
						/>

						<TextField
							name="client"
							label="Cliente"
							value={values.client}
							onChange={handleChange}
							margin="dense"
							sx={{ ml: '12px', flex: 1 }}
						/>
					</Box>

					<div>
						<Button
							variant="contained"
							onClick={() => navigate(routes.TECH_JOB_CREATE)}
						>
							Adicionar nova vaga de emprego
						</Button>

						<Button type="submit" style={{ marginLeft: 12 }} onClick={() => handleSubmit?.()}>
							Aplicar filtro
						</Button>
					</div>
				</Box>

				<TabOptionsContainer sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={selectedTab}
						onChange={(_, value) => setSelectedTab(value)}
					>
						<Tab label="Publicadas" />
						<Tab label="Em andamento" />
						<Tab label="Finalizadas" />
					</Tabs>
				</TabOptionsContainer>
			</Box>

			<ChosenPanel is={selectedTab}>
				<DataTable
					data={data.published}
					type="published"
					refetchData={refetch}
				/>

				<DataTable
					data={data.inProgress}
					type="inProgress"
					refetchData={refetch}
				/>

				<DataTable data={data.finished} type="finished" refetchData={refetch} />
			</ChosenPanel>
		</>
	)
}

export default TechJobs
