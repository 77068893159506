import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { authApi } from './Auth/AuthAPI'
import { userApi } from './User/UserApi'
import { professionalApi } from './Professional/api'
import professionalReducer from './Professional/slice'
import userReducer, { setLogout } from './User/UserSlice'
import layoutReducer from './Layout/slice'
import weFoundYourJobReducer from './WeFoundYourJob/slice'
import personalDataReducer from './PersonalData/slice'
import weInvitateVacanciesJobReducer from './WeInvitateVacanciesJob/slice'
import weRatedJobReducer from './Rating/slice'
import typeUserReducer from './UserType/slice'
import authReducer from './Auth/slice'

import locationReducer from './Location/slice'

import locationApi from './Location/api'
import plugNotasApi from './PlugNotas/api'
import plugBoletosApi from './PlugBoletos/api'
import { personalDataRedux } from './PersonalData'
import { searchVacanciesRedux } from './SearchVacancies'
import { promotion } from './Promotion'
import { ratingJob } from './Rating'
import { findYourJob } from './WeFoundYourJob'
import { profileRedux } from './Profile'
import { invitationVacanciesJob } from './WeInvitateVacanciesJob'
import { cadNewUser } from './CadNewUser'
import { FavoriteVacancies } from './FavoriteVacancies'
import { UserType } from './UserType'

import plugNotasReducer from './PlugNotas/slice'
import plugBoletosReducer from './PlugBoletos/slice'
import nodeReducer from './NodeApi/slice'
import nodeApi from './NodeApi/api'
import { clearAuth } from 'utils/Storage'

const expiredSessionMiddleware = (store) => (next) => (action) => {
	if (action?.payload?.data?.detail) {
		clearAuth()
		store.dispatch(setLogout(true))
		return
	}

	return next(action);
}

export const store = configureStore({
	reducer: {
		[authApi.reducerPath]: authApi.reducer,
		[userApi.reducerPath]: userApi.reducer,
		[professionalApi.reducerPath]: professionalApi.reducer,
		[locationApi.reducerPath]: locationApi.reducer,
		[plugNotasApi.reducerPath]: plugNotasApi.reducer,
		[plugBoletosApi.reducerPath]: plugBoletosApi.reducer,
		[personalDataRedux.reducerPath]: personalDataRedux.reducer,
		[searchVacanciesRedux.reducerPath]: searchVacanciesRedux.reducer,
		[findYourJob.reducerPath]: findYourJob.reducer,
		[profileRedux.reducerPath]: profileRedux.reducer,
		[ratingJob.reducerPath]: ratingJob.reducer,
		[invitationVacanciesJob.reducerPath]: invitationVacanciesJob.reducer,
		[promotion.reducerPath]: promotion.reducer,
		[nodeApi.reducerPath]: nodeApi.reducer,
		auth: authReducer,
		plugNotas: plugNotasReducer,
		plugBoletos: plugBoletosReducer,
		node: nodeReducer,
		[cadNewUser.reducerPath]: cadNewUser.reducer,
		[FavoriteVacancies.reducerPath]: FavoriteVacancies.reducer,
		[UserType.reducerPath]: UserType.reducer,
		userState: userReducer,
		layout: layoutReducer,
		location: locationReducer,
		weFindYourJob: weFoundYourJobReducer,
		personalData: personalDataReducer,
		weInvitationVacanciesJob: weInvitateVacanciesJobReducer,
		weRatingJob: weRatedJobReducer,
		typeUser: typeUserReducer,
		professional: professionalReducer,
	},
	devTools: process.env.NODE_ENV === 'development',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat([
			expiredSessionMiddleware,
			authApi.middleware,
			userApi.middleware,
			professionalApi.middleware,
			locationApi.middleware,
			plugNotasApi.middleware,
			plugBoletosApi.middleware,
			personalDataRedux.middleware,
			searchVacanciesRedux.middleware,
			promotion.middleware,
			ratingJob.middleware,
			findYourJob.middleware,
			invitationVacanciesJob.middleware,
			cadNewUser.middleware,
			nodeApi.middleware,
		]),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
