import {
	displayFormBody,
	displayListBody,
	defaultDisplayFields,
	requestedServiceColumns,
	defaultRequestedServiceForm,
} from 'data/utils/ServiceRequest/entity'
import MaterialTable from '../MaterialTable'
import MaterialDialog from '../MaterialDialog'
import { DialogConfig } from 'domain/models/dialog'
import AdditionalServiceForm from '../AdditionalServiceForm'
import { defaultDialogState } from 'data/utils/Dialog/static'
import { useCustomSnackbar } from 'data/hooks/useCustomSnackbar'
import {
	RequestedServiceModel,
	DisplayFields,
} from 'domain/models/requestedServiceModel'

import React, {
	useState,
	useEffect,
	useContext,
	ReactElement,
	createContext,
} from 'react'
import {
	SpeedDial,
	SpeedDialIcon,
	SpeedDialAction,
	TextField,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import {
	useDeleteServiceRequestMutation,
	useGetRequestedServicesQuery,
	usePutServiceRequestMutation,
} from 'store/NodeApi/serviceRequest/endpoint'
import { useGetAdditionalServicesQuery } from 'store/NodeApi/additionalService/endpoint'
import RequestedServiceForm from '../RequestedServiceForm'

const DisplayFormOrList = () => {
	const {
		displayFields,
		requestedServiceForm,
		handeEditServiceForm,
		setRequestedServiceForm,
	} = useContext(ServiceRequestContext)

	const formProps = {
		requestedServiceForm,
		setRequestedServiceForm,
		handeEditServiceForm,
	}

	if (displayFields.requestedServiceForm)
		return <RequestedServiceForm {...formProps} />

	return <ServiceList />
}

const ServiceList = () => {
	const {
		selectedServiceRows,
		serviceFilter,
		handleChangeServiceFilter,
		setSelectedServiceRows,
		requestedServices,
	} = useContext(ServiceRequestContext)

	const tableProps = {
		data: requestedServices,
		columns: requestedServiceColumns,
		selectedRows: selectedServiceRows,
		setSelectedRows: setSelectedServiceRows,
	}

	return (
		<div className="box-container">
			<div className="box-container-header flex-box">
				<AddBoxOutlinedIcon />
				<p>Serviços Adicionais</p>
			</div>
			<TextField
				id="service-filter"
				value={serviceFilter}
				label="Filtrar serviços"
				className="service-filter"
				placeholder="Insira o nome do solicitante/serviço"
				onChange={(e) => handleChangeServiceFilter(e?.target?.value)}
			/>
			<MaterialTable {...tableProps} />
		</div>
	)
}

export const ServiceRequestContext: any = createContext({})

const RequestedServices: React.FC = () => {
	const [dialogConfig, setDialogConfig] =
		useState<DialogConfig>(defaultDialogState)
	const [displayFields, setDisplayFields] =
		useState<DisplayFields>(defaultDisplayFields)

	const [serviceFilter, setServiceFilter] = useState('')
	const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false)
	const [requestedServices, setRequestedServices] = useState<any>([])
	const [requestedServiceForm, setRequestedServiceForm] = useState<any>(
		defaultRequestedServiceForm
	)
	const [selectedServiceRows, setSelectedServiceRows] = useState<number[]>([])

	const { successSnackbar, errorSnackbar } = useCustomSnackbar()

	const handleChangeServiceFilter = (value: string) => {
		setServiceFilter(value)
	}

	const [putServiceRequest] = usePutServiceRequestMutation()
	const [deleteServiceRequest] = useDeleteServiceRequestMutation()
	const { data: apiRequestedServices, refetch: refetchServices } =
		useGetRequestedServicesQuery('')
	const { data: apiAdditionalServices } = useGetAdditionalServicesQuery('')

	const buildServices = (
		apiRequestedServices: any,
		apiAdditionalServices: any,
		serviceFilter: any
	) => {
		const mountedServicesByServiceName = apiRequestedServices?.map(
			(reqService) => {
				const currentService = apiAdditionalServices?.find(
					(service) => service?.id === reqService?.serviceId
				)

				return {
					...reqService,
					service: currentService?.service,
				}
			}
		)

		let servicesToBuild = mountedServicesByServiceName

		const filterByNameOrService = (services) => {
			return services.filter(
				({ service, name }) =>
					service.toLowerCase().includes(serviceFilter.toLowerCase()) ||
					name.toLowerCase().includes(serviceFilter.toLowerCase())
			)
		}

		if (serviceFilter.length > 0)
			return filterByNameOrService(mountedServicesByServiceName)

		return servicesToBuild
	}

	useEffect(() => {
		refetchServices()
	}, [refetchServices])

	useEffect(() => {
		const buildedServices = buildServices(
			apiRequestedServices,
			apiAdditionalServices?.data,
			serviceFilter
		)

		setRequestedServices(buildedServices)
	}, [apiRequestedServices, apiAdditionalServices, serviceFilter])

	const handleCloseDialog = () =>
		setDialogConfig({ ...dialogConfig, open: false })

	const handleOpen = () => setOpenSpeedDial(true)
	const handleClose = () => setOpenSpeedDial(false)

	const handleDisplayTable = () => {
		setDisplayFields(displayListBody)

		handleClose()
	}

	const removeServiceById = (removeId) => {
		return requestedServices.filter((service: any) => service.id !== removeId)
	}

	const getSelectedRowService = (selectedServiceId: number) => {
		return requestedServices.find(
			(service: any) => service.id === selectedServiceId
		)
	}

	const handeEditServiceForm = () => {
		putServiceRequest(requestedServiceForm)
			.unwrap()
			.then(() => {
				refetchServices()
				successSnackbar('Serviço alterado!')
			})
			.catch(() => errorSnackbar('Não foi possível alterar o serviço'))
	}

	const confirmRemoveService = () => {
		const selectedService = selectedServiceRows?.[0]

		deleteServiceRequest(selectedService)
			.unwrap()
			.then(() => {
				successSnackbar('Serviço removido com sucesso')
				setRequestedServices(removeServiceById(selectedService))
				setSelectedServiceRows([])
			})
			.catch((err) => {
				errorSnackbar('Não foi possível remover o serviço')
			})

		setDialogConfig(defaultDialogState)
	}

	const cancelDialogState = {
		open: true,
		onConfirm: confirmRemoveService,
		dialogBodyText: 'Deseja remover a solicitação de serviço?',
	}

	const handleRemoveAction = () => {
		setDialogConfig(cancelDialogState)
	}

	const handleEditAction = () => {
		const selectedService = getSelectedRowService(selectedServiceRows?.[0])

		setRequestedServiceForm(selectedService)
		setDisplayFields(displayFormBody)

		handleClose()
	}

	const dialogProps = {
		open: dialogConfig?.open,
		onClose: handleCloseDialog,
		onConfirm: dialogConfig?.onConfirm,
		dialogBodyText: dialogConfig?.dialogBodyText,
	}

	const getDialActions = (): {
		icon: ReactElement
		name: string
		onClick: any
	}[] => {
		const seeServicesBody = {
			icon: <TableRowsOutlinedIcon />,
			name: 'Ver serviços solicitados',
			onClick: handleDisplayTable,
		}

		const editServiceBody = {
			icon: <EditIcon />,
			name: 'Editar serviço',
			onClick: handleEditAction,
		}
		const deleteServiceBody = {
			icon: <DeleteIcon />,
			name: 'Excluir serviço',
			onClick: handleRemoveAction,
		}

		let selectedBody = [seeServicesBody]

		if (selectedServiceRows?.[0])
			selectedBody = [...selectedBody, editServiceBody, deleteServiceBody]

		return selectedBody
	}

	const contextValues = {
		displayFields,
		requestedServices,
		selectedServiceRows,
		requestedServiceForm,
		handeEditServiceForm,
		setSelectedServiceRows,
		setRequestedServiceForm,
		handleChangeServiceFilter,
	}

	return (
		<ServiceRequestContext.Provider value={contextValues}>
			<MaterialDialog {...dialogProps} />
			<div style={{ display: 'flex', gap: '40px' }}>
				<div className="certificate-toolbar">
					<SpeedDial
						direction="down"
						onOpen={handleOpen}
						open={openSpeedDial}
						onClose={handleClose}
						icon={<SpeedDialIcon />}
						className="speed-dial-container"
						ariaLabel="SpeedDial tooltip example"
					>
						{getDialActions().map((action) => (
							<SpeedDialAction
								key={action.name}
								icon={action.icon}
								onClick={action.onClick}
								tooltipPlacement="right"
								tooltipTitle={action.name}
							/>
						))}
					</SpeedDial>
				</div>
				<DisplayFormOrList />
			</div>
		</ServiceRequestContext.Provider>
	)
}

export default RequestedServices
