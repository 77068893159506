import { useState } from 'react'
import { Tabs, Tab, Box } from '@mui/material'

import {
  AddressTab,
  CurriculumTab,
  DescriptionTab,
  ExperienceTab,
  LanguageTab,
  Operation,
  GeneralDataTab,
} from './components/tabs'
import './styles.scss'
import { Container } from './styles'
import TabPanel from './components/TabPanel'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Role } from 'utils/Enums'

const Profile = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const userRole = useSelector((state: RootState) => state.userState.role)

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {userRole === Role.COMPANY && (
          <Tabs
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value)}
          >
            <Tab label="Perfil Corporativo" value={0} />
            <Tab label="Endereço" value={1} />
            <Tab label="Descrição da Empresa" value={2} />
          </Tabs>
        )}

        {userRole === Role.PROFESSIONAL && (
          <Tabs
            value={selectedTab}
            onChange={(_, value) => {
              setSelectedTab(value)
            }}
          >
            <Tab label="Dados Pessoais" value={0} />
            <Tab label="Endereço" value={1} />
            <Tab label="Atuação" value={2} />
            <Tab label="Experiência" value={3} />
            <Tab label="Idioma" value={4} />
            <Tab label="Currículo" value={5} />
          </Tabs>
        )}
      </Box>

      <TabPanel value={selectedTab} index={0}>
        {<GeneralDataTab />}
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        {<AddressTab />}
      </TabPanel>

      {userRole === Role.PROFESSIONAL && (
        <>
          <TabPanel value={selectedTab} index={2}>
            {<Operation />}
          </TabPanel>

          <TabPanel value={selectedTab} index={3}>
            {<ExperienceTab />}
          </TabPanel>

          <TabPanel value={selectedTab} index={4}>
            {<LanguageTab />}
          </TabPanel>

          <TabPanel value={selectedTab} index={5}>
            {<CurriculumTab />}
          </TabPanel>
        </>
      )}
      {userRole === Role.COMPANY && (
        <TabPanel value={selectedTab} index={2}>
          {<DescriptionTab />}
        </TabPanel>
      )}
    </Container>
  )
}

export default Profile
