import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from '../actions'

export const initialState: any = {
  bankAccounts: [],
  assignorByCnpj: {},
  status: 'idle',
  error: null,
}

export const plugBoletosSlice = createSlice({
  name: 'plugNotas',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    setBankAccounts: (state, action: PayloadAction<any>) => {
      state.bankAccounts = action?.payload
    },
    setAssignorByCnpj: (state, action: PayloadAction<any>) => {
      state.assignorByCnpj = action?.payload
    },
  },
})

export const { setBankAccounts, setAssignorByCnpj } = plugBoletosSlice.actions

export default plugBoletosSlice.reducer
