const JobAttribute = ({ name, value }: JobAttributeProps) => {
    return (
        <div className="job-attribute">
            <span className="name">{name}:</span>
            <span>{value}</span>
        </div>
    )
}

interface JobAttributeProps {
    name: string
    value: React.ReactNode
}

export default JobAttribute