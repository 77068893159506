import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { NOTAS_API_URI, X_API_KEY } from 'utils/Constants'

const plugNotasApi = createApi({
  reducerPath: 'plugNotasApi',
  tagTypes: ['CERTIFICATE'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${NOTAS_API_URI}`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-api-key', X_API_KEY)

      return headers
    },
  }),
  endpoints: () => ({}),
})

export default plugNotasApi
