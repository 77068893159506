import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'
import { Button, TextareaAutosize } from '@mui/material'
import { useFormik } from 'formik'
import FileSaver from 'file-saver'

import validationSchema from './validations'
import '../../../styles.scss'
import { INITIAL_VALUES } from './constants'
import { CurriculumTabRef } from './types'
import { isEmpty } from 'lodash'

const CurriculumTab = (_, ref: React.Ref<CurriculumTabRef>) => {
  const inputRef = useRef<any>()

  const openInput = () => {
    inputRef.current.click()
  }

  const downloadFile = async () => {
    if (!values.curriculo_doc) return

    FileSaver.saveAs(values.curriculo_doc, values.curriculo_doc.name)
  }

  const { values, handleChange, setFieldValue, errors, validateForm } =
    useFormik({
      validationSchema,
      enableReinitialize: true,
      initialValues: INITIAL_VALUES,
      onSubmit: () => {},
      validateOnChange: false,
      validateOnBlur: false,
    })

  const validate = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        validateForm().then((errors) => {
          if (isEmpty(errors)) resolve()
          else reject()
        })
      }),
    [validateForm]
  )

  useImperativeHandle(ref, () => ({ validate, values }), [validate, values])

  return (
    <div className="horizontal">
      <TextareaAutosize
        placeholder="Copie e cole seu currículo. Este campo é muito importante para a análise dos recrutadores"
        style={{
          width: '100%',
          resize: 'vertical',
          borderColor: errors.descricao ? 'red' : '',
        }}
        minRows={7}
        value={values.descricao}
        onChange={handleChange}
        name="descricao"
      />

      {errors.descricao && (
        <label className="helper-box" style={{ minWidth: '100%' }}>
          {`${errors.descricao}`}
        </label>
      )}

      <div
        style={{
          marginBottom: '1rem',
          minWidth: '100%',
        }}
      />

      <Button
        onClick={openInput}
        variant="outlined"
        color="primary"
        style={{ minWidth: 200 }}
      >
        Enviar Arquivo (.pdf, .doc ou .docx)
      </Button>

      {values.curriculo_doc && (
        <Button
          onClick={downloadFile}
          variant="outlined"
          color="primary"
          style={{ marginLeft: 20 }}
        >
          Baixar arquivo de currículo enviado
        </Button>
      )}

      <input
        type="file"
        accept=".pdf, .doc, .docx"
        id="button-input-file"
        className="no-hidden"
        onChange={(event) => {
          if (!event.target.files) return

          setFieldValue('curriculo_doc', event.target.files[0])
        }}
        ref={inputRef}
      />
    </div>
  )
}

export default forwardRef(CurriculumTab)

export type { CurriculumTabRef }
