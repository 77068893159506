import {
	Box,
	Collapse,
	IconButton,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material'
import { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment from 'moment'
import { JobResponse } from 'types'
import { ApprovedQtyApplicants } from './styles'
import ApprovedApplicantsModal from './ApprovedApplicantsModal'
import { endOfDay, isAfter, parseISO, sub } from 'date-fns'

interface RowProps {
	projeto: JobResponse
}

const Row = ({ projeto }: RowProps) => {
	const [open, setOpen] = useState(false)
	const [isProfessionalListOpen, setIsProfessionalListOpen] = useState(false)
	const projectIsOff = !!projeto.data_final && !isAfter(endOfDay(parseISO(projeto.data_final)), new Date());

	if (!projeto?.numero_profissionais_alocados || projectIsOff) return null

	const STATUS = {
		E: 'Em Andamento',
		A: 'Aprovado',
		F: 'Finalizado',
		P: 'Pendente',
	}

	const status = STATUS[projeto.status] || '...'

	const handleOpenProfessionalList = () => setIsProfessionalListOpen(true)
	const handleCloseProfessionalList = () => setIsProfessionalListOpen(false)

	return (
		<>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton
						aria-label="expand projeto"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="projeto">
					{projeto.nome}
				</TableCell>
				<TableCell>{moment(projeto.data_cad).format('DD/MM/yyyy')}</TableCell>
				<TableCell>
					{moment(projeto.data_inicio).format('DD/MM/yyyy')}
				</TableCell>
				<TableCell>
					{moment(projeto.limite_submissao).format('DD/MM/yyyy')}
				</TableCell>
				<TableCell>{!!projeto.data_final ? moment(projeto.data_final).format('DD/MM/yyyy') : 'Indeterminado'}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Typography variant="subtitle2" gutterBottom component="div">
								Detalhe:
							</Typography>
							<Typography variant="subtitle2" gutterBottom component="div">
								Profissionais aprovados:{' '}
								<ApprovedQtyApplicants
									disabled={!projeto?.numero_profissionais_alocados}
									onClick={handleOpenProfessionalList}
									hasApproved={!!projeto?.numero_profissionais_alocados}
								>
									{projeto?.numero_profissionais_alocados}
								</ApprovedQtyApplicants>
							</Typography>
							<Typography variant="subtitle2" gutterBottom component="div">
								Status: {status}
							</Typography>
							<Typography variant="subtitle2" gutterBottom component="div">
								Tipo de contratação: {projeto.tipo_contratacao}
							</Typography>
							<Typography variant="subtitle2" gutterBottom component="div">
								Quantidade de profissionais: {projeto.numero_profissionais}
							</Typography>
							<Typography variant="subtitle2" gutterBottom component="div">
								Taxa hora:{' '}
								{projeto.pagamento === '' ? 'Não informada' : projeto.pagamento}
							</Typography>
							<Typography variant="subtitle2" gutterBottom component="div">
								Estado:{' '}
								{projeto.estado === '' ? 'Não informada' : projeto.estado} |
								Cidade:{' '}
								{projeto.cidade === '' ? 'Não informada' : projeto.cidade}
							</Typography>
							<Typography variant="subtitle2" gutterBottom component="div">
								Remoto: {projeto.alocacao_remota === true ? 'Sim' : 'Não'}
							</Typography>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
			{isProfessionalListOpen && (
				<ApprovedApplicantsModal
					projectHashcode={projeto?.hashcod}
					onClose={handleCloseProfessionalList}
				/>
			)}
		</>
	)
}

export default Row
