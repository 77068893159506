import locationApi from './api'
import { BrazilCityModel, BrazilStateModel } from './types'

const injectedLocationApi = locationApi.injectEndpoints({
  endpoints: (build) => ({
    getBrazilStates: build.query<BrazilStateModel[], string>({
      query: () => '/localidades/estados',
    }),
    getBrazilCitiesByState: build.query<BrazilCityModel[], string>({
      query: (code) => `/localidades/estados/${code}/municipios`,
    }),
  }),
})

export const {
  useGetBrazilStatesQuery,
  useGetBrazilCitiesByStateQuery,
  useLazyGetBrazilCitiesByStateQuery,
  useLazyGetBrazilStatesQuery,
} = injectedLocationApi

export default injectedLocationApi
