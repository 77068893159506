import { MenuItem, Select } from '@mui/material'
import { STATUS } from '../../constants'

interface StatusSelectProps {
  onChangeStatus: (value: string) => void
  status: string
}

const StatusSelect = ({ onChangeStatus, status }: StatusSelectProps) => {
  return (
    <Select
      displayEmpty
      style={{ marginLeft: '2rem' }}
      value={status}
      size="small"
      onChange={(event) => onChangeStatus(event.target.value)}
    >
      {Object.keys(STATUS).map((status) => (
        <MenuItem value={status}>{STATUS[status]}</MenuItem>
      ))}
    </Select>
  )
}

export default StatusSelect
