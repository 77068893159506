import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setLoading } from 'store/Layout/slice'
import DataTable from './DataTable'
import { useDiscriminitadedProjects } from 'hooks/useDiscriminatedProjects'

const HuntingProcess = () => {
	const dispatch = useDispatch()
	const { data, isError, refetch, isFetching } = useDiscriminitadedProjects();
	useEffect(() => {
		dispatch(setLoading(isFetching))

		return () => {
			dispatch(setLoading(false))
		}
	}, [dispatch, isFetching])

	if (isError) return <div>falha ao carregar oportunidades</div>

	if (!data) return <div>carregando</div>

	return <DataTable data={data.published} refetchData={refetch} />
}

export default HuntingProcess
