import { store } from 'store'
import nodeApi from '../api'
import { setAdditionalServices } from '../slice'

export type AdditionalService = {
	id: number
	value: string
	service: string
	payment_description: string
	description: string
	created_at: string
	updated_at: string
	average_delivery_time: string
}

const injectedNodeApi = nodeApi.injectEndpoints({
	endpoints: (build) => ({
		postAdditionalService: build.mutation<any, any>({
			query: (additionalService) => ({
				method: 'POST',
				url: '/additionalServices',
				body: additionalService,
			}),
			transformResponse: (response: any) => response?.data,
			invalidatesTags: ['ADDITIONAL_SERVICES']
		}),
		putAdditionalService: build.mutation<any, any>({
			query: (updatedAdditionalService) => ({
				method: 'PUT',
				url: `/additionalServices/${updatedAdditionalService?.id}`,
				body: updatedAdditionalService,
			}),
			transformResponse: (response: any) => response?.data,
			invalidatesTags: ['ADDITIONAL_SERVICES']
		}),
		getAdditionalServices: build.query<{ data: AdditionalService[] }, any>({
			providesTags: ['ADDITIONAL_SERVICES'],
			query: () => `/additionalServices`,
		}),
		deleteAdditionalService: build.mutation<any, any>({
			query: (additionalServiceId) => ({
				method: 'DELETE',
				url: `/additionalServices/${additionalServiceId}`,
			}),
			transformResponse: (response: any) => response?.data,
			invalidatesTags: ['ADDITIONAL_SERVICES']
		}),
	}),
})

export const {
	useGetAdditionalServicesQuery,
	usePutAdditionalServiceMutation,
	usePostAdditionalServiceMutation,
	useDeleteAdditionalServiceMutation,
} = injectedNodeApi

export default injectedNodeApi
