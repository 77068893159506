import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  APP_STORAGE_PREFIX,
  APP_API_URI,
  PROMOTION,
} from '../../utils/Constants'
import { StorageGetItem } from '../../utils/Storage'

export const promotion = createApi({
  reducerPath: `promotion`,
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_API_URI}`,
    prepareHeaders: (headers, { getState }) => {
      const token = JSON.parse(StorageGetItem(`${APP_STORAGE_PREFIX}authToken`))

      if (token) {
        headers.set('Authorization', `JWT ${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    getPromotion: builder.query({
      query: () => `${PROMOTION}`,
    }),
  }),
})

export const { useGetPromotionQuery } = promotion
