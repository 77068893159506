import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from '../actions'

interface PlanUserState {
  plano?: number
  planoApplyJobQtd?: number
}

export const initialState: PlanUserState = {
  plano: 0,
  planoApplyJobQtd: 0,
}

export const PersonalDataRedux = createSlice({
  name: 'personalDataRedux',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    setPlanUserState: (state, action: PayloadAction<number>) => {
      state.plano = action?.payload
    },
    setPlanUserApllyQtdState: (state, action: PayloadAction<number>) => {
      state.planoApplyJobQtd = action?.payload
    },
  },
})

export const { setPlanUserState, setPlanUserApllyQtdState } =
  PersonalDataRedux.actions

export default PersonalDataRedux.reducer
