import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectProps,
  Select as MUISelect,
  Typography,
} from '@mui/material'
import './styles.scss'

type MenuItemShape = {
  label: string
  value: string
}

type Props = {
  list: MenuItemShape[]
  helperText?: string
} & SelectProps

const error_color = '#D32F2F'

export const Select = ({ list, helperText, ...props }: Props) => (
  <FormControl fullWidth>
    <InputLabel
      id="label"
      className="MuiInputLabel-root"
      style={{ color: !!helperText ? error_color : '' }}
    >
      {props.label}
    </InputLabel>
    <MUISelect
      error={!!helperText}
      variant="standard"
      value={props.value}
      onChange={props.onChange}
      labelId="label"
      fullWidth
      name={props.name}
      label={props.label}
    >
      {list.map((invoice) => (
        <MenuItem key={invoice.value} value={invoice.value}>
          {invoice.label}
        </MenuItem>
      ))}
    </MUISelect>
    {!!helperText && (
      <Typography
        color={error_color}
        fontSize="0.75rem"
        fontWeight={400}
        lineHeight={1.66}
        marginTop={0.5}
      >
        {helperText}
      </Typography>
    )}
  </FormControl>
)
