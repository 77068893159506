import { RootState } from '../index'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LayoutModel } from '../../utils/Interfaces'
import routes, { SidebarRoutes } from '../../utils/Constants/routes'
import { clearState } from '../actions'

export const defaultSideBarState = {
  open: false,
  selected: routes.HOME,
}

export const initialState: LayoutModel = {
  sideBar: defaultSideBarState,
  loading: false,
}

export const layoutSlice = createSlice({
  name: 'JOB-Connect/layout',
  initialState,
  extraReducers: (builder) => builder.addCase(clearState, () => initialState),
  reducers: {
    showSideBar: (state, action: PayloadAction<boolean>) => {
      state.sideBar.open = action?.payload
    },
    setSelectedSideBarTab: (state, action: PayloadAction<SidebarRoutes>) => {
      state.sideBar.selected = action?.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action?.payload
    },
  },
})

export const { showSideBar, setSelectedSideBarTab, setLoading } =
  layoutSlice.actions

export const selectSideBarState = (state: RootState) => state.layout.sideBar

export default layoutSlice.reducer
