import styled from 'styled-components'
import MUIMarkunreadIcon from '@material-ui/icons/Markunread'
import MUICheckCircleIcon from '@material-ui/icons/CheckCircle'
import MUICancelIcon from '@material-ui/icons/Cancel'
import MUIExitToAppIcon from '@material-ui/icons/ExitToApp'
import MUIRemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

const ICON_SIZE = 60

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div<{ hasOnClick?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 3rem;
  width: 120px;
  cursor: ${(props) => (props.hasOnClick ? 'pointer' : 'initial')};
`

export const MarkunreadIcon = styled(MUIMarkunreadIcon)<{ iconColor: string }>`
  width: ${ICON_SIZE}px !important;
  height: ${ICON_SIZE}px !important;
  color: ${(props) => props.iconColor};
`

export const CheckCircleIcon = styled(MUICheckCircleIcon)<{
  iconColor: string
}>`
  width: ${ICON_SIZE}px !important;
  height: ${ICON_SIZE}px !important;
  color: ${(props) => props.iconColor};
`

export const CancelIcon = styled(MUICancelIcon)<{ iconColor: string }>`
  width: ${ICON_SIZE}px !important;
  height: ${ICON_SIZE}px !important;
  color: ${(props) => props.iconColor};
`

export const ExitToAppIcon = styled(MUIExitToAppIcon)<{ iconColor: string }>`
  width: ${ICON_SIZE}px !important;
  height: ${ICON_SIZE}px !important;
  color: ${(props) => props.iconColor};
`

export const RemoveCircleOutlineIcon = styled(MUIRemoveCircleOutlineIcon)<{
  iconColor: string
}>`
  width: ${ICON_SIZE}px !important;
  height: ${ICON_SIZE}px !important;
  color: ${(props) => props.iconColor};
`
