import styled from 'styled-components'

export const SelectExperienceInfo = styled.div`
  margin-top: 30px;
  font-size: 20px;
  color: rgba(41, 50, 65, 0.5);
  font-weight: 500; ;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
  margin-top: 15px;
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  height: 158px;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
`
export const ColumnDivider = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`
export const RowDivider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const CardTextInfos = styled.div`
  display: flex;
`
